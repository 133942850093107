import TitleAtom from "components/atoms/Title"
import { t } from "../translation/Translation"
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { useEffect, useState } from "react";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputFieldAndUnit } from "../templates/atoms/LabelWithInputFieldAndUnit";
import BackButton from "components/atoms/BackButton";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { CREATE_COMPANY_PAYROLL_CONFIG, GET_PARTICULAR_CONFIG_DATA } from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import { validateAmount, validateForm, validateNumber, validateRequired, validateSelectField } from "services/validation/ValidationService";
import { PATH_MANAGE_PAYROLL_CONFIG } from "constants/Paths";

interface OverTimeConstant {
    field: string;
    dependencyField: string;
    rules: any[];  // Adjust type if needed
}

const CreatePayroll = () => {
    const { id: routeId } = useParams();
    const [formData, setFormData] = useState<any>({
        company: routeId,
        dailyLimit: '',
        weeklyLimit: '',
        overTime: {
            percentage: '',
            hasOverTime: false,
        },
        advHours: '',
        overtimeOnSaturdays: {
            percentage: '',
            hasOverTime: false
        },
        overtimeOnSundays: {
            percentage: '',
            hasOverTime: false
        },
    });

    const navigate = useNavigate();

    const overTimeConstants: Record<string, OverTimeConstant> = {
        overTime: { field: 'percentage', dependencyField: 'hasOverTime', rules: [validateNumber] },
        overtimeOnSaturdays: { field: 'percentage', dependencyField: 'hasOverTime', rules: [validateNumber] },
        overtimeOnSundays: { field: 'percentage', dependencyField: 'hasOverTime', rules: [validateNumber] },
    }

    //Get validation rules based on the dependent field is selected or not
    const getValidationRules = (entityType: string | null = null) => {
        const rules: any = {};
        const formValues: any = {};

        for (const [key, value] of Object.entries(overTimeConstants)) {
            if (formData[key][value['dependencyField']]) {
                rules[key] = value.rules;
                formValues[key] = formData[key][value['field']];
            }
        }
        return { rules, formValues };
    }

    const [formError, setErrors] = useState({
        dailyLimit: '',
        weeklyLimit: '',
        advHours: '',
        overTime: '',
        overtimeOnSaturdays: '',
        overtimeOnSundays: '',
    });

    useEffect(() => {
        getPayrollConfigData();
    }, []);

    const getPayrollConfigData = async () => {
        try {
            const response = await ApiCall.service(
                GET_PARTICULAR_CONFIG_DATA,
                "POST",
                { id: routeId },
                true,
                M_MASTER_DATA,
            );
            if (response?.status == 200) {
                setFormData((prevState: any) => ({ ...prevState, ...response?.data[0] }))
            }
        }
        catch (Error) {
            console.log(Error);
        }
    }

    const handleSelectChange = (event: any, name: string, isValidate: boolean) => {
        setFormData((prevState: any) => ({ ...prevState, [name]: event }));
        if (isValidate) {
            validationFunction(name, event, true);
        }
    }

    const handleChange = (event: any, isValidate: boolean, entityType: string | null = null) => {
        const { name, value, type, checked } = event?.target;
        let postData: any = {};
        let fieldValue = type == 'checkbox' ? checked : value;

        if (entityType) {
            postData = { ...formData, [entityType]: { ...formData?.[entityType], [name]: fieldValue } }
        }
        //setFormData((prevState: any) => ({ ...prevState, [name]: event }));
        else {
            postData = { ...formData, [name]: fieldValue };
        }
        setFormData(postData);
        if (isValidate) {
            validationFunction(name, value, true, entityType, overTimeConstants);
        }
    }

    const validationRules: any = {
        dailyLimit: [validateRequired, validateAmount],
        weeklyLimit: [validateRequired, validateAmount],
        advHours: [validateRequired, validateAmount],
    }

    const validationFunction = (
        name: any = null,
        value: any = null,
        isSingleFieldValidation = false,
        entityType: any = null,
        rulesConstants: any = null,
    ) => {
        const validationErrors: any = validateForm(
            entityType ? { [entityType]: value } : (isSingleFieldValidation ? { ...formData, [name]: value } : { dailyLimit: formData?.dailyLimit, weeklyLimit: formData?.weeklyLimit, advHours: formData?.advHours, ...getValidationRules()?.formValues }),
            (entityType ? (formData[entityType][rulesConstants[entityType]['dependencyField']] == true ? { [entityType]: rulesConstants[entityType]['rules'] } : {}) : (isSingleFieldValidation ? validationRules[name] : { ...validationRules, ...getValidationRules()?.rules }))
        );

        if (isSingleFieldValidation) {
            setErrors((prevErrors: any) => {
                const fieldName = entityType ? entityType : name;
                if (validationErrors.hasOwnProperty(fieldName)) {
                    return { ...prevErrors, [fieldName]: validationErrors[fieldName] };
                }
                const { [fieldName]: ty, ...remains } = prevErrors;
                return { ...remains };
            });
        } else {
            setErrors(validationErrors);
            if (Object.keys(validationErrors).length > 0) {
                return false;
            } else {
                return true;
            }
        }
    };

    const handleSubmit = async () => {
        try {
            if (validationFunction()) {
                const response = await ApiCall.service(
                    CREATE_COMPANY_PAYROLL_CONFIG,
                    'POST',
                    formData,
                    true,
                    M_MASTER_DATA
                );
                if (response?.status == 200) {
                    CustomNotify({ type: "success", message: response?.message });
                    navigate(PATH_MANAGE_PAYROLL_CONFIG);
                }
                else {
                    CustomNotify({ type: "error", message: response?.message });
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="search-bar">
                <div className="row">
                    <TitleAtom
                        title={routeId ? t("Edit payroll config") : t("Create payroll config")}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="form-border">
                        <div className="name-text projectName marginBotttom1">{t("Company name")}: {formData?.companyName}</div>
                        <div className="row">
                            {/* <div className="col-4">
                                <SelectWithSearch
                                    onChange={(event) => { handleSelectChange(event, 'company', true) }}
                                    title={t("Company")}
                                    placeHolder={t("Company")}
                                    search={true}
                                    options={options?.companies}
                                    value={formData?.company}
                                    isDisabled={false}
                                    isMulti={true}
                                    isMandatory={true}
                                    error={formError?.company}
                                    name={"company"}
                                    id={"company"}
                                />
                            </div> */}
                            <div className="col-4">
                                <LabelWithInputField
                                    type={"text"}
                                    placeholder={t("Enter daily limit")}
                                    handleChange={(event) => { handleChange(event, true) }}
                                    label={t("Daily limit")}
                                    value={formData?.dailyLimit}
                                    readOnly={false}
                                    isDisabled={false}
                                    isMandatory={true}
                                    error={formError?.dailyLimit}
                                    name={"dailyLimit"}
                                    id={"dailyLimit"}
                                />
                            </div>
                            <div className="col-4">
                                <LabelWithInputField
                                    type={"text"}
                                    placeholder={t("Adv hours")}
                                    handleChange={(event) => { handleChange(event, true) }}
                                    label={t("Adv hours")}
                                    value={formData?.advHours}
                                    readOnly={false}
                                    isDisabled={false}
                                    isMandatory={true}
                                    error={formError?.advHours}
                                    name={"advHours"}
                                    id={"advHours"}
                                />
                            </div>
                            <div className="col-4">
                                <LabelWithInputField
                                    type={"text"}
                                    placeholder={t("Enter weekly limit")}
                                    handleChange={(event) => { handleChange(event, true) }}
                                    label={t("Weekly limit")}
                                    value={formData?.weeklyLimit}
                                    readOnly={false}
                                    isDisabled={false}
                                    isMandatory={true}
                                    error={formError?.weeklyLimit}
                                    name={"weeklyLimit"}
                                    id={"weeklyLimit"}
                                />
                            </div>
                            <div className="col-3">
                                <CheckBoxField
                                    label={t(
                                        "Overtime"
                                    )}
                                    name={"hasOverTime"}
                                    id={"hasOverTime"}
                                    isChecked={formData?.overTime?.hasOverTime}
                                    onChangeHandler={(event) => { handleChange(event, false, 'overTime') }}
                                    lineHeight="1.6vw"
                                />
                                {formData?.overTime?.hasOverTime && (
                                    <LabelWithInputFieldAndUnit
                                        type="text"
                                        placeholder="0.00%"
                                        handleChange={(event) => { handleChange(event, true, 'overTime') }}
                                        label={t("")}
                                        value={formData?.overTime?.percentage}
                                        name={'percentage'}
                                        id={'percentage'}
                                        isMandatory={false}
                                        error={formError?.overTime}
                                        min={0}
                                        unit={"%"}
                                        containerClassName="col-12"
                                    />
                                )}
                            </div>
                            <div className="col-3">
                                <CheckBoxField
                                    label={t(
                                        "Works on saturdays"
                                    )}
                                    name={"hasOverTime"}
                                    id={"workonSaturday"}
                                    isChecked={formData?.overtimeOnSaturdays?.hasOverTime}
                                    onChangeHandler={(event) => { handleChange(event, false, 'overtimeOnSaturdays') }}
                                    lineHeight="1.6vw"
                                />
                                {formData?.overtimeOnSaturdays?.hasOverTime && (
                                    <LabelWithInputFieldAndUnit
                                        type="text"
                                        placeholder="0.00%"
                                        handleChange={(event) => { handleChange(event, true, 'overtimeOnSaturdays') }}
                                        label={t("")}
                                        value={formData?.overtimeOnSaturdays?.percentage}
                                        name={'percentage'}
                                        id={'percentage'}
                                        isMandatory={false}
                                        error={formError?.overtimeOnSaturdays}
                                        min={0}
                                        unit={"%"}
                                        containerClassName="col-12"
                                    />
                                )}

                            </div>
                            <div className="col-3">
                                <CheckBoxField
                                    label={t(
                                        "Works on sunday and public holidays"
                                    )}
                                    name={"hasOverTime"}
                                    id={"workOnPublicHoliday"}
                                    isChecked={formData?.overtimeOnSundays?.hasOverTime}
                                    onChangeHandler={(event) => { handleChange(event, false, 'overtimeOnSundays') }}
                                    lineHeight="1.6vw"
                                />
                                {formData?.overtimeOnSundays?.hasOverTime && (
                                    <LabelWithInputFieldAndUnit
                                        type="text"
                                        placeholder="0.00%"
                                        handleChange={(event) => { handleChange(event, true, 'overtimeOnSundays') }}
                                        label={t("")}
                                        value={formData?.overtimeOnSundays?.percentage}
                                        name={'percentage'}
                                        id={'percentage'}
                                        isMandatory={true}
                                        error={formError?.overtimeOnSundays}
                                        min={0}
                                        unit={"%"}
                                        containerClassName="col-12"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between backPadding align-items-center">
                <div className="">
                    <BackButton />
                </div>

                <div className="">
                    <Button
                        title={t("Save")}
                        type={"button"}
                        handleClick={handleSubmit}
                        className={"form-button float-end"}
                    />
                </div>
            </div>
        </>
    )
}
export default CreatePayroll
