import { useTImesheetContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import WorkTimings from "../atoms/workTimings";
import Timings from "../atoms/timings";

const Work = () => {
  const { initialTimesheetState } = useTImesheetContext();
  let timesheet_data = initialTimesheetState.timesheet_data ? initialTimesheetState.timesheet_data : [];
  return (
    <>
      <Timings
        planTimingsData={timesheet_data}
        header1="Worksheet"
        header2="Start time"
        total=""
        isDisable={initialTimesheetState?.isDisabled}
        name="start_time"
      />

      <Timings
        planTimingsData={timesheet_data}
        header1=""
        header2="End time"
        total=""
        isDisable={initialTimesheetState?.isDisabled}
        name="end_time"
      />

      <WorkTimings
        planTimingsData={timesheet_data}
        header1=""
        header2="Pause"
        isDisable={initialTimesheetState?.isDisabled}
        name="pause_time"
      />

      <WorkTimings
        planTimingsData={timesheet_data}
        header1=""
        header2="Total"
        isDisable={true}
        type="text"
        name="totalHours"
      />
    </>
  );
};

export default Work;
