import LabelField from "components/atoms/LabelField";
import MonthPicker from "components/atoms/MonthPicker";
import React, { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import Button from "components/atoms/Button";
import ArrowsIcon from "static/images/Arrows";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import DatePicker from "react-multi-date-picker";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { FormDataProps, initialAddPartenaData } from "./PartenaInterface";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { mapToSelect } from "utils/MapToSelect";
import { OptionProps, ValidationRules } from "utils/TypeAnnotations";
import { PATH_MANAGE_PARTENA } from "constants/Paths";
import { validateForm, validateMultiSelectField, validateRequired } from "services/validation/ValidationService";

const AddPartenaCalculation = () => {
  const navigate = useNavigate();
  const user = useSelector(selectAuth);
  const [formData, setFormData] = useState<FormDataProps>(initialAddPartenaData);
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);
  const [businessUnits, setBusinessUnits] = useState<OptionProps[]>();
  const [initialBUnits, setInitialBUnits] = useState<OptionProps[]>([]);
  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);
  const [buError, setBuError] = useState("");

  useEffect(() => {

    fetchBusinessUnits();
  }, []);
  const fetchBusinessUnits = async () => {
    const response = await ApiCall.getService("getBusinessUnits", "get", M_MASTER_DATA, true);
    const bunits = mapToSelect(response.data);
    setInitialBUnits(bunits);
    setBusinessUnits(bunits);

  }

  const validation = (
    name: string,
    value: string | number | OptionProps[] | null | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      month: [validateRequired],
    };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const onChange = (selectedOption: OptionProps, field: string) => {
    setFormData((prevData: any) => {
      if (field === "businessUnit") {
        if (selectedOption?.value) {
          setBuError("");
        }
        const exists = prevData.businessUnits.some((unit: OptionProps) => unit?.value === selectedOption?.value);
        const filteredBusinessUnits = businessUnits?.filter((record: object) => record !== selectedOption);
        setBusinessUnits(filteredBusinessUnits);
        if (exists) {
          const filteredAssignedBUs = prevData.businessUnits.filter((record: object) => record !== selectedOption);
          setFormData((prevData: FormDataProps) => ({ ...prevData, businessUnits: filteredAssignedBUs }));
          setBusinessUnits([...(businessUnits ?? []), selectedOption]);
          return prevData;
        } else {
          return {
            ...prevData,
            businessUnits: [...prevData.businessUnits, selectedOption]
          };
        }
      } else if (field === "month") {
        validation(field, selectedOption?.value, true);
        return {
          ...prevData,
          month: selectedOption?.value
        };
      }
      return prevData; // Default case if field is not recognized
    });

  };

  const handleCheckBoxChange = () => {
    const newStatus: boolean = !checkBoxStatus;
    setCheckBoxStatus(newStatus);
    const businessUnit = newStatus ? initialBUnits : [];
    if (businessUnit.length > 0) {
      setBusinessUnits([]);
    } else {
      setBusinessUnits(initialBUnits);
    }
    setFormData((prevData: FormDataProps) => ({ ...prevData, businessUnits: businessUnit } as FormDataProps));

  }

  const handleSubmit = async (e: React.FormEvent, reset: boolean) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;

    if (formData?.businessUnits?.length === 0) {
      setBuError("Please select at least one business unit");
    }

    if (validation(name, value)) {

      if (reset) {
        setFormData((prevData) => ({ ...prevData, businessUnits: [], month: "" }))
      }
      const postData = {
        ...formData,
        createdBy: user?.userId,
        name: user?.name
      };

      const response = await ApiCall.service("storePartenaCalculation", "POST", postData, true, M_MASTER_DATA);

      if (response.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        navigate(`${PATH_MANAGE_PARTENA}?type=addPartenaCalculation`);
      }
      setErrors({});
    }
  };

  const handleDateChange = (date: any, name: any) => {
    const value = date.year;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="row">
      <div className="col partenaMonthPicker">
        <MonthPicker
          label={t("Choose a month")}
          name="month"
          handleChange={(value) => onChange(value, "month")}
          value={formData.month}
          error={errors.month}
        />
      </div>
      <div className="col">
        <div className="row">
          <LabelField title={t("Choose a year")} />
        </div>
        <div className="row publicHolidayYear">
          <DatePicker
            inputClass="form-control field-shadow"
            name="date"
            placeholder={new Date().getFullYear().toString()}
            onChange={(e) => handleDateChange(e, "year")}
            format="YYYY"
            onlyYearPicker={true}
            minDate={new Date(2014, 0, 1)}
            maxDate={new Date(new Date().getFullYear(), 11, 31)}
            zIndex={1000}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="border border-dark cursor-pointer flex-1" style={{ width: "45%" }}>
          <div className="borderBottomStyle">{t("Available business unit")}</div>
          {businessUnits && businessUnits.length > 0 &&
            businessUnits.map((record: any, index) => (
              <div onClick={() => onChange(record, "businessUnit")} key={index} className="businessUnitLabel">{record.label}</div>
            ))}
        </div>
        <div className="d-flex align-items-center justify-content-center" title={t("Arrow")} style={{ width: "10%" }}>
          <ArrowsIcon />
        </div>
        <div className="border border-dark cursor-pointer flex-1" style={{ width: "45%" }}>
          <div className="borderBottomStyle">{t("Assigned business unit")}</div>
          {formData?.businessUnits && formData?.businessUnits?.length > 0 &&
            formData?.businessUnits.map((record: any, index) => (
              <div onClick={() => onChange(record, "businessUnit")} key={index} className="businessUnitLabel">{record.label}</div>
            ))}
        </div>
      </div>
      <div className="text-danger">
        {buError}
      </div>
      <div className="my-3">
        <CheckBoxField
          label={t("Select all")}
          name="selectAll"
          isChecked={checkBoxStatus}
          onChangeHandler={() => handleCheckBoxChange()}
          id="selectAll"
        />
      </div>
      <div className=" d-flex justify-content-end">
        <Button
          title={t("Cancel")}
          handleClick={(e) => handleSubmit(e, true)}
          className={"delete-btn marginRightPoint5 shadow-none"}
        />
        <Button
          title={t("Save")}
          handleClick={(e) => handleSubmit(e, false)}
          className={"form-button shadow-none"}
        />
      </div>
    </div>
  );
}

export default AddPartenaCalculation;