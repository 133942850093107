import React, { useState, useEffect, useRef } from "react";
// import RequiredIcon from "../../static/icons/exclamation-mark1.png";
import TimePicker from "../atoms/TimePicker";
import LabelField from "../atoms/LabelField";

interface TimeInputProps {
    setTime: (value: string, type: string, index: number) => void;
    index: number;
    type: string;
    value: string;
    title: string;
    customStyle?: string;
    isMandatory?: boolean;
    error?: string;
    styleMargin?: string;
    minuteStep?:number;
    isDisabled?:boolean;
}

const TimeInputMolecule: React.FC<TimeInputProps> = ({
    setTime,
    index,
    type,
    value,
    title,
    customStyle,
    isMandatory,
    error,
    styleMargin,
    minuteStep,
    isDisabled
}) => {
    const timePickerRef = useRef<HTMLDivElement>(null);

    return (
        <div className={"col-12 position-relative title-position font-weight-bold " + customStyle}>
            <div className={"d-flex justify-content-between " + (error ? "" : styleMargin)}>
                <LabelField title={title} isMandatory={isMandatory}/>
            </div>
            <div className="col-12 todoTimePicker" ref={timePickerRef}>
                <TimePicker
                    hour={value ? value.split(":")[0] : "00"}
                    minute={value ? value.split(":")[1] : "00"}
                    index={index}
                    type={type}
                    setHourMin={setTime}
                    value={value}
                    minuteStep={minuteStep}
                    isDisabled={isDisabled}
                />
            </div>
            <div>
                {error && (
                    <p className="pt-1 pb-0 px-4 m-1 text-danger required-bg rounded font-weight-normal">
                        {error}
                    </p>
                )}
            </div>
        </div>
    );
};

export default TimeInputMolecule;
