import React, { Suspense, useState, useEffect, useRef } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { M_MASTER_DATA } from "constants/Constants";
import { CLIENTS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import ManageClient from "./ManageClient";

// Define the prop types for the component
interface ClientProps {
    id?: string | number;
    dispatch: any;
    history: any;
}

// Functional component using TypeScript
const Client: React.FC<ClientProps> = ({history, dispatch}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const [clientsData, setClientsData] = useState([]);
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        initialCall: true,
        refresh: true,
    });
    const getSearchData = () => {
        return {
            typeName: history?.filters?.typeName ?? "",
            name: history?.filters?.name ?? "",
            vatNumber: history?.filters?.vatNumber ?? "",
            email: history?.filters?.email ?? "",
            status: history?.filters?.status ?? "",
            orderBy: history?.filters?.orderBy ?? "id",
            sortDirection: history?.filters?.sortDirection ?? "desc",
        }
    };

    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
            type: "client",
            search: getSearchData(),
        };
        getClientsData(postData);
    }, [pageData?.initialCall, pageData?.refresh]);

    const getClientsData = async (postData: any) => {
        const response = await ApiCall.service(
            CLIENTS,
            "POST",
            postData,
            false,
            M_MASTER_DATA
        );
        if (response?.status === 200) {
            setClientsData(response?.data?.data);
            setPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords }))
        } else {
            console.log("error");
        }
    };
    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    return (
        <>
            <Suspense
                fallback={
                    <div className="text-center text-danger">{t("Loading")}...</div>
                }
            >
                <ManageClient
                    data={clientsData}
                    setMaterialData={setClientsData}
                    handleGetVacany={getClientsData}
                    dispatch={dispatch}
                    history={history}
                    handleRefresh={handleRefresh}
                    pageData={pageData}
                />
            </Suspense>
        </>
    );
};

export default Client;
