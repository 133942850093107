import { validateRequired, validateSelectField } from "services/validation/ValidationService";

export const ProjectParametersValidation = {
    project: [validateSelectField],
    projectParameters: [validateSelectField],
    timeTable: [validateSelectField],
    hourlyRate: [validateRequired],
    noOfKms: [validateRequired],
    euroPerKmRate: [validateRequired],
    hourlyWage: [validateRequired],
    noOfUnits: [validateRequired],
    euroPerKm: [validateRequired],
}

export const EmployeeTypeValidation = {
    name: [validateSelectField],
    amount: [validateRequired],
    coefficient: [validateRequired],
}

export const MarginValidation:any = {
    fixedProjectParameters: {
        project: [validateSelectField],
        projectParameters: [validateSelectField],
        timeTable: [validateSelectField],
        hourlyRate: [validateRequired],
        noOfKms: [validateRequired],
        euroPerKmRate: [validateRequired],
        hourlyWage: [validateRequired],
        noOfKmsWage: [validateRequired],
        euroPerKm: [validateRequired],
    },
    statute: {
        name: [validateSelectField],
        amount: [validateRequired],
        coefficient: [validateRequired],
    },
    car: {
        // name: [validateSelectField],
        // amount: [validateRequired],
        // coefficient: [validateRequired],
    },
    material: {
        // name: [validateSelectField],
        // amount: [validateRequired],
        // coefficient: [validateRequired],
    },
    clothes: {
        // name: [validateSelectField],
        // amount: [validateRequired],
        // coefficient: [validateRequired],
    },
    employee: {
        name: [validateSelectField],
        amount: [validateRequired],
        coefficient: [validateRequired],
    },
    dynamic: {
        // name: [validateSelectField],
        // amount: [validateRequired],
        // coefficient: [validateRequired],
    }
}