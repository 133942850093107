import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { GET_SHIFT, GET_TIMETABLES } from "routes/ApiEndpoints";
import ManageShiftsPage from "./Index";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "../translation/Translation";

export interface Shift {
  id?: number;
  shift_name: string;
  from: string;
  to: string;
  pause: number;
}

interface DataProps {
  page: number;
  search: {};
}

interface createTimeTableButtonProps {
  isTimeTable?: boolean;
}

const ManageShift: React.FC<createTimeTableButtonProps> = ({ isTimeTable }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const statusValue = queryParams.get("status");

  const status: number | undefined =
    statusValue !== null ? parseInt(statusValue) : undefined;
  const [shift, setShift] = useState<Shift[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState({
    shift_name: queryParams.get("name") ?? "",
  });
  const navigate = useNavigate();

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleDeleteData = (deleteId: number) => {
    setShift((prevdata) => prevdata.filter((shift) => shift.id !== deleteId));
    setCurrentPage(1);
  };

  return (
    <ManageShiftsPage
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      isTimeTable={isTimeTable}
      shiftObject={shift}
      searchValue={handleSearch}
      deleteFilter={handleDeleteData}
      searchData={search}
      totalPages={totalPages}
    />
  );
};

export default ManageShift;
