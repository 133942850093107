import React, { useState, useEffect } from "react";
import {
  PLANNING_FILTERS,
  PLANNING_VIEW_OPTIONS,
  SALES_VIEW_OPTIONS,
  SALES_LIST, OVERVIEW_TYPE
} from "../constants/planningConstants";
import Calender from "components/molecules/Calender";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  usePlanning,
  usePlanningsDispatch,
} from "../planning-redux/context/PlanningContext/PlanningContext";
import { PLANNING_ACTIONS } from "../planning-redux/actions/PlanningActions";
import { setCurrentViewUnit, getList } from "../utils/utils";
import { GET_PLANNING_FILTER_DATA } from "routes/ApiEndpoints";
import { PLANNING_FILTER_ACTIONS } from "../planning-redux/actions/PlanningFilterActions";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import {
  useFilterOptions,
  useFilterOptionsDispatch,
} from "../planning-redux/context/FiltersContext/FiltersContext";
import { initialEmployeeProps } from "../planning-redux/context/EmployeeContext/intialEmployeeProps";
import { useEmployeeDispatch } from "../planning-redux/context/EmployeeContext/EmployeeContext";
import { dateUtils } from "../utils/dateUtils";
import { EMPLOYEE_ACTIONS } from "../planning-redux/actions/EmployeesActions";
import { useSearchParams } from "react-router-dom";
import { getWeek } from 'pages/microservices/planning/utils/utils';
import { useDispatch } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useSelector } from "react-redux";
import ResetBtn from "components/atoms/ResetBtn";
import MultiSelectAtom from "components/atoms/MultiSelectAtom";

const PlanningFilters = ({ type }) => {
  const { planningState } = usePlanning();
  const { dispatchPlanning, setView } = usePlanningsDispatch();
  const { filterOptionsState } = useFilterOptions();
  const { dispatchFilterOptions } = useFilterOptionsDispatch();
  const { dispatchEmployee } = useEmployeeDispatch();
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({
    employee: "",
    project: "",
    refresh: true,
  });

  const [planningFilterState, setPlanningFilterState] = useState(PLANNING_FILTERS);
  const [filterData, setFilterData] = useState({
    companies: [],
    projects: [],
    businessUnits: [],
    employeeTypes: [],
    companyProjects: [],
    projectBusinessUnits: [],
    employees: [],
    salesview: null,
  });

  const updatePlanningFiltersData = (value, type) => {
    setPlanningFilterState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [type]: value,
      },
    }));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchPlanningDetails();
    }, 500);
    return () => clearTimeout(timeout);
  }, [params?.refresh, searchParams, planningState.view, planningState.startdate, planningState.enddate]);

  useEffect(() => {
    getFilterOptions();
    if (Object.values(history?.filters)?.length > 0) {
      updateReduxData();
    }
  }, []);

  const fetchPlanningDetails = async (list = []) => {
    let params = filterOptionsState.filteredState;
    if (
      params.employeesid.length == 0 &&
      list.employee != "" &&
      list.employee != undefined
    ) {
      params.employeesid = [list.employee];
    }
    params.startdate = dateUtils.getFormattedDates(planningState.startdate)
    params.enddate = dateUtils.getFormattedDates(planningState.enddate)
    if (type == "sales") {
      params = {
        ...params,
        type: (planningState.view != null && planningState.view.value != undefined) ? planningState.view.value : "week",
        view: planningFilterState.state.salesview,
        overview: planningState.planningOverview.value != undefined ? planningState.planningOverview.value : planningState.planningOverview
      };
    }

    let details = await initialEmployeeProps(params, type);
    updatePlanningFiltersData(details.employees, "employeelist")
    updateEmployeeList(
      planningFilterState.state.salesview,
      details.employees,
      type,
      details.employees
    );
    dispatchPlanning({
      type: PLANNING_ACTIONS.SET_PLANNING_EVENTS,
      payload: details.plans,
    });


    // if (filterData.employees.length == 0) {
    //   console.log(details.emplist);
    //   let filteredemplist = details.emplist.filter(
    //     (item) => !SALES_VIEW_LIST.includes(item.label)
    //   );
    //   setFilterData((prevState) => ({
    //     ...prevState,
    //     employees: filteredemplist,
    //   }));
    // }
  };

  const updateEmployeeList = (value, emplist, type = "sales", employeeslist = null) => {

    let view = value.label;
    if (view != "All" && type == "sales") {

      let viewlist = SALES_LIST.filter((item) => item != view);
      employeeslist = employeeslist != null ? employeeslist : planningFilterState.state.employeelist
      emplist = employeeslist.filter((item) => {
        return !viewlist.includes(item.name);
      });
    }
    dispatchEmployee({
      type: EMPLOYEE_ACTIONS.SET_EMPLOYEE,
      payload: { employees: emplist },
    });
  };

  const getFilterOptions = async () => {
    try {
      const response = await ApiCall.service(
        GET_PLANNING_FILTER_DATA,
        "post",
        {},
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        updateBulkFilterOptions(
          response.data,
          PLANNING_FILTER_ACTIONS.BULK_UPDATE_PLANNING_OPTIONS_STATE
        );
        setFilterData((prevState) => ({
          ...prevState,
          ["companies"]: getList(response?.data.companies, "status", 1),
          ["projects"]: getList(response?.data.projects, "status", 2, true),
          ["businessUnits"]: response?.data.businessUnits,
          ["employeeTypes"]: response?.data.employeeTypes,
          ["companyProjects"]: response?.data.companyProjects,
          ["projectBusinessUnits"]: response?.data.projectBusinessUnits,
          ["shifts"]: response?.data.shifts,
          ["employees"]: response?.data.employees_list,
          ["empCompanyProject"]: response?.data?.empCompanyProject,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChangeForProjectAndCompany = (value, type) => {
    setPlanningFilterState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [type]: value,
      },
    }));
    switch (type) {
      case "company":
        let selectedCompanyIds = value;
        let projectlist = filterOptionsState.state.projects;
        let employees_list = filterOptionsState?.state?.empCompanyProject;
        if (value.length > 0) {
          // selectedCompanyIds = value.map((company) => company.value);
          // const filteredProjects = filterData.companyProjects
          //   .filter((project) =>
          //     selectedCompanyIds.includes(project.company_id)
          //   )
          //   .map((project) => project.project_id);
          // projectlist = filterOptionsState.state.projects.filter((project) =>
          //   filteredProjects.includes(project.value)
          // );
          projectlist = projectlist.filter((eachProject) => value.includes(eachProject?.company_id));
          const employees = filterOptionsState?.state?.empCompanyProject?.filter((eachCompany) => value?.includes(eachCompany?.company_id))?.map((eachEmp) => eachEmp?.employee_id);
          employees_list = filterOptionsState?.state?.employees_list?.filter((eachEmp) => employees.includes(eachEmp?.value));
        }
        updateFilterOptions("company_id", selectedCompanyIds);
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          projects: projectlist,
          employees: employees_list,
        }));
        break;

      case "project":
        let selectedProjectIds = value;
        let employeesLt = filterOptionsState?.state?.empCompanyProject;
        if (value.length > 0) {
          //selectedProjectIds = value.map((project) => project.value);
          const employees = filterOptionsState?.state?.empCompanyProject?.filter((eachCompany) => value?.includes(eachCompany?.project_id))?.map((eachEmp) => eachEmp?.employee_id);
          employeesLt = filterOptionsState?.state?.employees_list?.filter((eachEmp) => employees.includes(eachEmp?.value));
        }
        updateFilterOptions("project_id", selectedProjectIds);
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          employees: employeesLt,
        }));
        break;
      case "bussiness_unit":
        let selectedbuIds = value;
        let companylist = getList(
          filterOptionsState.state.companies,
          "status",
          1
        );
        let projlist = getList(filterOptionsState.state.projects, "status", 2);
        if (value.length > 0) {
          // selectedbuIds = value.map((bu) => bu.value);
          const companyidslist = value.map((bu) => bu.companyid);
          companylist = filterOptionsState.state.companies.filter((company) =>
            companyidslist.includes(company.value.toString())
          );
          const filteredProjects = filterData.projectBusinessUnits
            .filter((project) => selectedbuIds.includes(project.bu_id))
            .map((project) => project.project_id);
          projlist = filterOptionsState.state.projects.filter((project) =>
            filteredProjects.includes(project.value)
          );
        }
        updateFilterOptions("businessUnits", selectedbuIds);
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          companies: companylist,
          projects: projlist,
        }));
        break;
      case "employees":
        let companiesList = filterOptionsState?.state?.companies;
        let projectList = filterOptionsState?.state?.projects;
        if (value.length > 0) {
          const filters = filterOptionsState?.state?.empCompanyProject?.filter((eachCompany) => value?.includes(eachCompany?.employee_id));
          const compIds = filters?.map((eachComp) => eachComp?.company_id);
          companiesList = filterOptionsState?.state?.companies?.filter((eachEmp) => compIds.includes(eachEmp?.value));

          const projIds = filters.map((eachEmp) => eachEmp?.project_id);
          projectList = filterOptionsState?.state?.projects?.filter((eachEmp) => projIds.includes(eachEmp?.value));
        }
        updateFilterOptions("employeesid", value);
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          projects: projectList,
          companies: companiesList,
        }));
        break;
      case "employeeTypes":
        let emptypes = value;
        const contType = filterOptionsState.state.employees_list
          ?.filter((employee) => emptypes.includes(employee?.contractType));
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          employees: contType,
        }));
        updateFilterOptions(type, emptypes);
      default:
        break;
    }
  };

  const updateBulkPlanningOptions = (payload) => {
    dispatchPlanning({
      type: PLANNING_ACTIONS.UPDATE_MUTIPLE_FIELDS,
      payload: payload,
    });
  }

  const updateFilterOptions = (fieldname, value) => {
    dispatchFilterOptions({
      type: PLANNING_FILTER_ACTIONS.UPDATE_PLANNING_SELECTED_FIELD,
      payload: {
        field: fieldname,
        value: value,
      },
    });
  };

  const updateBulkFilterOptions = (list, type) => {
    dispatchFilterOptions({
      type: type,
      payload: list,
    });
  };

  const handleSelectChange = (value, type, isMultiSelect) => {
    let noapicall = true;
    let dispatchFiltersData = {};
    let week = null;
    let payload = null;
    switch (type) {
      case "employees":
      case "company":
      case "project":
      case "bussiness_unit":
      case "employeeTypes":
        dispatchFiltersData = type === 'business_unit' ? { businessUnits: value } : { [type]: value };
        handleSelectChangeForProjectAndCompany(value, type);
        break;

      case "startdate":
        week = getWeek(planningState.view)
        payload = {
          startdate: value,
          enddate: planningState.enddate,
        }
        console.log(value);
        if (week != null) {
          let enddate = dateUtils.getDateAfterWeeks(value, week)
          payload.enddate = enddate
        }
        dispatchFiltersData = payload;
        updateBulkPlanningOptions(payload)
        handleSelectChangeForProjectAndCompany(value, type);
        break;
      case "enddate":
        payload = {
          enddate: value,
          view: null
        }
        updateBulkPlanningOptions(payload)
        dispatchFiltersData = payload;
        handleSelectChangeForProjectAndCompany(value, type);
        break;
      case "planningOverview":
        dispatchFiltersData = { planningOverview: value }
        dispatchEmployee({
          type: EMPLOYEE_ACTIONS.SET_EMPLOYEE,
          payload: { employees: [] },
        })
        let newpayload = {
          planningOverview: value
        }
        updateBulkPlanningOptions(newpayload)
        break;
      case "planning-type":
        dispatchFiltersData = { planning_type: value }
        let request = {
          planning_type: value
        }
        updateBulkPlanningOptions(request)
        break;
      case "planning-view":
        if (value.value == '') return;
        week = getWeek(value)
        if (week == null) break;
        let enddate = dateUtils.getDateAfterWeeks(planningState.startdate, week)
        payload = {
          enddate: enddate,
          view: value
        }
        noapicall = false;
        updateBulkPlanningOptions(payload)
        dispatchPlanning({
          type: PLANNING_ACTIONS.UPDATE_CURRENT_VIEW_YEAR,
          payload: new Date().getFullYear(),
        });
        dispatchFiltersData = {
          enddate: enddate?.toISOString(), view: value,
          [PLANNING_ACTIONS.UPDATE_CURRENT_VIEW_YEAR]: new Date().getFullYear()
        };
        break;
      case "salesview":
        if (value.value == '') return;
        let emplist = planningFilterState.state.employeelist;
        updateEmployeeList(value, emplist);
        dispatchPlanning({
          type: PLANNING_ACTIONS.SET_SALES_VIEW,
          payload: value,
        });
        noapicall = false;
        dispatchFiltersData = { salesview: value, [PLANNING_ACTIONS.SET_SALES_VIEW]: value };
        handleSelectChangeForProjectAndCompany(value, type);
        break;
    }
    dispatch(updatePageFilters({ filters: dispatchFiltersData }))
    noapicall && setParams((prev) => ({ ...prev, refresh: !params?.refresh }))
  };

  const updateReduxData = (clear = false) => {
    let [startdate, enddate] = setCurrentViewUnit(PLANNING_VIEW_OPTIONS[1]);
    let payload = {
      bussiness_unit: clear ? [] : history?.filters?.businessUnits ?? [],
      businessUnits: clear ? [] : history?.filters?.businessUnits ?? [],
      company: clear ? [] : history?.filters?.company ?? [],
      company_id: clear ? [] : history?.filters?.company ?? [],
      project: clear ? [] : history?.filters?.project ?? [],
      project_id: clear ? [] : history?.filters?.project ?? [],
      employeeTypes: clear ? [] : history?.filters?.employeeTypes ?? [],
      employees: clear ? [] : history?.filters?.employees ?? [],
      employeesid: clear ? [] : history?.filters?.employees ?? [],
      startdate: clear ? startdate : history?.filters?.startdate ? new Date(history.filters.startdate) : startdate,
      enddate: clear ? enddate : history?.filters?.enddate ? new Date(history.filters.enddate) : enddate,
      view: clear ? PLANNING_VIEW_OPTIONS[1] : history?.filters?.view ?? PLANNING_VIEW_OPTIONS[1],
      salesview: clear ? SALES_VIEW_OPTIONS[0] : history?.filters?.salesview ?? SALES_VIEW_OPTIONS[0],
      planningOverview: clear ? OVERVIEW_TYPE[0] : history?.filters?.planningOverview ?? OVERVIEW_TYPE[0],
    }
    updateBulkFilterOptions(
      payload,
      PLANNING_FILTER_ACTIONS.BULK_UPDATE_PLANNING_SELECTED_FILEDS
    );
    let type = clear ? "" : history?.filters?.type ?? "";
    let list = [];
    if (type == 'employee') {
      list = { employee: payload.employees };
    } else if (type == 'project') {
      list = { project: payload.project };
    } else {
      list = { project: "", employee: "" }
    }
    setPlanningFilterState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        ...payload
      },
    }));
    let planning_payload = {
      currentViewYear: clear ? new Date().getFullYear() : history?.filters?.[PLANNING_ACTIONS.UPDATE_CURRENT_VIEW_YEAR] ?? new Date().getFullYear(),
      salesview: clear ? SALES_VIEW_OPTIONS[0] : history?.filters?.[PLANNING_ACTIONS.SET_SALES_VIEW] ?? SALES_VIEW_OPTIONS[0],
      planningOverview: clear ? OVERVIEW_TYPE[0] : history?.filters?.planningOverview ?? OVERVIEW_TYPE[0],
      startdate: payload.startdate,
      enddate: payload.enddate,
      view: payload.view
    }
    updateBulkPlanningOptions(planning_payload)
    clear && dispatch(updatePageFilters({ reset: true }));
    setTimeout(() => {
      setParams((prev) => ({ ...prev, refresh: !params?.refresh, ...list }));
    }, 200);
  }

  return (
    <>
      {/* <div>Filters</div> */}
      <div className="d-grid hideLabel planningFilters" style={{ gridTemplateColumns: "repeat(11,1fr)", gap: "1vw" }}>
        <div style={{ marginBottom: "0.5vw" }}>
          <MultiSelectAtom
            search={true}
            onChange={(value) =>
              handleSelectChange(value, "bussiness_unit", false)
            }
            name={"bussiness_unit"}
            options={filterData.businessUnits}
            value={planningFilterState.state.bussiness_unit}
            placeHolder={t("Business unit")}
            isDisabled={params.project == "" ? false : true}
          />
        </div>
        <div>
          <MultiSelectAtom
            search={true}
            onChange={(value) => handleSelectChange(value, "company", false)}
            name={"company"}
            options={filterData.companies}
            value={planningFilterState.state.company}
            isDisabled={params.project == "" ? false : true}
            id={"company_planning_filter_id"}
            placeHolder={t("Company")}
          />
        </div>
        <div>
          <MultiSelectAtom
            search={true}
            onChange={(value) => handleSelectChange(value, "project", false)}
            placeHolder={t("Project")}
            options={filterData.projects}
            value={planningFilterState.state.project}
            isDisabled={params.project == "" ? false : true}
            isMulti={true}
            name={"project"}
            id={"project_planning_filter_id"}
          />
        </div>
        <div>
          <MultiSelectAtom
            search={true}
            onChange={(value) =>
              handleSelectChange(value, "employeeTypes", false)
            }
            placeHolder={t("Employee type")}
            options={filterData.employeeTypes}
            value={planningFilterState.state.employeeTypes}
            isDisabled={params.employee == "" ? false : true}
            isMulti={true}
            name={"employeeTypes"}
            id={"employee_type_planning_filter_id"}
          />
        </div>
        <div>
          <MultiSelectAtom
            search={true}
            onChange={(value) => handleSelectChange(value, "employees", false)}
            placeHolder={t("Employees")}
            options={filterData.employees}
            value={planningFilterState.state.employees}
            isDisabled={params.employee == "" ? false : true}
            isMulti={true}
            name={"employees"}
            id={"employees_planning_filter_id"}
          />
        </div>

        <div>
          <Calender
            onChange={(date) => handleSelectChange(date, "startdate", false)}
            selectedDate={planningState.startdate}
            placeHolder={"dd-mm-yyyy"}
            // label={t("End date")}
            isMandatory={false}
            name={"end_date"}
            // error={filterOptionsState.filteredState.end_date}
            isDisabled={false}
          ></Calender>
        </div>
        <div>
          <Calender
            onChange={(date) => handleSelectChange(date, "enddate", false)}
            selectedDate={planningState.enddate}
            placeHolder={"dd-mm-yyyy"}
            // label={t("End date")}
            isMandatory={false}
            name={"end_date"}
            // error={filterOptionsState.filteredState.end_date}
            isDisabled={false}
          ></Calender>
        </div>
        {type == "sales" && (
          <div className=" p-0">
            <SelectWithSearch
              title={t("")}
              name="planning_view"
              // placeHolder={t("Week")}
              isMandatory={true}
              search={true}
              options={SALES_VIEW_OPTIONS}
              value={planningState.salesview}
              onChange={(e) => handleSelectChange(e, "salesview")}
              isMulti={false}
              className="select-field"
            // error={formErrors.country}
            />
          </div>
        )}
        <div className="p-0">
          <SelectWithSearch
            title={t("")}
            name="planning_view"
            // placeHolder={t("Week")}
            isMandatory={true}
            search={true}
            options={PLANNING_VIEW_OPTIONS}
            value={planningState.view}
            onChange={(e) => handleSelectChange(e, "planning-view")}
            isMulti={false}
            className="select-field"
          // error={formErrors.country}
          />
        </div>
        <div className="p-0 ">
          <SelectWithSearch
            title={t("")}
            name="planning_overview"
            // placeHolder={t("Week")}
            isMandatory={true}
            search={true}
            options={OVERVIEW_TYPE}
            value={planningState.planningOverview}
            onChange={(e) => handleSelectChange(e, "planningOverview")}
            isMulti={false}
            className="select-field"
          // error={formErrors.country}
          />
        </div>
        {/* <div>
          <button type="submit" class="btn form-button text-uppercase px-2 w-100 h-45px" onClick={fetchPlanningDetails}> Search</button> */}
        {/* </div> */}
        <div className="d-flex align-items-start">
          {/* <div className="d-flex align-items-center">
            <SearchBtn handleSearchClick={fetchPlanningDetails} />
          </div> */}
          <ResetBtn
            handleResetClick={(e) => updateReduxData(true)}
          />
        </div>
      </div>

    </>
  );
};

export default PlanningFilters;
