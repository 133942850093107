import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as ENDPOINTS from "../../routes/ApiEndpoints";
import RightArrow from "static/images/RightArrow";
import AccessControl from "services/AccessControl";
import CardCircle from "components/atoms/CardCircle";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import EditIcon from "static/images/EditIcon";
import { EDIT_CANDIDATE, PATH_APPLICANT_SCREENING, PATH_CANDIDATE_SCREENING } from "constants/Paths";
import TextEllipsis from "components/atoms/TextEllipsis";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
// import { PHONE_CALL_URL } from "routes/ApiEndpoints";

export type MatchingCandidateProfileCardType = {
  id?: number | null;
  firstName: string;
  lastName: string;
  functions: any;
  mobNumber?: string;
  email?: string;
  street?: string;
  number?: string;
  box?: string;
  zipCode?: string;
  city?: string;
  picFileName?: string;
  picFilePath?: string;
  country?: string;
  cvData?: any;
  profilePicData?: any;
  isScreening?: boolean;
  source?: string;
  applicationId?: string;
  buIds?: number | null
};

export interface MatchingProfileCardProps {
  profileCardProps: MatchingCandidateProfileCardType;
  isCandidateCreation?: boolean;
}

const MatchingProfileCard: React.FC<MatchingProfileCardProps> = (props) => {
  const navigate = useNavigate();
  const userData = useSelector(selectAuth);

  const functionList =
    props.profileCardProps.functions === undefined
      ? []
      : props.profileCardProps.functions;

  let address = [];

  if (props.profileCardProps.street) {
    address.push(props.profileCardProps.street);
  }
  if (props.profileCardProps.number) {
    address.push(props.profileCardProps.number);
  }
  if (props.profileCardProps.zipCode) {
    address.push(props.profileCardProps.zipCode);
  }

  if (props.profileCardProps.city) {
    address.push(props.profileCardProps.city);
  }

  if (props.profileCardProps.country) {
    address.push(props.profileCardProps.country);
  }

  let fullAddress = address ? address.join(", ") : [];

  const downloadFile = async (
    id: number | null,
    name: string | null,
    path: string | null,
    uploadPath: string | null
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVICE}/${ENDPOINTS.FILE_DOWNLOAD}/${id}/${uploadPath}`
      );
      if (response.status === 200) {
        const fileUrl = response.data.url;
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = fileUrl;
        link.target = "_blank";
        link.download = response.data.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  const handleCvDownload = () => {
    try {
      // Parse the cvData JSON string
      const cvData = JSON.parse(props?.profileCardProps.cvData);
      const cvUrl = cvData.accessUrl; // Assuming this is equivalent to download_url
      const fileName = cvData.fileName;

      // Check if the URL is available
      if (cvUrl) {
        const link = document.createElement("a");
        link.href = cvUrl;
        link.target = "_blank"; // Open the link in a new tab
        link.setAttribute("download", fileName); // Set the file name for download
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          document.body.removeChild(link); // Clean up the DOM
        }, 1000);
      } else {
        console.error("Invalid CV data or missing download URL");
      }
    } catch (error) {
      console.error("Failed to parse CV data or download CV:", error);
    }
  };

  const stringUrl = props.profileCardProps?.profilePicData;

  let url: any = "";
  if (stringUrl) {
    url = stringUrl;
  }

  return (
    <div className="row">
      <div className="col-md-8">
        <div
          style={{
            marginLeft: "3vw",
          }}
        >
          <div
            className="card position-relative border-0"
            style={{
              backgroundColor: "var(--sideBarBgColor)",
              padding: "0.5vw",
              borderRadius: "0.5vw"
            }}
          >
            <div className="cv-upload">
              <CardCircle
                position="absolute"
                width="5.813vw"
                height="5.813vw"
                backgroundColor="var(--primaryBrandColor)"
                left="0%"
                top={"50%"}
                transform={`translate(-50%, -50%)`} // Centering horizontally and vertically
                className="flex-column cursor-pointer justify-content-center"
                color="var(--color-white)"
              >
                {url &&
                  url["fileName"] !== "" &&
                  url["fileName"] !== undefined ? (
                  <img
                    src={
                      url["accessUrl"].startsWith("https")
                        ? url["accessUrl"]
                        : ""
                      //  JSON.parse( props.profileCardProps?.profilePicData?.uploadPhoto)
                      // `${ENDPOINTS.FILE_URL}/${props.profileCardProps?.profilePicData?.filePath}`
                    }
                    alt={t("Uploaded")}
                    style={{
                      backgroundColor: "var(--color-white)",
                      borderRadius: "50%",
                      boxShadow: " #00A5CE74 0.052vw 0vw 0.521vw 0.156vw",
                    }}
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <img
                    src="/static/images/Person.svg"
                    alt="Profile"
                    width="100%"
                    height="100%"
                  />
                )}
              </CardCircle>
            </div>
            <div className="row">
              <div className="col-md-11 ms-auto position-relative candidateProfileCard">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    {(props.profileCardProps.firstName ||
                      props.profileCardProps.lastName) && (
                        <div className={`color-dark-skyblue name-text text-start`}>
                          {props.profileCardProps.firstName}{" "}
                          {props.profileCardProps.lastName}
                        </div>
                      )}
                    {functionList.length > 0 &&
                      <span
                        className="job-title text-start"
                        style={{ marginBottom: "0.8vw" }}
                      >
                        <strong> {functionList.join(", ")}</strong>
                      </span>
                    }
                  </div>
                  <div>
                    {props.isCandidateCreation && <Link
                      to={`${EDIT_CANDIDATE}/${props.profileCardProps?.id}`}
                      className="table-action-btn"
                    >
                      <EditIcon />
                    </Link>}
                  </div>
                </div>
                <div className="candidate-person-details">
                  <div className="row" style={{ marginBottom: "0.25vw" }}>
                    {props.profileCardProps.mobNumber && (
                      <div className="d-flex align-items-start col-3">
                        <img
                          src="/static/images/mobile.png"
                          alt="mobile"
                          className="profileCardImage"
                        />
                        <span style={{ marginLeft: "0.25vw" }}>
                          {props.profileCardProps.mobNumber}
                        </span>
                      </div>
                    )}
                    {props.profileCardProps.email && (
                      <div className="col-4">
                        <a
                          href={`mailto:${props.profileCardProps.email}`}
                          target="_blank"
                          className="link-color d-flex align-items-center"
                        >
                          <img
                            src="/static/images/mail.png"
                            alt="mail"
                            className="profileCardImage"
                          />
                          <span style={{ marginLeft: "0.25vw" }}>
                            {props.profileCardProps.email}
                          </span>
                        </a>
                      </div>
                    )}
                    {fullAddress && (
                      <div className="d-flex align-items-start col-5">
                        <img
                          src="/static/images/home.png"
                          alt="home"
                          className="profileCardImage"
                        />
                        <div style={{ marginLeft: "0.25vw" }}>
                          <TextEllipsis title={fullAddress} label={fullAddress} width="19vw" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 pt-1 ps-4 align-self-center candidate-cv-download">
        <div className="d-flex align-items-end">
          {/* <div className="shout-icon d-inline-flex align-items-center" style={{ paddingTop: "1.6vw" }}>
            <div className="d-flex align-items-center ">
              <img
                src="/static/images/megaphone.svg"
                alt="shout"
                className="megaPhoneIcon"
              />
              <div className="available-text mb-2 fraunces-italic color-dark-pink">
                <div>{t("Available")}</div>
                <div>{t("for work!")}</div>
              </div>
            </div>
          </div> */}
          {props.profileCardProps?.cvData !== "" ? (
            <div className="d-flex" style={{ marginLeft: "2vw" }}>
              {/* <span
                  onClick={(event) =>
                    downloadFile(
                      props.profileCardProps.cvData.fileName
                        ? props.profileCardProps.cvData.fileName
                        : "",
                      props.profileCardProps.cvData.filePath
                        ? props.profileCardProps.cvData.filePath
                        : "",
                      "candidate-cv"
                    )
                  }
                  className="table-action-icons cursor-pointer matching-btns"
                >
                  <div
                    className="candidate-download-btn px-3"
                    title={t("Download")}
                  >
                    <span className="me-4">{t("Download CV")}</span>
                    <DownloadIcon />
                  </div>
                </span> */}

              <Button
                title="Cv download"
                type="button"
                handleClick={() => handleCvDownload()}
                className="form-button"
              />
            </div>
          ) : (
            <></>
          )}
          {props.profileCardProps.isScreening && (userData?.role?.includes('SALES') || userData?.role?.includes('HR') || userData?.role?.includes('ADMIN') || userData?.role?.includes('SUPER_ADMIN')) && (
            <div className="d-flex m-auto">
              <Link
                className="form-button matchingBtn hoverLine"
                title=""
                to={props.profileCardProps.applicationId ? `${PATH_APPLICANT_SCREENING}/${props.profileCardProps.id}/${props.profileCardProps?.applicationId}` :
                  `${PATH_CANDIDATE_SCREENING}/${props.profileCardProps.id}`}
                state={{ origin: props.profileCardProps.source }}
              >
                <div className="d-flex align-items-center justify-content-between ">
                  {t("Screening")} &nbsp;
                  <span className="marginRightPoint5">
                    <RightArrow />
                  </span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>

  );
};

export default MatchingProfileCard;
