import {
  SWITCH_TAB,
  SET_OPTIONS,
  ADD_CONTACT,
  REMOVE_CONTACT,
  UPDATE_SELECTED_CONTACT_FIELD,
  UPDATE_CONTACT_SUBFORM_FIELD,
  UPDATE_CURRENT_TAB,
  SET_FIELD_GENERAL_TAB,
  SET_FIELD_ERROR_GENERAL_TAB,
  SET_ERRORS_GENERAL_TAB,
  SET_ERROR_SUBFIELD_GENERAL_TAB,
  UPDATE_TAB_DATA,
  UPDATE_FIELD_ERROR,
  UPDATE_ERRORS_CONTACTPERSONS_TAB,
  INITIAL_STATE,
  SIGNATURE_IMAGE,
} from "pages/microservices/project/context/Constants";
import {
  ADD_UPDATE_DYNAMIC_TEMP_AGENCY_DOCUMENT,
  REMOVE_TEMP_AGENCY_DOCUMENT_FIELD,
  SET_CONTACT_PERSON_OPTION,
  SET_ERRORS_ADDRESS_TAB,
  SET_ERRORS_LOCATION_TAB,
  SET_FIELD_ERROR_ADDRESS_TAB,
  TEMP_AGENCY_ADD_DOCUMENT,
} from "./Constants";
import TempAgencyDocument from "../components/TempAgencyDocument";

type TempAggencyReducerProps = {
  state: any;
  action: any;
};

const TempGeneralReducer = (
  state: TempAggencyReducerProps["state"],
  action: TempAggencyReducerProps["action"]
) => {
  switch (action.type) {
    case SWITCH_TAB:
      return {
        ...state,
        [action.field]: action.value,
      };
    case SET_OPTIONS:
      const options = { ...state.options };
      options[action.field] = action.value;
      return { ...state, options };

    case UPDATE_CURRENT_TAB:
      return {
        ...state,
        [action.field]: action.value,
      };

    // General tab
    case SET_FIELD_GENERAL_TAB:
      let updatedGeneralTabData = state.general.data;
      updatedGeneralTabData[action.field] = action.value;

      const updatedGeneralTabWithData = {
        ...state,
        general: {
          data: updatedGeneralTabData,
          errors: state.general.errors,
        },
      };
      return updatedGeneralTabWithData;

    case SET_FIELD_ERROR_GENERAL_TAB:
      let updatedGeneralTabErrors = state.general.errors;
      updatedGeneralTabErrors[action.fieldName] = action.error;

      const updatedGeneralTabWithFieldError = {
        ...state,
        general: {
          data: state.general.data,
          errors: updatedGeneralTabErrors,
        },
      };
      return updatedGeneralTabWithFieldError;

    case SET_ERRORS_GENERAL_TAB:
      const updatedGeneralTabWithErrors = {
        ...state,
        general: {
          data: state.general.data,
          errors: action.errors,
        },
      };
      return updatedGeneralTabWithErrors;

    case SET_ERROR_SUBFIELD_GENERAL_TAB:
      let generalTabErrors = state.general.errors;
      generalTabErrors.createLocation[action.field] = action.error;

      const updatedWithCreateLocationErrors = {
        ...state,
        general: {
          data: state.general.data,
          errors: generalTabErrors,
        },
      };
      return updatedWithCreateLocationErrors;
    // Overall tab related
    case UPDATE_TAB_DATA:
      const tabData = state[action.field];
      tabData.data = action.value;
      return { ...state, [action.field]: tabData };

    case "UPDATE_CONTACT_LOCATION_TAB":
      return {
        ...state,
        [action?.tab]: {
          ...state?.[action?.tab],
          [action?.list]: action?.data,
        },
      };

    case "UPDATE_CONTACT_FIELD_ERROR": {
      const { fieldName, error, tab } = action;
      // Check if the tab is 'contactsPersons'
      if (tab === "contactsPersons") {
        return {
          ...state,
          contactsPersons: {
            ...state.contactsPersons,
            errors: {
              ...state.contactsPersons.errors,
              contactsPersons: {
                ...state.contactsPersons.errors.contactsPersons,
                [fieldName]: error,
              },
            },
          },
        };
      }
      // Return the original state if tab is not 'contactsPersons'
      return state;
    }

    case "UPDATE_TAB_DETAILS":
      return {
        ...state,
        tabs: action.tabs,
      };

    case UPDATE_FIELD_ERROR:
      const tabId = action.tab;
      const tabState = state[tabId];
      const updatedTabState = {
        ...tabState,
        errors: {
          ...tabState.errors,
          [action.fieldName]: action.error,
        },
      };

      return {
        ...state,
        [tabId]: updatedTabState,
      };

    case "UPDATE_TAB_ERROR":
      return {
        ...state,
        tabs: state.tabs.map((tab: any, index: any) => ({
          ...tab,
          error: index === action.tabIndex ? action.error : tab.error,
          draft: action.draft,
        })),
      };

    case INITIAL_STATE:
      return { ...JSON.parse(JSON.stringify(action.payload)) };

    case SIGNATURE_IMAGE:
      return {
        ...state,
        signatureImage: action.payload.value,
      };

    case "DELETE_CONTACT_LOCATION_INDEX":
      const currentList = [...state?.[action?.tab]?.[action?.list]];
      currentList.splice(action?.index, 1);

      return {
        ...state,
        [action?.tab]: {
          ...state[action?.tab],
          [action?.list]: currentList,
        },
      };

    case "UPDATE_ADDRESS_FIELD":
      return {
        ...state,
        address: {
          ...state.address, // Ensure you spread the existing `address` state
          data: {
            ...state.address.data, // Only update the `data` part
            [action.field]: action.value,
          },
        },
      };

    case "UPDATE_ALL_ADDRESS_FIELDS":
      return {
        ...state,
        address: {
          ...state?.address,
          data: {
            ...state.address.data,
            ...action.data,
          },
          errors: {
            ...state?.address.errors,
            ...action?.errors,
          },
        },
      };

    case SET_FIELD_ERROR_ADDRESS_TAB:
      let updatedAddressTabErrors = state.address.errors;
      updatedAddressTabErrors[action.fieldName] = action.error;

      const updatedAddressTabWithFieldError = {
        ...state,
        addressl: {
          data: state.address.data,
          errors: updatedAddressTabErrors,
        },
      };
      return updatedAddressTabWithFieldError;
    case SET_ERRORS_ADDRESS_TAB:
      const updatedAddressTabWithErrors = {
        ...state,
        address: {
          data: state.address.data,
          errors: action.errors,
        },
      };
      return updatedAddressTabWithErrors;

    case "UPDATE_LOCATION_OR_CONTACT_FIELD":
      const { field, value, tab } = action;

      return {
        ...state,
        [tab]: {
          ...state[tab],
          data: {
            ...state[tab].data,
            [field]: value,
          },
        },
      };

    case "UPDATE_CONTACT_LOCATION_FIELD_ERROR":
      const { fieldName, error } = action;
      return {
        ...state,
        [action?.tab]: {
          ...state?.[action?.tab],
          errors: {
            ...state?.[action?.tab]?.errors,
            [fieldName]: error,
          },
        },
      };

    case "UPDATE_ALL_CONTACT_OR_LOCATION_FIELD":
      const { data } = action;

      return {
        ...state,
        [action?.tab]: {
          ...state[action?.tab],
          data: {
            ...state[action?.tab].data,
            ...data,
          },
          errors: {
            ...state[action?.tab].errors,
            ...action?.error,
          },
        },
      };

    case SET_ERRORS_LOCATION_TAB:
      return {
        ...state,
        location: {
          ...state.location,
          errors: action.errors,
        },
      };
    case "SET_FIELD_ERROR_LOCATION_TAB":
      return {
        ...state,
        location: {
          ...state.location,
          errors: state.location.errors.map((error: any, index: any) =>
            index === action.index
              ? { ...error, [action.field]: action.error }
              : error
          ),
        },
      };

    case "UPDATE_LOCATION_CONTACT_LIST_FIELD":
      const { isEdit } = action;
      let locFormData = null;
      let newData = [...state?.[action?.tab]?.[action?.list]];
      if (isEdit) {
        const index = state?.[action?.tab]?.editIndex;
        newData.splice(index, 1);
      }

      locFormData = [...newData, action?.value];
      return {
        ...state,
        [action.tab]: {
          ...state?.[action?.tab],
          editIndex: null,
          [action.list]: locFormData,
        },
      };

    case "EDIT_PARTICULAR_CONTACT_LOCATION_FIELD":
      const particularData = [...state[action?.tab]?.[action?.list]][
        action?.index
      ];
      return {
        ...state,
        [action?.tab]: {
          ...state[action?.tab],
          editIndex: action?.index,
          data: {
            ...state[action?.tab].data,
            ...particularData,
          },
        },
      };

    case "UPDATE_TAB_FIELDS_ERROR":
      const { tabIndex, errors } = action;

      const currentState = { ...state };
      const updatedList: any = {};
      for (let eachEle of Object.keys(currentState)) {
        if (eachEle == tabIndex) {
          updatedList[tabIndex] = updatedList[tabIndex] || {};
          updatedList[tabIndex]["errors"] = errors;
          updatedList[tabIndex]["data"] = currentState[tabIndex]["data"];
        } else {
          updatedList[eachEle] = currentState[eachEle];
        }
      }

      return {
        ...updatedList,
      };

    case "UPDATE_LOCATION_FIELDS_DATA":
      return {
        ...state,
        location: {
          ...state.location,
          data: {
            ...state.location.data,
            ...action.data,
          },
          errors: {
            ...state.location.errors,
            ...action.errors,
          },
        },
      };

    case UPDATE_ERRORS_CONTACTPERSONS_TAB:
      return {
        ...state,
        contactsPersons: {
          ...state?.contactsPersons,
          errors: {
            ...state?.contactsPersons?.errors,
            ...action?.errors,
          },
        },
      };
    case TEMP_AGENCY_ADD_DOCUMENT:
      const { data: documentData, index: documentIndex } = action;
      const newDocuments = [...state.tempAgencyDocument];

      // Update the specific coefficient at the given index
      newDocuments[documentIndex] = {
        ...documentData,
      };
      // Return the new state, updating remenurationDetails specifically
      return {
        ...state,
        tempAgencyDocument: newDocuments,
      };
    case REMOVE_TEMP_AGENCY_DOCUMENT_FIELD:
      const updatedDocuments = [...state.tempAgencyDocument];
      updatedDocuments.splice(action.index, 1);
      // Update the editId for remaining elements
      const documentsWithUpdatedIds = updatedDocuments.map((doc) => {
        // If editId is a number and greater than the removed index, decrement it
        if (typeof doc.editId === "number" && doc.editId > action.index) {
          return { ...doc, editId: doc.editId - 1 };
        }
        return { ...doc };
      });
      return {
        ...state,
        tempAgencyDocument: documentsWithUpdatedIds,
      };
    case ADD_UPDATE_DYNAMIC_TEMP_AGENCY_DOCUMENT:
      return {
        ...state,
        tempAgencyDocument: action.data,
      };

    case "UPDATE_LOCATIONS_FOR_CONTACT":
      const { contactData, locData } = action;

      const locationIds: any = {};
      locData.forEach((eachSub: any, index: number) => {
        locationIds[eachSub?.id] = {
          id: eachSub?.id,
          value: index + 1,
          label: eachSub?.locationName,
        };
      });

      const updatedConList = contactData.map((eachItem: any) => {
        const convertedData: any = [];
        eachItem?.["locations"].map((subItem: any) => {
          if (locationIds[subItem]) {
            convertedData.push(locationIds[subItem]);
          }
        });
        return { ...eachItem, locations: convertedData };
      });

      return {
        ...state,
        contactsPersons: {
          ...state.contactsPersons,
          contactsList: updatedConList,
        },
      };

    default:
      return state;
  }
};

export default TempGeneralReducer;
