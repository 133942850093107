import React, { useEffect, useState, Suspense } from "react";
import { ApiCall } from "services/ApiServices";
import { MANAGE_LOCATION } from "routes/ApiEndpoints";
import ManageLocationPage from "./ManageLocationPage";
import { M_COMPANY_CREATION } from "../../../../constants/Constants";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";

export interface LocationData {
  id?: number;
  company: string;
  location: string;
}

const ManageLocation: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [locations, setLocations] = useState<LocationData[]>([]);
  const pageHistory = useSelector(selectPageHistory);
  const history =
    pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });
  const getSearchData = () => {
    return {
      company: history?.filters?.company ?? "",
      location: history?.filters?.location ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    };
  };

  useEffect(() => {
    const data: any = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
    };
    fetchLocationDetails(data);
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchLocationDetails = async (data: any) => {
    const response = await ApiCall.service(
      MANAGE_LOCATION,
      "POST",
      data,
      false,
      M_COMPANY_CREATION
    );
    if (response?.status === 200) {
      setLocations(response?.data);
      setPageData((prev: any) => ({
        ...prev,
        totalPages: response.totalPages,
        totalRecords: response?.totalRecords,
        initialCall: false,
      }));
    }
  };

  const handleDeleteData = (deleteId: number) => {
    setLocations((prevdata) =>
      prevdata.filter((location) => location.id !== deleteId)
    );
  };

  const userData = useSelector(selectAuth);
  const handleRefresh = () =>
    setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Location",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <Suspense
          fallback={<div className="text-center text-danger">Loading...</div>}
        >
          <ManageLocationPage
            locationObject={locations}
            deleteFilter={handleDeleteData}
            history={history}
            pageData={pageData}
            dispatch={dispatch}
            handleRefresh={handleRefresh}
          />
        </Suspense>
      </>
    </AccessControl>
  );
};
export default ManageLocation;
