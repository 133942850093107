import Button from 'components/atoms/Button';
import { LabelWithInputField } from 'components/molecules/LabelWithInputField';
import { M_MASTER_DATA } from 'constants/Constants';
import { t } from 'pages/microservices/masterData/translation/Translation';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { MASTER_DATA_CONFIG_ELEMENT_CREATE } from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';

interface AddTagProps {
    modalState: any;
    setModalState: any;
    updateTagModel: (status?: boolean, response?: any) => void;
}

const AddTag: React.FC<AddTagProps> = ({ modalState, setModalState, updateTagModel }) => {

    const location = useLocation();
    const pathRequired = location.pathname.split('/').includes('vacancy');

    const handleChange = (name: string, value: any) => {
        let data = { ...modalState, data: { ...modalState?.data, [name]: value }, error: {} };
        let error = validate(data?.data);
        if (error) {
            data.error.tag = error;
        }
        setModalState(data)
    }

    const validate = (data: any) => {
        if (!data?.tag || data?.tag?.trim() === "") {
            return "The field is required";
        } else if (data?.tag?.trim()?.length > 255) {
            return "The field exceeds more than 255 characters";
        }
        return false;
    }

    const handleSubmit = async () => {
        try {
            let error = validate(modalState?.data);
            if (error) {
                setModalState((prev: any) => ({ ...prev, error: { tag: error } }))
            } else {
                const payload: any = {
                    "0": {
                        "name": "title",
                        "label": "Title",
                        "alias": "name",
                        "type": "text",
                        "required": true,
                        "validationRules": [
                            "required"
                        ],
                        "value": modalState?.data?.tag,
                    },
                    "elementCategory": "Tag",
                }
                const response = await ApiCall?.service(MASTER_DATA_CONFIG_ELEMENT_CREATE, "POST", payload, true, M_MASTER_DATA);
                updateTagModel(false, response?.data?.[0]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="row">
            <LabelWithInputField
                label={t("Title")}
                name="tag"
                isMandatory
                value={modalState?.data?.tag ?? ""}
                handleChange={(e) => handleChange("tag", e?.target?.value)}
                id="tag"
                type="text"
                error={modalState?.error?.tag ?? ""}
            />
            <div className="col-6 align-self-center">
                <Button
                    title={t("Cancel")}
                    handleClick={() => updateTagModel()}
                    className="form-button "
                />
            </div>
            <div className="col-6 text-end">
                <Button
                    title={t("Save")}
                    handleClick={handleSubmit}
                    className="form-button float-end"
                />
            </div>
        </div>
    );
};

export default AddTag;
