import FormNavigationOrganism from "components/organism/Actions/FormNavigationOrganism";
import General from "./components/General";
import Info from "./components/Info";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { t } from "../masterData/translation/Translation";
import AccessControl from "services/AccessControl";
import { useFormContext } from "./context/Context";
import SpecialAgreements from "./components/SpecialAgreements";
import { FormMode } from "components/common/CommonEnums";
import { useEffect, useState } from "react";
import { PATH_MANAGE_COOPERATION_AGREEMENT } from "constants/Paths";
import {
    CoefficientInitialState,
    coefficientKeys,
    SET_OPTIONS,
    SWITCH_TAB,
    UPDATE_FETCH_DATA,
    UPDATE_GENERAL_TAB_DATA,
    UPDATE_INFO_TAB_DATA,
    UPDATE_NEXT_OR_PREVIOUS_TAB,
    UPDATE_PCS_FIELD_ERROR,
    UPDATE_SPECIAL_AGREEMENT_TAB,
    UPDATE_TAB_ERROR,
    UPDATE_TABS_FIELDS_ERRORS,
    UPDATE_TEMPAGENCY_DATA
} from "./context/Constants";
import Coefficients from "./components/Coefficients";
import { validateAmount, validateForm, validatePercentage, validateSelectField } from "services/validation/ValidationService";
import { CoefficientsValidationRules, EmployeeTypeValidationRules, FunctionValidationRules, GeneralTabValidationRules, InfoTabValidationRules, PcsValidationRules, SpecialAgreementRules } from "./validationRules/ValidationRules";
import { ApiCall } from "services/ApiServices";
import { CREATE_COOPERATION_AGREEMENT, GET_COOPERATION_AGRE_DATA, GET_COOPERATION_AGREE_OPTIONS, GET_PC_FUNCTIONS } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { validateDuplicateCoefficients } from "./utils/Utils";

const CooperationAgreementForm = () => {

    const { state, dispatch } = useFormContext();
    const [isSticky, setIsSticky] = useState(false);
    const { tempAgId: routeId, coorpId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const currentTab = state?.currentTab;
    const pathName = location?.pathname?.split('/');
    const pageMode = pathName?.includes("edit") ? "edit" : (pathName?.includes("clone") ? "clone" : "create");



    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 10) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        getOptions();   //get all options

        if (pageMode !== 'create') {
            getCooperationData();
        }

        // dispatch({
        //     type: INITIAL_STATE,
        //     payload: INITIAL_STATE_OBJECT,
        // });

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const generalData = state?.general?.data;

    useEffect(() => {
        if ((generalData?.company !== null
            && (generalData?.blueCollarPcs?.pcsList || generalData?.whiteCollarPcs?.pcsList))) {
            getPcFunctions();
        }
        //Get function options when company and pcs,employee types are changed
    }, [generalData?.blueCollarPcs?.pcsList, generalData?.whiteCollarPcs?.pcsList, generalData?.company]);

    //set previous data
    const getCooperationData = async () => {
        try {
            const response = await ApiCall.getService(
                `${GET_COOPERATION_AGRE_DATA}/${coorpId}`,
                "get",
                M_MASTER_DATA,
                true,
            );
            if (response.status == 200) {
                const { basic, coefficient, specialAgreement, info } = response?.data;

                //Update general data
                dispatch({
                    type: UPDATE_GENERAL_TAB_DATA,
                    data: basic,
                });

                //Update coefficient data
                updateCoefficientData(coefficient);

                //Update special agreement data
                if (specialAgreement.length > 0) {
                    dispatch({
                        type: UPDATE_SPECIAL_AGREEMENT_TAB,
                        data: specialAgreement ?? [],
                        tab: "specialAgreement",
                    });
                }

                //Update info data
                dispatch({
                    type: UPDATE_INFO_TAB_DATA,
                    data: info,
                });

                dispatch({
                    type: UPDATE_FETCH_DATA,
                    isFetchData: false,
                })
            }

        } catch (error) {
            console.log(error);
        }
    }

    const formatPcFunctions = (pcData: any) => {
        const functions: number[] = [];
        pcData.forEach((eachItem: any) => {
            functions.push(eachItem?.pc?.value);
        });
        return functions;
    }

    const updateCoefficientData = (coefficientData: any) => {
        const newData = coefficientData?.map((eachPc: any) => {
            return {
                pcName: eachPc?.pcName,
                pcId: eachPc?.pcId,
                functionOptions: [],
                empTypeOptions: [],
                funEmpType: updateFunctionData(eachPc?.funEmpType)
            }
        });

        dispatch({
            type: UPDATE_SPECIAL_AGREEMENT_TAB,
            data: newData,
            tab: "coefficient",
        });
    }

    const updateFunctionData = (funcData: any) => {
        const newFuncData = funcData.map((eachFunc: any) => {
            return {
                function: eachFunc.function,
                id: eachFunc.id ?? null,
                errors: {
                    function: '',
                },
                empTypeData: updateEmpTypeData(eachFunc.empTypeData),
            }
        });
        return newFuncData;
    }

    const updateEmpTypeData = (empTypeData: any) => {
        const newEmpData = empTypeData?.map((eachEmp: any) => {
            const { selection, selectionReduced, payroll, selectionCent } = eachEmp?.coefficient[0];
            return {
                id: eachEmp?.id ?? null,
                employeeType: eachEmp?.employeeType ?? '',
                level: eachEmp?.level,
                type: eachEmp?.type,
                duration: eachEmp?.duration,
                startDate: eachEmp?.startDate ?? null,
                errors: {
                    employeeType: "",
                    level: "",
                    type: "",
                    duration: "",
                },
                coefficient: [{
                    selection,
                    selectionReduced,
                    payroll,
                    selectionCent,
                }],
                coeffErrors: {
                    selection: "",
                    selectionReduced: "",
                    payroll: "",
                    selectionCent: "",
                }
            }
        });
        return newEmpData;
    }

    const updateCoefficientTabData = (pcDetails: any) => {
        const pcData = [...generalData?.whiteCollarPcs?.pcsList, ...generalData?.blueCollarPcs?.pcsList];

        const prevCoeffData = [...state?.coefficient];

        const coefficientData: any = [];
        pcData?.map((eachPc: any) => {
            const isPresent = prevCoeffData?.find((eachPcData: any) => eachPcData?.pcId == eachPc?.pc?.value);
            if (isPresent) {
                const updatedData = {
                    ...isPresent,
                    functionOptions: pcDetails?.[eachPc?.pc?.value]?.functions ?? [],
                    empTypeOptions: eachPc?.employeeType
                };
                coefficientData.push(updatedData);
            }
            else {
                const newData = {
                    pcName: pcDetails?.[eachPc?.pc?.value]?.label,
                    pcId: eachPc?.pc?.value,
                    functionOptions: pcDetails?.[eachPc?.pc?.value]?.functions ?? [],
                    empTypeOptions: eachPc?.employeeType ?? [],
                    funEmpType: [
                        {
                            function: eachPc,
                            errors: {
                                function: '',
                            },
                            empTypeData: [
                                {
                                    employeeType: "",
                                    level: "",
                                    type: "",
                                    duration: "",
                                    startDate: null,
                                    errors: {
                                        employeeType: "",
                                        level: "",
                                        type: "",
                                        duration: "",
                                    },
                                    coefficient: [{
                                        selection: "",
                                        selectionReduced: "",
                                        payroll: "",
                                        selectionCent: "",
                                    }],
                                    coeffErrors: {
                                        selection: "",
                                        selectionReduced: "",
                                        payroll: "",
                                        selectionCent: "",
                                    }
                                }
                            ]

                        }
                    ]
                }
                coefficientData.push(newData);
            }

        });

        dispatch({
            type: UPDATE_SPECIAL_AGREEMENT_TAB,
            data: coefficientData,
            tab: "coefficient",
        });
    }

    const getPcFunctions = async () => {
        try {
            const generalData: any = state?.general?.data;
            const pcIds = formatPcFunctions([...generalData?.whiteCollarPcs?.pcsList, ...generalData?.blueCollarPcs?.pcsList]);
            const response = await ApiCall.service(
                GET_PC_FUNCTIONS,
                "POST",
                { pcids: pcIds },                                       //companyId: generalData?.company?.value,
                true,
                M_MASTER_DATA
            );
            if (response) {
                updateCoefficientTabData(response?.pcEmployeeTypes);  //update coefficient data
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const getOptions = async () => {
        try {
            const response = await ApiCall.service(
                GET_COOPERATION_AGREE_OPTIONS,
                "POST",
                { id: routeId, isEdit: coorpId ? false : true },
                true,
                M_MASTER_DATA
            )
            if (response) {
                const { wageElements, tempAgencyData, ...restOptions } = response;
                if (!coorpId) {
                    dispatch({
                        type: UPDATE_SPECIAL_AGREEMENT_TAB,
                        data: wageElements ?? [],
                        tab: "specialAgreement",
                    });
                }

                dispatch({
                    type: UPDATE_TEMPAGENCY_DATA,
                    tab: 'general',
                    data: tempAgencyData,
                });

                dispatch({
                    type: SET_OPTIONS,       //set options data
                    data: restOptions,
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const validateGeneralData = () => {
        const commonData = validateForm(state?.general?.data, GeneralTabValidationRules);
        if (Object.keys(commonData).length > 0) {
            dispatch({
                type: UPDATE_TABS_FIELDS_ERRORS,
                tab: "general",
                errors: commonData,
            });
            return true;
        }
        return !(Object.keys(commonData).length == 0 && validatePcsData());
    }

    const validatePcsData = () => {
        const whitecollarPcs = state?.general?.data?.whiteCollarPcs?.pcsList;
        const blueCollarpcs = state?.general?.data?.blueCollarPcs?.pcsList;
        if (whitecollarPcs.length == 0 && blueCollarpcs.length == 0) {
            const validationErrors = validateForm(state?.general?.data, PcsValidationRules);
            dispatch({
                type: UPDATE_PCS_FIELD_ERROR,
                errors: validationErrors,
                tab: 'general',
                entityType: 'whiteCollarPcs',
            });
            return false;
        }
        return true;
    }

    const validateSpecialAgreement = () => {
        let hasError = true;

        const spAgreeErrors = [...state?.specialAgreement]?.map((eachItem: any) => {
            if (eachItem?.wageId !== 29) {
                return eachItem;
            }
            const benefitType = eachItem?.type == 0 ? { ...SpecialAgreementRules, benefit_value: [validateAmount] } :
                (eachItem?.type == 1 ? ({ ...SpecialAgreementRules, benefit_value: [validateSelectField] })
                    : ({ ...SpecialAgreementRules, benefit_value: [validatePercentage] }));

            const validateErrors = validateForm(
                {
                    benefit_value: eachItem?.benefit_value,
                }, benefitType);

            if (Object.keys(validateErrors).length > 0) {
                hasError = hasError && false;
                return { ...eachItem, errors: validateErrors };
            }
            return { ...eachItem, errors: { benefit_value: '' } };
        });

        dispatch({
            type: UPDATE_SPECIAL_AGREEMENT_TAB,
            data: spAgreeErrors,
            tab: "specialAgreement",
        });
        return !hasError;
    }

    const checkForMaximumValue = (empTypeData: any) => {
        const { employeeType, level, coefficient } = empTypeData;
        const { company } = state?.general?.data;

        //GetParticular coefficients values based on the employee type level
        const { defaultCoefficients } = state?.options;
        const currentValidationValues = defaultCoefficients[company?.value][employeeType?.value]?.[level?.value ?? level];

        const errorsValues: any = {};
        Object.entries(coefficient[0])?.forEach(([coeff, value]) => {
            const maxValue = parseFloat(currentValidationValues[coefficientKeys[coeff]]);
            const currentValue = coefficient[0][coeff]?.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            const result = parseFloat(currentValue) > maxValue;

            if (result) {
                errorsValues[coeff] = `Value should not exceed ${maxValue}`;
            }
        });
        return errorsValues;
    }


    const validateCoefficients = () => {
        let hasError = true;
        const coeffData = [...state?.coefficient].map((eachItem: any) => {
            let funcError = true;
            const funcData = eachItem?.funEmpType?.map((eachFunc: any) => {
                const funcErrors = validateForm({ function: eachFunc?.function }, FunctionValidationRules);
                if (Object.keys(funcErrors).length > 0) {
                    funcError = funcError && false;
                }

                let isEmpError = true;
                const empTypeData = eachFunc?.empTypeData?.map((eachType: any) => {
                    const { employeeType, level, type, duration, coefficient } = eachType
                    const empTypeErrors = validateForm({ employeeType, level, type, duration }, EmployeeTypeValidationRules);

                    let newEmpData = eachType;
                    if (Object.keys(empTypeErrors).length > 0) {
                        newEmpData = { ...newEmpData, errors: empTypeErrors };
                        isEmpError = isEmpError && false;
                    }
                    else {
                        newEmpData = { ...newEmpData, errors: { employeeType: '', level: '', type: '', duration: '' } };
                    }

                    const coeffErrors = validateForm({ ...coefficient[0] }, CoefficientsValidationRules);

                    if (Object.keys(coeffErrors).length > 0) {
                        newEmpData = { ...newEmpData, coeffErrors };
                        isEmpError = isEmpError && false;
                    }
                    else {
                        const hasCoeffError = checkForMaximumValue(eachType); //Check wheather coeff value exceed max value
                        let defaultErrors = { selection: '', selectionReduced: '', payroll: '', selectionCent: '' };
                        if (Object.keys(hasCoeffError).length > 0) {
                            isEmpError = isEmpError && false;
                            defaultErrors = { ...defaultErrors, ...hasCoeffError };
                        }
                        newEmpData = { ...newEmpData, coeffErrors: defaultErrors };
                    }

                    return newEmpData;
                });
                funcError = isEmpError && funcError;
                return { ...eachFunc, empTypeData, };
            });
            hasError = hasError && funcError;
            return { ...eachItem, funEmpType: funcData };
        });

        if (!hasError) {
            dispatch({
                type: UPDATE_SPECIAL_AGREEMENT_TAB,
                data: coeffData,
                tab: "coefficient",
            });
        }
        return hasError;

    }

    const validateInfoTabData = () => {
        const infoErrors = validateForm(state?.info?.data, InfoTabValidationRules);
        if (Object.keys(infoErrors).length > 0) {
            dispatch({
                type: UPDATE_TABS_FIELDS_ERRORS,
                tab: "info",
                errors: infoErrors,
            });
            return true;
        }
        return false;
    }

    const validateOverAllForm = () => {
        let isErrorList: any = [];
        state?.tabs?.forEach((eachTab: any) => {
            let isError = false;
            switch (eachTab?.id) {
                //Validate general tab data
                case "general":
                    isError = validateGeneralData();

                    break;

                //Validate coefficient tab data
                case "coefficient":
                    isError = !validateCoefficients();

                    if (!isError) {
                        const hasDuplicates = validateDuplicateCoefficients(state?.coefficient)
                        if (!hasDuplicates?.hasError) {
                            isError = !hasDuplicates?.hasError
                            dispatch({
                                type: UPDATE_SPECIAL_AGREEMENT_TAB,
                                data: hasDuplicates?.coefficients,
                                tab: "coefficient",
                            });
                        }
                    }
                    break;

                //Validate special agreement tab data
                case "specialAgreements":
                    isError = validateSpecialAgreement();
                    break;

                //Validate info tab data
                case "info":
                    isError = validateInfoTabData();
                    break;
                default:
                    break;
            }

            isErrorList.push(isError);

            dispatch({
                type: UPDATE_TAB_ERROR,
                error: isError,
                tab: eachTab?.id
            });
        });

        return isErrorList.includes(true);

    }

    const ConstructTabs = (tab: string | number) => {
        switch (tab) {
            case "general":
                return <General />;
            case "coefficient":
                return <Coefficients />;
            case "specialAgreements":
                return <SpecialAgreements />;
            case "info":
                return <Info />;
            default:
                break;
        }
    };

    const handleDraft = () => {
        //
    }

    const handleSubmit = async () => {

        const hasError = validateOverAllForm();
        if (hasError) {
            return
        }

        const {
            noOfDays,
            billingType,
            settlementType,
            comments,
            accountNo,
            mandateReference,
            type,
            date,
            paymentByDirectDebit, ...invoice_options } = state?.info?.data;

        const infoData = {
            noOfDays,
            billingType,
            settlementType,
            comments,
            accountNo: paymentByDirectDebit ? accountNo : "",
            mandateReference: paymentByDirectDebit ? mandateReference : "",
            type: paymentByDirectDebit ? type : null,
            date: paymentByDirectDebit ? date : null,
            paymentByDirectDebit,
            invoice_options
        };



        const data = {
            tempAgId: routeId,
            basic: state?.general?.data,
            specialAgreement: state?.specialAgreement,
            info: infoData,
            coefficient: state?.coefficient,
        }
        const postData = pageMode == "edit" ?
            { id: coorpId, ...data } : (pageMode == 'clone' ? { cloneId: coorpId, ...data, } : data);

        try {
            const response = await ApiCall.service(
                CREATE_COOPERATION_AGREEMENT,
                "POST",
                postData,
                true,
                M_MASTER_DATA,
            );
            if (response?.status == 200) {
                CustomNotify({ type: "success", message: response?.message });
                navigate(`${PATH_MANAGE_COOPERATION_AGREEMENT}/${routeId}`);
            }
            else {
                CustomNotify({ type: "error", message: response?.message });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleNextStep = () => {
        dispatch({
            type: UPDATE_NEXT_OR_PREVIOUS_TAB,
            navigationType: "next",
        });
    }

    const handlePreviousStep = () => {
        dispatch({
            type: UPDATE_NEXT_OR_PREVIOUS_TAB,
            navigationType: "previous",
        });
    }

    function handleTabSwitch(tab: string) {
        dispatch({
            type: SWITCH_TAB,
            field: "currentTab",
            value: tab,
        });
    }

    const presentOperation = pageMode == "edit" ? "update" : "create";
    const currentObj: any = {
        permission: "Cooperation agreement",
    };
    currentObj[presentOperation] = true;
    const userData = useSelector(selectAuth);

    const pageTitle = pageMode == "clone" ? "Clone" : (pageMode == "edit" ? "Edit" : "Create");

    return (
        <div className="position-relative">
            <AccessControl
                requiredPermissions={[currentObj]}
                renderNoAccess={true}
                override={userData.isSuperAdmin}
            >
                <div
                    className="row header-sticky position-sticky"
                    style={{ marginBottom: "2.3vw", height: "auto" }}
                >
                    <div className="col-12">
                        <h1
                            className="page-title mb-0 d-flex align-items-center"
                            style={{ paddingBottom: "0.7vw" }}
                        >
                            {t(`${pageTitle} cooperation agreement`)}
                        </h1>
                    </div>
                </div>
                <nav
                    className={`projectTabs company-step-tabs createFormTabs ${isSticky
                        ? "createTabsSticky position-sticky"
                        : "company-step-tabs-absolute position-absolute w-100"
                        }`}
                >
                    <div
                        className="d-flex border-0 w-100 m-auto gap-3"
                        id="nav-tab"
                        role="tablist"
                    >
                        <div
                            className={`d-flex border-0 w-100 m-auto align-items-center justify-content-center`}
                            style={{ gap: "1vw" }}
                        >
                            {state.tabs.map((item: any) => (
                                <div className={`flex-1 text-center`} key={item.id}>
                                    <div
                                        className={`createStepBtn cursor-pointer ${state.currentTab === item.id ? "active" : "inactive"
                                            } ${item.error === true ? "error" : ""
                                            }`}
                                        onClick={() => handleTabSwitch(item.id)}
                                    >
                                        {t(item.title)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </nav>
                <div className="mt-2">{ConstructTabs(state.currentTab)}</div>
                <FormNavigationOrganism
                    isFirstTab={state.currentTab === state.tabs[0].id}
                    isSubmitTab={
                        state.currentTab === state.tabs[state.tabs.length - 1].id
                    }
                    draftButtonIsVisible={state.mode === FormMode.CREATE}
                    formExitBackTo={`${PATH_MANAGE_COOPERATION_AGREEMENT}/${routeId}`}
                    handlePreviousStep={handlePreviousStep}
                    handleNextStep={handleNextStep}
                    handleSubmit={handleSubmit}
                // {...(state.general.data.status == 0 && { handleDraft })}
                />
            </AccessControl>
        </div>
    );
}
export default CooperationAgreementForm