import Button from "components/atoms/Button";
import React, { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import { ApiCall } from "services/ApiServices";
import { MONTHS_TRANSLATABLE, M_MASTER_DATA } from "constants/Constants";
import AddPartenaCalculation from "./AddPartenaCalculation";
import { useLocation, useNavigate } from "react-router-dom";
import { PartenaData } from "./PartenaInterface";
import TitleAtom from "components/atoms/Title";
import ModalPopup from "components/atoms/ModalPopup";
import { GET_PARTENA_OVERVIEW_DATA } from "routes/ApiEndpoints";
import FilterOrganism from "components/organism/FilterOrganism";
import { useDispatch, useSelector } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { PartenaTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { Spinner } from "react-bootstrap";
import AccessControl from "services/AccessControl";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import TextEllipsis from "components/atoms/TextEllipsis";

const ManagePartena = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const addedPartenaCalculation = location?.search?.includes('addPartenaCalculation');
  const [partenaData, setPartenaData] = useState<PartenaData[]>();
  const [showModal, setShowModal] = useState(false);
  const pageHistory = useSelector(selectPageHistory);
  const userData = useSelector(selectAuth);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
    };
    if (addedPartenaCalculation) {
      setShowModal(false);
    }
    fetchData(postData);
  }, [pageData?.refresh, pageData?.initialCall, location, addedPartenaCalculation]);

  const getSearchData = () => {
    return {
      month: history?.filters?.month ?? "",
      businessUnit: history?.filters?.businessUnit ?? "",
      numberOfEmployees: history?.filters?.numberOfEmployees ?? "",
      numberSent: history?.filters?.numberSent ?? "",
      quantityNotSent: history?.filters?.quantityNotSent ?? "",
      createdBy: history?.filters?.createdBy ?? "",
      timeOfCreation: history?.filters?.timeOfCreation ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
  };
  const filters = [
    { name: 'month', fieldType: "text", placeholder: "Month", filterType: 'search' },
    { name: 'businessUnit', fieldType: "text", placeholder: "Business unit", filterType: 'search' },
    { name: 'numberOfEmployees', fieldType: "text", placeholder: "Number of employees", filterType: 'search' },
    { name: 'numberSent', fieldType: "text", placeholder: "Number sent", filterType: 'search' },
    { name: 'quantityNotSent', fieldType: "text", placeholder: "Quantity not sent", filterType: 'search' },
    { name: 'createdBy', fieldType: "text", placeholder: "Created by", filterType: 'search' },
    { name: 'timeOfCreation', fieldType: "date", placeholder: "Time of creation", filterType: 'search' },
  ];

  const fetchData = async (postData: any) => {
    const response = await ApiCall.service(GET_PARTENA_OVERVIEW_DATA, "POST", postData, true, M_MASTER_DATA);
    setPartenaData(response?.data);
    setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords, initialCall: false }));
    // setShowModal(!response?.modalStatus);
    setLoading(false);
  }

  const handleClick = async (event?: any, status: string = "", reset: boolean = true) => {
    if (status === "partenaCalculation") {
      navigate(`/partena/detail/${event.id}`)
    }
    const popUpStatus = status === "add" ? true : status === "close" ? false : false;
    setShowModal(popUpStatus);
    // status === "partenaCalculation" && navigate(`/partena/calculation/${event.id}`);
  }

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Partena",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Partena")} />
        </div>
        <div className="position-relative tableMainWrapper">

          <div className="row">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Partena",
                  create: true,
                },
              ]}
              renderNoAccess={true}
              override={userData.isSuperAdmin}
            >
              <div className="ManageCreateBtn">
                <Button
                  title={"+ " + t("Add")}
                  handleClick={(e) => handleClick(e, "add")}
                  className={"form-button shadow-none"}
                />
              </div>
            </AccessControl>
          </div>

          <div className="table-responsive managePartena tableSection">
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filters}
              handleRefresh={handleRefresh}
            />
            <div className="partenaTableHeight">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    {PartenaTableHeader?.map((header: any) => (
                      <th key={header?.alias}>
                        <>
                          {t(header.name)}
                          {header?.issort && (
                            <SortAtomForManagePage
                              value={{ alias: header?.alias }}
                              dispatch={dispatch}
                              history={history}
                              updatePageFilters={updatePageFilters}
                              handleRefresh={handleRefresh}
                            />
                          )}
                        </>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {loading ? <tr className="border-0">
                    <td
                      className="text-center text-info border-0 spinner-wrapper "
                      colSpan={10} rowSpan={4}
                    >
                      <Spinner size="sm" className="me-2 spinnerStyle" />{" "}
                      {t("Loading")}
                    </td>
                  </tr> :
                    <>
                      {partenaData && partenaData.length > 0 ?
                        (partenaData.map((record: any) => (<tr className="cursor-pointer" onClick={() => handleClick(record, "partenaCalculation")}>
                          <td>{MONTHS_TRANSLATABLE[record.month - 1]}</td>
                          <td>
                            <TextEllipsis title={record.businessUnits && record.businessUnits.map((record: any) => (record.business_unit_name)).join(', ')} label={record.businessUnits && record.businessUnits.map((record: any) => (record.business_unit_name)).join(', ')} width="9vw" />
                          </td>
                          <td>{record.businessUnits && record.businessUnits.map((record: any) => (record.no_of_employees))}</td>
                          <td>{record.businessUnits && record.businessUnits.map((record: any) => (record.export_employees))}</td>
                          <td>{record.quantityNotSent}</td>
                          <td>{record.name}</td>
                          <td>{record.creationTime}</td>
                          <td className="align-middle text-break">
                            <span
                              className={`d-flex align-items-center rounded-circle status-icon ${record.status !== 0
                                ? "bg-danger"
                                : "bg-success"
                                }`}
                              data-toggle="tooltip"
                            ></span>
                          </td>
                        </tr>
                        ))) : (<tr className="w-100 m-auto text-center bg-warning">
                          <td className="text-danger" colSpan={8}>
                            {t(" No records found")}
                          </td>
                        </tr>)

                      }
                    </>
                  }
                </tbody>
                <tr></tr>
              </table>
            </div >
            <div
              className="pagination d-block"
              style={{ paddingTop: "0.25vw" }}
            >
              {
                !loading && <div className="">
                  <PaginationWithPerPage
                    handleRefresh={handleRefresh}
                    dispatch={dispatch}
                    history={history}
                    pageData={pageData}
                  />

                </div>
              }
            </div>
          </div>
        </div>
        {showModal &&
          <>
            <ModalPopup
              show={showModal}
              // onHide={(e) => handleClick(e, "close")}
              onHide={() => handleClick(undefined, "close")} // Wrap `handleClick` in a no-arg function
              title={t("Partena add new calculation")}
              body={<AddPartenaCalculation />}
              className="partenaPopup"
            />
          </>
        }
      </>
    </AccessControl>
  );
}

export default ManagePartena;