import { useEffect, useState } from "react";
import Car from "./car/Car";
import Material from "./material/Material";
import Clothes from "./clothes/Clothes";
import Supplier from "./supplier/Supplier";
import Client from "./client/Client";
import { Link, useParams, useNavigate } from "react-router-dom";
import { t } from "../masterData/translation/Translation";
import PurchaseOrder from "./purchaseOrder/PurchaseOrder";
import ManageFleetLocation from "./locations/ManageLocations";
import BackButton from "components/atoms/BackButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import { selectAuth } from "features/auth/AuthSlice";

const FleetOverview = () => {
  const userData = useSelector(selectAuth);
  const isEmployee = userData.role?.includes("EMPLOYEE");
  const staticTabs = [
    { id: "car", tabName: "Cars", component: Car, open: 0, total: 0 },
    {
      id: "material",
      tabName: "Materials",
      component: Material,
      open: 0,
      total: 0,
    },
    { id: "clothes", tabName: "Clothes", component: Clothes },
    {
      id: "supplier",
      tabName: "Supplier",
      component: Supplier,
      showTab: isEmployee,
    },
    {
      id: "client",
      tabName: "Client",
      component: Client,
      showTab: !isEmployee,
    },
    {
      id: "purchaseOrder",
      tabName: "Purchase order",
      component: PurchaseOrder,
      showTab: !isEmployee,
    },
    {
      id: "locations",
      tabName: "Locations",
      component: ManageFleetLocation,
      showTab: !isEmployee,
    },
  ];

  const dispatch = useDispatch();
  const pageHistory = useSelector(selectPageHistory);

  const history =
    pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const params = useParams();
  const navigate = useNavigate();
  const [tabs, setTabsData] = useState(staticTabs);

  const [activeTab, setActiveTabs] = useState(tabs[0]);
  const visibleTabs = tabs.filter((tab) => tab.showTab !== false);
  useEffect(() => {
    updateCurrentTab();
  }, [params.type]);

  const updateCurrentTab = () => {
    const tabType = params.type;
    const updatedCurrentTab = tabs.filter((eachTab: any) => {
      if (eachTab.id == tabType) {
        return eachTab;
      }
      return false;
    });
    setActiveTabs(updatedCurrentTab[0]);
  };

  const handleChangeTab = (event: any) => {
    const { id } = event.target;
    const updatedTab = tabs.filter((eachItem) => {
      if (eachItem.tabName === event.target.textContent) {
        setActiveTabs(eachItem);
        return { ...eachItem };
      } else {
        return { ...eachItem };
      }
    });
    //setTabsData(updatedTab);
    navigate(`/fleetOverview/${id}`);
  };

  return (
    <>
      <div
        className="mx-auto d-grid createFormTabs fleetCreateFormTabs"
        style={{ gridTemplateColumns: "repeat(7,1fr)", gap: "1vw" }}
      >
        {visibleTabs.map((eachItem) => (
          <button
            key={eachItem.id}
            className={
              eachItem.id === activeTab.id
                ? "active fleetAndMaterialBtnActive"
                : "inactive fleetAndMaterialBtn"
            }
            id={eachItem.id}
            onClick={handleChangeTab}
            style={{ marginBottom: "0.25vw" }}
          >
            {eachItem.tabName}
          </button>
        ))}
      </div>
      {activeTab.component && (
        <activeTab.component
          dispatch={dispatch}
          history={history}
          setfunc={setTabsData}
        />
      )}
      {/* <div className="col-md-6 align-self-center" style={{ padding: "0.5vw 0" }}>
        <BackButton />
      </div> */}
    </>
  );
};
export default FleetOverview;
