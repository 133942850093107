import React, { useRef, useState } from "react";
import { useTimesheetModalContext, useTimesheetModalDispatch } from "../timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import { TIMESHEET_MODAL_ACTIONS } from "../timesheets-redux/actions/TimesheetModalActions";
import { getWeekDays } from "../utils/utils";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { t } from "pages/microservices/masterData/translation/Translation";
import TextEllipsis from "components/atoms/TextEllipsis";
import { decimalToTime, timeToDecimal } from "../utils/calculateUtils";

const PerformanceAndInvoice = ({ title, performanceAndInvoiceData, modalName, isDisabled, values }) => {
  const { initialTimesheetModalState } = useTimesheetModalContext();
  const { timesheetModalDispatch } = useTimesheetModalDispatch();
  const { filterOptionsState } = useFilterOptions()

  // Store total values persistently
  const totalRef = useRef({});

  const handleModalDispatch = (modalStatus, index, planId, item = [], editIndex = null) => {
    const actualDate = new Date(filterOptionsState?.selectedState?.currentDate);
    let weekDays = getWeekDays(actualDate);
    const date = new Date(weekDays[index]);
    const yy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${yy}-${mm}-${dd}`;
    timesheetModalDispatch({
      type: TIMESHEET_MODAL_ACTIONS.OPEN_AND_CLOSE_MODAL,
      payload: {
        status: !modalStatus,
        modalType: modalName,
        date: formattedDate,
        planId: planId,
        data: item ?? [],
        index: editIndex ?? null,
      },
    });
  };

  const isValid = performanceAndInvoiceData?.length > 0;
  const dataToRender = isValid ? performanceAndInvoiceData : Array(7).fill({});
  const priorityOrder = modalName === "performance" ? ['Worked', 'Overtime', 'Overtime allowance on a weekly basis'] : ['Km-allowance'];

  const renderItems = () => {
    let weekDays = [<td key="heading1"></td>, <td key="heading2"></td>];
    dataToRender.forEach((item, i) => {
      totalRef.current[i] = { total: 0, totalPrice: 0 };
      weekDays.push(
        item?.plan_id ? (
          <td
            key={i}
            className=" performanceTableData"
          >
            {(item?.[modalName] && Object?.values(item?.[modalName])?.length > 0) ? (
              <div className="d-flex flex-column justify-content-between h-100">
                {Object.values(item[modalName]).sort((a, b) => {
                  const aPriority = priorityOrder.indexOf(
                    values
                      .map((val) => val?.options?.find((opt) => a[val?.key] == opt?.value)?.label)
                      .find((label) => priorityOrder.includes(label))
                  );
                  const bPriority = priorityOrder.indexOf(
                    values
                      .map((val) => val?.options?.find((opt) => b[val?.key] == opt?.value)?.label)
                      .find((label) => priorityOrder.includes(label))
                  );

                  // Ensure that items found in priorityOrder come first
                  return (aPriority === -1 ? 999 : aPriority) - (bPriority === -1 ? 999 : bPriority);
                }).map((value, index) => {
                  const hourOrNumber = modalName === "performance" ? timeToDecimal(value?.hours ?? "00:00") : parseFloat((value?.numbers ?? "0")?.replace?.(",", "."));
                  const price = parseFloat((value?.totalPrice ?? "0")?.replace(",", "."));
                  
                  totalRef.current[i].total += hourOrNumber;
                  totalRef.current[i].totalPrice += price;

                  return (
                    <div className="timesheethovermain cursor-pointer marginBottomPoint5 text-white rounded text-start" key={index} onClick={() => handleModalDispatch(initialTimesheetModalState[modalName]?.isOpen, i, item?.plan_id, value, index)}>
                      {values.map((val, idx) =>
                        val?.options ? (
                          <div key={idx}>
                            <TextEllipsis
                              title={val?.options.find((opt) => value[val?.key] == opt?.value)?.label}
                              label={val?.options.find((opt) => value[val?.key] == opt?.value)?.label}
                              width="5vw"
                            />
                          </div>
                        ) : null
                      )}
                      <div className="d-grid timesheetGridTemplate">
                        {values.map((val, idx) =>
                          val?.options ? null : val?.key === "totalPrice" ? (
                            <React.Fragment key={idx}>
                              <div>{val.first}</div>
                              <div>
                                <TextEllipsis title={`${value[val?.key] ?? ""}${val?.last}`} label={`${value[val?.key] ?? ""}${val?.last}`} width="2.8vw" />
                              </div>
                            </React.Fragment>
                          ) : (
                            <div key={idx}>
                              <span>{`${val?.first}${value[val?.key] ?? ""}${val?.last}`}</span>
                            </div>
                          )
                        )}
                      </div>

                      <div className="timesheethoverchild cursor-pointer">
                        {values.map((val, idx) =>
                          val?.options ? (
                            <div key={idx} className="d-flex justify-content-start w-100">
                              <span className="marginRightPoint5">{`${val?.name}: `}</span>
                              <span>{val?.options.find((opt) => value[val?.key] == opt?.value)?.label}</span>
                            </div>
                          ) : (
                            <div key={idx} className="d-flex justify-content-start w-100">
                              <span className="marginRightPoint5">{`${val?.name}: `}</span>
                              <span>{value[val?.key] ?? ""}</span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )
                })}
                {!isDisabled && <div title={t("Add new")} className="cursor-pointer" onClick={() => handleModalDispatch(initialTimesheetModalState[modalName]?.isOpen, i, item?.plan_id)}>
                  <div className="bg-white">+</div>
                </div>}
              </div>
            ) : (
              <>
                {!isDisabled && <div title={t("Add new")} className="cursor-pointer" onClick={() => handleModalDispatch(initialTimesheetModalState[modalName]?.isOpen, i, item?.plan_id)}>
                  <div className="bg-white">+</div>
                </div>}
              </>
            )}
          </td>
        ) : (
          <td key={i} className=""></td>
        )
      );
    });
    return weekDays;
  };

  const renderTotal = () => {
    const totalData = totalRef.current;    
    // Calculate grand totals
    const grandTotal = Object.values(totalData).reduce((sum, item) => sum + item?.total, 0);
    const grandTotalPrice = Object.values(totalData).reduce((sum, item) => sum + item?.totalPrice, 0);
  
    return (
      <tr>
        {/* First column with Total and Total Price stacked */}
        <td className="text-start" colSpan={2}>
          <div>{t("Total")}</div>
          <div>{t("Total Price")}</div>
        </td>
  
      
  
        {/* Loop through totalRef.current for 7 days */}
        {Object.keys(totalData).map((key) => (
          <td className="text-start" key={key}>
            <div>{modalName === "performance" ? decimalToTime(totalData[key]?.total?.toString() ?? "0") : (totalData[key]?.total ?? 0)?.toFixed?.(2)?.replace?.(".", ",")}</div>
            <div>{(totalData[key]?.totalPrice ?? 0)?.toFixed?.(2)?.replace?.(".", ",")} EUR</div>
          </td>
        ))}
  
        {/* Last column with grand total */}
        <td className="fw-bold">
          <div>{modalName === "performance" ? decimalToTime(grandTotal?.toString() ?? "0") : grandTotal?.toFixed?.(2)?.replace?.(".", ",")}</div>
          <div>{grandTotalPrice?.toFixed?.(2)?.replace?.(".", ",")} EUR</div>
        </td>
      </tr>
    );
  };
  
  return (
    <>
      <tr>
        {renderItems()}
      </tr>
      {renderTotal()}
    </>
  );
};
export default PerformanceAndInvoice;
