import { Option } from "utils/TypeAnnotations";

//Manage wage porposal
export interface ManageWageProposalProps {
  id: number | null;
  user_id: number | null;
  employeeContractType: string;
  businessUnit: string;
  paritairComitee: string;
  salary: string;
  status: number | null;
  createdDate: null;
  salaryBenefits: null;
  businessUnitId: number | null;
  wagePropsalDownLoadPath: string | null;
}

export interface manageDropdownData {
  businessUnit: Option | null;
  paritairComitee: Option | null;
  employeeContractType: Option | null;
  salaryBenefits: Option | null;
}

export const initialPageDetails = {
  totalPages: 0,
  totalRecords: 0,
  refresh: true,
  initialCall: true,
};

export const initialManageData = {
  businessUnit: [],
  paritairComitee: [],
  employeeContractType: [],
};

//Create wageproposal
export interface SalaryBenefitProps {
  value: number | null;
  label: string;
  benefitValueType: number | Option | null;
  benefitValue: number | null;
  functionId: number | null;
}

export interface WageProposalProps {
  userId: number;
  employeeContractType: Option | null;
  businessUnit: Option | null;
  paritairComitee: Option[];
  function: Option[];
  salaryCategory: Option | null;
  salary: number | undefined;
  salaryBenefits: SalaryBenefitProps[] | null;
  salaryType: Option | null;
  info: string;
}

export const initialDropdownData = {
  employeeContractType: [],
  businessUnit: [],
  paritairComitee: [],
  function: [],
  salary: [],
  salaryBenefits: [],
  salaryCategory: [],
  salaryType: [],
};

export const initialSalaryBenefits = {
  value: null,
  label: "",
  benefitValueType: null,
  benefitValue: null,
  functionId: null
};

export const statusOptions = [
  { value: 0, label: "Created" },
  { value: 1, label: "sended to candidate" },
  { value: 2, label: "Approved" },
  { value: 4, label: "Rejected" },
  { value: 5, label: "Inactive" },
];
