import TitleFieldMolecule from "components/molecules/TitleField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { useState, ChangeEvent, useEffect } from "react";
import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import Button from "components/atoms/Button";
import Calender from "components/molecules/Calender";
import TableActions from "components/organism/Actions/TableAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import { ApiCall } from "services/ApiServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  EDIT_HOLIDAY_CODES,
  GET_HOLIDAY_CODES_DATA,
  GET_OPTIONS_FOR_HOLIDAY_CODES,
  SAVE_HOLIDAY_CODES,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { MANAGE_HOLIDAY_CODES } from "constants/Paths";
import { t } from "../../translation/Translation";
import { LabelWithInputFieldAndUnit } from "../../templates/atoms/LabelWithInputFieldAndUnit";
import Title from "components/atoms/Title";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import BackButton from "components/atoms/BackButton";
import CheckBoxField from "components/atoms/CheckBoxField";
// import InputColorfield from "components/atoms/InputColorField";

interface HolidayData {
  holiday_name: string;
  holiday_code: string;
  holiday_from: string | Date;
  holiday_to: string | Date;
  holiday_type: any;
  contract_type: any;
  employee_category: any;
  holiday_count: string;
  count_type: any;
  performanceCode: string;
  defaultHolidayCode: number | null;
  colorCode: string | undefined,
  businessUnit: string;
  company: string;
}

const CreateHolidayCodes = () => {
  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await ApiCall.getService(
        GET_OPTIONS_FOR_HOLIDAY_CODES,
        "GET",
        M_MASTER_DATA,
        false
      );
      if (response?.status === 200) {
        setHolidayCodeOptions((prevState) => ({
          ...prevState,
          holiday_types: response.data.holidayType,
          contract_type: response.data.contractTypes,
          employee_category: response.data.employeeCategory,
          partenaCodes: response?.data.partenaCodes,
          colorCode: response?.data?.colorCode,
          companyList: response?.data?.companyList,
          businessUnitList: response?.data?.businessUnitList
        }));
        fetchData();
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [holidayData, setHolidayData] = useState<HolidayData>({
    holiday_name: "",
    holiday_code: "",
    holiday_from: "",
    holiday_to: "",
    holiday_type: "",
    contract_type: "",
    employee_category: "",
    holiday_count: "",
    count_type: "",
    performanceCode: "",
    defaultHolidayCode: 0,
    colorCode: "",
    businessUnit: "",
    company: ""
  });

  const initialHolidayCodeErrors = {
    holiday_name: "",
    holiday_code: "",
    holiday_from: "",
    holiday_to: "",
    holiday_type: "",
    contract_type: "",
    employee_category: "",
    holiday_count: "",
    count_type: "",
    performanceCode: "",
    colorCode: "",
    businessUnit: "",
    company: ""
  };
  const [error, setErrorData] = useState(initialHolidayCodeErrors);

  const countType = [
    { value: 1, name: "Hour's", label: "Hour's" },
    { value: 2, name: "Day's", label: "Day's" },
  ];

  const [holidayDetails, setHolidayDetails] = useState<Array<object>>([]);
  const [editIndex, setEditIndex] = useState(null);
  const { id: id } = useParams();
  const navigate = useNavigate();
  const [holidayCodeOptions, setHolidayCodeOptions] = useState({
    holiday_types: [],
    contract_type: [],
    employee_category: [],
    partenaCodes: [],
    colorCode: [],
    businessUnitList: [],
    companyList: []
  });

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchData = async () => {
    if (id) {
      try {
        const response = await ApiCall.service(
          GET_HOLIDAY_CODES_DATA,
          "POST",
          { id: id },
          false,
          M_MASTER_DATA
        );
        if (response.status == 200) {
          setHolidayData((prevState) => ({ ...prevState, ...response.data }));
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
  };

  const handleInputChange = (event: any, category: null | string = null) => {
    const name = category === null ? event.target.name : category;
    let value = event.target === undefined ? event : event.target.value;

    const { type, checked } = event?.target;
    if (type === "checkbox") {
      value = checked ? 1 : 0;
    }

    setHolidayData((prevState) => ({ ...prevState, [name]: value }));
    validateHolidayData(name, value, true);
  };

  const handleSelectChange = (event: any, category: string) => {
    let updatedValue: any;
    if (Array.isArray(event)) {
      updatedValue = [...event];
    } else {
      updatedValue = event;
    }

    setHolidayData((prevState) => {
      return { ...prevState, [category]: updatedValue };
    });

    validateHolidayData(category, event, true);
  };

  const addAnotherData = () => {
    if (validateHolidayData()) {
      setHolidayDetails((prevState: any) => [...prevState, holidayData]);
      setHolidayData((prevData) => ({
        ...prevData,
        holiday_name: "",
        holiday_code: "",
        holiday_from: "",
        holiday_to: "",
        holiday_type: "",
        contract_type: "",
        employee_category: "",
        holiday_count: "",
        count_type: "",
        colorCode: "",
      }));
      setErrorData(initialHolidayCodeErrors);
    }
  };

  const handleOnSubmit = async () => {
    if (validateHolidayData()) {
      let finalHolidayCodes = null;
      if (holidayDetails.length === 0) {
        finalHolidayCodes = [holidayData];
      } else {
        finalHolidayCodes = [...holidayDetails, holidayData];
      }
      try {
        const response = await ApiCall.service(
          SAVE_HOLIDAY_CODES,
          "POST",
          { data: id ? { ...finalHolidayCodes, id } : finalHolidayCodes },
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          CustomNotify({ type: "success", message: response?.message });
          navigate(`${MANAGE_HOLIDAY_CODES}`);
        } else {
          CustomNotify({ type: "error", message: response?.message });
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
  };

  const handleDateChange = (event: any, categoryName: string) => {
    if (event) {
      const date = `${event.getFullYear()}-${event.getMonth() + 1 < 10
        ? "0" + (event.getMonth() + 1)
        : event.getMonth() + 1
        }-${event.getDate() < 10 ? "0" + event.getDate() : event.getDate()}`;
      setHolidayData((prevState) => ({ ...prevState, [categoryName]: date }));
      validateHolidayData(categoryName, date, true);
    }
  };

  const validateHolidayData = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationRule: any = {
      holiday_name: [validateRequired],
      holiday_code: [validateRequired],
      holiday_from: [validateRequired],
      holiday_to: [validateRequired],
      holiday_type: [validateSelectField],
      contract_type: [validateMultiSelectField],
      employee_category: [validateSelectField],
      holiday_count: [validateRequired],
      count_type: [validateSelectField],
      colorCode: [validateSelectField],
      performanceCode: [validateSelectField],
    };
    const validationErrors: any = validateForm(
      { ...holidayData, [name]: value },
      isSingleFieldValidation ? validationRule[name] : validationRule
    );
    const endDateValidation =
      new Date(name === "holiday_from" ? value : holidayData.holiday_from) <=
      new Date(name === "holiday_to" ? value : holidayData.holiday_to);
    if (!endDateValidation) {
      validationErrors["holiday_to"] = t(
        "End date must be greater than or equal to start date"
      );
    }

    if (isSingleFieldValidation) {
      setErrorData((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: nameValue, ...remains } = prevErrors;
        return { ...remains };
      });
    } else {
      setErrorData(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleEdit = (entryId: any) => {
    setHolidayData((prevState) => ({
      ...prevState,
      ...holidayDetails[entryId],
    }));
    setEditIndex(entryId);
    setErrorData(initialHolidayCodeErrors);
  };

  const handleSaveParticularData = () => {
    setHolidayDetails((prevstate: any) => {
      const updatedData = prevstate.map(
        (eachHolidayData: any, index: number) => {
          if (index === editIndex) {
            return { ...eachHolidayData, ...holidayData };
          }
          return eachHolidayData;
        }
      );
      return updatedData;
    });
    setHolidayData((prevData) => ({
      ...prevData,
      holiday_name: "",
      holiday_code: "",
      holiday_from: "",
      holiday_to: "",
      holiday_type: "",
      contract_type: "",
      employee_category: "",
      holiday_count: "",
      colorCode: "",
    }));
    setEditIndex(null);
  };

  const handleArchive = (deleteId: number) => {
    setHolidayDetails((prevState) => {
      const filteredData = prevState.filter(
        (eachHolidayData, index) => index !== deleteId
      );
      return filteredData;
    });
  };

  return (
    <>
      <div className="search-bar">
        <div className="row">
          <Title title={id ? t("Edit holiday codes") : t("Create holiday codes")} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-border marginBotttom1">
            <form>
              <div className="row">
                <div className="col-6">
                  <LabelWithInputField
                    type={"text"}
                    placeholder={"Enter holiday name"}
                    label={t("Name")}
                    isMandatory={true}
                    name="holiday_name"
                    id="holiday_name"
                    handleChange={handleInputChange}
                    value={holidayData.holiday_name}
                    error={error.holiday_name}
                  />
                </div>
                <div className="col-6">
                  <LabelWithInputField
                    type={"text"}
                    placeholder={"Enter holiday code"}
                    label={t("Codes")}
                    isMandatory={true}
                    name="holiday_code"
                    id="holiday_code"
                    handleChange={handleInputChange}
                    value={holidayData.holiday_code}
                    error={error.holiday_code}
                  />
                </div>
                <div className="col-sm-12 col-md-2 position-relative">
                  <Calender
                    onChange={(date) => handleDateChange(date, "holiday_from")}
                    label={t("From")}
                    isMandatory={true}
                    name="holiday_from"
                    error={error.holiday_from}
                    selectedDate={holidayData.holiday_from}
                    // minDate={new Date()}
                    isDisabled={false}
                    placeHolder="dd-mm-yyyy"
                  />
                </div>
                <div className="col-sm-12 col-md-2 position-relative">
                  <Calender
                    onChange={(date) => handleDateChange(date, "holiday_to")}
                    label={t("To")}
                    isMandatory={true}
                    name="holiday_to"
                    error={error.holiday_to}
                    selectedDate={holidayData.holiday_to}
                    // minDate={new Date()}
                    isDisabled={false}
                    placeHolder="dd-mm-yyyy"
                  />
                </div>
                <div className="col-4">
                  <SelectWithSearch
                    title={t("Holiday type")}
                    placeHolder={t("Select holiday type")}
                    search={true}
                    options={holidayCodeOptions.holiday_types}
                    name="holiday_type"
                    isMandatory={true}
                    id="holiday_type"
                    onChange={(e) => {
                      handleSelectChange(e, "holiday_type");
                    }}
                    value={holidayData.holiday_type}
                    error={error.holiday_type}
                  />
                </div>
                <div className="col-4">
                  <SelectWithSearch
                    title={t("Contract type")}
                    placeHolder={t("Select contract type")}
                    search={true}
                    isMulti={true}
                    options={holidayCodeOptions.contract_type}
                    name="contract_type"
                    id="contract_type"
                    isMandatory={true}
                    onChange={(e) => {
                      handleSelectChange(e, "contract_type");
                    }}
                    value={holidayData.contract_type}
                    error={error.contract_type}
                  />
                </div>
                <div className="col-4">
                  <SelectWithSearch
                    title={t("Employee category")}
                    placeHolder={t("Select employee category")}
                    search={true}
                    options={holidayCodeOptions.employee_category}
                    name="employee_category"
                    id="employee_category"
                    className="w-100"
                    isMulti={true}
                    isMandatory={true}
                    onChange={(e) => {
                      handleSelectChange(e, "employee_category");
                    }}
                    value={holidayData.employee_category}
                    error={error.employee_category}
                  />
                </div>

                <div className="col-4">
                  <LabelWithInputField
                    label={t("Count")}
                    type="number"
                    name="holiday_count"
                    id="holiday_count"
                    placeholder={"Enter count"}
                    min={0}
                    handleChange={handleInputChange}
                    isMandatory={true}
                    value={holidayData.holiday_count}
                    error={error.holiday_count}
                  />
                </div>
                <div className="col-4">
                  <SelectWithSearch
                    title={t("Count type")}
                    placeHolder={t("Select count type")}
                    search={true}
                    options={countType}
                    name="count_type"
                    id="count_type"
                    className="w-100"
                    isMandatory={true}
                    onChange={(e) => {
                      handleSelectChange(e, "count_type");
                    }}
                    value={holidayData.count_type}
                    error={error.count_type}
                  />
                </div>
                <div className="row">
                  <div className="col-4">
                    <SelectWithSearch
                      title={t("Performance code")}
                      placeHolder={t("Performance code")}
                      search={true}
                      options={holidayCodeOptions?.partenaCodes}
                      name="performanceCode"
                      id="performanceCode"
                      className="w-100"
                      isMandatory={true}
                      onChange={(e) => {
                        handleSelectChange(e, "performanceCode");
                      }}
                      value={holidayData.performanceCode}
                      error={error.performanceCode}
                    />
                  </div>
                  <div className="col-4">
                    <SelectWithSearch
                      title={t("Colour")}
                      placeHolder={t("Colour")}
                      search={true}
                      options={holidayCodeOptions?.colorCode}
                      name="colorCode"
                      id="colorCode"
                      className="w-100"
                      isMandatory={true}
                      onChange={(e) => {
                        handleSelectChange(e, "colorCode");
                      }}
                      value={holidayData?.colorCode}
                      error={error?.colorCode}
                    />
                  </div>
                  {/* <div className="col-4">
                    <SelectWithSearch
                      title={t("Company")}
                      placeHolder={t("Select company")}
                      search={true}
                      options={holidayCodeOptions.companyList}
                      name="company"
                      isMandatory={true}
                      id="company"
                      onChange={(e) => {
                        handleSelectChange(e, "company");
                      }}
                      value={holidayData.company}
                      error={error.company}
                    />
                  </div> */}
                  {/* <div> */}
                  {/* <LabelField
                      title={t(CommonServices.capitalizeLabel(field.name))}
                      mandatory={field.required}
                    /> */}
                  {/* <InputColorfield
                      name={"colorcode"}
                      className="w-100"
                      handleChange={handleInputChange}
                      value={holidayData.colorCode}
                    /> */}
                  {/* <ErrorMessage
                      errorMessage={t(formErrors[field.name])}
                    /> */}
                  {/* </div> */}
                  {/* <div className="col-4">
                    <SelectWithSearch
                      title={t("Business unit")}
                      placeHolder={t("Select business unit")}
                      search={true}
                      options={holidayCodeOptions.businessUnitList}
                      name="businessUnit"
                      isMandatory={true}
                      id="businessUnit"
                      onChange={(e) => {
                        handleSelectChange(e, "businessUnit");
                      }}
                      value={holidayData.businessUnit}
                      error={error.businessUnit}
                    />
                  </div> */}
                  <div className="col mt-4">
                    <CheckBoxField
                      label={t("Default holiday code")}
                      name="defaultHolidayCode"
                      onChangeHandler={handleInputChange}
                      isChecked={holidayData?.defaultHolidayCode === 1}
                      id="address"
                      lineHeight="1.7vw"
                    />
                  </div>
                </div>

                {id === undefined && (
                  <div className="col-md-12 ">
                    <Button
                      title={editIndex !== null ? t("Save") : t("+ Add another")}
                      type={"button"}
                      handleClick={
                        editIndex !== null
                          ? handleSaveParticularData
                          : addAnotherData
                      }
                      className={"form-button float-end"}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
          {holidayDetails.length > 0 && id === undefined && (
            <table className="table table-hover">
              <thead className="TableHeader">
                <tr>
                  <th>{t("Contract type")}</th>
                  <th>{t("Holiday type")}</th>
                  <th>{t("Employee category")}</th>
                  <th>{t("Counts")}</th>
                  <th>{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {holidayDetails.map((eachHolidayData: any, index) => (
                  <tr key={index}>
                    <td className="align-middle text-break">
                      {eachHolidayData.contract_type
                        .map((eachContractType: any) => eachContractType.label)
                        .join(",")}
                    </td>
                    <td className="align-middle text-break">
                      {eachHolidayData.holiday_type.label}
                    </td>
                    <td className="align-middle text-break">
                      {eachHolidayData.employee_category
                        .map((eachCategory: any) => eachCategory.label)
                        .join(",")}
                    </td>
                    <td className="align-middle text-break">
                      {`${eachHolidayData.holiday_count} ${eachHolidayData?.count_type?.label}`}
                    </td>
                    <td className="table-action-icons">
                      <span
                        onClick={() => handleEdit(index)}
                        className="table-action-btn cursor-pointer marginRightPoint5"
                        title={t("Edit")}
                      >
                        <EditIcon />
                      </span>
                      <span
                        onClick={() => handleArchive(index)}
                        className="table-action-btn cursor-pointer"
                        title={t("Delete")}
                      >
                        <DeleteIcon />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className="d-flex justify-content-between align-items-center backPadding">
            <div>
              <BackButton />
            </div>

            <div>
              <Button
                title={id ? t("Save") : t("Submit")}
                type={"submit"}
                handleClick={handleOnSubmit}
                className={"form-button"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateHolidayCodes;
