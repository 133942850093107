import React, { CSSProperties, useState, useEffect } from "react";
import "static/css/file-upload.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowDown,
  faCloudArrowUp,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import LabelField from "../atoms/LabelField";
import { t } from "pages/microservices/masterData/translation/Translation";
import ModalPopup from "components/atoms/ModalPopup";
import DeleteIcon from "static/images/DeleteIcon";
import ViewIcon from "static/images/ViewIcon";
import { log } from "node:console";

interface SelectedFile {
  name: string;
  url: string;
}

interface FileUploadProps {
  label: string;
  isMandatory?: boolean;
  id: string;
  edit?: any;
  fileId?: any;
  multiple?: any;
  uploadPath?: any;
  formats?: any;
  name: string;
  style?: CSSProperties;
  handleChange: any;
  fileObj?: any;
  maxFileSize?: any;
  mode?: any;
  error?: any;
  labelOpt?: boolean;
  popUp?: boolean;
  isDisabled?: boolean;
  isCV?: boolean;
}

const FileUploadMolecule: React.FC<FileUploadProps> = (props) => {
  const {
    label,
    isMandatory,
    id,
    name,
    style,
    fileObj,
    handleChange,
    formats,
    mode,
    isDisabled,
    isCV
  } = props;

  const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);
  const [error, setError] = useState("");
  const [popUp, setPopUp] = useState(false);

  useEffect(() => {
    if (fileObj) {
      const parsedFileObj = JSON.parse(fileObj);
      if (parsedFileObj?.accessUrl && parsedFileObj?.fileName) {
        setSelectedFile({
          name: parsedFileObj?.fileName,
          url: parsedFileObj?.accessUrl,
        });
      }
    }
  }, [fileObj]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0 && !isDisabled) {
      // if (!formats?.includes(files[0]?.type)) {

      //   setError("Invalid file format. Only files with the extensions " + formats + " are allowed");
      //   return;
      // }
      setSelectedFile({
        name: files[0].name,
        url: URL.createObjectURL(files[0]),
      });
      if (handleChange) handleChange(files[0]);
    }
  };

  const handleRemoveFile = () => {
    if (props?.popUp) {
      props?.popUp ? setPopUp(true) : setPopUp(false);
    } else {
      handleConfirmPopUp();
    }
  };

  const handleFileNameClick = () => {
    if (selectedFile && selectedFile?.url) {
      window.open(selectedFile?.url, "_blank");
    }
  };

  const trimFileName = (fileName: string) => {
    if (fileName.length > 10) {
      return fileName.substring(0, 22) + "...";
    }
    return fileName;
  };

  const handleClosePopUp = () => {
    setPopUp(false);
  };

  const handleConfirmPopUp = () => {
    setPopUp(false);
    setSelectedFile(null);
    if (handleChange) handleChange(null);
  };

  return (
    <>
      <div>
        {!props.labelOpt && <LabelField title={label} isMandatory={isMandatory} className="fileLabel" />}
        <div style={{ position: "relative", ...style }}>
          <div className="d-flex align-items-center"
            style={{ marginBottom: "5px" }}
          >
            <span>
              <label
                htmlFor={id}
                className={`${(!isCV && selectedFile && selectedFile?.name !== "" && selectedFile?.url !== "") ? "d-none " : ""}btn upload-btn cursor-pointer file-upload-wrapper w-100 text-left d-flex align-items-center`}
              >
                <span style={{ lineHeight: "0vw" }}>

                  {(selectedFile && selectedFile?.name !== "" && selectedFile?.url !== "") ? <FontAwesomeIcon icon={faCloudArrowDown} />
                    : <FontAwesomeIcon icon={faCloudArrowUp} />
                  }

                </span>
                <span className="uploadFileText ms-3">{props.label}</span>
              </label>
            </span>

            {selectedFile && selectedFile?.name !== "" && selectedFile?.url !== "" ? (
              <>

                {isCV ?
                  <input
                    type="button"
                    id={id}
                    name={name}
                    className="cursor-pointer"
                    accept={formats}
                    onClick={handleFileNameClick}
                    style={{ display: "none" }}
                    disabled={isDisabled || mode}
                  />
                  :
                  <div className="d-inline-block">
                    <span>
                      {trimFileName(selectedFile.name)}
                    </span>
                    <span
                      className="border-0 table-action-btn cursor-pointer"
                      onClick={handleFileNameClick}
                      title={t("View")}
                    >
                      <ViewIcon />
                    </span>
                    {/* View uploaded file */}
                  </div>
                }
                <span
                  className="border-0 table-action-btn cursor-pointer "
                  onClick={handleRemoveFile}
                  title={t("Remove")}
                  style={{ marginLeft: "0.5vw" }}
                >
                  <DeleteIcon />
                </span>

              </>

            ) : (
              <>

                <input
                  type="file"
                  id={id}
                  name={name}
                  className="cursor-pointer"
                  accept={formats}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  disabled={isDisabled || mode}
                />
              </>

            )}

          </div>
          {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
          {props?.error && <span className="text-danger">{props.error}</span>}
          {/* {selectedFile &&
            selectedFile?.name !== "" &&
            selectedFile?.url !== "" && (
              <div className="d-flex align-items-center table-action-icons">
                {!isDisabled && !isCV && (
                  <>
                    <span
                      className="border-0 table-action-btn cursor-pointer"
                      onClick={handleFileNameClick}
                      title={t("View")}
                    >
                      {trimFileName(selectedFile.name)}
                      <ViewIcon />
                    </span>
                    <span
                      className="border-0 table-action-btn cursor-pointer"
                      onClick={handleRemoveFile}
                      title={t("Remove")}
                    >
                      <DeleteIcon />
                    </span>
                  </>
                )}
              </div>
            )} */}
          {popUp && (
            <ModalPopup
              show={popUp}
              onHide={handleClosePopUp}
              title={t("Remove file confirmation")}
              body={t("Do you want to remove uploaded file")}
              onCloseButtonClick={handleClosePopUp}
              onConfirmButtonClick={handleConfirmPopUp}
              confirmTitle={t("Yes")}
              closeTitle={t("No")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FileUploadMolecule;
