import React from "react";
import CommonBox from "../atoms/commonBox";
import { useFilterOptions, useFilterOptionsDispatch } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { t } from "pages/microservices/masterData/translation/Translation";
import { TIMESHEET_FILTER_ACTIONS } from "../timesheets-redux/actions/TimesheetFilterActions";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const TimesheetsProjects = () => {
  const { filterOptionsState } = useFilterOptions()
  const { dispatchFilterOptions } = useFilterOptionsDispatch();
  const dispatch = useDispatch();

  const tableBodyStyle = {
    // maxHeight: "900px",
    // overflowY: "scroll",
    // display: "block",
  };

  const handleProjectClick = async (e, project_id) => {
    dispatch(updatePageFilters({ filters: { project_id: project_id } }));
    dispatchFilterOptions({
      type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
      payload: {name: 'project_id', value: project_id }
    });
  }

  return (
    <>
      <table className="w-100 h-100 overflow-auto">
        <thead className="position-sticky top-0 ">
          <tr className="position-relative">
            <th className="bg-lightskyblue position-relative">{t('Projects')}            
              <span
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={() => dispatchFilterOptions({type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE, payload: {name: 'showBuFilter', value: !filterOptionsState?.selectedState?.showBuFilter }})}
                title="Business Unit"
              >
                <FontAwesomeIcon icon={faFilter} />
              </span>
            </th>
          </tr>
        </thead>
        <tbody style={tableBodyStyle}>
          {filterOptionsState?.filteredOptions?.projects?.map((project, index) => (
            <tr key={project?.value} className="border-0 d-block cursor-pointer">
              <td style={{ minHeight: "2vw" }}
                className="d-block"
              >
                <CommonBox
                  data={project?.label ?? ""}
                  bg_color={
                    (filterOptionsState?.selectedState?.project_id == project?.value)
                      ? "#00a5ce"
                      : "#fff"
                  }
                  color={
                    (filterOptionsState?.selectedState?.project_id == project?.value)
                      ? "white"
                      : "black"
                  }
                  handleBoxClick={(e) => handleProjectClick(e, project?.value)}
                  zbox_id={project?.value}
                />

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TimesheetsProjects;
