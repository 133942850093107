import React from "react";
import CommonServices from "services/CommonService";

interface ColorMoleculeProps {
	color: any;
	isBorderRequired?: boolean;
	outerStyle?: any;
	innerStyle?: any;
	select?: boolean;
}

const ColorMolecule: React.FC<ColorMoleculeProps> = ({
	color,
	isBorderRequired = false,
	select = false,
	outerStyle = { height: "150px", width: "50px", maxWidth: "150px",  marginBottom: "12px" },
  	innerStyle = { height: "70px", width: "100%", maxWidth: "150px" }
}) => {

	const renderColorBlock = (code: string) => {
        return (
            <span
                key={code}
                className={`h-100 ${color?.squareview ? '' : 'multicolorblocks'} d-block`}
                style={{
                    backgroundColor: code,
                    width: '100%',
                }}
            ></span>
        );
  	};

	return (
		<div
			className={`${ color?.squareview ? '' : 'p-1 rounded'}`}
			style={{
				...outerStyle,
				border: isBorderRequired ? "2px solid var(--raven-red)" : "none",
			}}
		>
            <div
                className="rounded d-flex "
                style={{
                    ...innerStyle,
                    cursor: "pointer",
                    position: "relative",
                }}
            >
                <div className="position-relative d-flex w-100" >
                    <div className="w-100 d-flex">
                        {renderColorBlock(color?.code)}
                    </div>
                </div>

                { !select &&
                    <span
                        className={`color_name text-center text-truncate w-100`}
                        style={{ position: "absolute", bottom: "-35px" }}
                    >
                        {color?.label ?? color?.code}
                    </span>
                }
            </div>
		</div>
	);
};

export default ColorMolecule;
