import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import ManageProjects from "./ManageProjects";
import { M_MASTER_DATA } from "constants/Constants";
import { GET_FILTERED_PROJECTS, PROJECTS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useLocation, useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import CommonServices from "services/CommonService";

// Define the prop types for the component
interface ProjectsProps {
  id?: string | number;
}

// Functional component using TypeScript
const Projects: React.FC<ProjectsProps> = () => {
  const pageHistory = useSelector(selectPageHistory);
  const history =
    pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const dispatch = useDispatch();
  const url = useLocation();
  const urlParts = url.pathname.split("/");
  const last_name = urlParts[urlParts.length - 1];
  const userData = useSelector(selectAuth);
  let employeeId = 0;
  if (!isNaN(Number(last_name))) {
    employeeId = parseInt(last_name);
  }
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true,
    options: [],
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(
        history?.filters?.itemsPerPage ??
          process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ??
          20
      ),
      search: {
        user_id: Number(history?.filters?.user_id ?? null),
        company: history?.filters?.company ?? "",
        status: history?.filters?.status ?? "",
        hotlist: history?.filters?.hotlist ?? false,
        businessUnits: history?.filters?.businessUnits ?? [],
        startDate: CommonServices?.getTimezoneOffset(
          history?.filters?.startDate ?? null
        ),
        endDate: CommonServices?.getTimezoneOffset(
          history?.filters?.endDate ?? null
        ),
        location: history?.filters?.location ?? "",
        project: history?.filters?.project ?? "",
        orderBy: history?.filters?.orderBy ?? "id",
        sortDirection: history?.filters?.sortDirection ?? "desc",
        loginUserId: userData?.userId,
        loginUserRole: userData?.role,
        loginUserBu: userData?.businessUnit,
      },
      initialCall: pageData?.initialCall,
      employeeId: employeeId,
    };
    if (employeeId !== 0) {
      fetchFilteredProjects(postData);
    } else {
      getProjectData(postData);
    }
  }, [pageData.refresh, pageData.initialCall]);

  const fetchFilteredProjects = async (postData: any) => {
    try {
      ApiCall.service(
        GET_FILTERED_PROJECTS,
        "POST",
        postData,
        false,
        "m-masterdata"
      ).then((response) => {
        if (response?.data?.data) {
          setData(response?.data?.data);
        }
      });
    } catch (error) {
      console.error("Failed to fetch employees:", error);
    }
  };

  const getProjectData = async (postData: any) => {
    const response = await ApiCall.service(
      PROJECTS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      setData(response?.data?.data);
      setPageData((prev: any) => ({
        ...prev,
        totalPages: response?.data?.totalPages,
        totalRecords: response?.data?.totalRecords,
        initialCall: false,
      }));
    } else {
      console.log("error");
    }
  };
  const handleRefresh = () =>
    setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <>
      <Suspense
        fallback={
          <div className="text-center text-danger">{t("Loading")}...</div>
        }
      >
        <ManageProjects
          history={history}
          dispatch={dispatch}
          pageData={pageData}
          data={data}
          handleData={setData}
          handleRefresh={handleRefresh}
        />
      </Suspense>
    </>
  );
};

export default Projects;
