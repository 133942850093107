import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import { useDispatch, useSelector } from "react-redux";
import ManageDefaultCoefficientPage from "./ManageDefaultCoefficientPage";
import { GET_DEFAULT_COEFFICIENTS_OVERVIEW_DATA } from "routes/ApiEndpoints";

const ManageDefaultCoefficient = () => {
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
    const dispatch = useDispatch();

    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        initialCall: true,
        refresh: true,
    });

    const getSearchData = () => {
        return {
            company: history?.filters?.company ?? "",
            orderBy: history?.filters?.orderBy ?? "id",
            sortDirection: history?.filters?.sortDirection ?? "desc",
            initialCall: pageData?.initialCall,
        }
    };

    const [coeffData, setCoeffData] = useState([]);

    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
            search: getSearchData(),
        };
        getCoefficientData(postData);
    }, [pageData?.initialCall, pageData?.refresh]);

    const [options, setOptions] = useState({
        companies: [],
    });

    const getCoefficientData = async (postData: any) => {
        try {
            const response = await ApiCall.service(
                GET_DEFAULT_COEFFICIENTS_OVERVIEW_DATA,
                "POST",
                postData,
                true,
                M_MASTER_DATA
            );
            if (response?.status === 200) {
                setCoeffData(response?.data?.data);
                if (pageData?.initialCall) {
                    const { companies } = response?.data?.options;
                    setOptions((prevOptions: any) => ({ ...prevOptions, companies: companies }));
                }
                setPageData((prev: any) => ({
                    ...prev,
                    totalPages: response?.data?.totalPages,
                    totalRecords: response?.data?.totalRecords,
                    initialCall: false
                }));
            } else {
                console.log("error");
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    return (
        <>
            <Suspense
                fallback={
                    <div className="text-center text-danger">{t("Loading")}...</div>
                }
            >
                <ManageDefaultCoefficientPage
                    data={coeffData}
                    setCoefficientData={setCoeffData}
                    handleGetVacany={getCoefficientData}
                    dispatch={dispatch}
                    history={history}
                    handleRefresh={handleRefresh}
                    pageData={pageData}
                    dropDownOptions={options}
                />
            </Suspense>
        </>
    );
};

export default ManageDefaultCoefficient;