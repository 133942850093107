import TitleFieldMolecule from "components/molecules/TitleField";
import { t } from "../translation/Translation";
import Button from "components/atoms/Button";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "components/atoms/Pagination";
import CustomNotify from "components/atoms/CustomNotify";
import { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  GET_ALL_EMPLOYEES_LEAVE_COUNT,
  GET_BUSINESS_UNIT_AND_EMPLOYEE,
  GET_OPTIONS_FOR_EMPLOYER_LEAVE_COUNT,
} from "routes/ApiEndpoints";
import { MANAGE_EMPLOYER_LEAVES } from "constants/Paths";
import { PanelMenu } from "primereact/panelmenu";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import LeaveTreeTable from "./TreeTable";
import { IconData } from "services/IconData";
import BackButton from "components/atoms/BackButton";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import LabelField from "components/atoms/LabelField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import TitleAtom from "components/atoms/Title";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";

interface LeaveCountData {
  business_unit: any;
  employees: any;
}

interface DataProps {
  page: number;
  search?: any;
  role?: any;
}

interface Options {
  businessUnits: any;
  employees: any;
}

const LeaveCountOfEmployer = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const userData = useSelector(selectAuth);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState({
    business_unit: queryParams.get("business_unit") ?? "",
    employees: queryParams.get("employee") ?? "",
  });
  const [leaveCountOfEmployees, setLeaveCountOfEmployees] = useState<any>([]);
  const [options, setOptions] = useState<Options>({
    businessUnits: [],
    employees: [],
  });

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await ApiCall.service(
        GET_BUSINESS_UNIT_AND_EMPLOYEE,
        "POST",
        { role: userData?.role },
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        const { businessUnit, employee } = response;
        setOptions((prevState: any) => ({
          ...prevState,
          businessUnits: [...businessUnit],
          employees: [...employee],
        }));
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.error("Error occured ", error);
    }
  };

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
      role: userData?.role,
    };
    fetchLeaveCountOfEmployees(data); //To get leave count details of all employees
  }, [currentPage, submit]);

  const fetchLeaveCountOfEmployees = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        GET_ALL_EMPLOYEES_LEAVE_COUNT,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        const { data } = response?.data;

        setLeaveCountOfEmployees(response?.data);
        setTotalPages(response.totalPages);
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };

  //onChange event handler for select element
  const handleSelectChange = (e: { value: any; label: string } | null, name: string) => {
    setSearch((prevState) => ({
      ...prevState,
      [name]: e ? e.value : null,
    }));
  };

  //onSubmit event handler
  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearch({ business_unit: "", employees: "" });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(search);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: LeaveCountData) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const selectedBusinessUnit = options.businessUnits
    ? options.businessUnits.find(
      (unit: any) => unit.value === search.business_unit
    )
    : null;
  console.log(search.business_unit, "busi");

  const selectedEmployees = options.employees
    ? options.employees.find(
      (unit: any) => unit.value === search.employees
    )
    : null;


  return (
    <>
      <div>
        <div className="search-bar">
          <TitleAtom title={t("Leaves count")} />
          <div className="d-grid grid-3">
            <div>
              <SelectWithSearch
                title={t("Business unit")}
                name="business_unit"
                isMandatory={false}
                search={true}
                options={
                  options.businessUnits && options.businessUnits.length > 0
                    ? options.businessUnits.map((option: any) => ({
                      value: option.value,
                      label: option.label,
                    }))
                    : []
                }
                placeHolder={
                  options.businessUnits && options.businessUnits.length > 0
                    ? t("Select business unit")
                    : t("No business unit available")
                }
                value={
                  selectedBusinessUnit
                    ? { value: selectedBusinessUnit.value, label: selectedBusinessUnit.label }
                    : null
                }
                onChange={(e) => handleSelectChange(e, 'business_unit')}
                isMulti={false}
                className="select-field"
              />
            </div>
            <div>
              <SelectWithSearch
                title={t("Employee")}
                name="employees"
                isMandatory={false}
                search={true}
                options={
                  options.employees && options.employees.length > 0
                    ? options.employees.map((option: any) => ({
                      value: option.value,
                      label: option.label,
                    }))
                    : []
                }
                placeHolder={
                  options.employees && options.employees.length > 0
                    ? t("Select employee")
                    : t("No employee available")
                }
                value={selectedEmployees ? { value: selectedEmployees.value, label: selectedEmployees.label } : null}
                onChange={(e) => handleSelectChange(e, 'employees')}
                isMulti={false}
                className="select-field"
              />
            </div>
            <div className="align-self-end">
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                <SearchBtn handleSearchClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <div className="table-responsive tableSection">
          <div className="leave-count-employer">
            <LeaveTreeTable data={leaveCountOfEmployees} />
          </div >
          <div className="pagination justify-content-center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center backPadding">
        <BackButton />
      </div>
    </>
  );
};
export default LeaveCountOfEmployer;
