import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { GET_TIMEHSHEETS, TIMESHEET_OPTIONS } from "routes/ApiEndpoints";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";

export const getWeekDays = (date) => {
  const days = [];
  date = new Date(date);
  const dayOfWeek = date.getDay(); // Get the day of the week (0-6)
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Sunday as first day of the week
  date.setDate(diff);
  for (let i = 0; i < 7; i++) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const dateFormat = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month starts from 0, so add 1
  const year = date.getFullYear(); // Get last two digits of the year
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
  return formattedDate;
};

export function calculateWorkedTime(startTime, endTime, pauseTime) {
  // Helper function to parse time
  function parseTime(time) {
    const parts = time.split(":").map(Number);
    if (parts.length === 2) {
      parts.push(0); // add seconds if missing
    }
    return parts;
  }

  // Parse the start, end, and pause times
  const [startHours, startMinutes, startSeconds] = parseTime(startTime);
  const [endHours, endMinutes, endSeconds] = parseTime(endTime);
  const [pauseHours, pauseMinutes, pauseSeconds] = parseTime(pauseTime);

  // Create Date objects
  const startDate = new Date();
  startDate.setHours(startHours, startMinutes, startSeconds, 0);

  const endDate = new Date();
  endDate.setHours(endHours, endMinutes, endSeconds, 0);

  // Calculate the difference in milliseconds
  let diff = endDate - startDate;

  // If end time is less than start time, assume it goes over midnight
  if (diff < 0) {
    diff += 24 * 60 * 60 * 1000; // add 24 hours
  }

  // Convert pause time to milliseconds
  const pauseTimeInMs =
    (pauseHours * 60 * 60 + pauseMinutes * 60 + pauseSeconds) * 1000;

  // Subtract pause time
  diff -= pauseTimeInMs;

  // Convert the difference to hours and minutes
  const diffHours = Math.floor(diff / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  // Format the result with leading zeros if necessary
  const formattedHours = String(diffHours).padStart(2, "0");
  const formattedMinutes = String(diffMinutes).padStart(2, "0");

  // Combine hours and minutes
  const totalHours = `${formattedHours}:${formattedMinutes}`;

  return totalHours;
}

export function addTimes(time1, time2) {
  if (!time1 || !time2) return "00:00";
  const totalMinutes = timeToMinutes(time1) + timeToMinutes(time2);
  return minutesToTime(totalMinutes);
}

export function subtractTimes(time1, time2) {
  if (!time1 || !time2) return "00:00";
  const totalMinutes = timeToMinutes(time1) - timeToMinutes(time2);
  return minutesToTime(totalMinutes);
}

// Function to convert time string to total minutes
function timeToMinutes(time) {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

// Function to format minutes into HH:MM with correct negative handling
function minutesToTime(minutes) {
  const isNegative = minutes < 0;
  const absMinutes = Math.abs(minutes);

  let hours = Math.floor(absMinutes / 60);
  let remainingMinutes = absMinutes % 60;

  // If hours are 0 but minutes are negative, show -00:MM properly
  if (isNegative && hours === 0) {
    return `-00:${String(remainingMinutes).padStart(2, "0")}`;
  }
  return `${isNegative ? "-" : ""}${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`;
}

export const getAllDates = (date) => {
  const days = [];
  date = new Date(date);
  const dayOfWeek = date.getDay();
  // Calculate the start date of the week (Monday)
  const startDate = new Date(date);
  startDate.setDate(startDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
  // Get all the dates from Monday to Sunday
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    days.push(`${year}-${month}-${day}`);
  }
  return days;
};

export async function fetchWeeklyTimesheetData(data, currentDate, parameters = {}) {
  try {
    // Fetch timesheet data
    const timesheetResponse = await ApiCall.service(GET_TIMEHSHEETS,"POST", { timesheets: data }, true, M_MASTER_DATA);
    const timesheetDetails = timesheetResponse?.['timesheets']?.[0]?.timesheet_details || [];

    // Generate all dates for the given week and fill missing dates with dummy data
    const allDates = getAllDates(currentDate); 

    allDates?.forEach((date) => {
      if (!timesheetDetails?.some((entry) => entry?.plan_date === date)) {        
        timesheetDetails?.push({
          start_date_time: `${date} 00:00:00`, end_date_time: `${date} 00:00:00`, start_time: "",
          end_time: "", break_hours: "", regime: "", holiday: false, invoice_milage: false,
          purchase_invoice: false, invoice_no: "", week_number: "", plan_id: null,
          plan_date: `${date}`, totalHours: 0, pause_time: "", reimbursement: [], performance: [],
        });
      }
    });

    // Sort and slice the data for the week
    const sortedData = timesheetDetails?.sort((a, b) => new Date(a.plan_date) - new Date(b.plan_date)).slice(0, 7);
    return {
      timesheet_id: timesheetResponse?.['timesheets']?.[0]?.timesheet_id ?? null,
      isInvoiced: timesheetResponse?.['timesheets']?.[0]?.isInvoiced,
      isDisabled: timesheetResponse?.['timesheets']?.[0]?.isDisabled,
      timesheet_data: sortedData, more_info: timesheetResponse?.['more_info'] ?? {},
      week_number: timesheetResponse?.['timesheets']?.[0]?.week_number,
    };
  } catch (error) {
    console.error("Error fetching weekly timesheet data:", error);
    return { timesheet_id: null, timesheet_data: [] };
  }
}

export const getTimesheetOptions = async (payload) => {
  let response = await ApiCall.service(TIMESHEET_OPTIONS, 'POST', payload, true, M_MASTER_DATA);
  if (response?.status === 200) {
    return response?.data ?? [];
  }
  return [];
}