const ROLES = {
    ADMIN: "ADMIN",
    MAXICON: "MAXICON",
    EMPLOYEE: "EMPLOYEE",
    CONTACT_PERSON: "CONTACT_PERSON",
    SUPER_ADMIN: "SUPER_ADMIN",
    HR: "HR",
    EMPLOYER: "EMPLOYER",
    TEMP_WORKER: "TEMP_WORKER",
    TEMP_AGENCY_ADMIN: 'TEMP_AGENCY_ADMIN',
}

export const TimesheetRolePriority = [
    ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.HR, ROLES.EMPLOYER, ROLES.TEMP_AGENCY_ADMIN, ROLES.EMPLOYEE,
];

const TimesheetRoleMap: any = {
    SUPER_ADMIN: ROLES.MAXICON,
    ADMIN: ROLES.MAXICON,
    HR: ROLES.MAXICON,
    EMPLOYEE: ROLES.EMPLOYEE,
    EMPLOYER: ROLES.CONTACT_PERSON,
    TEMP_AGENCY_ADMIN: ROLES.TEMP_WORKER,
};

const checkForPermission = (type: any, permission = [ROLES.MAXICON]) => type && permission?.includes(type);

const TimesheetStatus = [
    { value: 1, label: "Planned" },
    { value: 2, label: "Employee approved" },
    { value: 3, label: "Client approved" },
    { value: 4, label: "Mismatched" },
    { value: 5, label: "To be approved" },
    { value: 6, label: "To be invoiced" },
    { value: 7, label: "Invoiced" },
];
export {
    TimesheetRoleMap, ROLES, checkForPermission, TimesheetStatus
}