import CheckBoxField from "components/atoms/CheckBoxField";
import RadioField from "components/atoms/RadioField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Calender from "components/molecules/Calender";
import { Accordion } from "react-bootstrap";
import { LabelWithInputFieldAndUnit } from "../templates/atoms/LabelWithInputFieldAndUnit";
import { t } from "../translation/Translation";
import { Key, MouseEventHandler } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import TimeField from "react-simple-timefield";
import LabelField from "components/atoms/LabelField";

interface CommonComponent {
  handleSelectChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
    category: string
  ) => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleDateChange: (date: Date | null, name: string) => void;
  handleOnSubmit: MouseEventHandler<HTMLButtonElement>;
  handleTimeChange: (event: any, value: any, field: string) => void;
  leavesType: any;
  radioButtonState: any;
  checkboxState: any;
  leaveData: any;
  leaveDataError: any;
  fileMode: string;
  hrOptions?: any;
  leaveBalance?: any;
}

interface FullDayComponent {
  handleDateChange: (date: Date | null, name: string) => void;
  leaveData: any;
  leaveDataError: any;
  fileMode: string;
}

interface HalfDayComponent {
  leaveData: any;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleDateChange: (date: Date | null, name: string) => void;
  leaveDataError: any;
  fileMode: string;
}

interface HourLeaveComponent {
  handleDateChange: (date: Date | null, name: string) => void;
  leaveData: any;
  leaveDataError: any;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  fileMode: string;
  handleTimeChange: (event: any, value: any, field: string) => void;
}

const FullDayComponent: React.FC<FullDayComponent> = ({
  handleDateChange,
  leaveData,
  leaveDataError,
  fileMode,
}) => {
  return (
    <>
      <div className="col-6 position-relative">
        <Calender
          onChange={(event) => {
            handleDateChange(event, "from_date");
          }}
          selectedDate={leaveData.from_date}
          label={t("From date")}
          isMandatory={true}
          name={"from_date"}
          isDisabled={fileMode === "view"}
          error={leaveDataError.from_date}
          placeHolder="dd-mm-yyyy"
          filterDate={(date) => (date.getDay() !== 0 && date.getDay() !== 6)}
        />
      </div>
      <div className="col-6 position-relative">
        <Calender
          onChange={(event) => {
            handleDateChange(event, "to_date");
          }}
          selectedDate={leaveData.to_date}
          label={t("To date")}
          name={"to_date"}
          isDisabled={fileMode === "view"}
          error={leaveDataError.to_date}
          placeHolder="dd-mm-yyyy"
          filterDate={(date) => (date.getDay() !== 0 && date.getDay() !== 6)}
        />
      </div>
    </>
  );
};

const HalfDayComponent: React.FC<HalfDayComponent> = ({
  leaveData,
  handleDateChange,
  leaveDataError,
  fileMode,
}) => {
  return (
    <>
      <div className="w-50 position-relative">
        <Calender
          onChange={(event) => {
            handleDateChange(event, "leave_date");
          }}
          selectedDate={leaveData.leave_date}
          label={t("Leave date")}
          isMandatory={true}
          name={"leave_date"}
          error={leaveDataError.leave_date}
          isDisabled={fileMode === "view"}
          placeHolder="dd-mm-yyyy"
          filterDate={(date) => (date.getDay() !== 0 && date.getDay() !== 6)}
        />
      </div>
    </>
  );
};

const HourLeaveComponent: React.FC<HourLeaveComponent> = ({
  handleDateChange,
  leaveData,
  leaveDataError,
  handleInputChange,
  handleTimeChange,
  fileMode,
}) => {
  return (
    <>
      <div className="d-grid grid-3">
        <div className="position-relative">
          <Calender
            onChange={(event) => {
              handleDateChange(event, "from_date");
            }}
            selectedDate={leaveData.from_date}
            label={t("From")}
            isMandatory={true}
            name={"from_date"}
            error={leaveDataError.from_date}
            isDisabled={fileMode === "view"}
            placeHolder="dd-mm-yyyy"
            filterDate={(date) => (date.getDay() !== 0 && date.getDay() !== 6)}
          />
        </div>
        {/* <div className="position-relative">
          <Calender
            onChange={(event) => {
              handleDateChange(event, "to_date");
            }}
            selectedDate={leaveData.to_date}
            label={t("To")}
            name={"to_date"}
            error={leaveDataError.to_date}
            isDisabled={fileMode === "view"}
            placeHolder="dd-mm-yyyy"
          />
        </div> */}
        <div className="d-flex">
          <div className="marginRight1">
            <LabelField
              title={t("From hour")}
            />
            <div>
              <TimeField
                value={leaveData?.from_hour}
                onChange={(event, value) => { handleTimeChange(event, value, 'from_hour') }}
                colon=":"
                showSeconds={false}
                style={{
                  border: '0.052vw solid #fff',
                  width: "100%",
                  borderRadius: "0.3vw",
                  outline: "0",
                  padding: "0.05vw 0.2vw",
                  textAlign: "center",
                  minHeight: "2.344vw",
                  boxShadow: "rgba(0, 165, 206, 0.7) 0vw 0.052vw 0.104vw 0vw, rgba(0, 165, 206, 0.3) 0vw 0.052vw 0.156vw 0.052vw"
                }}
              />
            </div>
            <p className="text-danger">{t(leaveDataError?.from_hour)}</p>
          </div>
          <div>
            <LabelField
              title={t("To hour")}
            />
            <div>
              <TimeField
                value={leaveData?.to_hour}
                onChange={(event, value) => { handleTimeChange(event, value, 'to_hour') }}
                colon=":"
                showSeconds={false}
                style={{
                  border: '0.052vw solid #fff',
                  width: "100%",
                  borderRadius: "0.3vw",
                  outline: "0",
                  padding: "0.05vw 0.2vw",
                  textAlign: "center",
                  minHeight: "2.344vw",
                  boxShadow: "rgba(0, 165, 206, 0.7) 0vw 0.052vw 0.104vw 0vw, rgba(0, 165, 206, 0.3) 0vw 0.052vw 0.156vw 0.052vw"
                }}
              />
            </div>
            <p className="text-danger">{t(leaveDataError?.to_hour)}</p>
          </div>
        </div>
        {/* <div>
          <LabelWithInputFieldAndUnit
            label={t("From hours")}
            type="number"
            name="fromHour"
            id="no_of_hours"
            placeholder={"Enter no of hours"}
            handleChange={handleInputChange}
            isMandatory={true}
            value={leaveData.no_of_hours}
            unit={"Hours"}
            error={leaveDataError.no_of_hours}
            isDisabled={fileMode === "view"}
            containerClassName="col-12"
          />
        </div>
        <div>
          <LabelWithInputFieldAndUnit
            label={t("To hours")}
            type="number"
            name="no_of_hours"
            id="no_of_hours"
            placeholder={"Enter no of hours"}
            handleChange={handleInputChange}
            isMandatory={true}
            value={leaveData.no_of_hours}
            unit={"Hours"}
            error={leaveDataError.no_of_hours}
            isDisabled={fileMode === "view"}
            containerClassName="col-12"
          />
        </div> */}
      </div>
    </>
  );
};

const CommonComponent: React.FC<CommonComponent> = ({
  handleSelectChange,
  handleInputChange,
  handleDateChange,
  handleTimeChange,
  leavesType,
  radioButtonState,
  checkboxState,
  leaveData,
  leaveDataError,
  fileMode,
  hrOptions,
  leaveBalance,
}) => {
  return (
    <>
      <div className="col-12 mb-2 leaveAccordion">
        {fileMode != "view" ? (
          <Accordion defaultActiveKey="0" flush className="mb-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t("Leaves and count")}</Accordion.Header>
              <Accordion.Body>
                <table className="table table-hover">
                  <thead className="TableHeader">
                    <tr>
                      <th className="border-0">{t("Leaves")}</th>
                      <th className="border-0">{t("Balance")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(leaveBalance) &&
                      leaveBalance.map((item, index) => (
                        <tr key={index}>
                          <td className="align-middle">{item.label}</td>
                          <td className="align-middle">{item.count}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          <></>
        )}
      </div>
      <div className="col-6">
        <SelectWithSearch
          onChange={(event) => {
            handleSelectChange(event, "leave_type");
          }}
          title={t("Types")}
          placeHolder={t("Select type")}
          search={true}
          options={leavesType}
          value={leaveData.leave_type}
          isDisabled={fileMode === "view"}
          isMandatory={true}
          error={leaveDataError.leave_type}
          name={"leave_type"}
          id={"leave_type"}
        />
      </div>
      <div className="col-6">
        <div className="row">
          {leaveData.count_type === "2"
            ? checkboxState
              .filter(
                (eachPeriod: { label: string }) =>
                  eachPeriod.label !== "Hours"
              )
              .map(
                (
                  eachPeriod: { label: string | undefined },
                  index: Key | null | undefined
                ) => (
                  <div className="col-4 plusIconSPace" key={index}>
                    <CheckBoxField
                      label={eachPeriod.label}
                      name="is_full_day"
                      id={eachPeriod.label}
                      isChecked={eachPeriod.label === leaveData.is_full_day}
                      onChangeHandler={handleInputChange}
                      disable={fileMode === "view"}
                    />
                  </div>
                )
              )
            : leaveData.count_type === "1"
              ? checkboxState
                .filter(
                  (eachPeriod: { label: string }) =>
                    eachPeriod.label === "Hours"
                )
                .map(
                  (
                    eachPeriod: { label: string | undefined },
                    index: Key | null | undefined
                  ) => (
                    <div className="col-4 plusIconSPace" key={index}>
                      <CheckBoxField
                        label={eachPeriod.label}
                        name="is_full_day"
                        id={eachPeriod.label}
                        isChecked={eachPeriod.label === leaveData.is_full_day}
                        onChangeHandler={handleInputChange}
                        disable={fileMode === "view"}
                      />
                    </div>
                  )
                )
              : null}

          <div className="col-8 offset-4 d-flex" style={{ marginTop: "0.5vw" }}>
            {leaveData.is_full_day === "Half day" &&
              leaveData.count_type === "2" && (
                radioButtonState.map((eachItem: any, index: number) => (
                  <div className="marginRightPoint5">
                    <RadioField
                      name="leave_period"
                      ischecked={eachItem.label === leaveData.leave_period}
                      handleChange={handleInputChange}
                      label={t(eachItem.label)}
                      id={eachItem.label}
                      key={index}
                      disable={fileMode === "view"}
                      className="halfDaROptions"
                    />
                  </div>
                ))
              )}
          </div>
        </div>
      </div>
      <>
        {leaveData.is_full_day === "Full day" &&
          leaveData.count_type === "2" ? (
          <FullDayComponent
            leaveData={leaveData}
            fileMode={fileMode}
            handleDateChange={handleDateChange}
            leaveDataError={leaveDataError}
          />
        ) : leaveData.is_full_day === "Half day" &&
          leaveData.count_type === "2" ? (
          <HalfDayComponent
            leaveData={leaveData}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            leaveDataError={leaveDataError}
            fileMode={fileMode}
          />
        ) : leaveData.count_type === "1" ? (
          <HourLeaveComponent
            leaveData={leaveData}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            leaveDataError={leaveDataError}
            fileMode={fileMode}
            handleTimeChange={handleTimeChange}
          />
        ) : null}
      </>
      <div className="d-grid grid-2">
        <div>
          <LabelWithInputField
            handleChange={handleInputChange}
            label={t("Reason")}
            value={leaveData.reason}
            name="reason"
            id="reason"
            error={leaveDataError.reason}
            isDisabled={fileMode === "view"}
          />
        </div>
        <div>
          <SelectWithSearch
            onChange={(event) => {
              handleSelectChange(event, "request_to");
            }}
            title={t("Send request to")}
            placeHolder={t("Select")}
            search={true}
            options={hrOptions}
            value={leaveData.request_to}
            isMandatory={true}
            error={leaveDataError.request_to}
            name={"request_to"}
            id={"request_to"}
            isDisabled={fileMode === "view"}
          />
        </div>
      </div>
    </>
  );
};
export default CommonComponent;
