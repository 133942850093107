import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { M_IDENTITY_MANAGER, UserStatus } from "constants/Constants";
import { UserData } from "./ManageUsers";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { ApiCall } from "services/ApiServices";
import "static/css/users.css";
import {
  DELETE_USER,
  SEND_ACTIVATION_MAIL,
  UNARCHIVE_USER,
} from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import { UserTableHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { convertArrayToOptions, handleClear } from "services/util/UtilService";
import FilterOrganism from "components/organism/FilterOrganism";
import { CREATE_CANDIDATE, EDIT_CANDIDATE, PATH_CANDIDATE_CORNER, VIEW_CANDIDATE } from "constants/Paths";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { Spinner } from "react-bootstrap";
import TextEllipsis from "components/atoms/TextEllipsis";
import ActionIconWrapper from "components/atoms/ActionIconWrapper";
import EditIcon from "static/images/EditIcon";
import Unarchive from "static/images/UnarchiveIcon";
import ArchieveIcon from "static/images/ArchiveIcon";
import ViewIcon from "static/images/ViewIcon";
import SendMailIcon from "static/images/sendMailIcon";
import InfoIcon from "static/images/InfoIcon";

interface ManageUsersPageProps {
  usersObj: UserData[];
  pageData: any;
  history: any;
  deleteFilter: (deleteId: number) => void;
  options?: any;
  dispatch: any;
  handleRefresh: () => void;
  loading: boolean;
}

const ManageUsersPage: React.FC<ManageUsersPageProps> = ({
  usersObj,
  pageData,
  history,
  deleteFilter,
  options,
  dispatch,
  handleRefresh,
  loading
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const userData = useSelector(selectAuth);
  const isAdmin = userData?.role?.includes('ADMIN');

  // start of index
  const columns = [
    "businessUnits",
    "first_name",
    "last_name",
    "email",
    "phoneNumber",
    "role",
    "status",
  ];

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_USER}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        const url = `archiveUser/${deleteID}`; //archive user in candidate-creation
        // await ApiCall.getService(url, "GET", "candidate-creation");
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleUnArchiveChanges = async (userId: number) => {
    if (userId) {
      const url = `${UNARCHIVE_USER}/${userId}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        deleteFilter(userId);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const deleteUser = (userData: UserData | any) => {
    setShowModal(true);
    setDeleteId(userData.id);
  };

  const handleEdit = (userData: UserData | any) => {
    if (userData?.role?.toLowerCase()?.includes('candidate') && userData?.role[0] === 'CANDIDATE') {
      navigate(`${EDIT_CANDIDATE}/${userData?.id}`)
    } else if (userData.id) {
      navigate(`/user/edit/${userData.id}`);
    }
  };

  const handleView = (userData: UserData | any) => {
    if (userData?.role?.toLowerCase()?.includes('candidate')) {
      navigate(`${VIEW_CANDIDATE}/${userData?.id}`)
    } else if (userData.id) {
      navigate(`/user/view/${userData.id}`);
    }
  };

  const handleUnArchive = (userData: UserData | any) => {
    if (userData.id) {
      handleUnArchiveChanges(userData.id);
    }
  };

  const sendActivationMail = async (userData: any) => {
    try {
      const response = await ApiCall.service(
        SEND_ACTIVATION_MAIL,
        "POST",
        { ...userData },
        false,
        M_IDENTITY_MANAGER
      );
      if (response.status == 200) {
        CustomNotify({ type: "success", message: response.message });
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error");
    }
  };

  const handleSendMail = (userData: UserData | any) => {
    if (userData.id) {
      sendActivationMail(userData);
    }
  };

  const filters = [
    { name: 'businessUnits', fieldType: "multiSelect", options: options?.businessUnits, placeholder: "Business unit", filterType: 'search' },
    { name: 'firstName', fieldType: "text", placeholder: "First name", filterType: 'search' },
    { name: 'lastName', fieldType: "text", placeholder: "Last name", filterType: 'search' },
    { name: 'email', fieldType: "text", placeholder: "Email", filterType: 'search' },
    { name: 'phoneNumber', fieldType: "text", placeholder: "Phone number", filterType: 'search' },
    { name: 'role', fieldType: "singleSelect", options: options?.roles, placeholder: "Role", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: convertArrayToOptions(UserStatus), placeholder: "Status", filterType: 'search' },
  ];

  function handleClick(userData: UserData | any): void {
    navigate(`${PATH_CANDIDATE_CORNER}/${userData.id}`, { state: { element: 'user' } });
  }

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Users",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title title={t("Users")} />
        </div>
        <div className="position-relative tableMainWrapper">

          <div className="row">
            <div className="ManageCreateBtn">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Role",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <LinkTo
                  pagelink="/user/create"
                  title={t("Create user")}
                  icon={faPlus}
                  className="marginRight1"
                />
              </AccessControl>

              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Candidates",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <LinkTo
                  pagelink={CREATE_CANDIDATE}
                  title={t("Create medewerker")}
                  icon={faPlus}
                />
              </AccessControl>
            </div>
          </div>

          <div className="table-responsive manage-users tableSection">
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filters}
              handleRefresh={handleRefresh}
            />
            <div className="TableHeight">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    {UserTableHeader?.map((header: any) => (
                      <th key={header?.alias}>
                        <>
                          {t(header.name)}
                          {header?.issort && (
                            <SortAtomForManagePage
                              value={{ alias: header?.alias }}
                              dispatch={dispatch}
                              history={history}
                              updatePageFilters={updatePageFilters}
                              handleRefresh={handleRefresh}
                            />
                          )}
                        </>
                      </th>
                    ))}
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? <tr className="border-0">
                    <td
                      className="text-center text-info border-0 spinner-wrapper "
                      colSpan={10} rowSpan={4}
                    >
                      <Spinner size="sm" className="me-2 spinnerStyle" />{" "}
                      {t("Loading")}
                    </td>
                  </tr> :
                    <>
                      {usersObj?.length > 0 ? (
                        usersObj?.map((user: any) => (
                          <tr key={user.id} >
                            <td><TextEllipsis title={user?.businessUnits} label={user?.businessUnits} width="6.5vw" /></td>
                            <td className="nameHoverClass">
                              <Link
                                to={`/user/edit/${user.id}?source=candidate`}
                                target=""
                                style={{ cursor: 'pointer' }}
                              >
                                <TextEllipsis classname="cursor-pointer hoverText" title={user?.first_name} label={user?.first_name} width="8vw" />
                              </Link>
                            </td>
                            <td className="nameHoverClass">
                              <Link
                                to={`/user/edit/${user.id}?source=candidate`}
                                target=""
                                style={{ cursor: 'pointer' }}
                              >
                                <TextEllipsis classname="cursor-pointer hoverText" title={user?.last_name} label={user?.last_name} width="8vw" />
                              </Link>
                            </td>
                            <td
                              className="text-break nameHoverClass"
                              data-label={t("Email")}
                            >
                              <a
                                href={`mailto:${user?.email}`}
                                target="_blank"
                              >
                                <TextEllipsis
                                  title={user?.email}
                                  label={user?.email}
                                  width="11vw"
                                  classname="cursor-pointer hoverText"
                                />
                              </a>
                            </td>
                            <td className="text-break nameHoverClass"
                              data-label={t("Phone number")}><TextEllipsis
                                title={user?.phoneNumber}
                                label={user?.phoneNumber}
                                width="11vw"
                                classname="cursor-pointer hoverText"
                              /></td>
                            <td><TextEllipsis title={user?.role} label={user?.role} width="8vw" /></td>
                            <td>
                              <span
                                className={`d-inline-block rounded-circle status-icon bg-${user?.status === 1
                                  ? "success" : user.status === 0 ? "warning"
                                    : user?.status === 2 ? "danger" : "secondary"
                                  }`}
                                data-toggle="tooltip"
                                title={UserStatus[user?.status]}
                              ></span>
                            </td>
                            <td>
                              <ActionIconWrapper initialVisibleCount={4}>
                                <span className="table-action-btn cursor-pointer" onClick={() => handleEdit(user)} title={t("Edit")}>
                                  <EditIcon />
                                </span>
                                <span className="table-action-btn cursor-pointer" onClick={() => handleView(user)} title={t("View")}>
                                  <ViewIcon />
                                </span>
                                <span className="table-action-btn cursor-pointer" onClick={user?.status == 3 ? () => handleUnArchive(user) : () => deleteUser(user)} title={user.status === 3 ? t("Unarchive") : t("Archive")}>
                                  {user.status === 3 ? <Unarchive /> : ((isAdmin || userData?.isSuperAdmin) && <ArchieveIcon />)}
                                </span>

                                <span className="table-action-btn cursor-pointer" onClick={() => handleSendMail(user)} title={t("Reset pasword")}>
                                  <SendMailIcon />
                                </span>
                                <span className='table-action-btn'>
                                  <div className="position-relative manageInfoIcon table-action-btn cursor-pointer">
                                    <InfoIcon />
                                    <span className="infoHelpText text-center">
                                      {t(`Created by on ${user.createdAt}\n 
                                        Modified by on ${user.updatedAt}`)}
                                    </span>
                                  </div>
                                </span>
                              </ActionIconWrapper >
                              <div>
                              </div>
                            </td >
                          </tr >
                        ))
                      ) : (
                        <tr className="border">
                          <td colSpan={10} className="border-0 text-center py-3">
                            <span className="text-danger fw-bold">{t("No records")}</span>
                          </td>
                        </tr>
                      )}
                    </>
                  }
                </tbody >
              </table >
            </div >
            <div
              className="pagination d-block"
              style={{ paddingTop: "0.25vw" }}
            >
              <PaginationWithPerPage
                handleRefresh={handleRefresh}
                dispatch={dispatch}
                history={history}
                pageData={pageData}
              />
            </div>
          </div>
        </div>

        {/* <div>
          <div
            className="col-md-6 align-self-center"
            style={{ padding: "0.5vw 0" }}
          >
            <BackButton />
          </div>
        </div> */}
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </>
    </AccessControl>
  );
};

export default ManageUsersPage;
