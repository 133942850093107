import React, { useState } from "react";
import WeekCalendar from "./weekCalendar";
import { useFilterOptions, useFilterOptionsDispatch } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import PerformanceAndInvoice from "../atoms/performanceAndInvoice";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useTImesheetContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { checkForPermission, ROLES } from "../constants/timesheetConstants";
import { TIMESHEET_FILTER_ACTIONS } from "../timesheets-redux/actions/TimesheetFilterActions";

const ProjectParameters = (props) => {
  const { planTimingsData } = props;
  const { filterOptionsState } = useFilterOptions();
  const { initialTimesheetState } = useTImesheetContext();
  const { dispatchFilterOptions } = useFilterOptionsDispatch();
  const [state, setState] = useState({
    tab: checkForPermission(props?.type, [ROLES?.EMPLOYEE]) ? 'reimbursement' : 'performance',
  })
  const changeTab = (tab) => {
    setState((prev) => ({ ...prev, tab }));
    // dispatchFilterOptions({
    //   type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
    //   payload: { name: "tab", value: tab }
    // });
  }

  return (
    <>
      {checkForPermission(props?.type, [ROLES?.MAXICON, ROLES?.EMPLOYEE]) && <tr>
        <th colSpan={5}
          style={{ backgroundColor: "#e8f8fb", color: state?.tab === 'performance' ? "#00a5ce" : "#000", borderRight:"0.08vw solid #fff" }}
          className="cursor-pointer"
          onClick={() => checkForPermission(props?.type) ? changeTab('performance') : {}}>
          {t("Performance")}
        </th>
        <th colSpan={5}
          className="cursor-pointer"
          onClick={() => changeTab('reimbursement')}
          style={{ backgroundColor: "#e8f8fb", color: state?.tab === 'reimbursement' ? "#00a5ce" : "#000" }}>
          {t("Reimbursements")}
        </th>
      </tr>}
      {checkForPermission(props?.type, [ROLES?.MAXICON, ROLES?.EMPLOYEE]) && <WeekCalendar
        month title={state?.tab === 'performance' ? 'Performance' : 'Reimbursements'}
        performanceAndInvoiceData={planTimingsData}
        isDisabled={initialTimesheetState?.isDisabled}
      />}
      {state?.tab === 'performance' && checkForPermission(props?.type) && <PerformanceAndInvoice
        title={t("Performance")}
        performanceAndInvoiceData={planTimingsData}
        modalName="performance"
        values={[
          { key: 'type', name: 'Type', options: filterOptionsState?.optionState?.performanceTypes },
          { key: 'hours', name: 'Hours', last: "u", first: "" },
          { key: 'standardPrice', name: 'Standard price', last: "", first: "€" },
          { key: 'totalPrice', name: 'Total price', last: "EUR", first: "Price:" }
        ]}
        isDisabled={initialTimesheetState?.isDisabled}
      />}
      {state?.tab === 'reimbursement' && checkForPermission(props?.type, [ROLES?.MAXICON, ROLES?.EMPLOYEE]) && <PerformanceAndInvoice
        title={t("Reimbursements")}
        performanceAndInvoiceData={planTimingsData}
        modalName="reimbursement"
        values={[
          { key: 'type', name: 'Type', options: filterOptionsState?.optionState?.reimbursementTypes },
          { key: 'numbers', name: 'Number', last: "u", first: "" },
          { key: 'price', name: 'Price', last: "", first: "€" },
          { key: 'totalPrice', name: 'Total price', last: "EUR", first: "Price:" },
        ]}
        isDisabled={initialTimesheetState?.isDisabled}
      />}
      {/* <PerformanceAndInvoice
        title="Invoice"
        performanceAndInvoiceData={planTimingsData}
        modalName="invoice"
      /> */}
    </>
  );
};
export default ProjectParameters;
