import React, { ChangeEvent, useState } from "react";
import Modal from "react-bootstrap/Modal";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import CommonComponent from "pages/microservices/masterData/competence/CommonComponent";
import CompetenceType from "pages/microservices/masterData/competence/CompetenceType";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import CheckBoxField from "components/atoms/CheckBoxField";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import RadioField from "components/atoms/RadioField";
import LoadingIcon from "utils/LoadingIcon";
import {
  validateDichtmsOptions,
  validateDichtmsOptionsAnswer,
  validateForm,
  validateMultiChoice,
  validateMultiChoiceAnswer,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { OptionProps, ValidationRules } from "utils/TypeAnnotations";
import { Option } from "utils/TypeAnnotations";
import CloseFile from "static/images/CloseFile";
import { STORE_QUESTIONS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";

interface commonDataProps {
  // questionCategory: Option | null;
  // pcSection: Option | null;
  function: object[];
  // pcCategory: object[];
}
interface CompetenceModalProps {
  handleClose: () => void;
  handleAdd: (question: any) => void;
  functionOptions: {};
  questionTypeOption: {};
}

interface QuestionProps {
  [key: string]:
    | string
    | number
    | null
    | boolean
    | Option
    | OptionProps[]
    | number[]
    | string[];
  questionLinkedId: number | null;
  questionType: Option | null;
  questionTitle: string;
  question: string;
  options: string[];
  answers: number[];
  description: string;
  questionStatus: number;
}

const AddProjectQuestion: React.FC<CompetenceModalProps> = ({
  handleClose,
  handleAdd,
  functionOptions,
  questionTypeOption,
}) => {
  ///********* */ Common form start********///
  const [commonData, setCommonData] = useState<commonDataProps>({
    function: [],
  });
  const [dropDownData, setDropDownData] = useState({
    function: [] as OptionProps[],
    pcSection: [] as OptionProps[],
    questionTypes: [] as OptionProps[],
    pcCategory: [] as OptionProps[],
  });

  const initialCommonErrors: { [key: string]: string } = {};
  const [commonErrors, setCommonErrors] = useState<{ [key: string]: string }>(
    initialCommonErrors
  );

  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [pcValue, setPcvalue] = useState([]);

  const commonValidation = (
    name: string,
    value: string | boolean | Date | object[] | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      pcSection: [validateSelectField],
      function: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...commonData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setCommonErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setCommonErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setCommonData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    // if (fieldName === "pcSection") {
    //   setPcvalue(selectedOption);
    //   getFunctionForPC(selectedOption);
    // }

    // if (fieldName === "pcCategory") {
    //   if (selectedOption.length === 0) {
    //     getFunctionForPC(pcValue);
    //   } else {
    //     getFunctionForCategory(selectedOption);
    //   }
    // }
    commonValidation(fieldName, selectedOption, true);
  };

  const [questions, setQuestions] = useState<QuestionProps[]>([
    {
      questionLinkedId: null,
      questionType: null,
      questionTitle: "",
      question: "",
      options: [""],
      answers: [],
      description: "",
      questionStatus: 1,
    },
  ]);
  const initialErrors: { [key: string]: string }[] = [];
  const [errors, setErrors] =
    useState<{ [key: string]: string }[]>(initialErrors);

  const validation = (
    name: string,
    value:
      | string
      | boolean
      | number
      | null
      | Option
      | number[]
      | OptionProps[]
      | string[],
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRulesByType: any = {
      1: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
        options: [validateMultiChoice],
        answers: [validateMultiChoiceAnswer],
      },
      2: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
        description: [validateRequired],
      },
      3: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
        options: [validateDichtmsOptions],
        answers: [validateDichtmsOptionsAnswer],
      },
      4: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
      },
    };
    const questionType: number = questions[index].questionType?.value || 4;
    const validationErrors = validateForm(
      { ...questions[index], [name]: value },
      validationRulesByType[questionType],
      isSingleFieldValidation ? name : undefined
    );
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];

      if (isSingleFieldValidation) {
        // Single-field validation
        if (!newErrors[index]) {
          newErrors[index] = {}; // Initialize the object if it doesn't exist
        }
        newErrors[index][name] = validationErrors[name];
      } else {
        // Full-form validation
        newErrors[index] = validationErrors;
        questions[index].options.forEach((optionValue, optionIndex) => {
          newErrors[index][`option-${index}-${optionIndex}`] =
            optionValue.trim() === "" ? "This field is required" : "";
        });
      }

      return newErrors;
    });

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const addOption = (questionIndex: number) => {
    const newFields = [...questions];
    if (newFields[questionIndex].questionType?.value === 1) {
      if (newFields[questionIndex].options.length < 4) {
        newFields[questionIndex].options.push("");
        setQuestions(newFields);
      }
    }
    if (newFields[questionIndex].questionType?.value === 3) {
      if (newFields[questionIndex].options.length < 2) {
        newFields[questionIndex].options.push("");
        setQuestions(newFields);
      }
    }
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;

    if (type === "radio") {
      const answer = parseInt(event.target.value);
      const newAnswers: number[] = [answer];
      const newFields: QuestionProps[] = [...questions];
      newFields[index]["answers"] = newAnswers;
      setQuestions(newFields);
    }
    // Check if the field is a checkbox
    else if (type === "checkbox" && name.startsWith("answers")) {
      const newFields: QuestionProps[] = [...questions];
      const answersList = newFields[index].answers || []; // Get the existing checked options array

      if ((event.target as HTMLInputElement).checked) {
        // If the checkbox is checked, add the value to the array
        answersList.push(parseInt(value));
      } else {
        // If the checkbox is unchecked, remove the value from the array
        const indexToRemove = answersList.indexOf(parseInt(value));
        if (indexToRemove !== -1) {
          answersList.splice(indexToRemove, 1);
        }
      }

      newFields[index].answers = answersList;
      validation(name, answersList, index, true);
      setQuestions(newFields);
    } else if (type === "checkbox" && name === "questionStatus") {
      const newFields: QuestionProps[] = [...questions];

      // Check if the checkbox is checked
      const isChecked = (event.target as HTMLInputElement).checked;

      // Set questionStatus to 1 if checked, else set it to 0
      newFields[index][name] = isChecked ? 1 : 0;

      setQuestions(newFields);
    } else {
      // Handle other input fields as before
      const newFields: QuestionProps[] = [...questions];
      newFields[index][name] = value;
      setQuestions(newFields);
      validation(name, value, index, true);
    }
  };

  const handleDescriptionFieldChange = (
    event: any,
    editor: any,
    index: number,
    name: string
  ) => {
    const content = editor.getData();
    const newFields: QuestionProps[] = [...questions];
    newFields[index][name] = content;
    setQuestions(newFields);
    validation(name, content, index, true);
  };

  const handleOptionFieldChange = (
    event: any,
    editor: any,
    index: number,
    optionIndex: number
  ) => {
    const content = editor.getData();

    // Update the state with the changed option content
    const newQuestions = [...questions];
    newQuestions[index].options[optionIndex] = content;
    setQuestions(newQuestions);
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (!newErrors[index]) {
        newErrors[index] = {};
      }
      newErrors[index][`option-${index}-${optionIndex}`] =
        content.trim() === "" ? "This field is required" : "";
      return newErrors;
    });
  };
  const removeFieldSet = (index: number): void => {
    const newFields: QuestionProps[] = [...questions];
    newFields.splice(index, 1);
    setQuestions(newFields);
    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  const handleDynamicSelectChange = (
    selectedOption: any,
    fieldName: string,
    index: number
  ) => {
    const newFields: QuestionProps[] = [...questions];
    if (
      selectedOption.value === 1 ||
      selectedOption.value === 2 ||
      selectedOption.value === 3
    ) {
      newFields[index] = {
        ...newFields[index],
        questionTitle: "",
        question: "",
        options: [""],
        answers: [],
        description: "",
        [fieldName]: selectedOption,
      };
      const newErrors = [...errors];
      newErrors[index] = {};
      setErrors(newErrors);
    }
    newFields[index] = {
      ...newFields[index],
      [fieldName]: selectedOption,
    };
    setQuestions(newFields);
    validation(fieldName, selectedOption, index, true);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    let hasErrors = false;

    for (let i = 0; i < questions.length; i++) {
      const { name, value } = questions[i];
      const validationResult = validation(name as string, value, i);
      if (validationResult !== true) {
        hasErrors = true;
      }
    }

    let commonValidationResult =
      activeTab === "General" ? true : commonValidation(name, value);

    if (commonValidationResult && !hasErrors) {
      // const mode = isCloneMode ? "clone" : isEditMode ? "edit" : "add";
      const formData = {
        mode: "add",
        questionCategory:
          activeTab === "General"
            ? "projectGeneralQuestion"
            : "projectFunction",

        functions: commonData.function,
        questions: questions,
      };
      const url = STORE_QUESTIONS;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_MASTER_DATA
      );

      if (response.status === 200) {
        const questions = response?.data;
        handleAdd(questions);
        CustomNotify({ type: "success", message: response.msg });
      } else if (response.status === 400) {
        setCommonErrors({ function: response.msg });
        // setCommonErrors({pcCategory: response.msg});
        CustomNotify({ type: "error", message: response.msg });
      }
      setLoading(false);
    }
  };

  const tabs = [
    { id: "General", tabName: "General" },
    { id: "Function", tabName: "Function" },
  ];
  const [activeTab, setActiveTab] = useState("General"); // Track the active tab
  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };
  return (
    <Modal
      size="xl"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      className="competencesModal addProjectQuestionPopup"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Add Question")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Tabs Section */}
        <div className="vacancy-tabs question-tabs d-flex backPadding">
          <div className="row">
            <div className="col-12">
              {tabs.map((eachItem) => (
                <button
                  key={eachItem.id}
                  className={`text-decoration-none border-0 bg-transparent ps-0 marginRight1 ${
                    activeTab === eachItem.id ? "active-tab" : ""
                  }`}
                  onClick={() => handleTabClick(eachItem.id)}
                >
                  {t(eachItem.tabName)}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="row" style={{ marginTop: "0.1vw" }}>
              {activeTab !== "General" && (
                <>
                  <div className="col-12">
                    <SelectWithSearch
                      title={t("Function")}
                      isMandatory={true}
                      search={true}
                      options={functionOptions}
                      onChange={(e) => handleSelectChange(e, "function")}
                      isMulti={true}
                      name="function"
                      isDisabled={false}
                      value={commonData.function}
                      error={commonErrors.function}
                      placeHolder={t("Select function")}
                    />
                  </div>
                </>
              )}
              <div className="col-12">
                <div className="form-border p-0">
                  {questions.map((question: any, index: number) => (
                    <div
                      key={index}
                      className="bg-white"
                      style={{
                        padding: "2vw",
                        borderRadius: "1vw",
                        marginBottom: "1.5vw",
                      }}
                    >
                      {index > 0 && (
                        <div className="row">
                          <div className="col-md-12 table-action-icons">
                            <span
                              title={t("Remove")}
                              onClick={() => removeFieldSet(index)}
                              className="table-action-btn cursor-pointer float-end"
                            >
                              <CloseFile />
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <SelectWithSearch
                            title={t("Question type")}
                            isMandatory={true}
                            search={true}
                            options={questionTypeOption}
                            id={`questionType-${index}`}
                            placeHolder={t("Select question type")}
                            onChange={(e) =>
                              handleDynamicSelectChange(
                                e,
                                "questionType",
                                index
                              )
                            }
                            isMulti={false}
                            className="select-field"
                            name="questionType"
                            value={question.questionType}
                            error={errors[index]?.questionType}
                            isDisabled={
                              question.questionLinkedId === null ? false : true
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <LabelWithInputField
                            isMandatory={true}
                            name="questionTitle"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={question.questionTitle}
                            id={`questionTitle-${index}`}
                            label={t("Question title")}
                            type="text"
                            error={errors[index]?.questionTitle}
                          />
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <LabelWithInputField
                            isMandatory={true}
                            name="question"
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            value={question.question}
                            id={`question-${index}`}
                            label={t("Question")}
                            type="text"
                            error={errors[index]?.question}
                          />
                        </div>
                      </div>
                      {/**Multiple choice start*/}
                      {question.questionType &&
                        question.questionType.value === 1 && (
                          <>
                            <div key={index} className="d-grid grid-2">
                              {question.options.map(
                                (option: any, optionIndex: number) => (
                                  <div key={optionIndex}>
                                    <div className="d-flex justify-content-center align-items-center multipleChoiceQns">
                                      <div style={{ width: "10%" }}>
                                        <CheckBoxField
                                          name={`answers-${index}-${optionIndex}`}
                                          id={`answers-${index}-${optionIndex}`}
                                          onChangeHandler={(event) =>
                                            handleFieldChange(event, index)
                                          }
                                          value={optionIndex}
                                          isChecked={question.answers.includes(
                                            optionIndex
                                          )}
                                        />
                                      </div>
                                      <div style={{ width: "90%" }}>
                                        <LabelWithCKEditorField
                                          isMandatory={true}
                                          label={`Option ${optionIndex + 1}`}
                                          name={`option-${index}-${optionIndex}`}
                                          placeholder={`Option ${
                                            optionIndex + 1
                                          }`}
                                          handleChange={(event, editor) =>
                                            handleOptionFieldChange(
                                              event,
                                              editor,
                                              index,
                                              optionIndex
                                            )
                                          }
                                          value={question.options[optionIndex]}
                                          id={`option-${index}-${optionIndex}`}
                                          error={
                                            errors[index]?.[
                                              `option-${index}-${optionIndex}`
                                            ]
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            {errors[index]?.options && (
                              <div className="col-md-12">
                                <span className="text-danger">
                                  {errors[index]?.options}
                                </span>
                              </div>
                            )}
                            {errors[index]?.answers && (
                              <div
                                className="col-md-12"
                                style={{ marginBottom: "0.5vw" }}
                              >
                                <span className="text-danger">
                                  {errors[index]?.answers}
                                </span>
                              </div>
                            )}
                            {question.options.length < 4 && (
                              <div className="row">
                                <div className="col-md-12">
                                  <Button
                                    title={t("Add option")}
                                    handleClick={() => addOption(index)}
                                    className="form-button float-end"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      {/**Multiple choice end*/}

                      {/**Boolean start */}
                      {question.questionType &&
                        question.questionType.value === 3 && (
                          <div key={index}>
                            <div className="d-grid grid-2">
                              {question.options.map(
                                (option: any, optionIndex: number) => (
                                  <div key={optionIndex}>
                                    <div className="d-flex justify-content-center align-items-center radioFieldQns">
                                      <div style={{ width: "5%" }}>
                                        <RadioField
                                          name={`answers-${index}`}
                                          id={`answers-${index}-${optionIndex}`}
                                          handleChange={(event) => {
                                            handleFieldChange(event, index);
                                          }}
                                          value={optionIndex}
                                          ischecked={question.answers.includes(
                                            optionIndex
                                          )}
                                          label=""
                                        />
                                      </div>
                                      <div style={{ width: "95%" }}>
                                        <LabelWithCKEditorField
                                          isMandatory={true}
                                          label={`Option ${optionIndex + 1}`}
                                          placeholder={`Option ${
                                            optionIndex + 1
                                          }`}
                                          name={`option-${index}-${optionIndex}`}
                                          handleChange={(event, editor) =>
                                            handleOptionFieldChange(
                                              event,
                                              editor,
                                              index,
                                              optionIndex
                                            )
                                          }
                                          value={question.options[optionIndex]}
                                          id={`option-${index}-${optionIndex}`}
                                          error={
                                            errors[index]?.[
                                              `option-${index}-${optionIndex}`
                                            ]
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            {errors[index]?.options && (
                              <div className="col-md-6">
                                <span className="text-danger">
                                  {errors[index]?.options}
                                </span>
                              </div>
                            )}
                            {errors[index]?.answers && (
                              <div
                                className="col-md-3"
                                style={{ marginBottom: "0.5vw" }}
                              >
                                <span className="text-danger">
                                  {errors[index]?.answers}
                                </span>
                              </div>
                            )}
                            {question.options.length < 2 && (
                              <div className="row">
                                <div className="col-md-12">
                                  <Button
                                    title={t("Add option")}
                                    handleClick={() => addOption(index)}
                                    className="form-button float-end"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      {/* Boolean end */}

                      {/* Descriptive start */}
                      {question.questionType &&
                        question.questionType.value === 2 && (
                          <div className="row">
                            <div className="col-lg-12">
                              <LabelWithCKEditorField
                                name="description"
                                handleChange={(event, editor) =>
                                  handleDescriptionFieldChange(
                                    event,
                                    editor,
                                    index,
                                    "description"
                                  )
                                }
                                label={t("Question description")}
                                isMandatory={true}
                                value={question.description}
                                error={errors[index]?.description}
                              />
                            </div>
                          </div>
                        )}
                      {/* Descriptive end*/}
                      <div className="row">
                        <div className="col-md-12">
                          <CheckBoxField
                            label={t("Publish question")}
                            name="questionStatus"
                            onChangeHandler={(event) =>
                              handleFieldChange(event, index)
                            }
                            isChecked={question.questionStatus === 1}
                            id="questionStatus"
                            lineHeight="1.5vw"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row" style={{ padding: "0.85vw 0" }}>
              <div className="col-6 align-self-center">
                <Button
                  title={t("Cancel")}
                  handleClick={() => handleClose()}
                  className={
                    "back-btn btn text-decoration-underline ps-0 border-0"
                  }
                />
              </div>
              <div className="col-md-6 text">
                {/* {!loading ? ( */}
                <Button
                  title={t("Save")}
                  type="submit"
                  className="form-button float-end"
                />
                {/* ) : (
                  <LoadingIcon
                    iconType="bars"
                    color="#00a5ce"
                    className="ms-auto"
                    width="2.5vw"
                    height="2.5vw"
                  />
                )} */}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddProjectQuestion;
