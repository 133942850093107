import Button from "components/atoms/Button";
import React, { useState, useEffect } from "react";
import { t } from "../translation/Translation";
import { validateAmount, validateForm, validateMultiSelectField, validateRequired, validateSelectField } from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { ApiCall } from "services/ApiServices";
import { GET_TYPE_OPTIONS, MARGIN_CONFIG_CREATE, MARGIN_CONFIG_MANAGE } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_MARGIN_CONFIG_MANAGE } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import BackButton from "components/atoms/BackButton";
import SelectWithSearch from "components/atoms/SelectWithSearch";

function CreateMarginConfig() {
  const [state, setState] = useState<any>({
    data: {
      id: null,
      name: "",
      model: "",
      amount: "",
      type: null,
    },
    tableData: [],
    editIndex: null,
    errors: {
      name: "",
      amount: "",
      type: "",
      model: "",
    },
    options: {
      types: [
        { value: 'car', label: 'Car' },
        { value: 'material', label: 'Material' },
        { value: 'clothes', label: 'Clothes' },
        { value: 'dynamic', label: 'Dynamic' },
      ],
      names: [],
    }
  });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (state?.data?.type !== 'dynamic') {
      fetchOptions();
    }
    else {
      setState((prev: any) => ({ ...prev, options: { ...prev.options, names: [] } }));
    }
  }, [state?.data?.type]);

  const [isChange, setIsChange] = useState(false);

  const fetchOptions = async () => {
    try {
      const response = await ApiCall.service(GET_TYPE_OPTIONS, 'POST', { type: state?.data?.type, id }, true, M_MASTER_DATA);
      if (response?.status == 200) {
        setState((prev: any) => ({ ...prev, data: { ...prev.data, model: isChange ? [] : prev.data.model }, options: { ...prev.options, names: response?.data } }));
      }
      else {
        CustomNotify({ type: 'error', message: response?.message });
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const fetchData = async () => {
    try {
      const response = await ApiCall.service(MARGIN_CONFIG_MANAGE, "POST", { id }, true, M_MASTER_DATA);
      if (response?.status === 200) {
        setState((prev: any) => ({ ...prev, data: response?.data }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeHandler = (e: any, name: any) => {
    let value = e?.target?.value ?? "";
    switch (name) {
      case 'type':
        value = e?.value ?? null;
        setIsChange(true)
        break;
      case 'amount':
        value = value.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1').replace(/(,.*),/g, '$1');
        break;
      case 'name':
        value = value;
        break;
      default:
        value = e;
        break;
    }
    const errors: any = validateInput(name, value, true);
    setState((prev: any) => ({
      ...prev,
      data: { ...prev.data, [name]: value, },
      errors: { ...prev.errors, [name]: errors[name] ?? "" },
    }));
  };

  const validateInput = (name: string | any, value: string | boolean | any, isSingleFieldValidation: boolean = false, strict = true) => {
    const validationRules: any = state?.data?.type == 'dynamic' ? {
      // model: [validateMultiSelectField],
      amount: [validateRequired, validateAmount],
      type: [validateRequired],
      name: [validateRequired],
    } : {
      model: [validateMultiSelectField],
      amount: [validateRequired, validateAmount],
      type: [validateRequired],
      name: [validateRequired],
    };

    // Validate the form data based on the rules
    const validationErrors = validateForm({ ...state?.data, [name]: value }, validationRules);

    // Update errors state based on the validation results
    if (isSingleFieldValidation) {
      return validationErrors;
    } else {
      (strict || state?.tableData?.length <= 0) && setState((prev: any) => ({ ...prev, errors: validationErrors }));
    }

    // Check if there are any validation errors
    return !(Object.keys(validationErrors).length > 0)
  };

  const handleRemove = (index: number) => {
    const updatedTableData = [...state?.tableData];
    updatedTableData?.splice(index, 1);
    setState((prev: any) => ({ ...prev, tableData: updatedTableData }));
  };

  const handleEdit = (index: number) => {
    const editedData = state?.tableData[index];
    setState((prev: any) => ({ ...prev, data: editedData, errors: { name: "", type: "", model: "", amount: "" }, editIndex: index }));
  };

  const getRowClass = (index: any) => {
    return state?.editIndex === index ? "highlighted-row" : "";
  };

  const handleAddClick = () => {
    const isFormValid = validateInput("name", state?.data?.name);
    if (isFormValid) {
      // Editing existing entry
      const updatedTableData = [...state?.tableData];
      if (state?.editIndex !== null) {
        updatedTableData[state?.editIndex] = state?.data;
      } else {
        updatedTableData?.push(state?.data);
      }
      setState((prev: any) => ({
        ...prev,
        tableData: [...updatedTableData],
        data: { name: "", type: null, id: null, amount: "" },
        editIndex: null
      }));
    }
  };

  const validateForUniqueness = () => {
    const optionsIds = state?.tableData?.filter((eachName: any) => eachName?.type == state?.data?.type).flatMap((eachData: any) => eachData?.model?.map((each: any) => each?.value));
    const filteredOptions = state?.options?.names?.filter((eachItem: any) => {
      return !(optionsIds.includes(eachItem?.value))
    });
    return filteredOptions;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let finalData = [...state?.tableData];
    const isFormValid = validateInput("name", state?.data?.name, false, false);

    if (isFormValid) {
      finalData?.push(state?.data);
    }

    if (isFormValid || state?.tableData?.length > 0) {
      const response = await ApiCall.service(MARGIN_CONFIG_CREATE, "POST", finalData, true, M_MASTER_DATA);
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response?.message });
        navigate(PATH_MARGIN_CONFIG_MANAGE);
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = { permission: "Margin config" };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <div className="row">
            <Title title={id ? t("Update margin config") : t("Create margin config")} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={handleSubmit} style={{ marginBottom: "1vw" }}>
              <div className="form-border">
                <div className="row">
                  <div className="col-md-3">
                    <LabelWithInputField
                      isMandatory
                      name="name"
                      handleChange={(e) => changeHandler(e, 'name')}
                      value={state?.data?.name}
                      id="name"
                      label={t("Name")}
                      placeholder={t("Name")}
                      type="text"
                      error={state?.errors?.name}
                    />
                  </div>
                  <div className="col-md-2">
                    <SelectWithSearch
                      isMandatory
                      search
                      name="type"
                      options={state?.options?.types}
                      onChange={(e) => changeHandler(e, 'type')}
                      value={state?.data?.type}
                      id="type"
                      title={t("Type")}
                      placeHolder={t("Type")}
                      error={state?.errors?.type}
                    />
                  </div>
                  <div className="col-md-2">
                    <SelectWithSearch
                      isMandatory={state?.data?.type == 'dynamic' ? false : true}
                      search
                      name="model"
                      options={validateForUniqueness() ?? []}
                      onChange={(e) => changeHandler(e, 'model')}
                      value={state?.data?.model}
                      id="model"
                      title={t("Model")}
                      placeHolder={t("Model")}
                      error={state?.errors?.model}
                      isMulti={true}
                    />
                  </div>
                  <div className="col-md-2">
                    <LabelWithInputField
                      isMandatory
                      name="amount"
                      handleChange={(e) => changeHandler(e, 'amount')}
                      value={state?.data?.amount}
                      id="amount"
                      label={t("Amount")}
                      placeholder={t("Amount")}
                      type="text"
                      error={state?.errors?.amount}
                    />
                  </div>
                  <div className="col-md-3 addAnotherBtn">
                    {!id && (
                      <Button
                        title={state?.editIndex != null ? t("Update") : t("+ Add another")}
                        handleClick={handleAddClick}
                        className="form-button float-end"
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>

            {/* table below the form */}
            {state?.tableData?.length > 0 && (
              <div className="form-border createMarginCongidTable">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead className="TableHeader">
                      <tr>
                        <th style={{ width: "20%" }}>{t("Name")}</th>
                        <th style={{ width: "20%" }}>{t("Type")}</th>
                        <th style={{ width: "20%" }}>{t("Amount")}</th>
                        <th style={{ width: "15%" }}>{t("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state?.tableData?.map((item: any, index: number) => (
                        <tr key={index} className={getRowClass(index)}>
                          <td className="align-middle ps-4">{item.name}</td>
                          <td className="align-middle">{(state?.options?.types ?? [])?.find((type: any) => item?.type === type.value)?.label}</td>
                          <td className="align-middle">{item?.amount}</td>
                          <td className="table-action-icons">
                            <span
                              onClick={() => handleEdit(index)}
                              className="table-action-btn me-2 cursor-pointer"
                              title={t("Edit")}
                            >
                              <EditIcon />
                            </span>
                            <span
                              onClick={() => handleRemove(index)}
                              className="table-action-btn cursor-pointer"
                              title={t("Delete")}
                            >
                              <DeleteIcon />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between backPadding align-items-center">
              <div>
                <BackButton />
              </div>

              <div>
                <Button
                  title={t("Save")}
                  handleClick={handleSubmit}
                  className="form-button float-end shadow-none"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </AccessControl>
  );
}

export default CreateMarginConfig;
