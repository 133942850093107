import TitleFieldMolecule from "components/molecules/TitleField";
import { t } from "../translation/Translation";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import Search from "components/atoms/Search";
import {
  APPLY_LEAVE_OF_EMPLOYEE,
  EDIT_LEAVE_OF_EMPLOYEE,
  EMPLOYEE_LEAVE_COUNT,
  VIEW_LEAVE_OF_EMPLOYEE,
} from "constants/Paths";
import { useEffect, useState } from "react";
import CustomNotify from "components/atoms/CustomNotify";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import {
  DELETE_APPLIED_LEAVE,
  GET_APPLIED_LEAVE_DATA,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import TableActions from "components/organism/Actions/TableAction";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TitleAtom from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";

interface SearchData {
  leaveStatus: any;
  searchTitle: any;
}
interface DataProps {
  page: number;
  search?: any;
  userId?: any;
}

interface EmployeeLeaveData {
  id: number;
  status: number;
  leaveType: any;
  reason: any;
  from: any;
  to: any;
  unit: any;
}

const ManageLeavesOfEmployee = () => {
  const leaveStatus = [
    { name: "Approved", label: "Approved", value: 2 },
    { name: "Cancelled", label: "Cancelled", value: 3 },
    { name: "New", label: "New", value: 1 },
  ];
  const userData = useSelector(selectAuth);

  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState({
    leaveStatus: queryParams.get("leaveStatus") ?? leaveStatus[2]["value"],
    searchTitle: queryParams.get("searchTitle") ?? "",
  });
  const [id, setId] = useState<number | undefined>(undefined);
  const userId = useSelector(selectAuth).userId;
  const [employeeLeaveData, setEmployeeLeaveData] = useState<
    EmployeeLeaveData[]
  >([]);
  //onChange event handler for select element
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
      userId: userId,
    };
    fetchAppliedLeaveData(data);
  }, [currentPage, submit]);

  const fetchAppliedLeaveData = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        GET_APPLIED_LEAVE_DATA,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setEmployeeLeaveData(response?.data);
        setTotalPages(response?.totalPages);
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };
  //onSubmit event handler
  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearch({ leaveStatus: leaveStatus[2].value, searchTitle: "" });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(search);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }

    setSubmit((prevSubmit) => !prevSubmit);
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: SearchData) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //onClick edit handler
  const handleEdit = (entity: any) => {
    navigate(`${EDIT_LEAVE_OF_EMPLOYEE}/${entity}`);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleDelete = (entity: any) => {
    setId(entity);
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.service(
        DELETE_APPLIED_LEAVE,
        "POST",
        { id: id, type: "Delete" },
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response?.message });
        setEmployeeLeaveData((prevdata) =>
          (prevdata as EmployeeLeaveData[]).filter(
            (leaveData) => leaveData.id !== id
          )
        );
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
  };
  const handleView = (entity: any) => {
    navigate(`${VIEW_LEAVE_OF_EMPLOYEE}/${entity}`);
  };

  return (
    <>
      <div className="search-bar">
        <div className="row">
          <TitleAtom title={t("Leaves")} />
        </div>
        <div className="row" style={{ paddingBottom: "0.2vw" }}>
          <div className="col-3">
            <select
              className="form-select form-control field-shadow"
              name="leaveStatus"
              id="leaveStatus"
              onChange={handleSearchChange}
              value={search.leaveStatus}
            >
              <option value="">{t("Select leave status")}</option>
              {leaveStatus?.map((optn, key) => (
                <option key={key} value={optn.value}>
                  {optn.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3 hideLabel">
            <SearchWithLabel
              type="text"
              showLabel
              handleChange={handleSearchChange}
              placeholder="Search title"
              name="searchTitle"
              value={search.searchTitle}
              className="col-12"
            />
          </div>
          <div className="col-3">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
              <SearchBtn handleSearchClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                {/* As we are giving too many options to apply it's leading to confusion so commenting... */}
                {/* <LinkTo
                  pagelink="/calender"
                  title={t("Calender view")}
                  className="me-3"
                /> */}
                {userData.role?.includes("EMPLOYEE") &&
                  userData.contractType?.label !== "Freelancer" &&
                  userData.contractType?.label !== "Temporary" && (
                    <LinkTo
                      pagelink={`${APPLY_LEAVE_OF_EMPLOYEE}`}
                      title={t("Apply for holiday")}
                      className="me-3"
                    />
                  )}
                <LinkTo
                  pagelink={`${EMPLOYEE_LEAVE_COUNT}`}
                  title={t("Leave counts")}
                />
              </div>
            </div>
            <div className="table-responsive manage-projects tableSection">
              <div className="TableHeight">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      <th style={{ width: "10%" }}>{t("From")}</th>
                      <th style={{ width: "10%" }}>{t("To")}</th>
                      <th style={{ width: "10%" }}>{t("Unit")}</th>
                      <th style={{ width: "10%" }}>{t("Holiday code")}</th>
                      <th style={{ width: "10%" }}>{t("Reason")}</th>
                      <th style={{ width: "10%" }}>{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeLeaveData.length > 0 ? (
                      employeeLeaveData?.map((eachLeaveData) => (
                        <tr key={eachLeaveData.id}>
                          <td>{eachLeaveData.from}</td>
                          <td>{eachLeaveData.to}</td>
                          <td>{eachLeaveData.leaveType}</td>
                          <td>{eachLeaveData.unit}</td>
                          <td>{eachLeaveData.reason}</td>
                          <td>
                            {eachLeaveData.status === 1 ? (
                              <TableActions
                                isAction={true}
                                handleView={handleView}
                                handleEdit={
                                  userData.role?.includes("EMPLOYEE") &&
                                  userData.contractType?.label !==
                                    "Freelancer" &&
                                  userData.contractType?.label !== "Temporary"
                                    ? handleEdit
                                    : undefined
                                }
                                handleDelete={
                                  userData.role?.includes("EMPLOYEE") &&
                                  userData.contractType?.label !==
                                    "Freelancer" &&
                                  userData.contractType?.label !== "Temporary"
                                    ? handleDelete
                                    : undefined
                                }
                                value={eachLeaveData.id}
                                permission={"Leaves"}
                              />
                            ) : (
                              <TableActions
                                isAction={true}
                                handleView={handleView}
                                value={eachLeaveData.id}
                                permission={"Leaves"}
                              />
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          {t("No record available")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="pagination justify-content-center align-items-center"
                style={{ paddingTop: "0.25vw" }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <BackButton />
        </div> */}
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Delete confirmation"
        body="Are you sure want to delete?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
    </>
  );
};
export default ManageLeavesOfEmployee;
