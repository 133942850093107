import React from "react";
import { Link } from "react-router-dom";
import {
  PATH_MANAGE_EMPLOYEE_TYPE, PATH_MANAGE_BUSINESS_UNIT, PATH_EMAIL_TEMPLATE_MANAGE, PATH_MANAGE_SHIFTS,
  PATH_MANAGE_TEMPLATE, PATH_PC_MANAGE, PATH_COMPETENCE_MANAGE, PATH_MANAGE_TIMETABLE,
  PATH_CONFIG_ELEMENTS_MANAGE, PATH_APPLICATION_CONFIGURATION, MANAGE_QUESIONS, PATH_SALARY_BENEFITS_MANAGE,
  PATH_MANAGE_CONTRACT_TEMPLATE, PATH_MANAGE_DOCUMENT_TYPE, HOLIDAY_CONFIGS, PATH_MANAGE_TRANSLATIONS,
  PATH_MANAGE_WAGE_ELEMENT,
  PATH_MANAGECOEFF_DEVIATION,
  PATH_MANAGE_BUSINESS_UNIT_CITIES,
  PATH_MARGIN_CONFIG_MANAGE,
  PATH_CONTRACT_TYPE_MANAGE,
  PATH_VAT_RATE_MANAGE,
  PATH_MANAGE_DEFAULT_COEFFICIENTS,
  PATH_MANAGE_PAYROLL_CONFIG,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import TitleAtom from "components/atoms/Title";

const ConfigAndSettingsPage: React.FC = () => {
  const userData = useSelector(selectAuth);

  // Define the configuration for cards
  const configCards = [
    // { path: PATH_APPLICATION_CONFIGURATION, permission: "Application configuration", label: t("Application configurations")},
    { path: PATH_MANAGE_BUSINESS_UNIT, permission: "Business unit", label: t("Business unit") },
    { path: `${PATH_COMPETENCE_MANAGE}?type=general`, permission: "Competence", label: t("Competence") },
    { path: PATH_CONFIG_ELEMENTS_MANAGE, permission: "Config elements", label: t("Config elements") },
    { path: PATH_MANAGE_CONTRACT_TEMPLATE, permission: "Contract template", label: t("Contract template") },
    { path: PATH_MANAGE_DOCUMENT_TYPE, permission: "Document type", label: t("Document type") },
    { path: PATH_EMAIL_TEMPLATE_MANAGE, permission: "Email template", label: t("Email templates") },
    { path: PATH_MANAGE_EMPLOYEE_TYPE, permission: "Employee type", label: t("Employee Type") },
    { path: HOLIDAY_CONFIGS, permission: "Holiday Config", label: t("Holiday settings") },
    { path: PATH_PC_MANAGE, permission: "Paritair commitee", label: t("Paritair comitee") },
    { path: PATH_MANAGE_TEMPLATE, permission: "Templates", label: t("Project template") },
    { path: MANAGE_QUESIONS, permission: "Questions", label: t("Questions") },
    { path: PATH_SALARY_BENEFITS_MANAGE, permission: "Salary benefits", label: t("Salary benefits") },
    { path: PATH_MANAGE_SHIFTS, permission: "Shifts", label: t("Shifts") },
    { path: PATH_MANAGE_TIMETABLE, permission: "Timetable", label: t("Timetable") },
    { path: PATH_MANAGE_TRANSLATIONS, permission: "Translations", label: t("Translations") },
    { path: PATH_MANAGE_WAGE_ELEMENT, permission: "Wage elements", label: t("Wage element") },
    { path: PATH_MANAGECOEFF_DEVIATION, permission: "Coefficient deviation", label: t("Coefficient deviation") },
    { path: PATH_MANAGE_BUSINESS_UNIT_CITIES, permission: "Business unit cities", label: t("Business unit cities") },
    { path: PATH_MARGIN_CONFIG_MANAGE, permission: "Margin config", label: t("Margin config") },
    { path: PATH_CONTRACT_TYPE_MANAGE, permission: "Contract type", label: t("Contract type") },
    { path: PATH_VAT_RATE_MANAGE, permission: "Vat rate", label: t("Vat rate") },
    { path: PATH_MANAGE_DEFAULT_COEFFICIENTS, permission: "Default coefficients", label: t("Default coefficients") },
    { path: PATH_MANAGE_PAYROLL_CONFIG, permission: "Payroll config", label: t("Payroll config") },
  ];

  return (
    <>
      <div className="search-bar" style={{ paddingBottom: "1vw" }}>
        <TitleAtom title={t("Configuration and settings")} />
      </div>
      <div className="row recruiter-action-tabs mt-1">
        <div
          className="col-md-12 d-grid"
          style={{
            marginBottom: "1.5vw",
            paddingLeft: "0.75vw",
            paddingRight: "0.75vw",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "1.5vw",
          }}
        >
          {configCards?.map((card, index) => (
            <AccessControl
              key={index}
              requiredPermissions={[{ permission: card?.permission }]}
              override={userData?.isSuperAdmin}
            >
              <Link
                to={card?.path}
                className="card border-0 card-shadow justify-content-center h-100"
                style={{ padding: "2vw" }}
              >
                <div className="tab">{card?.label}</div>
              </Link>
            </AccessControl>
          ))}
        </div>
      </div>
    </>
  );
};

export default ConfigAndSettingsPage;
