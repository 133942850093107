import React from "react";
import { t } from "../../translation/Translation";
import TextEllipsis from "components/atoms/TextEllipsis";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import LabelField from "components/atoms/LabelField";
import "./css/Invoice.css";
import { ApiCall } from "services/ApiServices";
import { DELETE_INVOICE_RULES } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { useParams } from "react-router-dom";
import { calculateBtw, calculateSubTotal, calculateTotal } from "../utils/utils";

const InvoiceTab = (props: any) => {

    const params = useParams();

    const detailsLineHeader = [
        { name: "Description", alias: "discription" },
        { name: "Number", alias: "number" },
        { name: "Price", alias: "price" },
        { name: "Subtotal", alias: "subtotal" },
        { name: "Btw", alias: "btw" },
        { name: "Total", alias: "total_price" },
        { name: "Sales account", alias: "salesaccount" },
    ];

    const btwHeader = [
        { name: "Code", alias: "code" },
        { name: "Basis", alias: "basis" },
        { name: "Btw", alias: "btw" },
    ];

    const handleDelete = async (index: number) => {
        try {
            const updatedRuleData = [...props?.tabData?.ruleData];
            const data: any = updatedRuleData?.splice(index, 1);

            if (params?.id && data?.[0]?.id) {
                const response = await ApiCall.service(
                    DELETE_INVOICE_RULES,
                    "POST",
                    { id: data?.[0]?.id },
                    true,
                    M_MASTER_DATA,
                );
                if (response?.status == 200) {
                    props?.setTabData((prev: any) => ({
                        ...prev, initialCall: false
                    }));
                }
            }
            else {
                const { price, vatrateid, number } = data[0];

                let totalAmt: any = null;

                totalAmt = getCostFunction({ number, price, vatrateid });
                totalAmt = parseFloat((totalAmt)?.toString().replace(/\./g, '').replace(/,/g, '.'))

                props?.setTabData((prev: any) => {
                    let prevTotal = parseFloat((prev?.tobepaid)?.toString().replace(/\./g, '').replace(/,/g, '.'));
                    return {
                        ...prev, calcRefresh: !props?.tabData?.calcRefresh, ruleData: updatedRuleData, tobepaid: prevTotal - totalAmt,
                    }
                })
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const getCostFunction = (costData: any) => {
        const { number, price, vatrateid } = costData;
        const vatAmount = props?.dropdownData?.standardVatRates?.find((eachRate: any) => eachRate?.value == vatrateid);

        const subTotal = calculateSubTotal({ number, price });

        const btwAmount = calculateBtw({ subtotal: subTotal, vatrate: vatAmount?.percentage });
        const totalAmount = calculateTotal({ subtotal: subTotal, btw: btwAmount });
        return totalAmount;
    }

    return (
        <>
            <LabelField title={t("Detail lines")} className="tab-subtitle pb-0 invoiceTitle" />
            <div className="table-responsive createInvoiceTable tableWithSearchBar tablePadding border">
                <div className="invoiceDetailsLines">
                    <table className="table table-hover">
                        <thead>
                            <tr className="TableHeader">
                                {detailsLineHeader?.map((header) => (
                                    <th key={header?.alias} className={"table_actions"}>
                                        <>{t(header?.name)}</>
                                    </th>
                                ))}
                                <th>{t("Action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props?.tabData?.ruleData && props?.tabData?.ruleData?.length > 0 ? (
                                props?.tabData?.ruleData?.map((record: any, index: any) => {
                                    const standardSalesAccount = props?.dropdownData?.standardSalesAccounts?.find((item: any) => item?.value == (record?.standard_account_id ?? props?.formData?.standardSalesAccount));
                                    return (
                                        <tr key={index}>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={record?.description}
                                                    title={record?.description}
                                                    width="24vw"
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={record?.number}
                                                    title={record?.number}
                                                    width="8vw"
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={record?.price + ' €'}
                                                    title={record?.price + ' €'}
                                                    width="8vw"
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={record?.subtotal + ' €'}
                                                    title={record?.subtotal + ' €'}
                                                    width="8vw"
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={record?.btw + ' €'}
                                                    title={record?.btw + ' €'}
                                                    width="8vw"
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={record?.total_price + ' €'}
                                                    title={record?.total_price + ' €'}
                                                    width="8vw"
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={standardSalesAccount?.label}
                                                    title={standardSalesAccount?.label}
                                                    width="8vw"
                                                />
                                            </td>
                                            <td>
                                                {!record?.ruletype && <>
                                                    <span
                                                        onClick={() => props?.setTabData((prev: any) => ({
                                                            ...prev, popup: {
                                                                ...prev?.popup, name: 'newrule',
                                                                data: {
                                                                    quantity: record?.number ?? "", price: record?.price ?? "", btw: record?.vatrateid,
                                                                    salesaccount: record?.standard_account_id ?? props?.formData?.standardSalesAccount,
                                                                    description: record?.description, index
                                                                },
                                                                errors: { quantity: "", price: "", btw: "", salesaccount: "", description: "" }
                                                            }
                                                        }))}
                                                        className="table-action-btn me-2 cursor-pointer"
                                                        title={t("Edit")}
                                                    >
                                                        <EditIcon />
                                                    </span>
                                                    <span
                                                        onClick={() => {
                                                            handleDelete(index)
                                                        }}
                                                        className="table-action-btn cursor-pointer"
                                                        title={t("Delete")}
                                                    >
                                                        <DeleteIcon />
                                                    </span>
                                                </>}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={10} className="text-center">
                                        {t("No records found")}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="d-grid grid-2 marginTopPoint5">
                <div>
                    <LabelField title={t("Btw")} className="tab-subtitle pb-0 invoiceTitle" />
                </div>
                <div>
                    <LabelField title={t("Totals")} className="tab-subtitle pb-0 invoiceTitle" />
                </div>
            </div>
            <div className="d-grid grid-2">
                <div>
                    <div className="createInvoiceTable border h-100">
                        <table className="table table-hover">
                            <thead>
                                <tr className="TableHeader">
                                    {btwHeader?.map((header) => (
                                        <th key={header?.alias} className={"table_actions"}>
                                            <>{t(header?.name)}</>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object?.values(props?.tabData?.btw)?.map((item: any, index: number) => (
                                    <>
                                        <tr key={index}>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={item?.code}
                                                    title={item?.code}
                                                    width="5vw"
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={item?.basis + ' €'}
                                                    title={item?.basis + ' €'}
                                                    width="8vw"
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={item?.btw + ' €'}
                                                    title={item?.btw + ' €'}
                                                    width="8vw"
                                                />
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div className="createInvoiceTable border h-100 invoiceTotals">
                        <table className="table table-hover">
                            <thead>
                                <tr className="TableHeader">
                                    <th key={"subtotal"} className={"table_actions"}>
                                        <>{t("Subtotal")}</>
                                    </th>
                                    <td key={"1"} className={"table_actions"}>
                                        <>{props?.tabData?.totals?.subtotal + ' €'}</>
                                    </td>
                                </tr>
                                <tr className="TableHeader">
                                    <th key={"btw"} className={"table_actions"}>
                                        <>{t("Btw")}</>
                                    </th>
                                    <td key={2} className={"table_actions"}>
                                        <>{props?.tabData?.totals?.btw + ' €'}</>
                                    </td>
                                </tr>
                                <tr className="TableHeader">
                                    <th key={"total"} className={"table_actions"}>
                                        <>{t("Total")}</>
                                    </th>
                                    <td key={3} className={"table_actions"}>
                                        <>{props?.tabData?.totals?.total + ' €'}</>
                                    </td>
                                </tr>
                                <tr className="TableHeader">
                                    <th key={"tobepaid"} className={"table_actions"}>
                                        <>{t("To be paid")}</>
                                    </th>
                                    <td key={4} className={"table_actions"}>
                                        <>{props?.tabData?.tobepaid + ' €'}</>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoiceTab;
function getCostFunction(arg0: { number: any; price: any; vatrateid: any; }): any {
    throw new Error("Function not implemented.");
}

