import { t } from "pages/microservices/masterData/translation/Translation";
import React, { useState } from "react";
const LabelBox = (props) => {
  const { planTimingsData, header1, header2, name, linkname } = props;

  const renderItems = () => {
    const weekDays = [
      <td key={"heading1"} className="text-start">
        {t(header1)}
      </td>,
      <td key={"heading2"} className="text-start">{t(header2)}</td>,
    ];
    const dataToRender = planTimingsData && planTimingsData.length > 0 ? planTimingsData : Array(7).fill({});
    
    dataToRender?.forEach((plan, i) => {   
      const isWeekend = i === 5 || i === 6; // Saturday (index 5) & Sunday (index 6)
   
      weekDays?.push(
        <td key={i} className="regimeHeight" style={{
          backgroundColor: isWeekend ? "#87d2e561" : "transparent", // Highlight weekends
        }}>
          <div className={(linkname && plan[linkname]) ? 'cursor-pointer nameHoverClass' : ''} onClick={() => (linkname && plan[linkname]) ? window.open(`/invoice/create/${plan[linkname]}`, "_blank") : {}}>
            <span className="hoverTextName">{plan[name] ? plan[name] : ""}</span>
          </div>
        </td>
      );
    });
    return weekDays;
  };

  return (
    <tr>
      {renderItems()}
      <td ></td>
    </tr>
  );
};
export default LabelBox;
