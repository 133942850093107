import { FormMode } from "components/common/CommonEnums";

export const initialState = {
    mode: FormMode.CREATE,
    tabs: [
        {
            id: "general",
            title: "General",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "address",
            title: "Address",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "location",
            title: "Location",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "contactsPersons",
            title: "Contacts Persons",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "document",
            title: "Document",
            showStatus: true,
            error: false,
            draft: false,
        },
    ],
    tabErrors: [],
    general: {
        data: {
            id: null,
            vat: "",
            businessUnitNumber: "",
            name: "",
            type: "",
            code: "",
            company: "",
            info: "",
            isActive: true,
            retentionCount: "",
            retentionPeriod: "",
            website: "",
            consultant: "",
            status: "",
        },
        errors: {
            vat: "",
            businessUnitNumber: "",
            name: "",
            type: "",
            code: "",
            company: "",
        },
    },
    contactsPersons: {
        data: {
            tittle: null,
            userId: null,
            fName: "",
            lName: "",
            email: "",
            company: null,
            mobNumber: "",
            language: null,
            functionData: "",
            linkedIn: "",
            roles: [],
            dob: "",
            locations: [],
            businessUnit: [],
            info: "",
        },
        contactsList: [],
        editIndex: null,
        errors: {
            tittle: null,
            fName: "",
            lName: "",
            email: "",
            company: null,
            mobNumber: "",
            language: null,
            functionData: "",
            linkedIn: "",
            roles: [],
            dob: "",
            locations: [],
            businessUnit: [],
            info: "",
        },
    },
    address: {
        data: {
            street: "",
            number: "",
            box: "",
            zipCode: "",
            country: null,
            city: "",
            initialLat: "",
            initialLn: "",
            biStreet: "",
            biNumber: "",
            biBox: "",
            biZipCode: "",
            biCountry: null,
            biCity: "",
            hqAddress: 1,
            sameAddress: 1,
            biinitialLat: "",
            biinitialLn: "",
        },
        errors: {
            street: "",
            number: "",
            zipCode: "",
            country: "",
            city: "",
            biStreet: "",
            biNumber: "",
            biZipCode: "",
            biCountry: "",
            biCity: "",
            hqAddress: "",
            sameAddress: "",
        },
    },
    location: {
        locationList: [],
        editIndex: null,
        data:
        {
            id: null,
            locationName: "",
            locationPhoneNumber: "",
            locationEmail: "",
            locationStreet: "",
            locationNumber: "",
            locationBox: "",
            locationZipCode: "",
            locationCity: "",
            locationCountry: null,
            initialLat: "",
            initialLn: "",
            locationInfo: "",
        },
        errors:
        {
            id: "",
            locationName: "",
            locationPhoneNumber: "",
            locationEmail: "",
            locationStreet: "",
            locationNumber: "",
            locationBox: "",
            locationZipCode: "",
            locationCity: "",
            locationCountry: "",
        },
    },
    currentTab: "general",
    tempAgencyDocument: [],

    options: {
        businessUnit: [],
        tempAgencyTypes: [],
        company: [],
        locations: [],
        companyUsers: [],
        gender: [],
        language: [],
        roles: [],
        functions: [],
        templates: [],
        shifts: [],
        countries: [],
        documentCategoryList:[],
    },
};
