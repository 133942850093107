import React, { useState } from "react";
import { useTImesheetContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { t } from "pages/microservices/masterData/translation/Translation";
import { addTimes, calculateWorkedTime } from "../utils/utils";
const PlanTimings = () => {
  const { initialTimesheetState } = useTImesheetContext();
  let timesheet_data = initialTimesheetState?.timesheet_data ? initialTimesheetState.timesheet_data: [];
  let totalTime = "00:00";
  
  const renderWeekDays = () => {
    const weekDays = [
      <td key={"heading1"} className="text-start">
        {t("Planning")}
      </td>,
      <td key={"heading2"} className="text-start">
        <div>{t("Hours")}</div>
        <div>{t("Total")}</div>
      </td>,
    ];

    timesheet_data?.map((plan, i) => {      
      const startdatestring = plan?.start_date_time?.split(' ');
      const enddatestring = plan?.end_date_time?.split(' ');
      const total = calculateWorkedTime(
        startdatestring?.[1]?.split?.("+")?.[0] ?? "00:00:00",
        enddatestring?.[1]?.split?.("+")?.[0] ?? "00:00:00",
        plan?.break_hours ? plan?.break_hours : "00:00:00"
      );
      totalTime = addTimes(totalTime, total === "" ? "00:00" : total);  
      
      weekDays.push(
        plan?.plan_id ? (
          <td key={i} className="regimeHeight">
            <div className="text-end">
              {(startdatestring?.[1]?.split?.("+")?.[0] ?? "00:00:00")?.slice(0, 5) + " - " + (enddatestring?.[1]?.split?.("+")?.[0] ?? "00:00:00")?.slice(0, 5)}
            </div>
            <div className="text-end">{total}</div>
          </td>
        ) : (
          <td key={i} className=""></td>
        )
      );
    });
    return weekDays;
  };

  return (
    <tr>
      {renderWeekDays()}
      <td key={'last'} className="fw-bold">{totalTime}</td>
    </tr>
  );
};
export default PlanTimings;
