import { calculateWorkedTime } from "pages/microservices/timesheets/utils/utils";
import { TIMESHEET_ACTIONS } from "../../actions/TimesheetActions";
import { calculateTotalForDay, calculateTotalForWholeWeek } from "pages/microservices/timesheets/utils/calculateUtils";

export const timesheetReducer = (timesheetData, action) => {
  switch (action.type) {
    case TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA:
      return updatePlanTimings(timesheetData, action.payload);

    case TIMESHEET_ACTIONS.CHANGE_WEEK:
      return action.payload.data;
    
    case TIMESHEET_ACTIONS.UPDATE_TIMESHEET_TOTAL:
      return { ...timesheetData, timesheet_data: action.payload };
    }
};

const updatePlanTimings = (prevData, data) => {  
  let updatedData = prevData.timesheet_data.map((item, i) => {
    if (item.plan_id === data.planId) {
      let updatedItem = {
        ...item,
        [data.name]: data.value, // Update the specified property
      };
      // Recalculate hoursWorked if start_time or end_time changes      
      if (data.name === "start_time" || data.name === "end_time" || data.name === "pause_time") {
        updatedItem.totalHours = calculateWorkedTime(
          updatedItem.start_time.split(" ")[1],
          updatedItem.end_time.split(" ")[1],
          updatedItem.pause_time ? updatedItem?.pause_time : "00:00:00"
        );
        // updatedItem = updatePerformanceAndReimbursmentData(updatedItem, data)
      // } else if (data.name === "regime" || data.name === "holiday" || data.name === "invoice_milage" || data.name === "purchase_invoice") {
      //   updatedItem = updatePerformanceAndReimbursmentData(updatedItem, data)
      }
      return updatedItem;
    } else {
      return item;
    }
  });  
  return { ...prevData, timesheet_data: updatePerformanceAndReimbursmentData(updatedData ,data) };
};

const updatePerformanceAndReimbursmentData = (updatedData, data) => {
  if (data.name === "start_time" || data.name === "end_time" || data.name === "pause_time" || data.name === "regime" || data.name === "holiday" || data.name === "invoice_milage") {
    return calculateTotalForWholeWeek({
      data: updatedData, options: data?.extraprops?.filterOptionsState?.optionState,
      parameters: data?.extraprops?.filterOptionsState.optionState?.prjectParameters,
    });
  }
  return updatedData;
}