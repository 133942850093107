import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import PhoneInput from "react-phone-number-input";
import Button from "components/atoms/Button";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { validateForm, validateRequired, validatePhoneNumber, validateSelectField } from "services/validation/ValidationService";
import { Option } from "utils/TypeAnnotations";
import { M_IDENTITY_MANAGER, M_MASTER_DATA } from "constants/Constants";
import { COMPANY_LIST, CREATE_BUSINESS_UNIT, GET_BUSINESS_UNIT, PROJECTS_OPTIONS, SAVE_PROFILE } from "routes/ApiEndpoints";
import { E164Number } from "libphonenumber-js";
import LabelField from "components/atoms/LabelField";
import { t } from "../translation/Translation";
import TitleAtom from "components/atoms/Title";
import { PATH_MANAGE_BUSINESS_UNIT } from "constants/Paths";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface BusinessUnitFormProps {
    // id: string;
    name: string;
    number: string;
    company: Option[];
    phoneNumber: string | E164Number;
    email: string;
    street: string;
    houseNumber: string;
    box?: string;
    zipcode: string;
    city: string;
    country: Option[];
    latitude: any;
    longitude: any;
}

const BusinessUnit: React.FC = () => {
    const location = useLocation();
    const isEdit = location.pathname.includes('edit');
    const navigate = useNavigate();
    const params = useParams();
    const [formData, setFormData] = useState<BusinessUnitFormProps>({
        name: "",
        number: "",
        company: [],
        phoneNumber: "",
        email: "",
        street: "",
        houseNumber: "",
        box: "",
        zipcode: "",
        city: "",
        country: [],
        latitude: null,
        longitude: null,
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(false);

    const [dropdownData, setDropdownData] = useState<{
        companyList: Option[];
        countryList: Option[];
    }>({
        companyList: [],
        countryList: [],
    });

    useEffect(() => {
        fetchDropdownData();
    }, []);

    const fetchDropdownData = async () => {
        const data = isEdit ? {
            ...formData,
            id: params.id
        } : formData;
        const response = await ApiCall.service(GET_BUSINESS_UNIT, "POST", data, true, M_MASTER_DATA);
        if (response?.status === 200) {
            setDropdownData({
                companyList: response?.dropdownData?.companyList || [],
                countryList: response?.dropdownData?.countryList || [],
            });
            if (isEdit) {
                setFormData((prevData: any) => (response?.data));
            }
        }
    };

    const validation = (
        name: string,
        value: string | boolean | Option | E164Number | undefined,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules = {
            name: [validateRequired],
            number: [validateRequired],
            phoneNumber: [validatePhoneNumber],
            company: [validateSelectField],
            email: [validateRequired],
            street: [validateRequired],
            houseNumber: [validateRequired],
            zipcode: [validateRequired],
            city: [validateRequired],
            country: [validateSelectField],
        };
        const validationErrors = validateForm(
            { ...formData, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );
        if (isSingleFieldValidation && Object.keys(errors).length > 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: validationErrors[name],
            }));
        } else {
            setErrors(validationErrors);
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "radio" ? value : value,
        }));
        validation(name, value, true);
    };

    const handlePhoneNumberChange = (e: string | E164Number | undefined) => {
        setFormData((prevData: any) => ({
            ...prevData,
            phoneNumber: e,
        }));
        validation("phoneNumber", e, true);
    };

    const handleSelectChange = async (selectedOption: Option, fieldName: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: selectedOption,
        }));
        if (fieldName === "company") {
            const response = await getCompanyAddress(selectedOption);
            setFormData((prevData: any) => ({
                ...prevData,
                street: response?.street,
                houseNumber: response?.houseNumber,
                box: response?.box,
                zipcode: response?.zipcode,
                city: response?.city,
                country: response?.country,
                latitude: response?.latitude,
                longitude: response?.longitude,
            }));
        }
        validation(fieldName, selectedOption, true);
    };

    const getCompanyAddress = async (selectedOption: any) => {
        try {
            const response = await ApiCall.service(
                PROJECTS_OPTIONS,
                'POST',
                {
                    options: ["CompanyAddress"], companyId: (selectedOption?.value !== null && selectedOption?.value !== undefined) ?
                        selectedOption?.value : selectedOption
                },
                true,
                M_MASTER_DATA
            );
            if (response?.status == 200) {
                return response?.data?.CompanyAddress?.[0];
            }
            else {
                return [];
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        if (validation("name", formData.name) && validation("number", formData.number)) {
            const postData = isEdit ? { id: params?.id, ...formData } : formData;
            const response = await ApiCall.service(CREATE_BUSINESS_UNIT, "POST", postData, true, M_MASTER_DATA);
            if (response?.status === 200) {
                CustomNotify({ type: "success", message: response?.message });
                navigate(PATH_MANAGE_BUSINESS_UNIT); // Redirect after successful submission
            } else if (response?.status === 400) {
                CustomNotify({ type: "warning", message: response?.message });
                setErrors(response?.errors);
            }
        }
        setLoading(false);
    };

    return (
        <div>
            <TitleAtom title={isEdit ? t('Edit business unit') : t('Create business unit')} />
            <div className="form-border">
                <div className="row">
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="name"
                            handleChange={changeHandler}
                            value={formData.name}
                            label="Business Unit Name"
                            placeholder="Business Unit Name"
                            error={errors.name}
                        />
                    </div>
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="number"
                            handleChange={changeHandler}
                            value={formData.number}
                            label="Business Unit Number"
                            placeholder="Business Unit Number"
                            error={errors.number}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            search={true}
                            name="company"
                            title="Company"
                            isMandatory={true}
                            options={dropdownData.companyList}
                            onChange={(e) => handleSelectChange(e, "company")}
                            value={formData.company}
                            error={errors.company}
                            placeHolder="Select Company"
                        />
                    </div>
                    <div className="col">
                        <LabelField
                            title={t("Phone number")}
                            isMandatory={false}
                            key="PhoneInput"
                        />
                        <div className="form-control field-shadow">
                            <PhoneInput
                                defaultCountry="BE"
                                international
                                placeholder="Enter phone number"
                                onChange={(mobileNumber) =>
                                    handlePhoneNumberChange(mobileNumber)
                                }
                                value={formData?.phoneNumber}
                            />
                        </div>
                        <div>
                            {errors?.phoneNumber &&
                                <span className="text-danger">{errors?.phoneNumber}</span>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="email"
                            handleChange={changeHandler}
                            value={formData.email}
                            label="Email"
                            placeholder="Email"
                            error={errors.email}
                        />
                    </div>
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="street"
                            handleChange={changeHandler}
                            value={formData.street}
                            label="Street"
                            placeholder="Street"
                            error={errors.street}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="houseNumber"
                            handleChange={changeHandler}
                            value={formData.houseNumber}
                            label="House Number"
                            placeholder="House Number"
                            error={errors.houseNumber}
                        />
                    </div>
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={false}
                            name="box"
                            handleChange={changeHandler}
                            value={formData.box || ""}
                            label="Box"
                            placeholder="Box"
                            error={errors.box}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="zipcode"
                            handleChange={changeHandler}
                            value={formData.zipcode}
                            label="Zipcode"
                            placeholder="Zipcode"
                            error={errors.zipcode}
                        />
                    </div>
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="city"
                            handleChange={changeHandler}
                            value={formData.city}
                            label="City"
                            placeholder="City"
                            error={errors.city}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            search={true}
                            name="country"
                            title="Country"
                            isMandatory={true}
                            options={dropdownData.countryList}
                            onChange={(e) => handleSelectChange(e, "country")}
                            value={formData.country}
                            placeHolder="Select Country"
                            error={errors.country}
                        />
                    </div>
                </div>

                <div className="footer-btn pointer-cursor">
                    <Button className="form-button float-end mt-3" title="Save" handleClick={handleSubmit} disabled={loading} />
                </div>
            </div>
        </div>
    );
};

export default BusinessUnit;
