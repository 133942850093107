import React, { useState } from "react";
import LabelWithSelectField from "components/molecules/LabelwithSelectField";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { t } from "pages/microservices/masterData/translation/Translation";

const Regime = (props) => {
  const { planTimingsData, header1, header2, total, name, isDisable } = props;
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { filterOptionsState } = useFilterOptions()
  // const { initialProjectParameterState } = useProjectParameterContext();
  const hadleChange = (event, planId) => {
    const { value, name } = event?.target;
    if (planId) {
      timesheetDispatch({
        type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
        payload: {
          planId: planId,
          value: value,
          name: name,
          extraprops: {
            filterOptionsState,
          }
        },
      });
    }
  };
  // const renderRegime = () => {
  //   const weekDays = [
  //     <td key={"heading1"} className="text-start">
  //       {t(header1)}
  //     </td>,
  //     <td key={"heading2"} className="text-start">{t(header2)}</td>,
  //   ];

  //   planTimingsData.map((data, i) => {
  //     weekDays.push(
  //       data?.plan_id ? (
  //         <td key={i} className="p-0">
  //           <LabelWithSelectField
  //             label={""}
  //             name={name}
  //             placeholder={t("Select")}
  //             selectedValue={data[name] ?? undefined}
  //             handleChange={(event) => hadleChange(event, data?.plan_id)}
  //             options={filterOptionsState?.optionState?.regimen?.map((item) => {
  //               return { ...item, name: item?.label}
  //             })}
  //             id={name}
  //             error={""}
  //             labelClassName="d-none"
  //             errorClass="d-none"
  //             emptyOptionNotRequired
  //             className="rounded-0 shadow-none"
  //             disabled={isDisable}
  //           />
  //         </td>
  //       ) : ( 
  //         <td key={i} className=""></td>
  //       )
  //     );
  //   });
  //   return weekDays;
  // };
  const renderRegime = () => {
    const weekDays = [
      <td key={"heading1"} className="text-start">
        {t(header1)}
      </td>,
      <td key={"heading2"} className="text-start">{t(header2)}</td>,
    ];

    planTimingsData.map((data, i) => {
      const isWeekend = i === 5 || i === 6; // Saturday (index 5) & Sunday (index 6)

      weekDays.push(
        data?.plan_id ? (
          <td
            key={i}
            className="p-0 regimeHeight"
            style={{
              backgroundColor: isWeekend ? "#87d2e561" : "transparent", // Different color for weekends
            }}
          >
            <LabelWithSelectField
              label={""}
              name={name}
              placeholder={t("Select")}
              selectedValue={data[name] ?? undefined}
              handleChange={(event) => hadleChange(event, data?.plan_id)}
              options={filterOptionsState?.optionState?.regimen?.map((item) => ({
                ...item,
                name: item?.label,
              }))}
              id={name}
              error={""}
              labelClassName="d-none"
              errorClass="d-none"
              emptyOptionNotRequired
              className="rounded-0 shadow-none py-0"
              disabled={isDisable}
            />
          </td>
        ) : (
          <td
            key={i}
            className=""
            style={{
              backgroundColor: isWeekend ? "#87d2e561" : "transparent", // Apply background color for empty cells too
            }}
          ></td>
        )
      );
    });

    return weekDays;
  };

  return (
    <tr>
      {renderRegime()}
      <td key={'last'} className="text-start">{total}</td>
    </tr>
  );
};
export default Regime;
