import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as ENDPOINTS from "routes/ApiEndpoints";
import ViewIcon from "static/images/ViewIcon";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
// import "../questions/questions.css";
import { Spinner } from "react-bootstrap";
import MatchingProfileCard, {
  MatchingCandidateProfileCardType,
} from "components/Matching/MatchingCandidateProfileCard";
import { OptionProps } from "utils/TypeAnnotations";
import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";
import CustomNotify from "components/atoms/CustomNotify";
import Title from "components/atoms/Title";
import Button from "components/atoms/Button";
import { t } from "../translation/Translation";
import { M_MASTER_DATA } from "constants/Constants";
import BackButton from "components/atoms/BackButton";
import { EDIT_CANDIDATE, MANAGE_CANDIDATES, PATH_SCREENING_ASSESSMENT } from "constants/Paths";
import { useDispatch, useSelector } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import FilterOrganism from "components/organism/FilterOrganism";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { ScreeningTableHeader } from "TableHeader";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import CreateTodo from "../follow-up-todo/Create";
import ModalPopup from "components/atoms/ModalPopup";
import { log } from "node:console";

interface manageData {
  screenedBy: string;
  date: string;
  id: number;
  function: string;
  status: boolean;
  //   officeName: string;
}

interface searchData {
  date: Date | null;
  screenedBy: OptionProps[];
  //   office: OptionProps[];
}

const ManageScreening: React.FC = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const navigate = useNavigate();
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });

  const getSearchData = () => {
    return {
      date: history?.filters?.date ?? '',
      screenedBy: history?.filters?.screenedBy ?? [],
      function: history?.filters?.function ?? '',
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
  };

  const [profileCardData, setProfileCardData] =
    useState<MatchingCandidateProfileCardType>({
      id: null,
      firstName: "",
      lastName: "",
      functions: "",
      mobNumber: "",
      email: "",
      street: "",
      number: "",
      box: "",
      zipCode: "",
      city: "",
      picFileName: "",
      picFilePath: "",
      country: "",
      cvData: "",
      profilePicData: "",
      isScreening: true,
      source: "manageScreening",
      applicationId: '',
    });

  const [formData, setFormData] = useState([] as manageData[]);
  const [loading, setLoading] = useState(true);
  const { candidateId } = useParams<{ candidateId: string }>();

  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    screenedBy: [] as OptionProps[],
    // offices: [] as OptionProps[],
    functions: [] as OptionProps[]
  });

  //follow up states
  const [entity, setEntity] = useState<{
    entityType?: string;
    entityId: string | number | null;
    entityName?: string;
    businessUnit: string | number | null;
  }>({ entityId: null, businessUnit: null, entityType: "", entityName: "" });

  const [followUpPopUp, setFollowupPopUp] = useState(false);


  useEffect(() => {
    fetchScreeningDataBySearch();
  }, [pageData?.refresh, pageData?.initialCall]);

  //API call for to fetch countries
  const fetchScreeningDataBySearch = async (searchData = {}, page = 1) => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
    };

    const response = await ApiCall.service(
      `${ENDPOINTS.MANAGE_SCREENING}/${candidateId}`,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      setProfileCardData((prev) => ({
        ...prev,
        ...response.candidateData.candidateInfo.general['candidateInfo'][0],
        country: response.candidateData.candidateInfo.general.country,
        // ...{ cvData: response.candidateData.candidateInfo?.cvData },
        // ...{
        profilePicData: response.candidateData.candidateInfo?.profilePicData,
        // },
        ...{ functions: response.candidateData.candidateInfo?.functions },
        applicationId: applicationId,
        buIds: response?.candidateData?.candidateInfo.general['buIds'] ?? []
      }));
      setFormData(response.data?.data);
      setDropDowns((prevData) => ({
        ...prevData,
        screenedBy: mapToSelect(response.dropdownData?.screenedBy),
        functions: response?.dropdownData?.functions
      }));
      setPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords, initialCall: false }));
      setLoading(false);
    }
  };

  const filters = [
    { name: 'screenedBy', fieldType: "text", placeholder: "Screened by", filterType: 'search' },
    { name: 'function', fieldType: "singleSelect", options: dropDowns?.functions, placeholder: "Function", filterType: 'search' },
    { name: 'date', fieldType: "date", placeholder: "dd-mm-yyyy", filterType: 'search' },
  ];

  const handleClick = (value: boolean) => {
    let candidateResult = true;
    if (!value) {
      candidateResult = false;
      navigate(`${MANAGE_CANDIDATES}`);
    }
    const postData = {
      id: candidateId,
      candidateResult: candidateResult
    }
    ApiCall.service(`${ENDPOINTS.CANDIDATE_RESULT}`, "POST", postData, true, M_MASTER_DATA).then((response) => {
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
      }
    });
  }

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  //pop up for followup
  const handlePopUp = async (
    id: number | undefined | null,
    businessUnit: string | number | null | undefined,
    name: string
  ) => {
    setEntity({
      entityType: "Candidate",
      entityName: name ?? "",
      entityId: id ?? null,
      businessUnit: (businessUnit) ? businessUnit : null,
    });
    setFollowupPopUp(true);
    //update screening status to selected
    handleClick(true);
  };

  const handleClosePhonePopUp = () => {
    setFollowupPopUp(false);
  };

  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "Candidates",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
      <div className="search-bar">
        <Title title={t("Screening")} />
      </div>
      <div style={{ paddingTop: "0.25vw" }}>
        <div className="row marginBottomPointTwo5">
          <div className="col-md-12">
            <MatchingProfileCard profileCardProps={profileCardData} />
            <div className="text-end">
              {formData && formData.length > 0 &&
                <>
                  <Button
                    title={t("Selected")}
                    type="submit"
                    // handleClick={() => handleClick(true)}
                    handleClick={() =>
                      handlePopUp(
                        profileCardData?.id,
                        profileCardData?.buIds,
                        profileCardData?.firstName
                      )
                    }
                    className="form-button shadow-none marginRightPoint5"
                  />
                  <Button
                    title={t("Rejected")}
                    type="submit"
                    handleClick={() => handleClick(false)}
                    className="form-button shadow-none"
                  />
                </>
              }
            </div>
          </div>
        </div>
        <div className="form-height-dashboard">
          <div className="search-bar">
            <div className="row">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
            </div>
          </div>
          <div className="position-relative tableMainWrapper">
            <div className="table-responsive  tableSection">
              <div className="manageScreeningTable">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr className="TableHeader">
                      {ScreeningTableHeader?.map((header: any) => (
                        <th key={header?.alias}>
                          <>
                            {t(header.name)}
                            {header?.issort && (
                              <SortAtomForManagePage
                                value={{ alias: header?.alias }}
                                dispatch={dispatch}
                                history={history}
                                updatePageFilters={updatePageFilters}
                                handleRefresh={handleRefresh}
                              />
                            )}
                          </>
                        </th>
                      ))}
                      <th>{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr className="border-0">
                        <td
                          className="text-center border-0 spinner-wrapper"
                          colSpan={3}
                        >
                          <Spinner size="sm" className="me-2" />{" "}
                          {t("Loading")}
                        </td>
                      </tr>
                    ) : (
                      <>
                        {formData && formData.length > 0 ? (
                          formData.map((screening) => (
                            <tr
                              key={screening.id}
                              className="border-bottom mb-3 box-shadow align-middle"
                            >
                              <>
                                <td
                                  data-label={t("screenedBy")}
                                  className="align-middle ps-lg-4"
                                >
                                  {screening.screenedBy}
                                </td>
                                <td
                                  data-label={t("screenedBy")}
                                  className="align-middle ps-lg-4"
                                >
                                  {screening.function}
                                </td>
                                <td
                                  data-label={t("date")}
                                  className="align-middle ps-lg-4"
                                >
                                  {screening.date}
                                </td>
                                <td
                                  data-label={t("status")}
                                  className="align-middle ps-lg-4"
                                >
                                  <span
                                    className={`d-inline-block rounded-circle status-icon bg-${screening?.status
                                      ? "success" : "danger"
                                      }`}
                                    data-toggle="tooltip"
                                    title={screening.status ?
                                      "Selected" : "Not selected"
                                    }
                                  ></span>
                                  {screening.status}
                                </td>
                                {/* <td
                                    data-label={t("office")}
                                    className="align-middle ps-lg-4"
                                  >
                                    {screening.officeName}
                                  </td> */}
                              </>

                              <td className="table-action-icons px-2 align-middle">
                                <Link
                                  to={applicationId ? `${PATH_SCREENING_ASSESSMENT}/${screening.id}/${candidateId}/${applicationId}` : `${PATH_SCREENING_ASSESSMENT}/${screening.id}/${candidateId}`}
                                  className="table-action-btn shadow-none"
                                  title={t("View")}
                                >
                                  <ViewIcon />
                                </Link>

                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="border rounded-3">
                            <td
                              colSpan={7}
                              className="border-0 text-center py-3 no-records"
                            >
                              <span className="text-danger w-100 d-block text-center">
                                {t("No records")}
                              </span>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {followUpPopUp && (
              <ModalPopup
                title={"Create followup"}
                show={followUpPopUp}
                onHide={handleClosePhonePopUp}
                className="candidate-follow-up-modal"
                body={
                  <>
                    <CreateTodo
                      popUp={true}
                      entityData={entity}
                      onSubmit={handleClosePhonePopUp}
                    />
                  </>
                }
              />
            )}
            {
              !loading && <div className="">
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            }
            {/* {totalPages > 1 && (
              <div className="pagination justify-content-center align-items-center mt-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              </div>
            )} */}
          </div>
        </div>
        <div className="col-md-6 align-self-center backPadding">
          <BackButton />
        </div>
      </div >
      {/* </AccessControl> */}
    </>
  );
};

export default ManageScreening;
