import { useEffect, useRef } from "react";

type SelectableListProps = {
  options: any;
  selected: string | number;
  onChange: (value: any) => void;
};

const SelectableList: React.FC<SelectableListProps> = ({options = [], selected, onChange}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const allOptions = [{ value: "all", label: "All" }, ...options];
    const selectedRef = useRef<HTMLDivElement | null>(null);
    
    useEffect(() => {
        if (selectedRef.current && containerRef.current) {
            containerRef.current.scrollTo({
                top: selectedRef.current.offsetTop - containerRef.current.offsetTop,
                behavior: "smooth",
            });
        }
    }, [selected]);

    return (
        <div ref={containerRef} className="w-64 h-60 overflow-y-auto border p-2 rounded-md">
            {allOptions?.map((option) => (
                <div
                    key={option?.value}
                    ref={option?.value === selected ? selectedRef : null}
                    onClick={() => onChange(option)}
                    className={`cursor-pointer p-2 rounded-md ${option?.value == selected ? "bg-blue-500 selectedOfc" : "hover:bg-gray-100"}`}
                >
                    {option?.label}
                </div>
            ))}
        </div>
    );
}


export default SelectableList;
