import React, { useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { addTimes, calculateWorkedTime, subtractTimes } from "../utils/utils";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import TimeField from "react-simple-timefield";
import { t } from "pages/microservices/masterData/translation/Translation";

const WorkTimings = (props) => {
  const { planTimingsData, header1, header2, total, type, isDisable, name } = props;
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { filterOptionsState } = useFilterOptions()

  const handleChange = (name, value, plan) => {
    if (plan?.plan_id) {
      timesheetDispatch({
        type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
        payload: {
          planId: plan?.plan_id,
          value: name == "pause_time" ? value : plan?.plan_date + " " + value,
          name: name,
          extraprops: {
            filterOptionsState,
          }
        },
      });
    }
  };

  let totalTime = "00:00";
  let totalPlanTime = "00:00";

  const getTotalColorClass = () => {
    totalPlanTime = name === 'totalHours' ? subtractTimes(totalTime, totalPlanTime === "" ? "00:00" : totalPlanTime) : "00:00";    
    return totalPlanTime !== "00:00"
      ? totalPlanTime?.startsWith("-") 
        ? "red"   // Negative value -> Red color
        : "green" // Positive value -> Green color
      : "inherit"; // Default color if not 'diff' or if totalHours is "00:00"
  }

  const renderItems = () => {
    const weekDays = [
      <td key={"heading1"} className="regimeHeight text-start">
        {t(header1)}
      </td>,
      <td key={"heading2"} className="regimeHeight text-start">{t(header2)}</td>,
    ];

    const dataToRender = planTimingsData && planTimingsData.length > 0 ? planTimingsData : Array(7).fill({});

    dataToRender.forEach((plan, i) => {
      const isWeekend = i === 5 || i === 6; // Saturday (index 5) & Sunday (index 6)

      let totalHours = plan["start_time"] && plan["end_time"] && plan["start_time"] !== "" && plan["end_time"] !== ""
        ? calculateWorkedTime(
          plan["start_time"]?.split(" ")[1],
          plan["end_time"]?.split(" ")[1],
          plan["pause_time"] ? plan["pause_time"] : "00:00:00"
        )
        : "";
      totalTime = addTimes(totalTime, totalHours === "" ? "00:00" : totalHours);

      const planTime = (name === 'diff' || name === 'totalHours') && plan["start_date_time"] && plan["end_date_time"] && plan["start_date_time"] !== "" && plan["end_date_time"] !== ""
        ? calculateWorkedTime(
          plan["start_date_time"]?.split(" ")[1],
          plan["end_date_time"]?.split(" ")[1],
          plan["break_hours"] ? plan["break_hours"] : "00:00:00"
        )
        : "";
      totalPlanTime = addTimes(totalPlanTime, planTime === "" ? "00:00" : planTime)

      totalHours = name === 'diff' ? subtractTimes(totalHours, planTime === "" ? "00:00" : planTime) : totalHours;

      weekDays.push(
        <td
          key={i}
          className="p-0 timeTimingCol regimeHeight position-relative"
          style={{
            backgroundColor: isWeekend ? "#87d2e57a" : "transparent", // Different color for weekends
          }}
        >
          <span style={{textAlign: "right", color: totalHours.startsWith("-") ? "red" : ""}}>{totalHours.startsWith("-") ? "-" : ""}</span>
          <TimeField
            name={name}
            value={name === "totalHours" || name === 'diff' ? totalHours.replace("-", "") : plan[name] ? plan[name] : ""}
            onChange={(event, value) => handleChange(name, value, plan)}
            colon=":"
            showSeconds={false}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 0,
              border: "0.052vw solid transparent",
              outline: "0",
              textAlign: "right",
              pointerEvents: isDisable || !plan?.plan_id ? "none" : "auto",
              backgroundColor: isDisable || !plan?.plan_id ? "#e8f8fb75" : "",
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              color: name === "diff" && totalHours !== "00:00"
                ? totalHours.startsWith("-") 
                  ? "red"   // Negative value -> Red color
                  : "green" // Positive value -> Green color
                : "inherit", // Default color if not 'diff' or if totalHours is "00:00"
            }}
            isDisable={isDisable}
          />
        </td>
      );
    });

    return weekDays;
  };
  return (
    <tr>
      {renderItems()}
      <td className="fw-bold" style={{color: name == "totalHours" ? getTotalColorClass() : "" }}>
        {(name == "totalHours" ? (totalTime && totalTime !== 'NaN:NaN' ? totalTime : "") : "")}
      </td>
    </tr>
  );
};
export default WorkTimings;
