import React, { useEffect, useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { CompetenceGeneralHeader, CompetenceHeader } from "TableHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { COMPETENCE_ARCHIVE, COMPETNCE_GET } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { M_MASTER_DATA } from "constants/Constants";
import {
  PATH_COMPETENCE_CREATE,
  PATH_COMPETENCE_EDIT,
  PATH_COMPETENCE_VIEW,
  PATH_MASTER_DATA,
} from "constants/Paths";
import { Competence } from "./Manage";
import AccessControl from "services/AccessControl";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FilterOrganism from "components/organism/FilterOrganism";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import TableActions from "components/organism/Actions/TableAction";
import { getStandardStatus, getStandardToolTip } from "services/util/UtilService";

interface ManageCompetencePageProps {
  competenceObject: Competence[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  searchData: any;
  submit?: any;
}

const ManageCompetencePage: React.FC<ManageCompetencePageProps> = ({
  competenceObject,
  currentPage,
  totalPages,
  onPageChange,
  searchValue,
  deleteFilter,
  searchData,
  submit,
}) => {

  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}

  const [Competence, setCompetence] = useState<Competence[]>([]);

  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('type');

  const [deleteID, setDeleteId] = useState<any>({
    id: null,
    type: ''
  });
  const [showModal, setShowModal] = useState(false);

  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });

  const [options, setOptions] = useState({
    paritairComitee: [],
    pcFunctions: [],
    status: [{
      value: '0',
      label: 'Archived'
    },
    {
      value: '1',
      label: 'Active'
    }
    ],
    initialCall: true,
  });

  const getSearchData = () => {
    return {
      competenceName: history?.filters?.competenceName ?? "",
      pcName: history?.filters?.pcName ?? "",
      pcFunctions: history?.filters?.pcFunctions ?? "",
      type: history?.filters?.type ?? paramValue,
      status: history?.filters?.status ?? "",
    }
  };


  useEffect(() => {
    const data = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
      search: getSearchData(),
      initialCall: options?.initialCall,
    };
    fetchData(data);
  }, [pageData?.refresh, pageData?.initialCall, paramValue]);

  const fetchData = async (data: any) => {
    const response = await ApiCall.service(
      COMPETNCE_GET,
      "POST",
      data,
      false,
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      setCompetence(response?.data);
      setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords }));
      if (options?.initialCall) {
        const { pcFunctions, pc } = response?.options;

        setOptions((prevState: any) => ({ ...prevState, paritairComitee: pc, pcFunctions: pcFunctions, initialCall: false }));
      }
    }
  };


  const navigate = useNavigate();

  const tabs = [
    {
      id: 'general',
      tabName: 'General'
    },
    {
      id: 'function',
      tabName: 'Function'
    }
  ]

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const response = await archiveOrUnarchiveFunction(deleteID);

      if (response?.status == 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const archiveOrUnarchiveFunction = async (postData: any) => {
    try {
      const response = await ApiCall.service(
        COMPETENCE_ARCHIVE,
        "POST",
        postData,
        true,
        M_MASTER_DATA
      );
      return response;
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleDelete = (id: any) => {
    setShowModal(true);
    setDeleteId({ id: id?.id, type: 'archive' });
  };

  const handleEdit = (competence: Competence | any) => {
    if (competence.id) {
      navigate(`${PATH_COMPETENCE_EDIT}/${competence.id}/${paramValue}`);
    }
  };

  const handleView = (competence: Competence | any) => {
    if (competence.id) {
      navigate(`${PATH_COMPETENCE_VIEW}/${competence.id}`);
    }
  };

  const handleUnArchive = async (id: any) => {
    setShowModal(true);
    setDeleteId({
      id: id?.id,
      type: 'unarchive'
    });
    // if (id) {
    //   const data = {
    //     id: id,
    //     type: 'unarchive'
    //   }
    //   const response = await archiveOrUnarchiveFunction(data);

    //   if (response?.status == 200) {
    //     CustomNotify({ type: "success", message: response.message });
    //     handleRefresh();
    //   } else {
    //     CustomNotify({ type: "error", message: response.message });
    //   }
    // }
  }

  const userData = useSelector(selectAuth);

  const handleClick = (event: any) => {
    const { id } = event?.target;
    const filteredList = tabs.filter((eachItem: any) => (
      eachItem?.id == id
    ));
    navigate(`?type=${filteredList[0]?.id}`);
  }

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const filterFields = [
    { name: 'competenceName', fieldType: "text", placeholder: "Competence name", filterType: 'search' },
    { name: 'pcName', fieldType: "singleSelect", options: options?.paritairComitee, placeholder: "Paritair comitee", filterType: 'search' },
    { name: 'pcFunctions', fieldType: "multiSelect", options: options?.pcFunctions, placeholder: "Functions", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: options?.status, placeholder: "Status", filterType: 'search' },
  ];

  const generalFields = [
    { name: 'competenceName', fieldType: "text", placeholder: "Competence name", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: options?.status, placeholder: "Status", filterType: 'search' },
  ]

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Competence",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <div className="row">
          <Title title={t("Competence")} />
          <div className=" vacancy-tabs question-tabs">
            {tabs.map((eachItem: any) => (
              <button className={`text-decoration-none border-0 bg-transparent marginRight1 ps-0 ${eachItem?.id == paramValue ? "active" : "inactive"
                }`} onClick={handleClick} id={eachItem?.id}
                style={{ marginBottom: "0.25vw" }}
              >
                {t(eachItem?.tabName)}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <div className="row">
          <div className="ManageCreateBtn">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Competence",
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <LinkTo
                pagelink={`${PATH_COMPETENCE_CREATE}/${paramValue}`}
                title={t("Create competence")}
                icon={faPlus}
              />
            </AccessControl>
          </div>
        </div>
        <div className="table-responsive manage-competences tableSection">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={paramValue == 'general' ? generalFields : filterFields}
            handleRefresh={handleRefresh}
          />
          <div className="manageCompetencesHeight">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  {(paramValue == 'general' ? (CompetenceGeneralHeader) : (CompetenceHeader)).map((header) => (
                    <th key={header.alias}>
                      <span>
                        {t(header.name)}
                        {header.issort && (
                          <SortAtomForManagePage
                            value={{ alias: header.alias }}
                            dispatch={dispatch}
                            history={history}
                            updatePageFilters={updatePageFilters}
                            handleRefresh={handleRefresh}
                          />
                        )}
                      </span>
                    </th>
                  ))}
                  <th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Competence",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                      override={userData.isSuperAdmin}
                    >
                      {t("Actions")}
                    </AccessControl>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Competence && Competence.length > 0 ? (
                  Competence.map((record: any, key: any) => {
                    return (
                      <tr key={key}>
                        <td className="align-middle text-break">
                          {record?.competenceName}
                        </td>
                        {(paramValue !== 'general') && (<>
                          <td className="align-middle text-break">
                            {record?.functions}
                          </td>
                          <td className="align-middle text-break">
                            {record?.pcName}
                          </td>
                        </>)}
                        <td className="align-middle text-break">
                          <div
                            className={`${getStandardStatus(record.status)} d-inline-block rounded-circle status-icon`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={getStandardToolTip(record.status)}
                          ></div>
                        </td>
                        <td className="align-middle">
                          <TableActions
                            isAction={true}
                            handleEdit={handleEdit}
                            {...(record?.status) ? { handleArchive: handleDelete } : { handleUnArchive: handleUnArchive }}
                            permission={"Competence"}
                            value={record}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={16} className="text-center">
                      {t("No records found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* <DynamicTableStructure
              isAction
              headers={paramValue == 'general' ? CompetenceGeneralHeader : CompetenceHeader}
              data={Competence}
              handleEdit={handleEdit}
              handleArchive={handleDelete}
              //   handleView={handleView}
              permission={"Competence"}
              history={history}
              handleRefresh={handleRefresh}
            /> */}
          </div>
          <div
            className="pagination d-block"
            style={{ paddingTop: "0.25vw" }}
          >
            <PaginationWithPerPage
              handleRefresh={handleRefresh}
              dispatch={dispatch}
              history={history}
              pageData={pageData}
            />
          </div>
        </div>
      </div>
      {/* <div className="row backPadding">
        <div className="col-12">
          <BackButton />
        </div>
      </div> */}
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t(deleteID.type == 'archive' ? "Archive confirmation" : "Unarchive confirmation")}
        body={t(deleteID.type == 'archive' ? "Are you sure want to archive?" : "Are you sure want to unarchive?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};
export default ManageCompetencePage;

{
  /* <div className="table-responsive">
<DynamicTableStructure
  isAction
  headers={CompetenceHeader}
  data={competenceObject}
  handleEdit={handleEdit}
  handleArchive={handleDelete}
  handleView={handleView}
  permission={"Competence"}
/>
</div>
<div className="pagination justify-content-center align-items-center my-3">
<Pagination
  currentPage={currentPage}
  totalPages={totalPages}
  onPageChange={onPageChange}
/>
</div>
<div className="">
  <BackButton />
</div> */
}
