import React from "react";
import ManageTimesheetOverviewOrganism from "./organisms/ManageTimesheetOverviewOrganism";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { ROLES, TimesheetRoleMap, TimesheetRolePriority } from "./constants/timesheetConstants";

export default function ManageTimesheetOverview() {
    const userData = useSelector(selectAuth);

    const type = TimesheetRolePriority?.find((priorityRole: any) => userData?.role?.includes(priorityRole))
        ? TimesheetRoleMap[TimesheetRolePriority?.find((priorityRole: any) => userData?.role?.includes(priorityRole)) as string]
        : ROLES?.EMPLOYEE;

    return (
        <AccessControl
            requiredPermissions={[{
                permission: "Timesheets",
                read: true,
            }]}
            actions={true}
            strict={false}
            override={userData.isSuperAdmin}
        >
            <ManageTimesheetOverviewOrganism type={type} user={userData} />
        </AccessControl>
    );
}
