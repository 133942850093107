import React, { useEffect, useState } from "react";

import { t } from "pages/microservices/masterData/translation/Translation";

import {
  SWITCH_TAB,
  SET_OPTIONS,
  SET_ERRORS_GENERAL_TAB,
  UPDATE_TAB_DATA,
  UPDATE_ERRORS_CONTACTPERSONS_TAB,
  INITIAL_STATE,
  INITIAL_STATE_OBJECT,
  SET_ERRORS_ADDRESS_TAB,
  SET_ERRORS_LOCATION_TAB,
  mandatoryLocFields,
  mandatoryContactFields,
  ADD_UPDATE_DYNAMIC_TEMP_AGENCY_DOCUMENT,
} from "./context/Constants";

import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
  validateTextFieldAlpha,
  validateVAT,
} from "services/validation/ValidationService";
import FormNavigationOrganism from "components/organism/Actions/FormNavigationOrganism";
import { FormMode } from "components/common/CommonEnums";
import { PATH_MANAGE_TEMP_AGENCY } from "constants/Paths";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isObjectEmpty } from "services/util/UtilService";

import { Option } from "components/common/CommonInterfaces";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CustomNotify from "components/atoms/CustomNotify";

import {
  ContactPersonTabErrors,
  ContactsPersons as IContactPersons,
  GeneralTabErrors,
  AddressTabErrors,
} from "./context/Interface";
import {
  AddressTabValidationRules,
  ContactsTabSubformValidationRules,
  ContactsTabValidationRules,
  GeneralTabValidationRules,
  LocationTabValidationRules,
} from "./validationRules/TempAgencyGeneralRules";
import General from "./components/General";
import { ApiCall } from "services/ApiServices";
import {
  CREATE_TEMP_AGENCY,
  GET_TEMP_AGENCY_BY_ID,
  PROJECTS_OPTIONS,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import Address from "./components/Address";
import Location from "./components/Location";
import { useFormContext } from "./context/Context";
import Contacts from "./components/Contacts";
import { checkUniqueness } from "./Utils";
import TempAgencyDocument from "./components/TempAgencyDocument";
import CompanyCornerProfileCard from "../Company/CompanyCorner/CompanyCornerProfileCard";
import { CompanyCornerProps } from "../Company/CompanyCorner/CompanyCorner";
import Company from "static/images/Company";
import User from "static/images/User";

export const TempAgencyGeneralForm = () => {
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const [tempAgancyId, setTempAgencyId] = useState("");
  const [users, setUsers] = useState([]);
  const location = useLocation();
  const isClone = location.pathname?.includes("clone");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 10) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    dispatch({
      type: INITIAL_STATE,
      payload: INITIAL_STATE_OBJECT,
    });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const checkForMandatoryFields = (
    data: any,
    rules: any,
    mandatoryList: String[]
  ) => {
    const locationFields = validateForm(data, rules);
    return Object.keys(locationFields).length == mandatoryList.length;
  };

  const projectFormHasErrors = (): boolean => {
    let hasErrors = false;
    state.tabs.forEach((tab: any, index: number) => {
      let tabHasErrors = false;

      switch (tab.id) {
        case "general":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsGeneralTab(), {
            createLocationHasError: "",
            createLocation: "",
          });
          break;
        case "contactsPersons":
          const contactsResult =
            //checkForMandatoryFields(state?.contactsPersons?.data, ContactsTabSubformValidationRules, mandatoryContactFields);
            handleFieldValidationsContactPersonsTab();
          tabHasErrors = !contactsResult;
          break;

        case "location":
          //checkForMandatoryFields(state?.location?.data, LocationTabValidationRules, mandatoryLocFields)
          const result = handleFieldValidationsLocationTab();
          tabHasErrors = !result;
          break;
        case "address":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsAddressTab());
          break;
        default:
          break;
      }

      dispatch({
        type: "UPDATE_TAB_ERROR",
        tabIndex: index,
        error: tabHasErrors,
        draft: tab.draft,
      });

      if (tabHasErrors) {
        hasErrors = true;
      }
    });

    return hasErrors;
  };

  const handleFieldValidationsGeneralTab = (): GeneralTabErrors => {
    let newGeneralTabErrors = validateForm(
      state.general.data,
      GeneralTabValidationRules
    ) as Object;

    let newGeneralTabSubformErrors = {};

    Object.assign(newGeneralTabErrors, {
      createLocation: newGeneralTabSubformErrors,
    });

    dispatch({
      type: SET_ERRORS_GENERAL_TAB,
      errors: newGeneralTabErrors as GeneralTabErrors,
    });

    return newGeneralTabErrors as GeneralTabErrors;
  };

  const handleFieldValidationsContactPersonsTab = () => {
    const contactsData = state?.contactsPersons?.data;
    const contactList = state?.contactsPersons?.contactsList;

    let contactsTabErrors = validateForm(
      contactsData,
      ContactsTabSubformValidationRules
    ) as Object;

    let contactData = [...contactList];
    if (state?.contactsPersons?.editIndex !== null) {
      contactData.splice(state?.contactsPersons?.editIndex, 1);
    }

    const isUnique = checkUniqueness(contactsData, "email", contactData);

    if (isUnique) {
      CustomNotify({ type: "warning", message: "Email should be unique" });
    }

    if (
      Object.keys(contactsTabErrors).length == 0 &&
      contactList.length == 0 &&
      isUnique == undefined
    ) {
      return true;
    }

    if (
      Object.keys(contactsTabErrors).length == 0 &&
      contactList.length > 0 &&
      isUnique == undefined
    ) {
      return true;
    }

    const contRes =
      Object.keys(contactsTabErrors).length == mandatoryContactFields.length &&
      contactList.length > 0 &&
      isUnique == undefined;
    if (!contRes) {
      dispatch({
        type: UPDATE_ERRORS_CONTACTPERSONS_TAB,
        errors: contactsTabErrors,
      });
    }

    return contRes;
  };

  const handleFieldValidationsAddressTab = (): AddressTabErrors => {
    let newAddressTabErrors = validateForm(
      state.address.data,
      AddressTabValidationRules
    ) as Object;

    dispatch({
      type: SET_ERRORS_ADDRESS_TAB,
      errors: newAddressTabErrors as AddressTabErrors,
    });

    return newAddressTabErrors as AddressTabErrors;
  };
  const handleFieldValidationsLocationTab = () => {
    const locationData = state?.location?.data;
    const locationList = state?.location?.locationList;

    const locationErrors = validateForm(
      locationData,
      LocationTabValidationRules
    );

    if (Object.keys(locationErrors).length == 0 && locationList.length >= 0) {
      return true;
    }

    const result =
      Object.keys(locationErrors).length == mandatoryLocFields.length &&
      locationList.length > 0;
    if (!result) {
      dispatch({
        type: SET_ERRORS_LOCATION_TAB,
        errors: locationErrors,
      });
    }

    return result;
  };
  /**
   * Handling the validations for sequence tab
   * @returns object
   */

  // Save as Draft validation
  const draftValidation = () => {
    const validationRules = {
      vat: [validateVAT],
      businessUnitNumber: [validateRequired, validateMultiSelectField],
      name: [validateRequired, validateTextFieldAlpha],
      company: [validateRequired, validateSelectField],
    };

    const generalData = state.general.data;
    const validationErrors = validateForm(generalData, validationRules);
    if (Object.keys(validationErrors).length > 0) {
      dispatch({
        type: "UPDATE_TAB_ERROR",
        tabIndex: 0,
        error: false, // to update tab error
        draft: true,
      });

      dispatch({
        type: "UPDATE_TAB_FIELDS_ERROR",
        tabIndex: state?.currentTab,
        errors: validationErrors,
      });
      return false;
    }
    return true;
  };

  const ConstructTabs = (tab: string | number) => {
    switch (tab) {
      case "general":
        return <General />;
      case "contactsPersons":
        return <Contacts />;
      case "address":
        return <Address />;
      case "location":
        return <Location />;
      case "document":
        return <TempAgencyDocument />;
      default:
        break;
    }
  };

  useEffect(() => {
    getOptions();
  }, [state?.general?.data?.company, id]);

  async function getOptions() {
    if (state.general.data.company) {
      companyOptions();
    } else if (id) {
      tempAgecyDetails(id);
    } else {
      options();
    }
  }

  async function options() {
    let postData = {
      options: [
        "businessUnit",
        "internalCompany",
        "gender",
        "language",
        "roles",
        "country",
        "TempAgencyType",
        "PaymentTerm",
        "InvoiceStatus",
        "Coefficient",
        "tittle",
        "projectConsultant",
        "documentCategory",
      ],
    };
    const response = await ApiCall.service(
      PROJECTS_OPTIONS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const {
        businessUnit,
        internalCompany,
        gender,
        language,
        TempAgencyType,
        roles,
        country,
        tittle,
        projectConsultant,
        documentCategory,
      } = response?.data;

      const selectAllOption: Option = {
        label: "Select All",
        value: "selectAll",
      };

      // Step 2: Add the "Select All" option to the beginning of the array
      const updatedBusinessUnit = [selectAllOption, ...businessUnit];

      // Step 3: Dispatch the updated array with the "Select All" option included
      dispatch({
        type: SET_OPTIONS,
        field: "businessUnit",
        value: updatedBusinessUnit,
      });
      dispatch({ type: SET_OPTIONS, field: "company", value: internalCompany });
      dispatch({ type: SET_OPTIONS, field: "countries", value: country });
      dispatch({ type: SET_OPTIONS, field: "gender", value: gender });
      dispatch({ type: SET_OPTIONS, field: "language", value: language });

      dispatch({ type: SET_OPTIONS, field: "roles", value: roles });
      dispatch({ type: SET_OPTIONS, field: "tittle", value: tittle });
      dispatch({
        type: SET_OPTIONS,
        field: "documentCategoryList",
        value: documentCategory,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "consultant",
        value: projectConsultant,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "tempAgencyTypes",
        value: TempAgencyType,
      });
    }
  }
  async function companyOptions() {
    const company = state.general.data.company;
    if (!company) {
      return;
    }
    const postData = {
      options: ["contactPersons", "companyLocations", "companyFunctions"],
      companyId: company.value,
      tempId: id ? id : null,
    };

    const response = await ApiCall.service(
      PROJECTS_OPTIONS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const { contactPersons, companyLocations, companyFunctions } =
        response?.data;
      dispatch({
        type: SET_OPTIONS,
        field: "companyUsers",
        value: contactPersons,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "locations",
        value: companyLocations,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "functions",
        value: companyFunctions,
      });
    }
  }
  async function tempAgecyDetails(id: string) {
    if (!id) {
      return;
    }
    options();
    companyOptions();

    const response = await ApiCall.getService(
      `${GET_TEMP_AGENCY_BY_ID}/${id}`,
      "GET",
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      const {
        general,
        contactsPersons,
        address,
        location,
        tempAgencyDocument,
      } = response?.data;
      dispatch({ type: UPDATE_TAB_DATA, field: "general", value: general });

      dispatch({
        type: "UPDATE_CONTACT_LOCATION_TAB",
        tab: "contactsPersons",
        list: "contactsList",
        data: contactsPersons,
      });

      const addressData = !Array.isArray(address)
        ? address
        : {
            street: "",
            number: "",
            box: "",
            zipCode: "",
            country: null,
            city: "",
            initialLat: "",
            initialLn: "",
            biStreet: "",
            biNumber: "",
            biBox: "",
            biZipCode: "",
            biCountry: null,
            biCity: "",
            hqAddress: 0,
            sameAddress: 1,
            biinitialLat: "",
            biinitialLn: "",
          };

      dispatch({
        type: UPDATE_TAB_DATA,
        field: "address",
        value: addressData,
      });
      if (tempAgencyDocument && tempAgencyDocument.length > 0) {
        dispatch({
          type: ADD_UPDATE_DYNAMIC_TEMP_AGENCY_DOCUMENT,
          data: tempAgencyDocument,
        });
      }
      dispatch({
        type: "UPDATE_CONTACT_LOCATION_TAB",
        list: "locationList",
        tab: "location",
        data: location,
      });

      dispatch({
        type: "UPDATE_LOCATIONS_FOR_CONTACT",
        contactData: contactsPersons,
        locData: location,
      });
    }
  }
  function handleTabSwitch(tab: string) {
    dispatch({
      type: SWITCH_TAB,
      field: "currentTab",
      value: tab,
    });
  }

  // Navigation tabs
  const handlePreviousStep = () => {
    const currentTabIndex = state.tabs.findIndex(
      (tab: any) => tab.id === state.currentTab
    );
    const nextTabIndex = (currentTabIndex - 1) % state.tabs.length;
    const nextTabId = state.tabs[nextTabIndex].id;
    dispatch({
      type: "UPDATE_CURRENT_TAB",
      field: "currentTab",
      value: nextTabId,
    });
  };
  // const generateLocationOptions = () => {
  //   const locationTable = state.location.locationList; // Existing table data
  //   const locationData = state.location.data; // Single location data

  //   const requiredFields = [
  //     "locationName",
  //     "locationNumber",
  //     "locationCity",
  //     "locationCountry",
  //     "locationZipCode",
  //     "locationStreet",
  //   ];

  //   // Check if locationData has all required fields
  //   const isLocationDataValid = requiredFields.every(
  //     (field) => locationData[field as keyof typeof locationData]
  //   );

  //   let options: { value: number; label: string }[] = [];

  //   if (locationTable.length === 0) {
  //     // Case 1: Table is empty
  //     if (isLocationDataValid) {
  //       options.push({
  //         value: 1, // Start value from 1 instead of 0
  //         label: locationData.locationName, // Use locationName as label
  //       });
  //     }
  //   } else {
  //     // Case 2: Table has data
  //     locationTable.forEach((item: { locationId: any; locationName: any }) => {
  //       options.push({
  //         value: Number(item.locationId), // Use locationId for existing entries
  //         label: item.locationName, // Use locationName from table
  //       });
  //     });

  //     if (isLocationDataValid) {
  //       options.push({
  //         value: locationTable.length + 1, // Next index for new entry, start from 1 instead of 0
  //         label: locationData.locationName, // Use locationName from locationData
  //       });
  //     }
  //   }

  //   return options;
  // };

  const handleNextStep = () => {
    const currentTabIndex = state.tabs.findIndex(
      (tab: any) => tab.id === state.currentTab
    );
    const nextTabIndex = (currentTabIndex + 1) % state.tabs.length;
    const nextTabId = state.tabs[nextTabIndex].id;
    // if (state.currentTab === "location" && nextTabId === "contactsPersons") {
    //   const locationOptions: any = generateLocationOptions();
    //   dispatch({
    //     type: SET_OPTIONS,
    //     field: "locations",
    //     value: locationOptions,
    //   });
    // }
    dispatch({
      type: "UPDATE_CURRENT_TAB",
      field: "currentTab",
      value: nextTabId,
    });
  };

  const handleSubmit = async () => {
    const formHasErrors = projectFormHasErrors();
    if (formHasErrors) {
      return;
    }

    //Location data
    const locationFieldData = checkForMandatoryFields(
      state?.location?.data,
      LocationTabValidationRules,
      mandatoryLocFields
    )
      ? []
      : [state?.location?.data];

    const locCurrentList = [...state?.location?.locationList];

    if (state?.location?.editIndex !== null) {
      locCurrentList.splice(state?.location?.editIndex, 1);
    }

    const locationData = locationFieldData
      ? [...locCurrentList, ...locationFieldData]
      : [...locCurrentList];

    //Contacts data
    const contactFieldData = checkForMandatoryFields(
      state?.contactsPersons?.data,
      ContactsTabSubformValidationRules,
      mandatoryContactFields
    )
      ? []
      : [state?.contactsPersons?.data];

    const conCurrentList = [...state?.contactsPersons?.contactsList];

    if (state?.contactsPersons?.editIndex !== null) {
      conCurrentList.splice(state?.contactsPersons?.editIndex, 1);
    }

    const contactsData = contactFieldData
      ? [...conCurrentList, ...contactFieldData]
      : [...conCurrentList];

    const postData = {
      isDraft: false,
      general: { ...state.general.data, id: id !== undefined ? id : null },
      contactsPersons: contactsData,
      address: state.address.data,
      location: locationData,
      document: state.tempAgencyDocument,
      clone: isClone,
      loginUserId: userData?.userId,
    };

    const response = await ApiCall.service(
      CREATE_TEMP_AGENCY,
      "POST",
      postData,
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      CustomNotify({ type: "success", message: `${response.message}` });
      const tempAgencyId = response?.result?.tempAgencyId;
      const user = response?.result?.user;
      setTempAgencyId(response?.result?.tempAgencyId);
      setUsers(user);
      if (response?.result?.duplicateEmails) {
        CustomNotify({
          type: "warning",
          message: response?.result?.duplicateEmails,
        });
      }
      navigate(PATH_MANAGE_TEMP_AGENCY);
    } else if (response?.status === 500) {
      CustomNotify({ type: "error", message: `${response.message}` });
    }
  };
  const handleDraft = async () => {
    if (draftValidation()) {
      //Location data
      const locationFieldData = checkForMandatoryFields(
        state?.location?.data,
        LocationTabValidationRules,
        mandatoryLocFields
      )
        ? []
        : [state?.location?.data];

      const locCurrentList = [...state.location?.locationList];

      if (state?.location?.editIndex !== null) {
        locCurrentList.splice(state?.location?.editIndex, 1);
      }

      const locationData = locationFieldData
        ? [...locCurrentList, ...locationFieldData]
        : [...locCurrentList];

      //Contacts data
      const contactFieldData = checkForMandatoryFields(
        state?.contactsPersons?.data,
        ContactsTabSubformValidationRules,
        mandatoryContactFields
      )
        ? []
        : [state?.contactsPersons?.data];

      const contactListData = [...state?.contactsPersons?.contactsList];

      if (state?.contactsPersons?.editIndex !== null) {
        contactListData.splice(state?.contactsPersons?.editIndex, 1);
      }

      const contactsData = contactFieldData
        ? [...contactListData, ...contactFieldData]
        : [...contactListData];

      const postData = {
        isDraft: true,
        general: { ...state.general.data, id: id !== undefined ? id : null },
        contactsPersons: contactsData,
        address: state.address.data,
        location: locationData,
        clone: isClone,
        loginUserId: userData?.userId,
      };

      const response = await ApiCall.service(
        CREATE_TEMP_AGENCY,
        "POST",
        postData,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: `${response.message}` });
        navigate(PATH_MANAGE_TEMP_AGENCY);
        const tempAgencyId = response?.result?.tempAgencyId;
        const user = response?.result?.user;
        setTempAgencyId(response?.result?.tempAgencyId);
        setUsers(user);
      } else if (response?.status === 500) {
        CustomNotify({ type: "error", message: `${response.message}` });
      }
    }
  };

  const presentOperation = id ? "update" : "create";
  const currentObj: any = {
    permission: "Temp agency",
  };
  currentObj[presentOperation] = true;
  const userData = useSelector(selectAuth);

  const generalData = state?.general?.data;

  const pagePath = location?.pathname.split("/");
  const pageMode = pagePath.includes("view")
    ? "View"
    : pagePath.includes("edit")
    ? "Edit"
    : pagePath.includes("clone")
    ? "Clone"
    : "Create";
  const className = pageMode == "View" ? "form-border disabled" : "form-border";

  return (
    <div className="position-relative">
      <AccessControl
        requiredPermissions={[currentObj]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <div
          className="row header-sticky position-sticky"
          style={{ height: "auto" }}
        >
          <div className="col-12">
            <h1
              className="page-title mb-0 d-flex align-items-center"
              style={{ paddingBottom: "0.7vw" }}
            >
              {t(`${pageMode} temp agency`)}
            </h1>
          </div>
        </div>
        <div className="col-12">
          {/* <CompanyCornerProfileCard profileCardProps={profileCardData} /> */}
          <div
            style={{
              backgroundColor: "#e8f8fb",
              padding: "1vw",
              borderRadius: "1vw",
              marginBottom: "2vw",
            }}
          >
            <h4 className="name-text projectName cardNameText">
              {generalData?.name && t(generalData?.name)}
            </h4>
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: "0.5vw" }}
            >
              <span style={{ width: "5%" }} className="color-dark-pink">
                <span style={{ width: "5%" }} className="color-dark-pink">
                  <Company />
                </span>
              </span>
              <span>
                {generalData?.company?.label
                  ? generalData.company?.label
                  : generalData.company ?? ""}
              </span>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: "0.5vw" }}
            >
              <strong className="color-dark-pink" style={{ width: "5%" }}>
                {"VAT"}
              </strong>
              {generalData?.vat && <span>{generalData?.vat}</span>}
            </div>
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: "0.5vw" }}
            >
              <span
                title={t("Consultant")}
                className="color-dark-pink"
                style={{ width: "5%" }}
              >
                <User />
              </span>
              {(generalData?.consultant as any)?.label && (
                <span>{(generalData.consultant as any).name}</span>
              )}
            </div>

            {/* <span className="marginRight1">
              <strong>{t("Company")} : </strong>
              {t(
                (generalData.company?.label
                  ? generalData.company?.label
                  : generalData.company) ?? ""
              )}
            </span> */}
            {/* <span className="marginRight1">
              <strong>{t("Vat number")} : </strong>
              {generalData?.vat && generalData?.vat}
            </span> */}
            {/* <span>
              <strong>Start date :</strong>
              {formatDate(startDate)}
            </span> */}
          </div>
        </div>
        <div className="position-relative">
          <nav
            className={`temp-agency-tabs projectTabs project-form company-step-tabs createFormTabs ${
              isSticky
                ? "createTabsSticky position-sticky"
                : "company-step-tabs-absolute position-absolute w-100"
            }`}
          >
            <div
              className="d-flex border-0 w-100 m-auto gap-3"
              id="nav-tab"
              role="tablist"
            >
              <div
                className={`d-flex border-0 w-100 m-auto align-items-center justify-content-center`}
                style={{ gap: "1vw" }}
              >
                {state.tabs.map((item: any) => (
                  <div className={`flex-1 text-center`} key={item.id}>
                    <div
                      className={`createStepBtn cursor-pointer ${
                        state.currentTab === item.id ? "active" : "inactive"
                      } ${item.error === true ? "error" : ""}`}
                      onClick={() => handleTabSwitch(item.id)}
                    >
                      {t(item.title)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </nav>
          <div className={`mt-2 ${className}`}>
            {ConstructTabs(state.currentTab)}
          </div>
        </div>
        <FormNavigationOrganism
          isFirstTab={state.currentTab === state.tabs[0].id}
          isSubmitTab={
            state.currentTab === state.tabs[state.tabs.length - 1].id
          }
          draftButtonIsVisible={state.mode === FormMode.CREATE}
          formExitBackTo={PATH_MANAGE_TEMP_AGENCY}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
          {...(pageMode !== "View" && {
            handleSubmit: handleSubmit,
            ...(state.general.data.status == 0 && { handleDraft }),
          })}
          isHideNext={
            state.currentTab === state.tabs[state.tabs.length - 1].id &&
            pageMode == "View"
          }
          // {...(state.general.data.status == 0 && { handleDraft })}
        />
      </AccessControl>
    </div>
  );
};
export default TempAgencyGeneralForm;
