import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import ManageUsersPage from "./ManageUsersPage";
import { GET_USERS } from "routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";

export interface UserData {
  user_id?: number;
  email: string;
  status: any;
  userName: any;
  businessUnits: any;
  role: string;
}

const ManageUsers: React.FC = () => {
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [users, setUsers] = useState<UserData[]>([]);
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true
  })
  const userAuth = useSelector(selectAuth);;
  const [options, setOptions] = useState({
    roles: [],
    businessUnits: [],
    initialCall: true
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const data: any = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
      search: {
        user_id: Number(history?.filters?.user_id ?? null),
        email: history?.filters?.email ?? "",
        businessUnits: history?.filters?.businessUnits ?? [],
        status: history?.filters?.status ?? "",
        lastName: history?.filters?.lastName ?? "",
        firstName: history?.filters?.firstName ?? "",
        phoneNumber: history?.filters?.phoneNumber ?? "",
        role: history?.filters?.role ?? "",
        orderBy: history?.filters?.orderBy ?? "id",
        sortDirection: history?.filters?.sortDirection ?? "desc",
      },
      initialCall: options?.initialCall
    };

    fetchUsers(data);
  }, [pageData?.refresh, options?.initialCall]);

  const fetchUsers = async (data: any) => {
    const response = await ApiCall.service(GET_USERS, "POST", data);
    if (response?.status === 200) {
      setUsers(response.data);
      setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords }))
      if (options?.initialCall && response?.options) {
        setOptions((prev) => ({
          ...prev, roles: response?.options?.roles,
          businessUnits: response?.options?.businessUnits,
          initialCall: false
        }))
      }
      setLoading(false);
    }
  };

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const handleDeleteData = (deleteId: number) => {
    setUsers((prevdata) => prevdata.filter((users) => users.user_id !== deleteId));
  }

  return (
    <>
      <ManageUsersPage
        usersObj={users}
        history={history}
        pageData={pageData}
        deleteFilter={handleDeleteData}
        options={options}
        dispatch={dispatch}
        handleRefresh={handleRefresh}
        loading={loading}
      />
    </>
  );
};

export default ManageUsers;
