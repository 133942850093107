import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import HelpInfoIcon from "static/images/HelpIcon";
import Button from "components/atoms/Button";
import { RANGE_VALUE, RANGE_VALUE_FOR_GENERAL } from "constants/Constants";
import CustomRangeSlider from "components/atoms/CustomRangeSlider";
import TextEllipsis from 'components/atoms/TextEllipsis';

interface Option {
  label: string;
  value: string;
  range: string;
}

interface CompetenceModalProps {
  handleClose: () => void;
  handleAdd: (selectedOptions: {
    [competence_id: string]: {
      key: string;
      label: string;
      options: Option[];
    };
  }) => void;
  competenceData: CompetenceSection[];
  selectedCompetence: {
    [competence_id: string]: {
      key: string;
      label: string;
      info: string;
      maxRange: any;
      options: Option[];
    };
  };
}

interface CompetenceSection {
  competence_id: any;
  key: string;
  label: string;
  maxRange: any
  info: string;
  options: { label: string; value: string }[];
}

const CompetenceModal: React.FC<CompetenceModalProps> = ({
  handleClose,
  handleAdd,
  competenceData,
  selectedCompetence
}) => {
  const [selectedCompetenceOptions, setSelectedCompetenceOptions] = useState<{
    [competence_id: string]: {
      key: string;
      label: string;
      options: Option[];
      maxRange: any
    };
  }>({});

  useEffect(() => {
    selectedCompetence && setSelectedCompetenceOptions(selectedCompetence)
    // console.log(selectedCompetence, competenceData);

  }, [selectedCompetence])

  const handleSelect = (sectionId: string, selected: Option[], key: any) => {
    setSelectedCompetenceOptions((prevState) => ({
      ...prevState,
      [sectionId]: {
        ...prevState[sectionId],
        options: selected,
      },
    }));
  };

  const getTitleForRating = (range: string) => {
    switch (parseInt(range)) {
      case 1:
        return "A1";
      case 2:
        return "A2";
      case 3:
        return "B1";
      case 4:
        return "B2";
      case 5:
        return "C1";
      case 6:
        return "C2";
      default:
        return "A1";
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    sectionId: string,
    index: number
  ) => {
    const newOptions = [...selectedCompetenceOptions[sectionId].options];
    newOptions[index] = {
      ...newOptions[index],
      range: e.target.value,
    };

    setSelectedCompetenceOptions((prev) => ({
      ...prev,
      [sectionId]: {
        ...prev[sectionId],
        options: newOptions,
      },
    }));
  };

  const handleAddClick = () => {
    handleAdd(selectedCompetenceOptions);
  };
  const activeKeys = competenceData?.filter((section: any) => selectedCompetenceOptions?.[section?.competence_id]?.options?.length > 0).map((section: any) => section?.key);

  return (
    <Modal
      size="xl"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      className="competencesModal competencesModalPopup"
    >
      <Modal.Header closeButton >
        <Modal.Title>
          {t("Competence")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey={activeKeys} alwaysOpen>
          {competenceData?.map((section) => (
            <Accordion.Item key={section.key} eventKey={section.key}>
              <Accordion.Header>{section.label}</Accordion.Header>
              <Accordion.Body>
                <div className="hideHeight">
                  <SelectWithSearch
                    name={section.key}
                    isMandatory={false}
                    search={true}
                    placeHolder="Select"
                    options={section.options}
                    value={selectedCompetenceOptions[section.competence_id]?.options || []}
                    onChange={(selected) =>
                      handleSelect(section.competence_id, selected, section.label)
                    }
                    isMulti={true}
                    className="select-field"
                    error={""}
                  />
                </div>
                <div className="row">
                  {selectedCompetenceOptions[section.competence_id]?.options?.length > 0 && (
                    <div
                      className="text-end"
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title={t(section?.info)}
                      style={{ marginTop: "0.5vw" }}
                    >
                      <HelpInfoIcon />
                    </div>
                  )}
                </div>
                <div className="d-grid competenceGridModal" style={{ paddingLeft: "0.5vw" }}>
                  {/* {data?.length > 0 &&
                    data?.find((item: any) => item.key === section.key)?.options && (
                      <div
                        className="text-end mt-2"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title={section?.info}
                      >
                        <HelpInfoIcon />
                      </div>
                    )} */}
                  {selectedCompetenceOptions[section.competence_id]?.options?.map((option, index) => (
                    <div key={index} className="marginBottomPoint5">
                      <div className="marginBottomPoint5 fw-bolder"><TextEllipsis title={option?.label} label={option?.label} width="13.5vw" /></div>
                      <div className="w-100">
                        <CustomRangeSlider
                          min={1}
                          max={section?.maxRange ?? "6"}
                          title={getTitleForRating(option?.range === "0" ? "1" : option?.range)}
                          value={option?.range === "0" ? "1" : option?.range}
                          className="w-100"
                          handleChange={(e) => handleChange(e, section?.competence_id, index)}
                        />
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            {(section?.maxRange == 4 ? RANGE_VALUE_FOR_GENERAL : RANGE_VALUE)?.map((value, index) => (
                              <span key={value}>{value}</span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Button
              title={t("Cancel")}
              handleClick={handleClose}
              className="delete-btn shadow-none"
            />
          </div>
          <div>
            <Button
              title={t("Add")}
              handleClick={handleAddClick}
              className="form-button shadow-none"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompetenceModal;
