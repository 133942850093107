import React, { useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import HidePassword from "static/images/Hide.svg";
import ShowPassword from "static/images/Show.svg";
import LabelField from "components/atoms/LabelField";
import InputTextfield from "components/atoms/InputTextField";
import { ApiCall } from "services/ApiServices";
import LoadingIcon from "utils/LoadingIcon";
import { useDispatch } from "react-redux";
import EncryptDecryptService from "services/EncryptDecryptService";
import { addUserDetails } from "features/auth/AuthSlice";
import ImageField from "components/atoms/ImageField";
import "static/css/login.css";
import {
  validateForm,
  validateEmail,
  validateRequired,
  validatePassword,
  // validateCheckbox,
} from "services/validation/ValidationService";
import CustomNotify from "components/atoms/CustomNotify";
import // GoogleReCaptchaProvider,
// GoogleReCaptcha,
"react-google-recaptcha-v3";
import { reCaptchaSiteKey } from "constants/Constants";
import { LOGIN } from "routes/ApiEndpoints";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

interface Credentials {
  email?: string;
  password?: string;
  isCaptcha?: boolean;
}

interface ValidationRules {
  [key: string]: Function[];
}

const LoginForm = () => {
  const [passwordType, setPasswordType] = useState("password");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [data, setData] = useState<Credentials>({
    email: "",
    password: "",
    isCaptcha: false,
  });

  const [errors, setErrors] = useState<Credentials>({});

  const [loading, setLoading] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const validation = (
    name: string,
    value: string,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      email: [validateRequired, validateEmail],
      password: [validateRequired, validatePassword],
      // isCaptcha: [validateCheckbox],
    };

    const validationErrors = validateForm(
      { ...data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    const formData = {
      email: data.email,
      password: data.password,
    };

    if (validation(name, value)) {
      const response = await ApiCall.service(LOGIN, "POST", formData, true);
      if (response.status === "200") {
        const getRefreshToken = localStorage.getItem("refresh_token");
        let token;
        if (getRefreshToken === "" || getRefreshToken === null) {
          token = null;
        } else {
          const refreshTokenObj = JSON.parse(getRefreshToken);
          token = refreshTokenObj.token;
        }
        const user = {
          token: response.access_token,
          email: response.email,
          token_type: response.token_type,
          isAuthenticated: true,
          refreshToken: token,
          userId: response.userId,
          role: response.role,
          name: response.name,
          user_language: response?.user_language,
          languageOptions: response?.app_languages,
          employeeId:response?.employee_id,
          businessUnit:response?.businessUnit,
          contractType:response?.contractType,
        };
        dispatch(addUserDetails(user));
        localStorage?.setItem(
          "translations",
          JSON?.stringify(response?.translations ?? {})
        );
        navigate("/dashboard");
        CustomNotify({ type: "success", message: "Logged in successfully!" });
      } else {
        if (response.field === "email") {
          setErrors({ email: response.msg });
        } else {
          setErrors({ password: response.msg });
        }
        CustomNotify({ type: "error", message: response.msg, autoClose: 2000 });
      }
    }
    setLoading(false);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // const handleCaptchaVerify = (response: string | null) => {
  //   if (response) {
  //     setData((prevFormData) => ({
  //       ...prevFormData,
  //       isCaptcha: true,
  //     }));
  //   }
  // };

  return (
    <>
      <div className="container-fluid login-bg">
        <div className="row">
          <div className="col-6 px-0 vh-100">
            <ImageField
              altText="Banner"
              className="w-100 h-100 img-fluid object-fit-cover"
              src="/static/images/Welcome-dashboard.png"
            />
          </div>
          <div className="col-6 align-self-center">
            {/* <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}> */}
            <div style={{ width: "60%", margin: "auto" }}>
              <div className="text-center" style={{ marginBottom: "3vw" }}>
                <ImageField
                  altText={"Logo"}
                  className="text-center logo-width"
                  src="static/images/logo.svg"
                />
              </div>
              <form onSubmit={submitHandler} className="login-form">
                <div className="text-center" style={{ marginBottom: "2vw" }}>
                  <span className="text-center login-title position-relative">
                    Login
                  </span>
                </div>
                <LabelWithInputField
                  label={"Email"}
                  id="email"
                  isMandatory={true}
                  placeholder={"Enter email"}
                  type="email"
                  name="email"
                  handleChange={changeHandler}
                  value={data.email}
                  error={errors.email}
                />
                <div className="col-lg-12">
                  <LabelField
                    title={"Password"}
                    isMandatory={true}
                    htmlfor="password"
                  />
                  <div className="d-flex position-relative">
                    <InputTextfield
                      handleChange={changeHandler}
                      type={passwordType}
                      value={data.password}
                      id="password"
                      placeholder={"Enter password"}
                      name="password"
                    />
                    <p
                      onClick={togglePassword}
                      className="position-absolute cursor-pointer eye-icon"
                    >
                      {passwordType === "password" ? (
                        <img src={HidePassword} alt="Hide Password" />
                      ) : (
                        <img src={ShowPassword} alt="Show Password" />
                      )}
                    </p>
                  </div>
                  <div className="row">
                    <div
                      className="height-20"
                      style={{ marginBottom: "0.5vw" }}
                    >
                      {errors.password && (
                        <span className="text-danger">{errors.password}</span>
                      )}
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "1.6vw" }}>
                    <div className="col-md-12">
                      <div className="ms-auto">
                        <Link
                          to="/forgot-password"
                          className="float-end link-color"
                        >
                          {" Forgot password?"}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    {/* <GoogleReCaptcha
                      onVerify={handleCaptchaVerify}
                    /> */}
                  </div>
                  <div className="row">
                    <div className="text-center">
                      {errors.isCaptcha && (
                        <span className="text-danger">
                          {"Please verify that you are not a robot."}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 mx-auto text-center">
                    {!loading ? (
                      <Button
                        title={"Login"}
                        type="submit"
                        className="form-button w-100"
                        style={{ marginBottom: "1.5vw" }}
                        icon={faAngleRight}
                      />
                    ) : (
                      <LoadingIcon
                        iconType="bars"
                        color="#00a5ce"
                        height={"3vw"}
                        width={"3vw"}
                      />
                    )}
                  </div>
                  {/* <div className="col-md-12 text-center">
                        <span>
                          {t("Don't have an account?")}{" "}
                          <Link to="/register" className="link-color">
                            {t("Register as a candidate")}
                          </Link>
                        </span>
                      </div> */}
                </div>
                <div className="col-md-12 text-center">
                  <span>
                    Don't have an account?
                    <Link
                      to="/register"
                      className="link-color ms-1 text-decoration-underline"
                    >
                      Register as a applicant
                    </Link>
                  </span>
                </div>
              </form>
            </div>
            {/* </GoogleReCaptchaProvider> */}
          </div>
        </div>
      </div>

      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-7 col-xl-6 col-lg-6 vh-100 ps-0 justify-content-center align-items-center vh-100 ps-0 d-none d-md-none d-lg-block">
            <ImageField
              altText="Banner"
              className="w-100 h-100 img-fluid object-fit-cover"
              src="/static/images/loginImage.jpg"
            />
          </div>
          <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-8 m-auto register-form__fields p-xxl-3 p-xl-0 p-lg-0">
            <div className="row align-items-center justify-content-center h-100">
              <div className="col-xxl-8 col-xl-8 col-lg-9 m-auto px-xxl-1 p-lg-0">
                <div className="row">
                  <div className="col-md-12 text-center mb-5">
                    <ImageField
                      altText="Logo"
                      className="text-center logo-width"
                      src="static/images/logo.svg"
                    />
                  </div>
                </div>
                {/* <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}> *
                <form onSubmit={submitHandler} className="login-form">
                  <h2 className="text-center mb-4 login-title fw-bold">
                    {" "}
                    {t("Login")}
                  </h2>
                  <LabelWithInputField
                    label={t("Email")}
                    id="email"
                    isMandatory={true}
                    placeholder={t("Enter email")}
                    type="email"
                    name="email"
                    handleChange={changeHandler}
                    value={data.email}
                    error={errors.email}
                    className="form-control shadow-none rounded-0"
                  />
                  <div className="col-lg-12">
                    <LabelField
                      title={t("Password")}
                      isMandatory={true}
                      htmlfor="password"
                    />
                    <div className="d-flex position-relative">
                      <InputTextfield
                        handleChange={changeHandler}
                        type={passwordType}
                        value={data.password}
                        id="password"
                        placeholder={t("Enter password")}
                        name="password"
                        className="form-control shadow-none rounded-0"
                      />
                      <p
                        onClick={togglePassword}
                        className="position-absolute mt-1 me-2 end-0 cursor-pointer"
                      >
                        {passwordType === "password" ? (
                          <img src={HidePassword} alt="Hide Password" />
                        ) : (
                          <img src={ShowPassword} alt="Show Password" />
                        )}
                      </p>
                    </div>
                    <div className="row">
                      <div className="height-20 mb-2">
                        {errors.password && (
                          <span className="text-danger">{errors.password}</span>
                        )}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-12">
                        <div className="ms-auto">
                          <Link
                            to="/forgot-password"
                            className="float-end link-color"
                          >
                            {t(" Forgot password?")}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      {/* <GoogleReCaptcha
                      onVerify={handleCaptchaVerify}
                    /> *
                    </div>
                    <div className="row">
                      <div className="text-center mb-2">
                        {errors.isCaptcha && (
                          <span className="text-danger">
                            {t("Please verify that you are not a robot.")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10 mx-auto text-center">
                      {!loading ? (
                        <Button
                          title={t("Login")}
                          type="submit"
                          className="btn form-button mb-4 w-100 shadow-none rounded-0 text-uppercase"
                        />
                      ) : (
                        <LoadingIcon iconType="bars" color="#00a5ce" />
                      )}
                    </div>
                    {/* <div className="col-md-12 text-center">
                        <span>
                          {t("Don't have an account?")}{" "}
                          <Link to="/register" className="link-color">
                            {t("Register as a candidate")}
                          </Link>
                        </span>
                      </div> 
                  </div>
                  <div className="col-md-12 text-center">
                    <span>
                      {t("Don't have an account") + "?"}&nbsp;
                      <Link to="/register" className="link-color ms-1">
                        {t("Register as a candidate")}
                      </Link>
                    </span>
                  </div>
                </form>
                {/* </GoogleReCaptchaProvider>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default LoginForm;
