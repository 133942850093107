import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CustomNotify from 'components/atoms/CustomNotify';
import LinkTo from 'components/atoms/LinkTo';
import ModalPopup from 'components/atoms/ModalPopup';
import TableStructure from 'components/atoms/TableStructure';
import Title from 'components/atoms/Title';
import PaginationWithPerPage from 'components/molecules/PaginationWithPerPage';
import FilterOrganism from 'components/organism/FilterOrganism';
import { selectAuth } from 'features/auth/AuthSlice';
import { t } from 'pages/microservices/masterData/translation/Translation';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DELETE_ROLE, GET_ROLES } from 'routes/ApiEndpoints';
import AccessControl from 'services/AccessControl';
import { ApiCall } from 'services/ApiServices';
import { selectPageHistory, updatePageFilters } from 'store/pageHistory/pageHistorySlice';
import { RoleTableHeader } from 'TableHeader';

interface RolesType {
    id?: number;
    name?: string;
    rank?: number;
}

export const ManageRoles = () => {
    const userData = useSelector(selectAuth);
    const [roles, setRoles] = useState<RolesType[]>([]);
    const [loading, setLoading] = useState(true);
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
    const dispatch = useDispatch();
    const columns = ["name", "rank"];
    const [deleteID, setDeleteId] = useState<number | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModelBody] = useState(t("Are you sure want to delete?"));
    const navigate = useNavigate();

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        const response = await ApiCall.getService(GET_ROLES, "GET");
        if (response.status === 200) {
            setRoles(response?.data);
            setLoading(false);
        }
    }

    const filterOrder = roles?.sort((a: any, b: any) => {
        if (!history?.filters?.orderBy) {
            const dateA = new Date(a?.created_at).getTime();
            const dateB = new Date(b?.created_at).getTime();
            return dateB - dateA;
        } else {
            if (history?.filters?.orderBy === 'rank') {
                const rankA = a?.rank ?? 0;
                const rankB = b?.rank ?? 0;
                return ((history?.filters?.sortDirection || 'desc') === 'asc') ? rankA - rankB : rankB - rankA;
            } else if (history?.filters?.orderBy === 'name') {
                let comparison = a?.name.localeCompare(b?.name);
                return ((history?.filters?.sortDirection || 'desc') === 'asc') ? comparison : -comparison;
            }
        }
    });

    // Filter the data
    const filteredData = filterOrder?.filter((item: RolesType) => {
        const searchQuery = history?.filters?.name?.trim()?.toLowerCase() ?? "";
        const searchRank = history?.filters?.rank?.trim() ?? "";

        const nameMatches = item.name?.toLowerCase().includes(searchQuery);

        const rankMatches = searchRank
            ? item.rank !== undefined && item.rank !== null && item.rank === Number(searchRank) ? true : false
            : true;

        return nameMatches && rankMatches;
    });

    const indexOfLastItem = Number(history?.filters?.currentPage ?? 1) * Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20);
    const indexOfFirstItem = indexOfLastItem - Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20);
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate the total number of pages
    const totalPages = Math.ceil(roles.length / Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20));

    const deleteRole = (RolesType: RolesType | any) => {
        setShowModal(true);
        setDeleteId(RolesType.id);
    };

    const EditRole = (RolesType: RolesType | any) => {
        if (RolesType.id) {
            navigate(`/role/edit/${RolesType.id}`);
        }
    };

    const cloneRole = (RolesType: RolesType | any) => {
        if (RolesType.id) {
            navigate(`/role/clone/${RolesType.id}`)
        }
    }

    // Modal popup for delete confirmation
    const handleModalClose = () => {
        setShowModal(!showModal);
        setModelBody(t("Are you sure want to delete?"));
    };

    const handleSaveChanges = async () => {
        setShowModal(false);
        let body = {
            roleId: deleteID,
            force: false,
        };
        if (modalBody !== t("Are you sure want to delete?")) {
            body.force = true;
        }
        const response = await ApiCall.service(DELETE_ROLE, "POST", body);
        if (response.status === 403) {
            setModelBody(response.msg);
            setShowModal(true);
        } else if (response.status === 200) {
            const newRolesObj = roles.filter(
                (item: RolesType) => item.id !== deleteID
            );
            setRoles(newRolesObj);
            CustomNotify({ type: "success", message: response.msg });
        } else {
            CustomNotify({ type: "error", message: response.msg });
        }
    };

    const filters = [
        { name: 'name', fieldType: "text", placeholder: "Role name", filterType: 'search' },
        { name: 'rank', fieldType: "text", placeholder: "Rank", filterType: 'search' }
    ];

    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "Role",
                    read: true,
                },
            ]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <>
                <div className="search-bar" style={{ paddingBottom: "0.25vw" }}>
                    <Title title={t("Roles")} />
                </div>
                <div className="position-relative tableMainWrapper">
                    <AccessControl
                        requiredPermissions={[
                            {
                                permission: "Role",
                                create: true,
                            },
                        ]}
                        override={userData.isSuperAdmin}
                    >
                        <div className="d-none d-lg-block">
                            <div className="row">
                                <div className="ManageCreateBtn">
                                    <LinkTo
                                        pagelink="/role/create"
                                        title={t("Create role")}
                                        icon={faPlus}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-lg-none">
                            <div className="row">
                                <div className="col-md-6 ms-auto pb-3">
                                    <LinkTo
                                        pagelink="/role/create"
                                        title={t("Create role")}
                                        icon={faPlus}
                                        className="align-items-center justify-content-center w-100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive manage-roles tableSection">
                            <FilterOrganism
                                dispatch={dispatch}
                                history={history}
                                updatePageFilters={updatePageFilters}
                                filters={filters}
                            />
                            <div className="TableHeight">
                                <TableStructure
                                    isAction
                                    history={history}
                                    headers={RoleTableHeader}
                                    data={currentItems}
                                    values={columns}
                                    handleEdit={EditRole}
                                    handleDelete={deleteRole}
                                    handleClone={cloneRole}
                                    permissionType="Role"

                                />
                            </div>
                            <div
                                className="pagination d-block"
                                style={{ paddingTop: "0.25vw" }}
                            >
                                <PaginationWithPerPage
                                    dispatch={dispatch}
                                    history={history}
                                    pageData={{ totalPages: totalPages, totalRecords: roles?.length ?? 0 }}
                                />
                            </div>
                        </div>
                        <ModalPopup
                            show={showModal}
                            onHide={handleModalClose}
                            title={t("Delete confirmation")}
                            body={modalBody}
                            onCloseButtonClick={handleModalClose}
                            onConfirmButtonClick={handleSaveChanges}
                            closeTitle={t("No")}
                            confirmTitle={"Yes"}
                        />
                    </AccessControl>
                </div>
            </>
        </AccessControl >
    );
}