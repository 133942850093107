import React, { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { AccomplishData, CalculationSearchProps, EmployeeDataProps, initialAccomplishmentData, initialCalculationSearch, initialEmployeeData } from "./PartenaInterface";
import './css/Partena.css';
import { CALCULATE_PARTENA, EXPORT_PARTENA, GET_EMPLOYEE_EXPORTED_DATA } from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import CheckBoxField from "components/atoms/CheckBoxField";
import FilterOrganism from "components/organism/FilterOrganism";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch, useSelector } from "react-redux";
import { PartenaEmployeeTableHeader, PartenaPerformanceTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const PartenaCalculation: React.FC = () => {
  const params = useParams();
  const partenaId = params?.id;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPerformanceModal, setShowPerformanceModal] = useState<boolean>(false);
  const [search, setSearch] = useState<CalculationSearchProps>(initialCalculationSearch);
  const [status, setStatus] = useState({
    employee: true,
    accomplishment: false
  })
  const [employeeData, setEmployeeData] = useState<EmployeeDataProps[]>([initialEmployeeData]);
  const [performanceData, setPerformanceData] = useState<AccomplishData[]>([initialAccomplishmentData]);
  const [userPerfromaneData, setUserPerformaneData] = useState<AccomplishData[]>([initialAccomplishmentData]);
  const [userIdsToExport, setUserIdsToExport] = useState<number[]>([]);
  const [popUpEmpId, setPopUpEmpId] = useState<number | null>();
  // const [dropdownData, setDropdownData] = useState<Option[]>([]);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [employeePageData, setEmployeePageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });
  const [empPopUpPageData, setEmpPopUpPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });
  const [performancePageData, setPerformancePageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });
  const getPerformanceSearchData = () => {
    return {
      perfEmployee: history?.filters?.perfEmployee ?? "",
      perfDate: history?.filters?.perfDate ?? "",
      perfDepartment: history?.filters?.perfDepartment ?? "",
      perfType: history?.filters?.perfType ?? "",
      perfWageCode: history?.filters?.perfWageCode ?? "",
      perfShiftCode: history?.filters?.perfShiftCode ?? "",
      perfHours: history?.filters?.perfHours ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
  };

  const getEmpPopUpSearchData = () => {
    return {
      empPopUpEmployee: history?.filters?.empPopUpEmployee ?? "",
      empPopUpDate: history?.filters?.empPopUpDate ?? "",
      empPopUpDepartment: history?.filters?.empPopUpDepartment ?? "",
      empPopUpType: history?.filters?.empPopUpType ?? "",
      empPopUpWageCode: history?.filters?.empPopUpWageCode ?? "",
      empPopUpShiftCode: history?.filters?.empPopUpShiftCode ?? "",
      empPopUpHours: history?.filters?.empPopUpHours ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
  };

  const getEmployeeSearchData = () => {
    return {
      employee: history?.filters?.employee ?? "",
      user: history?.filters?.user ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
  };

  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(true);
  const [performanceLoading, setPerformanceLoading] = useState(true);

  useEffect(() => {
    if (showModal) {
      getEmpPerformanceData();
    } else {
      handleCalculate();
    }
  }, [employeePageData?.refresh, employeePageData?.initialCall,
  empPopUpPageData?.refresh, empPopUpPageData?.initialCall,
  performancePageData?.refresh, performancePageData?.initialCall]);

  const handleClick = async (value: string | null | EmployeeDataProps | boolean) => {
    if (typeof value === "string") {
      const employeeStatus: boolean = value.includes('Employees');
      const accomplishmentStatus: boolean = value.includes('Performance');
      setStatus((prevData: any) => ({ ...prevData, employee: employeeStatus, accomplishment: accomplishmentStatus }));
      // setStatus((prevData: any) => ({ ...prevData, accomplishment: accomplishmentStatus }));
    } else if (typeof value === "object") {
      setShowModal(true);
      setPopUpEmpId(value?.userId);
      getEmpPerformanceData();
    } else if (typeof value === "boolean") {
      setShowModal(value)
    }
  }
  const getEmpPerformanceData = async () => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getEmpPopUpSearchData(),
      initialCall: employeePageData?.initialCall,
      userId: popUpEmpId,
      partenaId: partenaId,
    }

    const response = await ApiCall.service(GET_EMPLOYEE_EXPORTED_DATA, "POST", postData, true, M_MASTER_DATA);
    if (response?.status === 200) {
      setUserPerformaneData(response.data?.data);
      //pagination
      setModalLoading(false);
      setEmpPopUpPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords, initialCall: false }));
      // CustomNotify({ type: 'success', message: response?.message });
    }
  }

  const handleModalClose = () => {
    setShowModal(false);
    handleEmpPopUpPageRefresh();
  }

  const hadlePopupModal = () => {
    setShowPerformanceModal(true);
  }
  function handlePerformanceModalClose(): void {
    setShowPerformanceModal(false);
  }

  const handleCalculate = async (type?: string) => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: status?.employee ? getEmployeeSearchData() : getPerformanceSearchData(),
      initialCall: employeePageData?.initialCall,
      partenaId: partenaId,
      // type: status?.employee ? 'employee' : status?.accomplishment ? 'performance' : ''
    };

    const response = await ApiCall.service(CALCULATE_PARTENA, "POST", postData, true, M_MASTER_DATA);
    if (response?.status === 200) {
      if (type) {
        CustomNotify({ type: 'success', message: response?.message });
      }
      const performanceResData = response?.data?.performanceData;
      setEmployeeData(response.data?.employeeData);
      setPerformanceData(performanceResData?.data);
      //Filter data to be prfilled
      setSearch(response?.data?.filtersData);
      //pagination
      setPerformancePageData((prev: any) => ({ ...prev, totalPages: performanceResData?.totalPages, totalRecords: performanceResData?.totalRecords, initialCall: false }));
      setEmployeePageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords, initialCall: false }));
      setPerformanceLoading(false);
      setLoading(false);
    }
    setShowPerformanceModal(false);
  }

  const downloadCSV = async (csvContent: any) => {
    // Create a Blob from the CSV string
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;

    // Define the filename
    link.setAttribute("download", "employee_performance.csv");

    // Append the link to the body
    document.body.appendChild(link)
      ;

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link)
      ;
    URL.revokeObjectURL(url);
  }

  const handleExport = async (value?: any, type?: string) => {
    if (userIdsToExport?.length == 0) {
      return;
    }
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getPerformanceSearchData(),
      initialCall: employeePageData?.initialCall,
      userId: userIdsToExport,
      partenaId: partenaId
    }
    const response = await ApiCall.service(EXPORT_PARTENA, "POST", postData, true, M_MASTER_DATA);

    if (response?.status === 200) {
      setUserPerformaneData(response.data);
      //pagination
      setModalLoading(false);
      if (userIdsToExport?.length > 0) {
        downloadCSV(response?.data?.original);
      }
      handleEmpPopUpPageRefresh();
      setUserIdsToExport([]);
    }
    handleModalClose();
  }

  const handleFieldChange = (event: any, data: EmployeeDataProps) => {
    const { checked } = event.target;
    if (checked && data?.userId) {
      setUserIdsToExport((prevData: any) => [...prevData, data?.userId]);
    } else {
      setUserIdsToExport((prevData: any) => prevData.filter((id: any) => id !== data?.userId));
    }

    setEmployeeData((prevData: any) =>
      prevData.map((employee: any) => employee.userId === data?.userId ?
        { ...employee, isExported: !employee.isExported }
        : employee));
  }


  const filtersToRepresentData = [
    { name: 'month', fieldType: "text", placeholder: "Month", filterType: 'search', default: search.month, isDisable: true },
    { name: 'businessUnit', fieldType: "text", placeholder: "Business unit", filterType: 'search', default: search.businessUnit, isDisable: true },
    { name: 'createdBy', fieldType: "text", placeholder: "Created by", filterType: 'search', default: search.createdBy, isDisable: true },
    { name: 'creationTime', fieldType: "text", placeholder: "Time of creation", filterType: 'search', default: search.creationTime, isDisable: true },
  ];


  const employeeFilters = [
    { name: 'employee', fieldType: "text", placeholder: "Search employee", filterType: 'search' },
    { name: 'user', fieldType: "text", placeholder: "Search user", filterType: 'search' },
  ];


  const performnaceFilters = [
    { name: 'perfEmployee', fieldType: "text", placeholder: "Search employee", filterType: 'search' },
    { name: 'perfDate', fieldType: "date", placeholder: "Date", filterType: 'search' },
    { name: 'perfDepartment', fieldType: "text", placeholder: "Department", filterType: 'search' },
    { name: 'perfType', fieldType: "text", placeholder: "Type", filterType: 'search' },
    { name: 'perfWageCode', fieldType: "text", placeholder: "Wage code", filterType: 'search' },
    { name: 'perfShiftCode', fieldType: "text", placeholder: "Shift code", filterType: 'search' },
    { name: 'perfHours', fieldType: "text", placeholder: "Hours", filterType: 'search' },
  ];

  const empPerformanceFilters = [
    { name: 'empPopUpEmployee', fieldType: "text", placeholder: "Search employee", filterType: 'search' },
    { name: 'empPopUpDate', fieldType: "date", placeholder: "Date", filterType: 'search' },
    { name: 'empPopUpDepartment', fieldType: "text", placeholder: "Department", filterType: 'search' },
    { name: 'empPopUpType', fieldType: "text", placeholder: "Type", filterType: 'search' },
    { name: 'empPopUpWageCode', fieldType: "text", placeholder: "Wage code", filterType: 'search' },
    { name: 'empPopUpShiftCode', fieldType: "text", placeholder: "Shift code", filterType: 'search' },
    { name: 'empPopUpHours', fieldType: "text", placeholder: "Hours", filterType: 'search' },
  ];

  const handleEmployeePageRefresh = () => setEmployeePageData((prev: any) => ({ ...prev, refresh: !employeePageData?.refresh }));

  const handleEmpPopUpPageRefresh = () => setEmpPopUpPageData((prev: any) => ({ ...prev, refresh: !empPopUpPageData?.refresh }));

  const handlePerformnacePageRefresh = () => setPerformancePageData((prev: any) => ({ ...prev, refresh: !performancePageData?.refresh }));

  return (
    <>
      {/* not required as disabled fields */}
      <div className="search-bar">
        <div className="d-flex align-items-center justify-content-between">
          <div className="w-75">
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filtersToRepresentData}
              handleRefresh={handleEmployeePageRefresh}
            />
          </div>
          <Button
            title={t("Calculate performance")}
            handleClick={(e) => hadlePopupModal()}
            className={"form-button shadow-none"}
          />
        </div>
      </div>

      <div className="d-grid marginTopPoint5 marginBottomPointTwo5 text-center cursor-pointer modal-title partenaCalculationTabs">
        <div className={`${status.employee ? "partenaTabsActive" : "partenaTabs"}`} onClick={(e) => handleClick(e.currentTarget.textContent)}>Employees</div>
        <div className={`${status.accomplishment ? "partenaTabsActive" : "partenaTabs"}`} onClick={(e) => handleClick(e.currentTarget.textContent)} >Performance</div>
      </div>
      {status.employee && employeeData && (<>
        <div className="d-flex w-50 justify-content-start marginTopPoint5">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={employeeFilters}
            handleRefresh={handleEmployeePageRefresh}
          />
        </div>
        <div className="position-relative tableMainWrapper mt-0">
          <div className="table-responsive managePartenaCalculation tableSection">
            <div className="managePartenaEmployeeHeight">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    {PartenaEmployeeTableHeader?.map((header: any) => (
                      <th key={header?.alias}>
                        <>
                          {t(header.name)}
                          {header?.issort && (
                            <SortAtomForManagePage
                              value={{ alias: header?.alias }}
                              dispatch={dispatch}
                              history={history}
                              updatePageFilters={updatePageFilters}
                              handleRefresh={handleEmployeePageRefresh}
                            />
                          )}
                        </>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(employeeData) && employeeData.length > 0 ? employeeData.map((data: EmployeeDataProps) => (
                    <>
                      <tr className="cursor-pointer" >
                        <td onClick={() => handleClick(data)}>{data.name}</td>
                        <td onClick={() => handleClick(data)}>{data.exportedBy}</td>
                        <td onClick={() => handleClick(data)}>{data.exportedTime}</td>
                        <td>{data.exportedFile}</td>
                        <td> {data?.isExported !== null && <CheckBoxField
                          name="export"
                          onChangeHandler={(e) => handleFieldChange(e, data)}
                          isChecked={data?.isExported ?? false}
                          lineHeight="1.7vw"
                        />}</td>
                      </tr >
                      {showModal && userPerfromaneData &&
                        <div className="w-75">
                          <ModalPopup
                            show={showModal}
                            onHide={() => setShowModal(false)}
                            title={t("Partena Details")}
                            body={
                              <>
                                <FilterOrganism
                                  dispatch={dispatch}
                                  history={history}
                                  updatePageFilters={updatePageFilters}
                                  filters={empPerformanceFilters}
                                  handleRefresh={handleEmpPopUpPageRefresh}
                                />
                                <div className="mt-0 table table-responsive">
                                  <div className="partenaDetailsPopup">
                                    <table className="table table-borderless">
                                      <thead>
                                        <tr className="TableHeader">
                                          {PartenaPerformanceTableHeader?.map((header: any) => (
                                            <th key={header?.alias}>
                                              <>
                                                {t(header.name)}
                                                {header?.issort && (
                                                  <SortAtomForManagePage
                                                    value={{ alias: header?.alias }}
                                                    dispatch={dispatch}
                                                    history={history}
                                                    updatePageFilters={updatePageFilters}
                                                    handleRefresh={handleEmpPopUpPageRefresh}
                                                  />
                                                )}
                                              </>
                                            </th>
                                          ))}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {userPerfromaneData.length > 0 ? userPerfromaneData.map((data: AccomplishData) => (
                                          <tr>
                                            <td>{data.employeeName}</td>
                                            <td>{data.date}</td>
                                            <td>{data.department}</td>
                                            <td>{data.type}</td>
                                            <td>{data.wageCode}</td>
                                            <td>{data.shiftCode}</td>
                                            <td>{data.hours}</td>
                                          </tr>
                                        )) : (
                                          <tr className="border">
                                            <td colSpan={10} className="border-0 text-center py-3">
                                              <span className="text-danger fw-bold">{t("No records")}</span>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                {
                                  !modalLoading && <div className="container-fluid partenaModalFooter">
                                    <PaginationWithPerPage
                                      handleRefresh={handlePerformnacePageRefresh}
                                      dispatch={dispatch}
                                      history={history}
                                      pageData={empPopUpPageData}
                                    />
                                  </div>
                                }
                              </>
                            }
                            onCloseButtonClick={handleModalClose}
                            onConfirmButtonClick={handleModalClose}
                            closeTitle="No"
                            confirmTitle="Yes"
                            modalClassName="partena-modal-width"
                          />
                        </div>

                      }
                    </>
                  )) : (
                    <tr className="border">
                      <td colSpan={10} className="border-0 text-center py-3">
                        <span className="text-danger fw-bold">{t("No records")}</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {
              !loading && status?.employee && <div className="">
                <PaginationWithPerPage
                  handleRefresh={handleEmployeePageRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={employeePageData}
                />
              </div>
            }

          </div>
          <div className="marginTopPoint5">
            <Button
              type="submit"
              title={t(`(${userIdsToExport?.length}) Export`)}
              handleClick={() => handleExport(employeeData, "export")}
              className="float-end form-button shadow-none"
            />
          </div>
        </div>
      </>)
      }

      {
        status.accomplishment &&
        (
          <>
            <div>
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={performnaceFilters}
                handleRefresh={handlePerformnacePageRefresh}
              />
            </div>
            <div className="position-relative tableMainWrapper">
              <div className="table-responsive managePartenaPerformanceCalculation tableSection">
                <div className="managePartenaPerformanceHeight">
                  <table className="table table-hover">
                    <thead>
                      <tr className="TableHeader">
                        {PartenaPerformanceTableHeader?.map((header: any) => (
                          <th key={header?.alias}>
                            <>
                              {t(header.name)}
                              {header?.issort && (
                                <SortAtomForManagePage
                                  value={{ alias: header?.alias }}
                                  dispatch={dispatch}
                                  history={history}
                                  updatePageFilters={updatePageFilters}
                                  handleRefresh={handlePerformnacePageRefresh}
                                />
                              )}
                            </>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {performanceData && performanceData.length > 0 ? performanceData.map((data: AccomplishData) => (
                        <tr>
                          <td>{data.employeeName}</td>
                          <td>{data.date}</td>
                          <td>{data.department}</td>
                          <td>{data.type}</td>
                          <td>{data.wageCode}</td>
                          <td>{data.shiftCode}</td>
                          <td>{data.hours}</td>
                        </tr>
                      )) : (
                        <tr className="border">
                          <td colSpan={10} className="border-0 text-center py-3">
                            <span className="text-danger fw-bold">{t("No records")}</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {
                  !performanceLoading &&
                  <PaginationWithPerPage
                    handleRefresh={handleEmpPopUpPageRefresh}
                    dispatch={dispatch}
                    history={history}
                    pageData={performancePageData}
                  />
                }
              </div>
            </div>
          </>
        )
      }
      {
        showPerformanceModal &&
        <ModalPopup
          show={showPerformanceModal}
          onHide={handlePerformanceModalClose}
          title="Recalculate?"
          body="Do you want to recalculate achievements not yet exported?"
          onCloseButtonClick={handlePerformanceModalClose}
          onConfirmButtonClick={() => handleCalculate("calculate")}
          closeTitle="No"
          confirmTitle="Yes"
        />
      }



    </>
  );
}


export default PartenaCalculation;