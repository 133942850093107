import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CLONE_CAR, PATH_CREATE_CAR, PATH_EDIT_CAR, PATH_PAYROLL_CONFIG_CREATE, PATH_VIEW_CAR } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
   CAR_DELETE,
   FLEET_EMP_DELETE,
   FLEET_EMP_CREATE,
   DELETE_PAYROLL_CONFIG,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { getFleetStatus, getFleetToolTip } from "services/util/UtilService";

import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import FilterOrganism from "components/organism/FilterOrganism";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { CarTableHeader, PayrollConfigHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import TextEllipsis from "components/atoms/TextEllipsis";
import RightSideBar from "pages/microservices/masterData/follow-up-todo/RightSideBar";
import TickIcon from "static/images/TickIcon";
import Title from "components/atoms/Title";

interface ManageCarProps {
   data?: any;
   dispatch: any;
   history: any;
   pageData: any;
   handleRefresh: () => void;
   dropDownData: any;
}

const ManagePayroll: React.FC<ManageCarProps> = ({
   data,
   dispatch,
   history,
   pageData,
   handleRefresh,
   dropDownData
}) => {
   const [id, setId] = useState<number | undefined>(undefined);
   const [showModal, setShowModal] = useState(false);
   const navigate = useNavigate();

   const handleSaveChanges = async () => {
      setShowModal(false);

      if (id) {
         let postData = {
            id: id,
         };
         const response = await ApiCall.service(DELETE_PAYROLL_CONFIG, 'POST', postData, true, M_MASTER_DATA);

         if (response?.status == 200) {
            CustomNotify({ type: "success", message: response.message });
            handleRefresh();
         } else {
            CustomNotify({ type: "error", message: response.message });
         }
      }
   };

   const handleModalClose = () => setShowModal(!showModal);

   const handleEdit = (id: number) => {
      navigate(`${PATH_PAYROLL_CONFIG_CREATE}/${id}`);
   };

   const handleDelete = (id: number) => {
      setShowModal(true);
      setId(id);
   };
   const userData = useSelector(selectAuth);
   const filters = [
      { name: 'company', fieldType: "singleSelect", options: dropDownData?.companies, placeholder: "Company", filterType: 'search' },
   ];

   return (
      <AccessControl
         requiredPermissions={[
            {
               permission: "Payroll config",
               read: true,
            },
         ]}
         renderNoAccess={true}
         override={userData.isSuperAdmin}
      >
         <>
            <div className="search-bar">
               <Title title={t("Manage payroll config")} />

            </div>

            <div className="position-relative tableMainWrapper">
               {/* <div className="">
                  <AccessControl
                     requiredPermissions={[
                        {
                           permission: "Payoll config",
                           create: true,
                        },
                     ]}
                     override={userData.isSuperAdmin}
                  >
                     <div className="row">
                        <div className="ManageCreateBtn">
                           <LinkTo
                              pagelink={PATH_CREATE_CAR}
                              title={t("Create payroll config")}
                              icon={faPlus}
                           />
                        </div>
                     </div>
                  </AccessControl>
               </div> */}

               <div className="table-responsive tableSection">
                  <div className="managePayrollFilter">
                     <FilterOrganism
                        dispatch={dispatch}
                        history={history}
                        updatePageFilters={updatePageFilters}
                        filters={filters}
                        handleRefresh={handleRefresh}
                     />
                  </div>
                  <div className="managePayrollFilter managePayrollTable">
                     <table className="table table-hover">
                        <thead>
                           <tr className="TableHeader">
                              {PayrollConfigHeader.map((header) => (
                                 <th key={header.alias}>
                                    <div className="d-flex align-items-center">
                                       <TextEllipsis title={t(header.name)} label={t(header.name)} />
                                       {header.issort && (
                                          <SortAtomForManagePage
                                             value={{ alias: header.alias }}
                                             dispatch={dispatch}
                                             history={history}
                                             updatePageFilters={updatePageFilters}
                                             handleRefresh={handleRefresh}
                                          />
                                       )}
                                    </div>
                                 </th>
                              ))}
                              <th>
                                 <AccessControl
                                    requiredPermissions={[
                                       {
                                          permission: "Payroll config",
                                          update: true,
                                          delete: true,
                                       },
                                    ]}
                                    actions={true}
                                    strict={false}
                                    override={userData.isSuperAdmin}
                                 >
                                    {t("Actions")}
                                 </AccessControl>
                              </th>
                           </tr>
                        </thead>
                        {/* if isStatus=false then enable all the options else give option for only status is 3 for other things disable that */}
                        <tbody>
                           {data && data.length > 0 ? (
                              data.map((record: any, key: any) => {
                                 return (
                                    <tr key={key}>
                                       <td className="align-middle text-break">
                                          {record?.label}
                                       </td>
                                       <td className="align-middle text-break">
                                          <TableActions
                                             isAction={true}
                                             handleEdit={handleEdit}
                                             // handleArchive={handleDelete}
                                             value={record?.companyId}
                                             permission={"Payroll config"}
                                          />
                                       </td>
                                    </tr>
                                 );
                              })
                           ) : (
                              <tr>
                                 <td colSpan={16} className="text-center">
                                    {t("No records found")}
                                 </td>
                              </tr>
                           )}
                        </tbody>
                     </table>
                  </div>
                  <div
                     className=""
                     style={{ paddingTop: "0.25vw" }}
                  >
                     <PaginationWithPerPage
                        handleRefresh={handleRefresh}
                        dispatch={dispatch}
                        history={history}
                        pageData={pageData}
                     />
                  </div>
               </div>
            </div>
            <ModalPopup
               show={showModal}
               onHide={handleModalClose}
               title={t("Archive confirmation")}
               body={t("Are you sure want to archive?")}
               onCloseButtonClick={handleModalClose}
               onConfirmButtonClick={handleSaveChanges}
               closeTitle="No"
               confirmTitle="Yes"
            />
         </>
      </AccessControl>
   );
};
export default ManagePayroll;