import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import ManageCar from "./ManageCar";
import { M_MASTER_DATA } from "constants/Constants";
import { CARS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";

// Define the prop types for the component
interface CarProps {
  id?: string | number;
  type?: string;
  setfunc?: any;
  dispatch?: any;
  history?: any;
}

// Functional component using TypeScript
const Car: React.FC<CarProps> = ({ type, setfunc, dispatch, history }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { id: routeId, userType: userType } = useParams();
  const [materialData, setMaterialData] = useState([]);
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });

  const getDate = () => {
    const currentDate = new Date();
    return `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1 < 10
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1
    }-${
      currentDate.getDate() < 10
        ? "0" + currentDate.getDate()
        : currentDate.getDate()
    }`;
  };

  const getSearchData = () => {
    return {
      ownerCompany: history?.filters?.ownerCompany ?? "",
      businessUnits: history?.filters?.businessUnits ?? [],
      brand: history?.filters?.brand ?? "",
      model: history?.filters?.model ?? "",
      carType: history?.filters?.carType ?? "",
      licencePlate: history?.filters?.licencePlate ?? "",
      userType: history?.filters?.userType ?? "",
      driver: history?.filters?.driver ?? "",
      chassisNumber: history?.filters?.chassisNumber ?? "",
      contractNumber: history?.filters?.contactNumber ?? "",
      startDate: history?.filters?.startDate ?? "",
      endDate: history?.filters?.endDate ?? "",
      fromDate: history?.filters?.fromDate ?? "",
      toDate: history?.filters?.toDate ?? getDate(),
      status: type == "fleet" ? [2, 6, 5] : history?.filters?.status ?? "",
      isRental: history?.filters?.isRental ?? false,
      location: history?.filters?.location ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
      isUserSpecific: type == "fleet" ? true : false,
    };
  };

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(
        history?.filters?.itemsPerPage ??
          process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ??
          20
      ),
      type: "car",
      userId: routeId ?? null,
      userType: userType ?? null,
      search: getSearchData(),
    };
    getCarData(postData);
  }, [pageData?.refresh, pageData?.initialCall]);

  const getCarData = async (postData: any) => {
    const response = await ApiCall.service(
      CARS,
      "POST",
      postData,
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      setMaterialData(response?.data?.data?.cars);
      const countData = response?.data?.data?.count;
      setfunc((prevTabs: any) => {
        return prevTabs.map((eachItem: any) => {
          if (Object.keys(countData).includes(eachItem.id)) {
            return { ...eachItem, ...countData[eachItem.id] };
          }
          return { ...eachItem };
        });
      });
      setPageData((prev: any) => ({
        ...prev,
        totalPages: response?.data?.totalPages,
        totalRecords: response?.data?.totalRecords,
      }));
    } else {
      console.log("error");
    }
  };
  const handleRefresh = () =>
    setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <>
      <Suspense
        fallback={<div className="text-center text-danger">{t("Loading")}</div>}
      >
        <ManageCar
          data={materialData}
          fleetProps={type}
          setMaterialData={setMaterialData}
          handleGetVacany={getCarData}
          dispatch={dispatch}
          history={history}
          handleRefresh={handleRefresh}
          pageData={pageData}
        />
      </Suspense>
    </>
  );
};

export default Car;
