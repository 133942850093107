import SelectWithSearch from "components/atoms/SelectWithSearch";
import React from "react";
import { t } from "../../translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { validateForm, validateRequired, validateSelectField } from "services/validation/ValidationService";
import Button from "components/atoms/Button";
import { LabelWithInputFieldAndUnit } from "../../templates/atoms/LabelWithInputFieldAndUnit";
import { calculateBtw, calculateSubTotal, calculateTotal } from "../utils/utils";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { CREATE_INVOICE_RULES } from "routes/ApiEndpoints";
import { useParams } from "react-router-dom";

const NewRulePopUp = (props: any) => {

    const params = useParams();

    const handleFieldChange = (e: any, name: any) => {
        let value = e?.target?.value ?? e;
        switch (name) {
            case 'btw':
            case 'salesaccount':
                value = value?.value;
                break;

            case 'quantity':
            case 'price':
                value = value?.replace(/[^0-9.,]/g, '') // Remove all characters except digits, dot, and comma
                    .replace(/(\,.*)\./g, '$1'); // Allow only one dot
                break;

            default:
                break;
        }

        const errors: any = validateInput(name, value, true);

        props?.setTabData((prev: any) => ({
            ...prev, popup: {
                ...prev?.popup,
                errors: { ...prev?.popup?.errors, [name]: errors },
                data: { ...prev?.popup?.data, [name]: value },
            }
        }));
    }

    const validateInput = (name: string | any, value: string | boolean | any, isSingleFieldValidation: boolean = false) => {
        const validationRules: any = {
            quantity: [validateRequired],
            price: [validateRequired],
            btw: [validateSelectField],
            salesaccount: [validateSelectField],
            description: [validateRequired],
        };

        // Validate the form data based on the rules
        const validationErrors = validateForm({ ...props?.tabData?.popup?.data, [name]: value }, validationRules);

        // Update errors state based on the validation results
        if (isSingleFieldValidation) {
            return validationErrors[name] ?? "";
        } else {
            props?.setTabData((prev: any) => ({
                ...prev, popup: {
                    ...prev?.popup,
                    errors: { ...prev?.popup?.errors, ...validationErrors },
                }
            }));
        }

        // Check if there are any validation errors
        return !(Object?.keys(validationErrors).length > 0)
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const isFormValid = validateInput("quantity", props?.tabData?.popup?.data?.quantity);
        if (isFormValid) {
            let ruleData = [...props?.tabData?.ruleData];
            let updateData = {
                description: props?.tabData?.popup?.data?.description,
                number: props?.tabData?.popup?.data?.quantity,
                price: props?.tabData?.popup?.data?.price,
                vatrateid: props?.tabData?.popup?.data?.btw,
                standard_account_id: props?.tabData?.popup?.data?.salesaccount,
                ruletype: false,
            }
            let totalAmt: any = null;

            if (params?.id) {
                try {
                    const tabData = props?.tabData?.ruleData;
                    const totalAmount = getCostFunction({ number: updateData?.number, price: updateData?.price, vatrateid: updateData?.vatrateid });
                    const data = { id: tabData[props?.tabData?.popup?.data?.index]?.id ?? null, ...updateData, total_price: totalAmount };
                    const response = await ApiCall.service(
                        CREATE_INVOICE_RULES,
                        "POST",
                        { rulesData: data, invoiceData: { id: params?.id } },
                        true,
                        M_MASTER_DATA,
                    );
                    if (response?.status == 200) {
                        props?.setTabData((prev: any) => ({
                            ...prev, initialCall: false, popup: { ...prev?.popup, name: '', data: null }
                        }));
                    }
                }
                catch (Error) {
                    console.log(Error);
                }
            }
            else {
                if (props?.tabData?.popup?.data?.index !== null && props?.tabData?.popup?.data?.index !== undefined) {

                    const { price, vatrateid, number } = ruleData[props?.tabData?.popup?.data?.index];

                    totalAmt = getCostFunction({ number, price, vatrateid });
                    totalAmt = parseFloat((totalAmt)?.toString().replace(/\./g, '').replace(/,/g, '.'))

                    ruleData[props?.tabData?.popup?.data?.index] = updateData;
                } else {
                    ruleData?.push(updateData);
                }

                const totalAmount = getCostFunction({ number: updateData?.number, price: updateData?.price, vatrateid: updateData?.vatrateid });

                props?.setTabData((prev: any) => {
                    let prevTotal = parseFloat((prev?.tobepaid)?.toString().replace(/\./g, '').replace(/,/g, '.'));
                    prevTotal = totalAmt !== null ? (prevTotal - totalAmt) : prevTotal;

                    return {
                        ...prev, ruleData, calcRefresh: !props?.tabData?.calcRefresh,
                        popup: { name: '', data: null, errors: null },
                        tobepaid: Math.round(prevTotal + totalAmount
                        ),
                    }
                })
            }

        }
    }

    const getCostFunction = (costData: any) => {
        const { number, price, vatrateid } = costData;
        const vatAmount = props?.dropdownData?.standardVatRates?.find((eachRate: any) => eachRate?.value == vatrateid);

        const subTotal = calculateSubTotal({ number, price });

        const btwAmount = calculateBtw({ subtotal: subTotal, vatrate: vatAmount?.percentage });
        const totalAmount = calculateTotal({ subtotal: subTotal, btw: btwAmount });
        return totalAmount;
    }

    return (
        <>
            <div className="d-grid grid-col-2">
                <div className="col">
                    <LabelWithInputField
                        isMandatory
                        name="quantity"
                        handleChange={(e) => handleFieldChange(e, 'quantity')}
                        id="quantity"
                        label={t("Quantity")}
                        type="text"
                        value={props?.tabData?.popup?.data?.quantity}
                        error={props?.tabData?.popup?.errors?.quantity}
                    />
                </div>
                <div className="col">
                    <LabelWithInputFieldAndUnit
                        isMandatory
                        name="price"
                        handleChange={(e) => handleFieldChange(e, 'price')}
                        id="price"
                        label={t("Price")}
                        type="text"
                        value={props?.tabData?.popup?.data?.price}
                        error={props?.tabData?.popup?.errors?.price}
                        unit={"€"}
                        containerClassName="col-12"
                    />
                </div>
                <div className="col">
                    <SelectWithSearch
                        title={t("Btw")}
                        isMandatory
                        search
                        options={props?.dropdownData?.standardVatRates}
                        onChange={(e) => handleFieldChange(e, "btw")}
                        name="btw"
                        value={props?.tabData?.popup?.data?.btw}
                        error={props?.tabData?.popup?.errors?.btw}
                        placeHolder="Select"
                    />
                </div>
                <div className="col">
                    <SelectWithSearch
                        title={t("Sales account")}
                        isMandatory
                        search
                        options={props?.dropdownData?.standardSalesAccounts}
                        onChange={(e) => handleFieldChange(e, "salesaccount")}
                        name="project"
                        value={props?.tabData?.popup?.data?.salesaccount}
                        error={props?.tabData?.popup?.errors?.salesaccount}
                        placeHolder="Select"
                    />
                </div>
            </div>
            <div>
                <div className="col">
                    <LabelWithTextAreaField
                        isMandatory
                        name="description"
                        handleChange={(e) => handleFieldChange(e, "description")}
                        label={t("Description")}
                        value={props?.tabData?.popup?.data?.description}
                        error={props?.tabData?.popup?.errors?.description}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-between backPadding align-items-center">
                <Button
                    title={t("Cancel")}
                    handleClick={props?.handleClosePopup}
                    className="close-button float-end shadow-none"
                />
                <Button
                    title={t("Save")}
                    handleClick={handleSubmit}
                    className="form-button float-end shadow-none"
                />
            </div>
        </>
    );
};

export default NewRulePopUp;
