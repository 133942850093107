import CustomNotify from "components/atoms/CustomNotify";
import { M_MASTER_DATA } from "constants/Constants";
import { GET_PROJECT_OPTIONS_FOR_MARGIN } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";

export const customFunction = async (dataObj: any) => {
  console.log("paylods=>", dataObj);
  try {
    const response = await ApiCall.service(
      GET_PROJECT_OPTIONS_FOR_MARGIN,
      "POST",
      { data: dataObj },
      false,
      M_MASTER_DATA
    );
    if (response?.status == 200) {
      return response.data;
    } else {
      CustomNotify({ type: "warning", message: response?.message });
    }
  } catch (error) {
    console.log("Error");
  }
};
