import React, { useEffect, useState, useRef } from "react";
import PlanningBox from "../atoms/planningBox";
import { useEmployee } from "../planning-redux/context/EmployeeContext/EmployeeContext";
import { usePlanning, usePlanningsDispatch } from "../planning-redux/context/PlanningContext/PlanningContext";
import { PLANNING_MODAL_ACTIONS } from "../planning-redux/actions/PlanningModalActions";
import { getRandomInt, renderPlannedEvents } from "../utils/utils";
import { usePlanningsModalDispatch } from "../planning-redux/context/PlanningModalContext/PlanningModalContext";
import { SALES_VIEW_EXCEPTION_LIST } from "../constants/planningConstants";
import { PLANNING_ACTIONS } from "../planning-redux/actions/PlanningActions";
import { useFilterOptions } from '../planning-redux/context/FiltersContext/FiltersContext';
import CustomNotify from "components/atoms/CustomNotify";
import { getTitle } from "../utils/utils";
import Popup from "components/molecules/Popup";
import { t } from "pages/microservices/masterData/translation/Translation";

const PlanningWeekly = ({ weeklyDates, openPlanningModal, savePlanning, type, status }) => {
  const { initialEmployeeState } = useEmployee();
  const { filterOptionsState } = useFilterOptions();
  const { planningState } = usePlanning();
  const planningOverview = planningState.planningOverview.value;
  const { dispatchPlanning } = usePlanningsDispatch();
  const { dispatchPlanningModal } = usePlanningsModalDispatch();
  const [highlightedDiv, setHighlightedDiv] = useState(null);
  const cardRef = useRef(null);
  const [popup, setPopup] = useState(false)
  const [detailslist, setDetails] = useState({
    employee_id: null,
    date: null,
    plan: null
  });
  const [contextMenuId, setContextMenuId] = useState(null);
  let clickTimer = null;
  const [plandet, setPlanDet] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState("bottom right");

  const setMenuPosition = (e) => {
    if (cardRef.current) {
      const { top, bottom, left, right } = cardRef.current.getBoundingClientRect();
      const spaceBelow = top - bottom;
      const spaceAbove = top;
      const spaceRight = right - e.clientX;
      const spaceLeft = e.clientX - left;
      let classname = '';
      // Convert pixel values to vw
      const spaceBelowVw = (spaceBelow / window.innerWidth) * 100;
      const spaceAboveVw = (spaceAbove / window.innerWidth) * 100;
      const spaceRightVw = (spaceRight / window.innerWidth) * 100;
      const spaceLeftVw = (spaceLeft / window.innerWidth) * 100;
    
      if (spaceBelowVw < 30 && spaceAboveVw > 10) { // 500px ≈ 25vw, 200px ≈ 10vw
        classname = "top";
      } else {
        classname = "bottom";
      }

      if (spaceRightVw < 10 && spaceLeftVw > 10) { // 200px ≈ 10vw
        classname += " left";
      } else {
        classname += " right";
      }
      setTooltipPosition(classname)
    }
  }

  useEffect(() => {
    const container = document.getElementById('weeklyPlaniingTable-mouse-events');
    if (container) {
      container.tabIndex = 0;
      container.addEventListener('keydown', handleKeyDown);
      container.focus();
    }
    return () => {
      if (container) {
        container.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [highlightedDiv]);



  const handleClick = (e, id, employeeid, date, plan = null) => {
    e.preventDefault()
    clearTimeout(clickTimer);
    setContextMenuId(null);
    setPlanDet(null)

    let userdet = planningState.planningEvents[employeeid]
    if ((planningOverview == 'project' && planningState.planningEvents[employeeid].type == "company_overview") ||
      (SALES_VIEW_EXCEPTION_LIST.includes(employeeid))) return;
    setPlanDet(userdet)

    clickTimer = setTimeout(() => {

      let empid = employeeid
      employeeid = userdet.type == "project_overview" ? null :
        (userdet.type == "employee_overview" ? userdet.employer_user_id : employeeid)
      let projectid = userdet.employer_project_id != undefined ? userdet.employer_project_id : null
      let companyid = userdet.employer_company_id != undefined ? userdet.employer_company_id : null

      updateBulkPlanningOptions(employeeid, date, id, plan, projectid, companyid)
      setHighlightedDiv(id);
      setDetails({
        ...detailslist,
        employee_id: empid,
        plan: plan,
        date: date
      })
    }, 250);
  };

  const handleRightClick = (e, id, employeeid, date, plan = null) => {
    e.preventDefault();

    let userdet = planningState.planningEvents[employeeid]
    if ((planningOverview == 'project' && planningState.planningEvents[employeeid].type == "company_overview") ||
      (SALES_VIEW_EXCEPTION_LIST.includes(employeeid))) return;

    let empid = employeeid
    employeeid = userdet.type == "project_overview" ? null :
      (userdet.type == "employee_overview" ? userdet.employer_user_id : employeeid)
    let projectid = userdet.employer_project_id != undefined ? userdet.employer_project_id : null
    let companyid = userdet.employer_company_id != undefined ? userdet.employer_company_id : null

    updateBulkPlanningOptions(employeeid, date, id, plan, projectid, companyid)
    setHighlightedDiv(id);
    setDetails({
      ...detailslist,
      employee_id: empid,
      plan: plan,
      date: date
    })
    setMenuPosition(e)
    setContextMenuId(id);
  };


  const handleKeyDown = (e) => {
    e.preventDefault();
    // if (!highlightedDiv) return;
    switch (true) {
      case e.ctrlKey && e.key === 'c':
        handleCopy(e)
        break;
      case e.ctrlKey && e.key === 'v':
        handlePaste(e)
        break;
      case e.key === 'Delete':
        handleDelete(e)
        break;
      default:
        break;
    }
  };

  const handleWeeklyClickEventOnPlannedUnit = (
    e, employee_id, date, id, plan
  ) => {
    clearTimeout(clickTimer);
    if ((planningOverview == 'project' && planningState.planningEvents[employee_id].type == "company_overview") ||
      (SALES_VIEW_EXCEPTION_LIST.includes(employee_id))) return;
    clearStates()
    createNewPlanning(e, employee_id, date, plan)
  };

  const createNewPlanning = (e, employee_id, date, plan) => {
    let userdet = planningState.planningEvents[employee_id]
    employee_id = userdet.type == "project_overview" ? null :
      (userdet.type == "employee_overview" ? userdet.employer_user_id : employee_id)
    let payload = {
      employee_id: employee_id,
      start_date: date,
      frequency: "weekly"
    }
    if (planningState.planningOverview.value == 'project') {
      let projectid = userdet.employer_project_id != undefined ? { value: userdet.employer_project_id } : null
      let companyid = userdet.employer_company_id != undefined ? { value: userdet.employer_company_id } : null
      payload = {
        ...payload,
        company: companyid,
        project: projectid
      }
    }
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODAL_DEPENDENT_FIELD,
      payload: payload,
    });
    if (plan != "" && plan != null) {
      updatePlanningModalId(plan.planid)
    }
    let planid = plan != null ? plan.planid : null;
    openPlanningModal(e, planid);
  }

  const updatePlanningModalId = (planid) => {
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.UPDATE_PLANNING_MODEL_FIELD,
      payload: {
        field: "id",
        value: planid,
      },
    });
  }

  const updateBulkPlanningOptions = (employee, date, id, plan, ...args) => {
    let payload = {
      plan: plan,
      id: id,
      employee: employee,
      date: date,
      project: args[0],
      company: args[1]
    }
    dispatchPlanning({
      type: PLANNING_ACTIONS.UPDATE_CELL_STATE,
      payload: payload,
    });
  }

  const handleAction = (e, type, plan) => {
    e.preventDefault();
    clearStates()
    switch (type) {
      case 'new':
        createNewPlanning(e, detailslist.employee_id, detailslist.date, null)
        break;
      case 'copy':
        handleCopy(e)
        break;
      case 'delete':
        handleDelete(e)
        break;
      case 'paste':
        handlePaste(e)
        break;
      default:
        break;
    }
  }

  const handleCopy = (e) => {
    e.preventDefault();
    let celstate = planningState.cellState
    if (celstate.plan == null) return;
    let payload = {
      copydetails: celstate.plan
    }
    planningActionsState('UPDATE_MUTIPLE_FIELDS', payload)
    let companyname = getTitle(filterOptionsState.state.companies, celstate.plan.company_id)
    let projectname = getTitle(filterOptionsState.state.projects, celstate.plan.project_id)
    let employeename = getTitle(initialEmployeeState.employees, celstate.employee, null, 'id', 'name')

    let message = (
      <div>
        <div>Planning copied:</div>
        <div>{projectname}</div>
        <div>{celstate.plan.plan_date}</div>
        <div>{`${celstate.plan.start_time} - ${celstate.plan.end_time}`}</div>
        <div></div>
        <div>{employeename}</div>
      </div>
    );
    CustomNotify({ type: "success", message: message });
  }

  const handlePaste = (e) => {
    if (planningState.copydetails == null) return;
    savePlanning(e, null, null, true)
  }

  const handleDelete = (e) => {
    let celstate = planningState.cellState
    if (celstate.plan == null) return;
    setPopup(true)
  }

  const closeModal = (e, type) => {
    setPopup(false);
    if (type == 'nothing') return;
    let celstate = planningState.cellState
    let deleteall = type == "yes" ? "yes" : "no"
    updatePlanningModalId(celstate.plan.planid)
    savePlanning(e, deleteall, celstate.plan.planid)
  }

  const planningActionsState = (type, payload) => {
    dispatchPlanning({
      type: PLANNING_ACTIONS[type],
      payload: payload,
    });
  }
  const clearStates = () => {
    setHighlightedDiv(null);
    setContextMenuId(null);
  }

  const handleMouseEnter = (e) => {
    setMenuPosition(e)
  };

  const handleMouseLeave = () => {
  };

  const handleEvents = (type, e, id, employee_id, date, eachplan) => {
    switch (type) {
      case "singleclick":
        handleClick(e, id, employee_id, date, eachplan)
        break;
      case "doubleclick":
        handleWeeklyClickEventOnPlannedUnit(e, employee_id, date, id, eachplan)
        break;
      case "rightclick":
        handleRightClick(e, id, employee_id, date, eachplan)
        break;
      case "mousehover":
        handleMouseEnter(e)
        break;
      case "mouseblur":
        handleMouseLeave(e)
        break;
      default:
        break;
    }
  }

  const setWeeklyPlanningUnit = () => {
    if (
      initialEmployeeState.employees &&
      initialEmployeeState.employees.length > 0
    ) {
      return initialEmployeeState.employees.map((employee) => (
        <tr key={getRandomInt(1, 1000)} className={(employee.id == 'total' ? "planningCostSticky" : "")}>
          {/* <td></td> */}
          {weeklyDates.map((date) => (
            <td
              key={getRandomInt(1, 1000)}
              style={{
                position: "relative",
              }}
            >
              <PlanningBox
                key={getRandomInt(1, 1000)}
                date={date["value"]}
                employee_id={employee.id}
                bg_color={"#ebebeb"}
                handleEvents={handleEvents}
                highlightedDiv={highlightedDiv}
                handleAction={handleAction}
                tooltipPosition={tooltipPosition}
                contextMenuId={contextMenuId}
              />
              {renderPlannedEvents(
                planningState,
                employee,
                date,
                highlightedDiv,
                handleEvents,
                handleAction,
                contextMenuId,
                tooltipPosition
              )}
            </td>
          ))}
          <td></td>
        </tr>
      ));
    }
  };

  return (
    <>
      <table className="table border border-white table-bordered weeklyPlaniingTable mb-0" id="weeklyPlaniingTable-mouse-events">
        <tbody ref={cardRef} >{setWeeklyPlanningUnit()}</tbody>
      </table>
      {popup && (
        <Popup
          title={
            `${t("Delete plan(s)")}`
          }
          modalclass="planningPopupModal"
          body={"Do you want to delete all upcoming plans"}
          cancel={(e) => closeModal(e, "nothing")}
          modalSize="lg"
          extraButtonText="No"
          extraButtonFunction={(e) => closeModal(e, "no")}
          bodyclassName="text-start"
          yestext={"Yes"}
          submit={(e) => closeModal(e, "yes")}
        />
      )}
    </>
  );
};

export default PlanningWeekly;
