import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { M_MASTER_DATA } from "constants/Constants";
import { MANAGE_COMPANY_PAYROLL_CONFIG } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import ManagePayroll from "./ManagePayroll";

// Define the prop types for the component
interface PayrollProps {
    id?: string | number;
    dispatch?: any;
    history?: any;
}

// Functional component using TypeScript
const ManagePayrollPage: React.FC<PayrollProps> = ({ dispatch, history }) => {
    const [payrollData, setPayrollData] = useState([]);
    const [dropDownData, setDropDownData] = useState({
        companies: [],
    });
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        initialCall: true,
        refresh: true,
    });

    const getSearchData = () => {
        return {
            company: history?.filters?.company ?? "",
        }
    };

    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
            search: getSearchData(),
            initialCall: pageData?.initialCall,
        };
        getCarData(postData);
    }, [pageData?.refresh, pageData?.initialCall]);

    const getCarData = async (postData: any) => {
        const response = await ApiCall.service(MANAGE_COMPANY_PAYROLL_CONFIG, "POST", postData, true, M_MASTER_DATA);
        if (response?.status === 200) {
            setPayrollData(response?.data?.manageData);
            // if (pageData?.initialCall) {
            setDropDownData((prevState: any) => ({ ...prevState, ...response?.data?.dropdownData }));
            // }
            setPageData((prev: any) => ({
                ...prev,
                totalPages: response?.data?.totalPages,
                totalRecords: response?.data?.totalRecords, initialCall: false
            }))
        } else {
            console.log("error");
        }
    };
    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    return (
        <>
            <Suspense
                fallback={
                    <div className="text-center text-danger">{t("Loading")}</div>
                }
            >
                <ManagePayroll
                    data={payrollData}
                    dispatch={dispatch}
                    history={history}
                    handleRefresh={handleRefresh}
                    dropDownData={dropDownData}
                    pageData={pageData}
                />
            </Suspense>
        </>
    );
};

export default ManagePayrollPage;

