import { MANAGE_WAGE_PROPOSAL } from "constants/Paths";

export const PAGENAMES = {
  ACCESS_DENIED: "",
  PAGE_NOT_FOUND: "",
  HOME_PAGE: "Home",
  DASHBOARD: "Home",
  MANAGE_PERMISSION: "Permissions",
  CREATE_PERMISSION: "Create permission",
  EDIT_PERMISSION: "Edit permission",
  MANAGE_ROLE: "Roles",
  CREATE_ROLE: "Create role",
  EDIT_ROLE: "Edit role",
  CLONE_ROLE: "Clone role",
  MANAGE_USER: "Users",
  CREATE_USER: "Create user",
  EDIT_USER: "Edit user",
  VIEW_USER: "View user",
  CREATE_EMAIL_TEMPLATE: "Create email tempalte",
  MANAGE_EMAIL_TEMPLATE: "Email tempaltes",
  EDIT_EMAIL_TEMPLATE: "Edit email tempalte",
  VIEW_EMAIL_TEMPLATE: "View email tempalte",
  MANAGE_COMPANY: "Companies",
  CREATE_LOCATION: "Create location",
  MANAGE_LOCATION: "Locations",
  CREATE_CONTACT_PERSON: "Create contact person",
  MANAGE_CONTACT_PERSON: "Contact persons",
  EDIT_CHECKLIST: "Edit checklist",
  VIEW_CHECKLIST: "View checklist",
  MANAGE_CHECKLIST: "Checklist",
  MASTER_DATA: "Configuration and settings",
  MANAGE_PROFILE_FUNCTION: "Manage profile function",
  ADD_PROFILE_FUNCTION: "Add profile function",
  EDIT_PROFILE_FUNCTION: "Edit profile function",
  VIEW_PROFILE_FUNCTION: "View profile function",
  EDIT_LOW_COEFFICIENTS: "Edit low coefficients",
  ADD_LOW_COEFFICIENTS: "Add low coefficients",
  MANAGE_LOW_COEFFICIENTS: "Manage low coefficients",
  ADD_CITY_PROFILE: "Add city profile",
  EDIT_CITY_PROFILE: "Edit city profile",
  VIEW_CITY_PROFILE: "View city profile",
  MANAGE_CITY_PROFILE: "Manage city profile",
  EXTRA: "Extra",
  MANAGE_EXTRA: "Manage extra",
  INFLATION_COEEFICIENTS: "Inflation coefficients",
  CREATE_COOPERATION_AGREEMENT: "Create cooperation agreement",
  ADD_INFLATION: "Add inflation",
  EDIT_INFLATION: "Edit inflation",
  VIEW_INFLATION: "View inflation",
  MANAGE_INFLATION: "Manage inflation",
  MANAGE_EMPLOYEE_TYPE: "Employee types",
  CREATE_EMPLOYEE_TYPE: "Create employee type",
  MANAGE_BU: "Business units",
  CREATE_BU: "Create business unit",
  EDIT_BU: "Edit business unit",
  MANAGE_TAGS: "Manage tag",
  CREATE_TAGS: "Create tag",
  MANAGE_SHIFT: "Shifts",
  CREATE_SHIFT: "Create shift",
  EDIT_SHIFT: "Edit shift",
  MANAGE_PROJECT: "Projects",
  PROJECT_PROPOSAL: "Project proposal",
  MANAGE_PROJECT_PROPOSAL: "Manage project proposal",
  MANAGE_COMPANY_CORNER: "Manage company corner",
  MANAGE_PRPJECT_CORNER: "Manage project corner",
  CREATE_PROJECT: "Create project",
  EDIT_PROJECT: "Edit project",
  VIEW_PROJECT: "View project",
  CREATE_COMPANY: "Create company",
  EDIT_COMPANY: "Edit project",
  VIEW_COMPANY: "View project",
  MANAGE_PROJECT_TEMPLATE: "Project templates",
  EDIT_PROJECT_TEMPLATE: "Edit project template",
  VIEW_PROJECT_TEMPLATE: "View project template",
  PROPOSAL_AGREEMENT: "Proposal agreement",
  PROPOSAL_AGREEMENT_MANAGER: "Proposal agreement to manager",
  MANAGE_EMPLOYEE: "Employees",
  CREATE_EMPLOYEE: "Create employee",
  EDIT_EMPLOYEE: "Edit employee",
  MANAGE_PC: "Paritair committees",
  CREATE_PC: "Create PC",
  EDIT_PC: "Edit PC",
  CLONE_PC: "Clone PC",
  PC_SALARY_BENEFITS: "Pc salary benefits",
  MANAGE_FUNCTION_DOCUMENTS: "Manage function documents",
  MANAGE_SALARY_BENIFITS: "Salary benefits",
  CREATE_SALARY_BENIFITS: "Create salary benefits",
  CLONE_SALARY_BENIFITS: "Clone salary benefits",
  VIEW_SALARY_BENIFITS: "View Salary benefits",
  MANAGE_CONFIG_ELEMENTS: "Config elements",
  CREATE_CONFIG_ELEMENTS: "Create config elements",
  MY_PROFILE: "My profile",
  MANAGE_COMPETENCE: "Competence",
  CREATE_COMPETENCE: "Create competence",
  EDIT_COMPETENCE: "Edit competence",
  VIEW_COMPETENCE: "View competence",
  MANAGE_VACANCY: "Vacancies",
  CREATE_VACANCY: "Create vacancy",
  VACANCY_OVERVIEW: "Vacancy overview",
  CLONE_VACANCY: "Clone vacancy",
  MANAGE_TIMETABLE: "Timetable",
  CREATE_TIMETABLE: "Create timetable",
  EDIT_TIMETABLE: "Edit timetable",
  APPLICATION_CONFIGURATION: "Application configuration",
  EDIT_APPLICATION_CONFIGURATION: "Edit application configuration",
  MATCHING_PROJECT: "Matching project",
  EMPLOYEE_MATCHING: "Matching employee",
  MANAGE_HOTLIST: "Hotlist",
  MANAGE_DOCUMENTS: "Documents",
  MANAGE_QUESTION: "Questions",
  ADD_QUESTION: "Add question",
  EDIT_QUESTION: "Edit question",
  CLONE_QUESTION: "Clone question",
  VIEW_QUESTION: "View question",
  CANDIDATE_SCREENING: "Candidate screening",
  MANAGE_CANDIDATES: "Candidates",
  MANAGE_SCREENING: "Screening",
  SCREENING_ASSESSMENT: "Screening assessment",
  APPLICANT_SCREENING: "Applicant screening",
  HR_PLANNING: "HR planning",
  SALES_PLANNING: "Sales planning",
  WAGE_PROPOSAL: "Wage proposal",
  CREATE_CONTRACT: "Create contract",
  CONTRACT_OVERVIEW: "Contract overview",
  CREATE_CANDIDATE: "Create candidate",
  EDIT_CANDIDATES: "Edit candidate",
  REFUSED_COMPANIES: "Refused company",
  CADIDATE_DETAILS: "Candidate details",
  EMPLOYEE_DETAILS: "Employee details",
  CREATE_HOLIDAY_CODE: "Create holiday code",
  EDIT_HOLIDAY_CODE: "Edit holiday code",
  HOLIDAY_CONFIG: "Holiday config",
  CREATE_PUBLIC_HOLIDAY: "Create public holiday",
  EDIT_PUBLIC_HOLIDAY: "Edit public holiday",
  MANAGE_PUBLIC_HOLIDAYS: "Public holidays",
  MANAGE_HOLIDAY_CODES: "Holiday codes",
  MANAGE_HOLIDAY_PER_PC: "PC Holiday codes",
  LINK_HOLIDAY_PER_PC: "Link holiday per PC",
  EDIT_LINK_HOLIDAY_PER_PC: "Edit link holiday per PC",
  CREATE_TODO: "Create todo",
  VIEW_TODO: "View todo",
  MANAGE_TODO: "Todos",
  ADD_CONTRACT_TEMPLATE: "Add contract template",
  MANAGE_CONTRACT_TEMPLATE: "Contract templates",
  VIEW_CONTRACT_TEMPLATE: "View contract template",
  CLONE_CONTRACT_TEMPLATE: "Clone contract template",
  APPLY_LEAVE: "Apply leave",
  VIEW_LEAVES: "View leave",
  EDIT_LEAVES: "Edit leave",
  MANAGE_EMPLOYEE_LEAVES: "Employee leaves",
  EMPLOYEE_LEAVE_COUNT: "Employee leave count",
  MANAGE_EMPLOYER_LEAVES: "Employer leaves",
  EMPLOYER_LEAVE_COUNT: " Employer leave counts",
  MANAGE_TIMESHEET: "Timesheet overview",
  MANAGE_TIMESHEET_OVERVIEW: "Timesheets",
  FLEETANDMATERIAL: "Fleet and material",
  CREATE_MATERIAL: "Create material",
  EDIT_MATERIAL: "Edit material",
  VIEW_MATERIAL: "View material",
  MANAGE_MATERIAL: "Materials",
  MANAGE_CAR: "Cars",
  CREATE_CAR: "Create car",
  EDIT_CAR: "Edit car",
  CLONE_CAR: "Clone car",
  VIEW_CAR: "View car",

  MANAGE_CLOTHS: "Clothes",
  CREATE_CLOTHS: "Create clothes",
  EDIT_CLOTHS: "Edit clothes",
  MANAGE_SUPPLIER: "Suppliers",
  CREATE_SUPPLIER: "Create supplier",
  EDIT_SUPPLIER: "Edit supplier",
  VIEW_SUPPLIER: "View supplier",

  MANAGE_CLIENT: "Clients",
  CREATE_CLIENT: "Create client",
  EDIT_CLIENT: "Edit client",
  VIEW_CLIENT: "View client",

  MANAGE_PURCHASE_ORDER: "Purchase orders",
  CREATE_PURCHASE_ORDER: "Create purchase order",
  VIEW_PURCHASE_ORDER: "View purchase order",
  EDIT_PURCHASE_ORDER: "Edit purchase order",
  FLEET_OVERVIEW: "Fleet overview",
  EMPLOYEE_FLEET_OVERVIEW: "Employee fleet overview",
  MANAGE_FLEET_LOCATION: "Fleet location",
  CREATE_FLEET_LOCATION: "Create fleet location",
  EDIT_FLEET_LOCATION: "Edit fleet location",
  PAY_ROLL_OVERVIEW: "Payroll overview",
  MANAGE_PAY_ROLL: "Payroll",
  MANAGE_TEMP_AGENCY: "Temp agency",
  CREATE_TEMP_AGENCY: "Create temp agency",
  EDIT_TEMP_AGENCY: "Edit temp agency",
  TEMP_AGENCY_OVERVIEW: "Temp agency overview",
  MANAGE_COEFF_DEVIATION: "Coefficient deviations",
  EDIT_COEEFICIENT: "Edit coefficient",
  MANAGE_WAGE_ELEMENT: "Wage elements",
  MANAGE_TRANSLATIONS: "Translations",
  MARGIN_CLALCULATOR: "Margin calculator",
  MANAGE_PARTENA: "Partena",
  ADD_PARTENA: "Add partena",
  PARTENA_CALCULATIONS: "Partena calculations",
  MANAGE_APPLICANT: "Applicants",
  CREATE_APPLICANT: "Create applicant",
  VIEW_APPLICANT: "View applicant",
  APPLICANT_CORNER: "Applicant corner",
  CANDIDATE_CORNER: "Candidate corner",
  CREATE_WAGE_PROPOSAL: "Create wage proposal",
  MANAGE_WAGE_PROPOSAL: "Wage proposals",
  MANAGE_CONTRACT_PROPOSAL: "Contract proposals",
  CREATE_CONTRACT_PROPOSAL: "Create contract proposal",
  MANAGE_DOCUMENT_TYPE: "Document types",
  CREATE_DOCUMENT_TYPE: "Create document type",
  EDIT_DOCUMENT_TYPE: "Edit document type",
  JOB_APPLICATION_OVERVIEW: "Job application overview",
  CREATE_TEMP_AGENCY_GENERAL: "Create temp agency general",
  EDIT_TEMP_AGENCY_GENERAL: "Edit temp agency general",
  CLONE_TEMP_AGENCY_GENERAL: "Clone temp agency general",
  VIEW_TEMP_AGENCY_GENERAL: "View temp agency general",
  PATH_TEMPLATE_NOT_FOUND: "Template not found",
  MANAGE_COOPERATION_AGREEMENT: "Manage cooperation agreement",
  CREATE_MARGIN_CONFIG_TYPE: "Create margin config",
  MANAGE_MARGIN_CONFIG_TYPE: "Manage margin config",
  CREATE_CONTRACT_TYPE: "Create contract type",
  MANAGE_CONTRACT_TYPE: "Manage contract type",
  EDIT_COOPERATION_AGREEMENT: "Edit cooperation agreement",
  CLONE_COOPERATION_AGREEMENT: "Clone cooperation agreement",
  CREATE_VAT_RATE: "Create vat rate",
  MANAGE_VAT_RATE: "Manage vat rate",
  CREATE_DEFAULT_COEFFICIENT: "Create default coefficient",
  EDIT_DEFAULT_COEFFICIENT: "Edit default coefficient",
  MANAGE_DEFAULT_COEFFICIENT: "Manage default coefficient",
  CREATE_INVOICE: "Create invoice",
  MANAGE_INVOICE: "Manage invoice",
  MANAGE_PAYMENTS: "Payments",
  COMPANY_REFUSED_CANDIDATES: "Company refused candidates",

  CREATE_PAYROLL_CONFIG: "Create payroll config",
  EDIT_PAYROLL_CONFIG: "Edit payroll config",
  MANAGE_PAYROLL_CONFIG: "Manage payroll config",
};

export const BUPages = [
  PAGENAMES.MANAGE_USER,
  PAGENAMES.MANAGE_COMPANY,
  PAGENAMES.MANAGE_VACANCY,
  PAGENAMES.MANAGE_CAR,
  PAGENAMES.MANAGE_CLIENT,
  PAGENAMES.MANAGE_CLOTHS,
  PAGENAMES.MANAGE_MATERIAL,
  PAGENAMES.MANAGE_PURCHASE_ORDER,
  PAGENAMES.MANAGE_SUPPLIER,
  PAGENAMES.MANAGE_LOCATION,
  PAGENAMES.MANAGE_PROJECT,
  PAGENAMES.MANAGE_CONTACT_PERSON,
  PAGENAMES.MANAGE_CANDIDATES,
  PAGENAMES.MANAGE_TIMESHEET_OVERVIEW,
  PAGENAMES?.MANAGE_PAY_ROLL,
  PAGENAMES?.SALES_PLANNING,
  PAGENAMES?.HR_PLANNING,
  PAGENAMES?.MANAGE_TODO,
];
