import { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import Documents from "./document-content/Documents";
import Certificates from "./document-content/Certificates";
import { useFormContext } from "../../context/Context";
import CheckBoxField from "components/atoms/CheckBoxField";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import { EUROPEAN_CITIZEN, UPDATE_FIELD } from "../../annotations/CandidateConstants";
import { resetEuropeanCitizenRelatedFields } from "../../helpers/CandidateHelperFunctions";
import { t } from "pages/microservices/masterData/translation/Translation";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import Calender from "../../helpers/Calender";
import LabelField from "components/atoms/LabelField";
import { useLocation } from "react-router-dom";

const EmployeeDocumentsTab: React.FC = () => {
  const { state, dispatch } = useFormContext();
  const location = useLocation();
  const isView = location.pathname?.includes('view');
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };
  const handleFileChange = async (event: any, field: any) => {
    let fileData: any = null;
    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "documents");
    }
    dispatch({
      type: UPDATE_FIELD,
      tab: EUROPEAN_CITIZEN,
      field: field,
      value: fileData
    });
    // validation(field, fileData, true);
    // validation("fileName", event?.name, true);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UPDATE_FIELD,
      tab: EUROPEAN_CITIZEN,
      field: "isEuropeanCitizen",
      value: event.target.checked ? 1 : 0
    });
    if (state.europeanCitizen.isEuropeanCitizen === 1) {
      resetEuropeanCitizenRelatedFields(dispatch);
    }
    // validation(name, updatedValue, true);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    dispatch({
      type: UPDATE_FIELD,
      tab: EUROPEAN_CITIZEN,
      field: name,
      value: date
    });
    // validation(name, date, true);
  };

  return (<>
    <div className="row">
      <div className="col-12">
        <div className="form-border" style={{ paddingTop: "2vw" }}>
          <div className="row text-danger fw-3">
            {state.errors.documentValidation}
          </div>
          <Certificates />
          <Documents />
          <div className="row">
            <div className="col-12">
              <LabelField title={t("European citizen")} className="tab-subtitle" />
            </div>
            <div className="col" style={{ marginBottom: "1vw" }}>
              <CheckBoxField
                label={t("European citizen")}
                name="isEuropeanCitizen"
                onChangeHandler={(e) => handleFieldChange(e)}
                isChecked={state.europeanCitizen.isEuropeanCitizen === 1}
                className="document-checkbox"
                id="isEuropeanCitizen"
                lineHeight="1.5vw"
              />
            </div>
          </div>
          {!state.europeanCitizen.isEuropeanCitizen && (
            <>
              <div className="row" style={isView ? { pointerEvents: "auto" } : {}}>
                <div className="col fileUploadFullWidth">
                  <MaxiconFileUpload
                    label={t('Work permit')}
                    name='workPermit'
                    id='workPermit'
                    isMandatory={false}
                    edit={false}
                    fileId={null}
                    fileObj={JSON.stringify(state.europeanCitizen.workPermit)}
                    multiple={false}
                    uploadPath="candidate-workPermit"
                    formats={'value.formats'}
                    handleChange={(e: any) => handleFileChange(e, "workPermit")}
                    maxFileSize={10000}
                    mode={isView}
                  />
                </div>
                <div className="col">
                  <Calender
                    onChange={(date) => handleDateChange(date, "from")}
                    label={t('From')}
                    isMandatory={false}
                    name='from'
                    // error={errors.from}
                    selectedDate={state.europeanCitizen.from}
                    maxDate={state.europeanCitizen.to}
                  ></Calender>
                </div>
                <div className="col">
                  <Calender
                    onChange={(date) => handleDateChange(date, "to")}
                    label={t('To')}
                    isMandatory={false}
                    name='to'
                    // error={errors.to}
                    selectedDate={state.europeanCitizen.to}
                    minDate={state.europeanCitizen.from}
                  ></Calender>
                </div>
              </div>
            </>
          )
          }
          <div className="row marginTop1">
            <div className="col">
              {t(" Any form of discrimination based on sex, perceived race, colour, descent, nationality or ethnic origin, sexual orientation, marital status, birth, property, age, religious or philosophical beliefs, current or future state of health, disability or physical characteristic is prohibited.")}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style={{ padding: "1vw 0" }}>
      <Navigation validStatus={validStatus} />
    </div>
  </>);
}
export default EmployeeDocumentsTab;