import React, { useEffect, useState, Suspense } from "react";
import { ApiCall } from "services/ApiServices";
import { GET_ALL_DOCUMENT_DATA, MANAGE_LOCATION } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "../../../../constants/Constants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import ManageDocumentType from "./ManageDocumentType";

export interface DocumentTypeData {
    id?: number;
    documentModule: string;
    documentType: string;
}

const DocumentType: React.FC = () => {
    const [documentType, setDocumentType] = useState<DocumentTypeData[]>([]);
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        initialCall: true,
        refresh: true,
    });
    const getSearchData = () => {
        return {
            documentModule: history?.filters?.documentModule ?? "",
            documentType: history?.filters?.documentType ?? "",
            orderBy: history?.filters?.orderBy ?? "id",
            sortDirection: history?.filters?.sortDirection ?? "desc",
        }
    };

    useEffect(() => {
        const data: any = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
            search: getSearchData(),
            initialCall: pageData?.initialCall
        };
        getDocumentTypeData(data);
    }, [pageData?.refresh, pageData?.initialCall]);

    const getDocumentTypeData = async (postData: any) => {
        const response = await ApiCall.service(
            GET_ALL_DOCUMENT_DATA,
            "POST",
            postData,
            false,
            M_MASTER_DATA
        );
        if (response?.status === 200) {
            setDocumentType(response?.data?.data);
            setPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords }))
        } else {
            console.log("error");
        }
    };
    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));
    const userData = useSelector(selectAuth);

    return (
        <AccessControl
            requiredPermissions={[{
                permission: "Document type",
                read: true
            }]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <>
                <Suspense
                    fallback={<div className="text-center text-danger">Loading...</div>}
                >
                    <ManageDocumentType
                        data={documentType}
                        setDocumentData={setDocumentType}
                        handleGetVacany={getDocumentTypeData}
                        dispatch={dispatch}
                        history={history}
                        handleRefresh={handleRefresh}
                        pageData={pageData}
                    />
                </Suspense>
            </>
        </AccessControl>
    );
};
export default DocumentType;
