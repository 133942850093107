export const WEEK_VIEW_NUMBER = 2;
export const MONTH_VIEW_NUMBER = 1;
export const YEAR_VIEW_NUMBER = 3;
export const PROJECT_VIEW_CHARACTERS = 4;
export const DAY_KEYWORD = "day";
export const WEEK_KEYWORD = "week";
export const WEEK2_KEYWORD = "week2";
export const WEEK4_KEYWORD = "week4";
export const WEEK8_KEYWORD = "week8";
export const MONTH_KEYWORD = "month";
export const YEAR_KEYWORD = "year";
export const ALL = "all";
export const REVENUE = "revenue";
export const COST = "cost";
export const MARGIN = "margin";
export const PERCENTAGE = "percentage";
export const TOTAL_EMPLOYEE = "total_employee";

export const PLANNING_VIEW_OPTIONS = [
    // { value: DAY_KEYWORD, label: "Day" },
    // { value: WEEK4_KEYWORD, label: "4 Weeks"},
    { value: WEEK2_KEYWORD, label: "2 Weeks"},
    { value: WEEK4_KEYWORD, label: "4 Weeks"},
    { value: WEEK8_KEYWORD, label: "8 Weeks"},
    // { value: WEEK4_KEYWORD, label: "4 Weeks"},
    // { value: MONTH_KEYWORD, label: "Month" },
    // { value: YEAR_KEYWORD, label: "Year" },
]

export const PLANNING_TYPE_OPTIONS = [
    // { value: "sickness", label: "Sick leave"},
    { value: "leave", label: "Leave"},
    { value: "holiday", label: "Holiday"},
    { value: "not-planned", label: "Not planned"},
    { value: "planned", label: "Planned"},
]

export const SALES_VIEW_OPTIONS = [
    { value: ALL, label: "All" },
    { value: REVENUE, label: "Revenue" },
    { value: COST, label: "Cost" },
    { value: MARGIN, label: "Margin" },
    { value: PERCENTAGE, label: "Margin %" },
]

export const PLANNING_FILTERS =
{
    state: {
        employees: [],
        company: [],
        project: [],
        startdate: "",
        enddate: "",
        bussiness_unit: [],
        employeeTypes: [],
        salesview: SALES_VIEW_OPTIONS[0],
        employeelist: [],
        empCompanyProject:[],
    },
}


export const SALES_VIEW_LIST = [
    "Revenue",
    "Cost",
    "Margin",
    "Margin %",
    "Total employee"
]

// export const SALES_LIST = [
//     "Revenue",
//     "Cost",
//     "Margin",
//     "Margin %",
// ]

export const SALES_VIEW_EXCEPTION_LIST = [
    "revenue",
    "cost",
    "margin",
    "percentage",
    "total"
]

export const SALES_LIST = [
    "Revenue",
    "Cost",
    "Margin",
    "Margin %"
]

export const OVERVIEW_TYPE = [
    { value: "employee", label: "Employee"},
    { value: "project", label: "Project"},
];