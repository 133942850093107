import CheckBoxField from "components/atoms/CheckBoxField";
import { useFormContext } from "../context/Context";
import { t } from "pages/microservices/masterData/translation/Translation";
import TitleFieldMolecule from "components/molecules/TitleField";
import { MarginMapping } from "../context/Constants";
import { customFunction } from "../CustomFunctions";

const ContractTypesComponent = () => {
  const { state, dispatch } = useFormContext();
  const { statute } = state?.data?.options;

  const handleCheckBoxChange = async (event: any, entityType: string) => {
    const { name, checked } = event.target;
    dispatch({
      type: "UPDATE_INPUT_FIELDS",
      payload: {
        name,
        value: checked ? true : false,
        entityType,
        subKey: "form",
      },
    });
    if (Object.keys(MarginMapping).includes(entityType)) {
      const dependencyValues: any = {}; // To send the state values which the current value is dependent on
      for (let key of MarginMapping[entityType]) {
        dependencyValues[key] = state?.data?.[key]["form"].name;
      }
      const payload = {
        [entityType]: {
          ...state.data?.[entityType]?.form?.name,
          [name]: checked ? true : false,
        },
        ...dependencyValues,
        classification: "statute",
        projectId: state.data.fixedProjectParameters.project.value,
        timeTable: state.data.fixedProjectParameters.timeTable.value,
      };
     
      if (state.data.fixedProjectParameters.project.value && state.data.fixedProjectParameters.timeTable.value) {
        const response = await customFunction(payload); // Sending data to get options of a particular type
        dispatch({
          type: "UPDATE_OPTIONS",
          payload: {
            data: response,
            key: "statute",
            subKey: "employee",
          },
        });
      }
    }
  };
  return (
    <>
      {statute.length > 0 ? (
        statute.map((eachItem: any, index: number) => {
          return (
            <div className="col-4 marginTopPoint5" key={index}>
              <CheckBoxField
                label={t(`${eachItem.label}`)}
                name={t(`${eachItem.alias}`)}
                id={t(`${eachItem.alias}`)}
                isChecked={
                  state?.data?.statute?.form?.name?.[
                    `contractType_${eachItem.value}`
                  ]
                }
                onChangeHandler={(event) => {
                  handleCheckBoxChange(event, "statute");
                }}
                lineHeight="1.3vw"
              />
            </div>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};
export default ContractTypesComponent;
