import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import SelectWithSearch from "components/atoms/SelectWithSearch";

import { useEffect, useState } from "react";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { t } from "pages/microservices/masterData/translation/Translation";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import Button from "components/atoms/Button";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import ViewIcon from "static/images/ViewIcon";
import axios from "axios";
import ActiveLocation from "static/images/ActiveLocation";
import AddAddressLoactionViaMap, {
  AddressDetails,
} from "utils/AddAddressLoactionViaMap";
import { useFormContext } from "../context/Context";
import { LocationTabData } from "../context/Interfaces";
import {
  DELETE_COMPANY_LOCATION_INDEX,
  EDIT_COMPANY_LOCATION_FIELD,
  UPDATE_COMPANY_LOCATION_EDITED_FIELD,
  UPDATE_COMPANY_LOCATION_FIELD,
  UPDATE_COMPANY_LOCATION_TABLE_FIELD,
} from "../context/Constants";
import { getCityAndCountry } from "services/util/UtilService";

interface ICompanyFormLocationTabProps {
  // id?: string | number;
  type?: string;
}

const CompanyLocation: React.FC<ICompanyFormLocationTabProps> = ({ type }) => {
  const { state, dispatch } = useFormContext();
  const isViewMode = state.isViewMode;
  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    const processedValue = type === "number" ? String(value) : value;
    if (name === "locationZipCode") {
      const city = getCityAndCountry(value);
      city.then((response: any) => {
        const { city, country } = response;
        dispatch({
          type: UPDATE_COMPANY_LOCATION_FIELD,
          field: "locationCity",
          value: city,
        });
        const countryValue: any =
          country !== "Error"
            ? state.options.countryList.find(
              (coun: any) =>
                coun?.label?.toLowerCase() === country?.toLowerCase()
            )
            : null;
        dispatch({
          type: UPDATE_COMPANY_LOCATION_FIELD,
          field: "locationCountry", // Convert to domFieldName
          value: countryValue,
        });
      });
    }
    dispatch({
      type: UPDATE_COMPANY_LOCATION_FIELD,
      field: name,
      value: value,
    });
    // validation(name, processedValue, true);
  };
  // const validation = (
  //   name: string,
  //   value: any,
  //   isSingleFieldValidation: boolean = false
  // ) => {
  //   const validationRules: ValidationRules = {
  //     locationName: [validateRequired],
  //     locationStreet: [validateRequired],
  //     locationNumber: [validateNumber],
  //     //  locationBox: string;
  //     locationZipCode: [validateRequired],
  //     locationCountry: [validateSelectField],
  //     locationCity: [validateRequired],
  //     locationPhoneNumber: [validateRequired],
  //   };

  //   const validationErrors = validateForm(
  //     { ...state.companyLocation, [name]: value },
  //     validationRules,
  //     isSingleFieldValidation ? name : undefined
  //   );
  //   // dispatch({
  //   //   type: "UPDATE_FIELD_ERROR",
  //   //   fieldName: name,
  //   //   error: validationErrors[name],
  //   // });
  //   const isFieldValid = Object.keys(validationErrors).length === 0;

  //   // if (isFieldValid) {
  //   //   state.fieldError[name] = "";
  //   // }
  //   if (Object.keys(validationErrors).length > 0) {
  //     return false;
  //   }
  //   return true;
  // };
  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: UPDATE_COMPANY_LOCATION_FIELD,
      field: name,
      value: selectedOption,
    });
    // validation(name, selectedOption, true);
  };
  // const validateAllFields = (): boolean => {
  //   // Track overall validity
  //   // let allFieldsValid = true;

  //   // Object.keys(state.companyLocation.data).forEach((key) => {
  //   //   const value = state.companyLocation.data[key as keyof LocationTabData];
  //   //   const isFieldValid = validation(key, value, true);
  //   //   allFieldsValid = allFieldsValid && isFieldValid; // Combine results
  //   // });

  //   // return allFieldsValid; // Return overall validity
  // };
  const validateRequiredFields = (): boolean => {
    // Validate string fields for non-empty values
    const requiredStringFields: (keyof typeof state.companyLocation.data)[] = [
      "locationName",
      "locationStreet",
      "locationZipCode",
      "locationCity",
    ];

    const areStringFieldsValid = requiredStringFields.every((field) => {
      const value = state.companyLocation.data[field];
      return typeof value === "string" && value.trim() !== ""; // Check if it's a non-empty string
    });

    // Validate number fields for positive numbers
    const requiredNumberFields: (keyof typeof state.companyLocation.data)[] = [
      "locationNumber",
    ];

    const areNumberFieldsValid = requiredNumberFields.every((field) => {
      const value = state.companyLocation.data[field];
      return typeof value === "string" && value.trim() !== ""; // String-based numbers are non-empty
    });

    // Validate locationCountry
    const country = state.companyLocation.data.locationCountry;
    const isCountryValid =
      country !== null &&
      typeof country === "object" &&
      "value" in country &&
      typeof country.value === "number" &&
      country.value > 0; // Ensure the country has a valid value

    // Combine all validations
    return areStringFieldsValid && areNumberFieldsValid && isCountryValid;
  };

  const handleAddLocation = () => {
    const isValid = validateRequiredFields();
    if (isValid) {
      dispatch({
        type: UPDATE_COMPANY_LOCATION_TABLE_FIELD,
        value: state.companyLocation.data,
      });
      const locationAddressFields = [
        { field: "locationId", value: "" },
        { field: "locationPhoneNumber", value: "" },
        { field: "locationName", value: "" },
        { field: "locationStreet", value: "" },
        { field: "locationNumber", value: "" },
        { field: "locationBox", value: "" },
        { field: "locationZipCode", value: "" },
        { field: "locationCity", value: "" },
        { field: "locationCountry", value: "" },
        { field: "initialLat", value: "" },
        { field: "initialLn", value: "" },
      ];
      locationAddressFields.forEach((field) => {
        dispatch({
          type: UPDATE_COMPANY_LOCATION_FIELD,
          field: field.field,
          value: field.value,
        });
      });
    }
  };

  //   const handleSubmit = async (e: React.FormEvent) => {
  //     e.preventDefault();
  //     let finalCompanyLocation = null;
  //     if (state.companyLocationTable.length === 0) {
  //       finalCompanyLocation = [state.companyLocation];
  //     } else {
  //       const specificLocationFields: Array<keyof companyLocationProps> = [
  //         "locationName",
  //         "locationPhoneNumber",
  //         "locationStreet",
  //         "locationNumber",
  //         "locationZipCode",
  //         "locationCountry",
  //         "locationCity",
  //       ];

  //       // Check if all specific fields are filled
  //       const areSpecificFieldsFilled = specificLocationFields.every((field) => {
  //         const value = state.companyLocation[field];

  //         // Check if value is a string and apply trim if needed
  //         if (typeof value === "string") {
  //           return value.trim() !== ""; // Check for non-empty strings after trimming
  //         }

  //         // Otherwise, check for non-null and non-undefined values (for other types)
  //         return value !== null && value !== undefined;
  //       });

  //       // Initialize finalCompanyContact with existing entries
  //       finalCompanyLocation = [...state.companyLocationTable];

  //       // Add to finalCompanyContact only if all of the specific fields are filled
  //       if (areSpecificFieldsFilled) {
  //         finalCompanyLocation.push(state.companyLocation);
  //       }
  //     }
  //     const formData = {
  //       basic: state.basic,
  //       address: state.address,
  //       companyLocation: finalCompanyLocation,
  //     };
  //     formData.basic.companyId = companyId;
  //     if (state.basic.vat !== "" && state.basic.companyName !== "") {
  //       const url = !companyId
  //         ? REGISTER_COMPANY
  //         : `${REGISTER_COMPANY}/${companyId}`;
  //       const response = await ApiCall.service(
  //         url,
  //         "POST",
  //         formData,
  //         false,
  //         M_COMPANY_CREATION
  //       );
  //       if (response?.status === 200) {
  //         if (validationStatus.type === "draft") {
  //           CustomNotify({
  //             type: "success",
  //             message: t("Draft saved successfully") + "!",
  //           });
  //           navigate("/manage-companies");
  //         } else {
  //           const locationResponse = response?.locationId;
  //           locationResponse &&
  //             locationResponse.length > 0 &&
  //             locationResponse.map((item: any, index: number) =>
  //               dispatch({
  //                 type: "UPDATE_COMPANYLOCATION_FIELD",
  //                 field: "id",
  //                 value: item.locationId,
  //               })
  //             );
  //           fetchDataFromUrl();
  //           navigate(`/company/${response.companyId}`);
  //         }
  //       } else if (response?.status === 400) {
  //         CustomNotify({ type: "error", message: response.msg.vat_number });
  //         dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
  //         scrollToTop();
  //         state.fieldError.vat = response.msg.vat_number;
  //       }
  //     }
  //   };
  const handleEdit = (entryId: number) => {
    dispatch({
      type: EDIT_COMPANY_LOCATION_FIELD,
      index: entryId,
    });
    // const locationAddressFields = [
    //   { field: "locationId", value: "" },
    //   { field: "locationPhoneNumber", value: "" },
    //   { field: "locationName", value: "" },
    //   { field: "locationStreet", value: "" },
    //   { field: "locationNumber", value: "" },
    //   { field: "locationBox", value: "" },
    //   { field: "locationZipCode", value: "" },
    //   { field: "locationCity", value: "" },
    //   { field: "locationCountry", value: "" },
    // ];
    // locationAddressFields.forEach((field) => {
    //   //   dispatch({
    //   //     type: "UPDATE_FIELD_ERROR",
    //   //     fieldName: field.field,
    //   //     error: field.value,
    //   //   });
    // });
  };

  const handleArchive = (deleteId: number) => {
    dispatch({
      type: DELETE_COMPANY_LOCATION_INDEX,
      index: deleteId,
    });
  };
  const handleSaveParticularData = () => {
    const isValid = validateRequiredFields();
    if (isValid) {
      dispatch({
        type: UPDATE_COMPANY_LOCATION_EDITED_FIELD,
        value: state.companyLocation.data,
      });
      const locationAddressFields = [
        { field: "locationId", value: "" },
        { field: "id", value: "" },
        { field: "locationPhoneNumber", value: "" },
        { field: "locationName", value: "" },
        { field: "locationStreet", value: "" },
        { field: "locationNumber", value: "" },
        { field: "locationBox", value: "" },
        { field: "locationZipCode", value: "" },
        { field: "locationCity", value: "" },
        { field: "locationCountry", value: "" },
      ];
      locationAddressFields.forEach((field) => {
        dispatch({
          type: UPDATE_COMPANY_LOCATION_FIELD,
          field: field.field,
          value: field.value,
        });
      });
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleAddPopup = () => {
    setShowPopup(true); // Close the popup without saving
  };
  const handleClosePopup = () => {
    setShowPopup(false); // or whatever logic you have to close the popup
  };
  const handleConfirm = (details: AddressDetails | null) => {
    if (details) {
      const { street, nr, bus, postal, city, country, coordinates } = details;
      let companyOption: any = {}; // Declare as an empty object

      // Find the country option if country is not empty
      if (country !== "") {
        companyOption =
          state.options.countryList.find((option: any) =>
            option.label.toLowerCase().includes(country.toLowerCase())
          ) || {}; // If no match found, default to an empty object
      }

      // Parse coordinates and ensure they're numbers
      // const [lat, lng] = coordinates
      //   .replace("Lat: ", "")
      //   .replace("Lng: ", "")
      //   .split(", ")
      //   .map(Number); // Convert the values to numbers

      // Prepare the fields for dispatching
      const locationAddressFields = [
        { field: "locationStreet", value: street },
        { field: "locationNumber", value: nr },
        { field: "locationBox", value: bus },
        { field: "locationZipCode", value: postal },
        { field: "locationCity", value: city },
        { field: "locationCountry", value: companyOption },
        // { field: "initialLat", value: lat },
        // { field: "initialLn", value: lng },
      ];

      // Dispatch company name
      dispatch({
        type: UPDATE_COMPANY_LOCATION_FIELD,
        field: "locationName",
        value: `${state.companyBasic.data.companyName}-HQ`,
      });

      // Dispatch location address fields
      locationAddressFields.forEach((field) => {
        dispatch({
          type: UPDATE_COMPANY_LOCATION_FIELD,
          field: field.field,
          value: field.value,
        });
      });

      setShowPopup(false); // Close the popup after confirming the address
    }
  };

  const renderAddressGoogleMap = () => {
    return (
      <div style={{ marginBottom: "1vw" }}>
        <div className="d-flex align-items-center">
          <Link
            to={""}
            title={t("Search address via google map...")}
            onClick={handleAddPopup}
            className="color-dark-pink marginRightPoint5"
          >
            <ActiveLocation />
          </Link>
          <Link to={""} className="link-color" onClick={handleAddPopup}>
            {t("Search address via google map...")}
          </Link>
        </div>
        <AddAddressLoactionViaMap
          initialLat={
            state.companyLocation.data.initialLat === ""
              ? 50.9804738
              : parseFloat(state.companyLocation.data.initialLat)
          } // Replace with your initial latitude
          initialLng={
            state.companyLocation.data.initialLn === ""
              ? 3.139242
              : parseFloat(state.companyLocation.data.initialLn)
          } // Replace with your initial longitude
          onConfirm={handleConfirm}
          showAddPopup={showPopup}
          onHide={handleClosePopup}
        />
      </div>
    );
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "2vw" }}>
            {state.companyLocationTable.length > 0 && (
              <div className="bg-white locationBg">
                <table className="table table-hover">
                  <thead className="TableHeader">
                    <tr>
                      <th>{t("Location name")}</th>
                      <th>{t("Street")}</th>
                      <th>{t("ZipCode")}</th>
                      <th>{t("Number")}</th>
                      <th>{t("City")}</th>
                      <th>{t("Country")}</th>
                      <th>{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.companyLocationTable.map(
                      (eachLocationData: any, index: number) => (
                        <tr key={index}>
                          <td className="align-middle">
                            {eachLocationData.locationName}
                          </td>
                          <td className="align-middle">
                            {eachLocationData.locationStreet}
                          </td>
                          <td className="align-middle">
                            {eachLocationData.locationZipCode}
                          </td>
                          <td className="align-middle">
                            {eachLocationData.locationNumber}
                          </td>
                          <td className="align-middle">
                            {eachLocationData.locationCity}
                          </td>
                          <td className="align-middle">
                            {eachLocationData.locationCountry.label}
                          </td>
                          <td className="table-action-icons">
                            <span
                              onClick={() => handleEdit(index)}
                              className="table-action-btn cursor-pointer marginRightPoint5"
                              title={isViewMode ? t("View") : t("Edit")}
                            >
                              {isViewMode ? <ViewIcon /> : <EditIcon />}
                            </span>
                            {!isViewMode && (
                              <span
                                onClick={() => handleArchive(index)}
                                className="table-action-btn cursor-pointer"
                                title={t("Delete")}
                              >
                                <DeleteIcon />
                              </span>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <div className="row">
              {!isViewMode &&
                <div className="col-md-4" style={{marginTop:"1.7vw"}}>
                  {renderAddressGoogleMap()}
                </div>}
              {!isViewMode && (
                <>
                  <div className="col-sm-12 col-md-4">
                    <LabelWithInputField
                      isMandatory={false}
                      name="locationName"
                      handleChange={(event) => handleFieldChange(event)}
                      value={state.companyLocation.data.locationName ?? ""}
                      id={`locationName`}
                      label="Location name"
                      placeholder="Location name"
                      // error={state.fieldError.locationName}
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <LabelWithInputField
                      isMandatory={false}
                      name="locationPhoneNumber"
                      handleChange={(event) => handleFieldChange(event)}
                      value={
                        state.companyLocation.data.locationPhoneNumber ?? ""
                      }
                      id={`phoneNumber`}
                      label="Phone number"
                      placeholder="Phone number"
                      // error={state.fieldError.locationPhoneNumber}
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={false}
                      name="locationStreet"
                      handleChange={(event) => handleFieldChange(event)}
                      value={state.companyLocation.data.locationStreet ?? ""}
                      id={`street`}
                      label="Street"
                      placeholder="Street"
                      // error={state.fieldError.locationStreet}
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <LabelWithInputField
                      isMandatory={false}
                      name="locationNumber"
                      handleChange={(event) => handleFieldChange(event)}
                      value={state.companyLocation.data.locationNumber ?? ""}
                      id={`number`}
                      label="Number"
                      placeholder="Number"
                      // error={state.fieldError.locationNumber}
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <LabelWithInputField
                      isMandatory={false}
                      name="locationBox"
                      handleChange={(event) => handleFieldChange(event)}
                      value={state.companyLocation.data.locationBox}
                      id={`box`}
                      label="Box"
                      type="text"
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <LabelWithInputField
                      isMandatory={false}
                      name="locationZipCode"
                      handleChange={(event) => handleFieldChange(event)}
                      value={state.companyLocation.data.locationZipCode}
                      label="Zip code"
                      type="text"
                      // error={state.fieldError.locationZipCode}
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <LabelWithInputField
                      isMandatory={false}
                      name="locationCity"
                      handleChange={(event) => handleFieldChange(event)}
                      value={state.companyLocation.data.locationCity}
                      label="City"
                      type="text"
                      // error={state.fieldError.locationCity}
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <SelectWithSearch
                      title="Country"
                      name="locationCountry"
                      isMandatory={false}
                      search={true}
                      options={state.options.countryList}
                      placeHolder="Select"
                      value={state.companyLocation.data.locationCountry}
                      onChange={(e) => handleSelectChange(e, "locationCountry")}
                      isMulti={false}
                      className="select-field"
                      // error={state.fieldError.locationCountry}
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-lg-12">
                    <LabelWithTextAreaField
                      name="locationInfo"
                      handleChange={(event) => handleFieldChange(event)}
                      value={state.companyLocation.data.locationInfo ?? ""}
                      label="Info"
                      isDisabled={isViewMode}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="row">
              <div className="col-md-12">
                {!isViewMode && (
                  <Button
                    title={
                      state.companyLocation.data.id !== "" &&
                        state.companyLocation.data.id !== null
                        ? t("Save")
                        : t("+ Add another")
                    }
                    handleClick={
                      state.companyLocation.data.id !== "" &&
                        state.companyLocation.data.id !== null
                        ? handleSaveParticularData
                        : handleAddLocation
                    }
                    className="form-button float-end"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyLocation;
