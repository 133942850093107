import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Reducer,
} from "react";
import { companyFormData, TabProps } from "../annotations/CompanyAnnotations";
import { Option } from "services/validation/ValidationService";
import { OptionProps } from "utils/TypeAnnotations";

const initialFormData: companyFormData = {
  tabDetails: [
    {
      id: "basic",
      title: "Basic",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "address",
      title: "Address",
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "companyLocation",
      title: "Location",
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "contacts",
      title: "Contacts",
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "companyDocument",
      title: "Document",
      showStatus: false,
      error: false,
      draft: false,
    },
  ],
  basic: {
    vat: "",
    companyName: "",
    officialName: "",
    pc: [],
    sector: [],
    domain: [],
    function: [],
    consultant: null,
    naceCodes: [],
    companyType: null,
    isBussinessUnit: false,
    bussinessUnit: null,
    website: "",
    hq: 0,
    parentCompanies: null,
    coEfficient: 0,
    companyStatus: 0,
    active: true,
  },
  address: {
    // location: "",
    street: "",
    number: "",
    box: "",
    zipCode: "",
    country: null,
    city: "",
    biStreet: "",
    initialLat: "",
    initialLn: "",
    biNumber: "",
    biBox: "",
    biZipCode: "",
    biCountry: null,
    biCity: "",
    hqAddress: 0,
    biInitialLat: "",
    biInitialLn: "",
    sameAddress: 1,
  },
  documents: [],
  companyLocation: {
    locationName: "",
    locationPhoneNumber: "",
    locationStreet: "",
    locationNumber: "",
    locationBox: "",
    locationZipCode: "",
    locationCountry: null,
    locationCity: "",
    locationInfo: "",
    id: "",
    initialLat: "",
    initialLn: "",
    locationId: "",
  },

  contacts: {
    tittle: null,
    fName: "",
    lName: "",
    email: "",
    mobileNumber: "",
    language: null,
    info: "",
    businessUnit: null,
    functionData: "",
    linkedIn: "",
    dob: "",
    // title: null,
    location: [],
    roles: null,
    id: "",
    userId: "",
  },
  // selectedContact: {
  //   personcontact: [],
  // },
  fieldError: {
    vat: "",
    companyName: "",
    officialName: "",
    pc: "",
    location: "",
    website: "",
    hq: "",
    parentCompanies: "",
    naceCodes: "",
    companyType: "",
    bussinessUnit: "",
    coEfficient: "",
    street: "",
    number: "",
    box: "",
    zipCode: "",
    country: "",
    city: "",
    biStreet: "",
    biNumber: "",
    biBox: "",
    biZipCode: "",
    biCountry: "",
    biCity: "",
    hqAddress: "",
    sameAddress: "",
    locationName: "",
    locationPhoneNumber: "",
    locationStreet: "",
    locationNumber: "",
    locationBox: "",
    locationZipCode: "",
    locationCountry: "",
    locationCity: "",
    mobileNumber: "",
    phoneNumber: "",
    fName: "",
    lName: "",
    email: "",
    gender: "",
  },
  companyLocationTable: [],
  companyContactPersonTable: [],
  countryList: [],
  pcList: [],
  sectorList: [],
  domainList: [],
  functionList: [],
  categoryList: [],
  consultantList: [],
  locationList: [],
  companiesList: [],
  rolesList: [],
  languageList: [],
  // locationIds: [],
  naceCodesList: [],
  bussinessUnitList: [],
  // cities: [],
  genders: [],
  contactsList: [],
  tittle: [],
  // selectedContact: undefined,
  // dynamicErrors: []
};

// Define form actions
type FormAction =
  | { type: "NEXT_STEP" }
  | { type: "PREVIOUS_STEP" }
  | {
    type: "SET_DROPDOWN";
    dropdownValues: OptionProps[];
    field: keyof companyFormData;
  }
  | { type: "UPDATE_TAB_DETAILS"; tabDetails: TabProps[] }
  | { type: "UPDATE_FIELD_ERROR"; fieldName: string; error: string }
  | { type: "UPDATE_TAB_ERROR"; tabIndex: number; error: boolean }
  | {
    type: "UPDATE_CONTACTS_FIELD_ERROR";
    field: string;
    error: string;
  }
  | {
    type: "UPDATE_COMPANY_LOCATION_FIELD_ERROR";
    field: string;
    error: string;
  }
  | {
    type: "REMOVE_DOCUMENT_FIELD";
    index: number;
  }
  | {
    type: "UPDATE_BASIC_FIELD";
    field: string;
    value: string | number | null | boolean | Option;
  }
  | {
    type: "UPDATE_SELECT_FIELD";
    field: string;
    value: string | number | null | boolean | Option;
  }
  | {
    type: "UPDATE_ADDRESS_FIELD";
    field: string;
    value: string | number | null | boolean | Option;
  }
  // | {
  //     type: "UPDATE_LOCTION_ID";
  //     field: string;
  //     value: LocationIds[];
  //   }
  | {
    type: "ADD_UPDATE_DYNAMIC";
    field: string;
    value: any;
  }
  | {
    type: "ADD_UPDATE_DYNAMIC_COMPANY_LOCATION";
    field: string;
    value: any;
  }
  | {
    type: "UPDATE_CONTACTS_FIELD";
    field: string;
    value: string | number | Date | null | undefined;
  }
  | {
    type: "ADD_DOCUMENT";
    data: any;
    index: number;
  }
  | {
    type: "ADD_COMPANY_DOCUMENT";
    data: any;
  }
  | {
    type: "UPDATE_COMPANYLOCATION_FIELD";
    field: string;
    value: string | number | Date | null | undefined | Option;
  }
  | { type: "ADD_CONTACT" }
  | { type: "ADD_LOCATION" }
  | { type: "REMOVE_LOCATION"; indexToRemove: number }
  | { type: "REMOVE_CONTACT"; indexToRemove: number }
  | {
    type: "UPDATE_COMPANYLOCATION_TABLE_FIELD";
    value: any;
  }
  | {
    type: "EDIT_COMPANYLOCATION_FIELD";
    index: number;
  }
  | {
    type: "UPDATE_COMPANYLOCATION_EDITED_FIELD";
    value: any;
  }
  | {
    type: "DELETE_COMPANYLOCATION_INDEX";
    index: number;
  }
  | {
    type: "UPDATE_CONTACTPERSON_TABLE_FIELD";
    value: any;
  }
  | {
    type: "EDIT_COMPANYCONTACT_PERSON_FIELD";
    index: number;
  }
  | {
    type: "DELETE_COMPANYCONTACT_PERSON_INDEX";
    index: number;
  }
  | {
    type: "UPDATE_COMPANYCONTACT_PERSON_EDITED_FIELD";
    value: any;
  } | {
    type: "UPDATE_ADDRESS_DEFAULT";
    value: any;
  };

type Dispatch = (action: FormAction) => void;

const FormContext = createContext<
  | {
    state: companyFormData;
    dispatch: Dispatch;
  }
  | undefined
>(undefined);

// Define a reducer to handle form actions
const formReducer: Reducer<companyFormData, FormAction> = (state, action) => {
  switch (action.type) {
    case "NEXT_STEP":
      const currentTab = state.tabDetails.find((tab) => tab.showStatus);
      if (currentTab) {
        const currentIndex = state.tabDetails.findIndex(
          (tab) => tab.id === currentTab.id
        );
        const nextIndex = (currentIndex + 1) % state.tabDetails.length;

        const updatedTabDetails = state.tabDetails.map((tab) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[nextIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetails,
        };
      }
      return state;

    case "PREVIOUS_STEP":
      const currentTabPrev = state.tabDetails.find((tab) => tab.showStatus);
      if (currentTabPrev) {
        const currentIndexPrev = state.tabDetails.findIndex(
          (tab) => tab.id === currentTabPrev.id
        );
        const previousIndex =
          (currentIndexPrev - 1 + state.tabDetails.length) %
          state.tabDetails.length;

        const updatedTabDetailsPrev = state.tabDetails.map((tab) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[previousIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetailsPrev,
        };
      }
      return state;

    case "SET_DROPDOWN":
      return {
        ...state,
        [action.field]: action.dropdownValues,
      };

    case "UPDATE_TAB_DETAILS":
      return {
        ...state,
        tabDetails: action.tabDetails,
      };
    // case "UPDATE_LOCTION_ID":
    //   return {
    //     ...state,
    //     locationIds: action.value,
    //   };

    case "UPDATE_TAB_ERROR":
      const { tabIndex, error } = action;
      return {
        ...state,
        tabDetails: state.tabDetails.map((tab, index) => ({
          ...tab,
          error: index === tabIndex ? error : tab.error,
        })),
      };
    case "UPDATE_BASIC_FIELD":
      return {
        ...state,
        basic: {
          ...state.basic,
          [action.field]: action.value,
        },
      };

    case "UPDATE_ADDRESS_FIELD":
      return {
        ...state,
        address: {
          ...state.address,
          [action.field]: action.value,
        },
      };

    case "UPDATE_CONTACTS_FIELD":
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.field]: action.value,
        },
      };
    case "UPDATE_COMPANYLOCATION_FIELD": {
      return {
        ...state,
        companyLocation: {
          ...state.companyLocation,
          [action.field]: action.value,
        },
      };
    }

    case "ADD_UPDATE_DYNAMIC":
      return {
        ...state,
        companyContactPersonTable: action.value,
      };
    case "ADD_UPDATE_DYNAMIC_COMPANY_LOCATION":
      return {
        ...state,
        companyLocationTable: action.value,
      };
    case "UPDATE_FIELD_ERROR":
      return {
        ...state,
        fieldError: {
          ...state.fieldError,
          [action.fieldName]: action.error,
        },
      };
    case "UPDATE_COMPANYLOCATION_TABLE_FIELD":
      const newValue = action.value;
      return {
        ...state,
        companyLocationTable: [...state.companyLocationTable, newValue],
      };

    case "EDIT_COMPANYLOCATION_FIELD":
      const entryIndex = action.index; // Get the index of the entry to edit

      // Retrieve the data from companyLocationTable at the specified index
      const entryToEdit = state.companyLocationTable[entryIndex];

      // Check if the entry exists to avoid errors
      if (!entryToEdit) {
        return state; // Return the current state if the entry doesn't exist
      }

      return {
        ...state,
        companyLocation: {
          ...entryToEdit, // Update companyLocation with the entry data
          id: entryIndex, // Set the id to the id of the entry
        },
      };

    case "UPDATE_CONTACTS_FIELD_ERROR":
      return {
        ...state,
        fieldError: {
          ...state.fieldError,
          [action.field]: action.error,
        },
      };
    case "UPDATE_COMPANYLOCATION_EDITED_FIELD":
      const editedLocation = action.value;
      const editedLocationIndex = editedLocation.id;
      const updatedCompanyLocationTable = [...state.companyLocationTable];
      updatedCompanyLocationTable[editedLocationIndex] = {
        ...updatedCompanyLocationTable[editedLocationIndex],
        ...editedLocation,
      };

      return {
        ...state,
        companyLocationTable: updatedCompanyLocationTable, // Set the updated array back to state
      };

    case "EDIT_COMPANYCONTACT_PERSON_FIELD":
      const contactEntryIndex = action.index; // Get the index of the entry to edit

      // Retrieve the data from companyLocationTable at the specified index
      const contactEntryToEdit =
        state.companyContactPersonTable[contactEntryIndex];

      // Check if the entry exists to avoid errors
      if (!contactEntryToEdit) {
        return state; // Return the current state if the entry doesn't exist
      }

      return {
        ...state,
        contacts: {
          ...contactEntryToEdit, // Update companyLocation with the entry data
          id: contactEntryIndex, // Set the id to the id of the entry
        },
      };
    case "DELETE_COMPANYLOCATION_INDEX":
      const deleteIndex = action.index;

      return {
        ...state,
        companyLocationTable: state.companyLocationTable.filter(
          (_, index) => index !== deleteIndex
        ),
      };
    case "DELETE_COMPANYCONTACT_PERSON_INDEX":
      const deleteContactIndex = action.index;

      return {
        ...state,
        companyContactPersonTable: state.companyContactPersonTable.filter(
          (_, index) => index !== deleteContactIndex
        ),
      };
    case "UPDATE_CONTACTPERSON_TABLE_FIELD":
      const newContactValue = action.value;
      return {
        ...state,
        companyContactPersonTable: [
          ...state.companyContactPersonTable,
          newContactValue,
        ],
      };
    case "UPDATE_COMPANYCONTACT_PERSON_EDITED_FIELD":
      const editedContacts = action.value;
      const editedContactsIndex = editedContacts.id;
      const updatedCompanyContactTable = [...state.companyContactPersonTable];
      updatedCompanyContactTable[editedContactsIndex] = {
        ...updatedCompanyContactTable[editedContactsIndex],
        ...editedContacts,
      };
      return {
        ...state,
        companyContactPersonTable: updatedCompanyContactTable,
      };
    case "ADD_DOCUMENT":
      const { data: documentData, index: documentIndex } = action;
      const newDocuments = [...state.documents];

      // Update the specific coefficient at the given index
      newDocuments[documentIndex] = {
        ...documentData,
      };
      // Return the new state, updating remenurationDetails specifically
      return {
        ...state,
        documents: newDocuments,
      };
    case "ADD_COMPANY_DOCUMENT":
      return {
        ...state,
        documents: action.data,
      };
    case "REMOVE_DOCUMENT_FIELD":
      const updatedDocuments = [...state.documents];
      updatedDocuments.splice(action.index, 1);
      // Update the editId for remaining elements
      const documentsWithUpdatedIds = updatedDocuments.map((doc) => {
        // If editId is a number and greater than the removed index, decrement it
        if (typeof doc.editId === "number" && doc.editId > action.index) {
          return { ...doc, editId: doc.editId - 1 };
        }

        return { ...doc };
      });

      return {
        ...state,
        documents: documentsWithUpdatedIds,
      };

    case "UPDATE_COMPANY_LOCATION_FIELD_ERROR":
      return {
        ...state,
        fieldError: {
          ...state.fieldError,
          [action.field]: action.error,
        },
      };
    default:
      return state;
  }
};

// Create a FormProvider component to wrap your form
interface FormProviderProps {
  children: ReactNode;
}

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialFormData);

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};
