import SelectWithSearch from "components/atoms/SelectWithSearch"
import { t } from "../../masterData/translation/Translation"
import { LabelWithInputFieldAndUnit } from "../../masterData/templates/atoms/LabelWithInputFieldAndUnit"
import CheckBoxField from "components/atoms/CheckBoxField"
import { LabelWithInputField } from "components/molecules/LabelWithInputField"
import { Accordion } from "react-bootstrap";
import { useFormContext } from "../context/Context"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import Button from "components/atoms/Button"
import { validateForm, validateRequired, validateSelectField } from "services/validation/ValidationService"
import { customFunction } from "../CustomFunctions"
import { MarginMapping } from "../context/Constants"
import ContractTypesComponent from "./ContractTypesComponent"

const CommonComponent = (props: any) => {
  const { index, parameter } = props;

  const { id, name } = parameter;

  const { state, dispatch } = useFormContext();

  const handleChange = (event: any, entityType: string) => {
    const { name, value, checked, type } = event.target;
    let fieldValue = type !== "checkbox" ? value : (checked ? true : false);

    dispatch({
      type: "UPDATE_INPUT_FIELDS",
      payload: {
        value: fieldValue,
        entityType,
        name,
        subKey: 'form',
      }
    })
    if (type !== 'checkbox') {
      validationFunction(name, value, true);
    }
  }

  const validationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false,
    entityType?: string,
  ) => {
    const validationRules: any = {
      name: [validateSelectField],
      amount: [validateRequired],
      ...(!["material", "clothes", "dynamic", "car"].includes(entityType || "") && {
        coefficient: [validateRequired],
      }),
    };
  

    const validationErrors = validateForm(
      { ...state.data?.[id]['form'], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    const isFieldValid = Object.keys(validationErrors).length === 0;

    let updatedErrors;
    if (isSingleFieldValidation) {
      updatedErrors = {
        ...state.errors[id],
        [name]: isFieldValid ? "" : validationErrors[name],
      };
      dispatch({
        type: "UPDATE_FIELD_ERRORS",
        payload: {
          id,
          errors: updatedErrors,
        },
      });
    } else {
      updatedErrors = {
        ...state.errors[id],
        ...validationErrors,
      };
      dispatch({
        type: "UPDATE_FIELD_ERRORS",
        payload: {
          id,
          errors: updatedErrors,
        },
      });
      return Object.keys(validationErrors).length;;
    }
  };


  const handleAddAnother = (event: any, entityType: string) => {
    const res = validationFunction(null, null, false, entityType);
    if (res == 0) {
      dispatch({
        type: "ADD_ANOTHER_ELEMENT",
        payload: {
          entityType,
        }
      })
    }
  }


  const handleSelectChange = async (event: any, name: any, entityType: string) => {
    dispatch({
      type: "UPDATE_INPUT_FIELDS",
      payload: {
        value: event,
        entityType,
        name,
        subKey: 'form',
      }
    });
    validationFunction(name, event, true);  

    if (Object.keys(MarginMapping).includes(entityType)) {

      const dependencyValues: any = {};
      for (let key of MarginMapping[entityType]) {
        dependencyValues[key] = state?.data?.[key]['form'].name;
      }

      const payload = {
        ...state?.data?.[id],
        [entityType]: event.value,
        ...dependencyValues,
        classification: 'statute',
      }
      // if (entityType == 'employee') {
      //   const response = await customFunction(payload);
      //   dispatch({
      //     type: "UPDATE_OPTIONS",
      //     payload: {
      //       data: response,
      //       key: 'employee',
      //       subKey: entityType,
      //     },
      //   });
      // }
    }
  }

  const handleArchive = (index: any, entityType: string) => {
    dispatch({
      type: "REMOVE_DYNAMIC_ELEMENT",
      payload: {
        index,
        entityType,
        subKey: 'form',
      }
    })
  }

  const handleSave = (entityType: string) => {
    dispatch({
      type: "UPDATE_DATA_LIST",
      payload: {
        entityType
      }
    })
  }

  // const handleEdit = (index: any, entityType: string) => {
  //     dispatch({
  //         type: "EDIT_DYNAMIC_ELEMENT",
  //         payload: {
  //             index,
  //             entityType,
  //             subKey: 'form',
  //         }
  //     })
  // }

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t(name)}</Accordion.Header>
          <Accordion.Body>
            <div className="accordianClass">
              <div className="row m-0">
                {name == 'Employee' ? (<ContractTypesComponent />) : <></>}
                <div className="col-12 marginTopPoint5">
                  <SelectWithSearch
                    onChange={(event) => { handleSelectChange(event, 'name', id) }}
                    title={t("Name")}
                    placeHolder={t("Select name")}
                    search={true}
                    options={state?.data?.options?.[id]}
                    value={state?.data?.[id]?.form?.['name']}
                    isDisabled={false}
                    // isMulti={(name == 'Material' || name == 'Dare') ? true : false}
                    isMulti={false}
                    isMandatory={true}
                    error={state?.errors?.[id]?.['name']}
                    name={"name"}
                    id={`name-${index + 1}`}
                  />
                </div>
                <div className="col-6">
                  <LabelWithInputFieldAndUnit
                    type="text"
                    placeholder="Amount"
                    handleChange={(event) => { handleChange(event, id) }}
                    label={name == 'Employee' ? t("Wage") : t("Amount per day")}
                    value={state?.data?.[id]?.form?.['amount']}
                    name={'amount'}
                    id={`amount-${index + 1}`}
                    isMandatory={name == 'Employee' ? true : false}
                    error={state?.errors?.[id]?.['amount']}
                    min={0}
                    unit={"€"}
                    containerClassName="col-12"
                  />
                </div>
                {name == 'Employee' &&
                <>
                <div className="col-6">
                  <LabelWithInputField
                    type="text"
                    placeholder="Coefficient"
                    handleChange={(event) => { handleChange(event, id) }}
                    label={t("Coefficient")}
                    value={state?.data?.[id]?.form?.['coefficient']}
                    name={'coefficient'}
                    id={`coefficient-${index + 1}`}
                    isMandatory={true}
                    error={state?.errors?.[id]?.['coefficient']}
                    min={0}
                  />
                </div>
                <div className="col-6">
                  <CheckBoxField
                    label={t(
                      "Per hour"
                    )}
                    name={"perHour"}
                    id={`perHour-${index + 1}`}
                    // isChecked={state?.data?.[id]?.form?.['perHour']}
                    isChecked={true}
                    onChangeHandler={(event) => { handleChange(event, id) }}
                  />
                </div>
                <div className="col-6">
                  <CheckBoxField
                    label={t(
                      "Invoicing"
                    )}
                    name={"invoicing"}
                    id={`invoicing-${index + 1}`}
                    isChecked={state?.data?.[id]?.form?.['invoicing']}
                    onChangeHandler={(event) => { handleChange(event, id) }}
                  />
                </div>
                </>}
                <div className="col-md-12 text-end marginBottomPoint5 marginTopPoint5">
                  <Button
                    title={state.data?.[id]?.isEdit ? t("Save") : t("+ Add another")}
                    type={"button"}
                    handleClick={state.data?.[id]?.isEdit ?
                      () => { handleSave(id) } : (event) => { handleAddAnother(event, id) }}
                    className={
                      "form-button shadow-none"
                    }
                  />
                </div>
                {(state?.data?.[id]?.dataList.length > 0) ? (
                  <div className="col-12">
                    <table className="table table-hover">
                      <thead className="TableHeader">
                        <tr>
                          <th>{t('Name')}</th>
                          <th>{t('Amount')}</th>
                          <th>{t('Coefficient')}</th>
                          <th>{"Actions"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.data[id].dataList.map((eachrow: any, index: number) => {
                          return (
                            <tr>
                              <td>
                                {Array.isArray(eachrow.name) ?
                                  (eachrow?.name?.map((eachItem: any) => eachItem.label).join(', ')) :
                                  eachrow?.name?.label}
                              </td>
                              <td>{eachrow.amount}</td>
                              <td>{eachrow.coefficient}</td>
                              <td>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  onClick={() => handleArchive(index, id)}
                                  style={{ cursor: "pointer", color: "#808080" }}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title={t("Delete")}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (<></>)}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )

}
export default CommonComponent