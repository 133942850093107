import React from "react";
import CheckBoxField from "components/atoms/CheckBoxField";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { t } from "pages/microservices/masterData/translation/Translation";

const CommonCheckBox = (props) => {
  const { planTimingsData, header1, header2, name, isDisable } = props;
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { filterOptionsState } = useFilterOptions();

  const handleChange = (event, planId) => {
    const { checked, name } = event.target;
    if (planId) {
      timesheetDispatch({
        type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
        payload: {
          planId: planId,
          value: checked,
          name: name,
          extraprops: {
            filterOptionsState,
          },
        },
      });
    }
  };

  const renderItem = () => {
    const weekDays = [
      <td key={"heading1"} className="text-start">
        {t(header1)}
      </td>,
      <td key={"heading2"} className="text-start">{t(header2)}</td>,
    ];

    planTimingsData.map((data, i) => {
      const isWeekend = i === 5 || i === 6; // Saturday (index 5) & Sunday (index 6)

      weekDays.push(
        data?.plan_id ? (
          <td
            key={i}
            className="regimeHeight"
            style={{
              backgroundColor: isWeekend ? "#87d2e561" : "transparent", // Highlight weekends
            }}
          >
            <CheckBoxField
              label={""}
              name={name}
              id={name}
              isChecked={data[name]}
              disable={isDisable}
              onChangeHandler={(event) => handleChange(event, data?.plan_id)}
            />
          </td>
        ) : (
          <td
            key={i}
            className=""
            style={{
              backgroundColor: isWeekend ? "#87d2e561" : "transparent",
            }}
          ></td>
        )
      );
    });

    return weekDays;
  };

  return (
    <tr>
      {renderItem()}
      <td key={'last'} className=""></td>
    </tr>
  );
};

export default CommonCheckBox;
