import TitleAtom from "components/atoms/Title";
import React, { useEffect, useState } from "react";
import { t } from "../masterData/translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import Calender from "./helpers/Calender";
import { Option, ValidationRules } from "utils/TypeAnnotations";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import { FETCH_REFUSED_COMPANIES_DATA, GET_COMPANY_PROJECT_OPTION, REFUSED_COMPANY_CREATION, REFUSED_COMPANY_DELETE } from "routes/ApiEndpoints";
import { useParams } from "react-router-dom";
import { M_IDENTITY_MANAGER } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import DeleteIcon from "static/images/DeleteIcon";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { useDispatch, useSelector } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { RefusedCompaniesTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import FilterOrganism from "components/organism/FilterOrganism";
import { validateForm, validateSelectField } from "services/validation/ValidationService";
import { selectAuth } from "features/auth/AuthSlice";
import AccessControl from "services/AccessControl";

interface FormDataProps {
    userId: string;
    company: Option | null;
    info: string;
    refusedDate: Date | null;
    // project: Option | null;
}

interface DropdownDataProps {
    companyList: Option[];
    // projectList: Option[];
}

interface ManageDataProps {
    id: number | null;
    company: string;
    info: string;
    refusedDate: string;
    // project: string;
}

export const RefusedCompany = () => {
    const userData = useSelector(selectAuth);
    const candidateId = useParams();
    const userId = candidateId.id;
    const initialFormData = {
        userId: userId || '',
        company: null,
        info: '',
        refusedDate: new Date(),
        // project: null,
    }
    const [formData, setFormData] = useState<FormDataProps>(initialFormData);
    const [dropdownData, setDropdownData] = useState<DropdownDataProps>({
        companyList: [],
        // projectList: []
    });
    const [showPopUp, setShowPopUp] = useState(false);
    const [manageData, setManageData] = useState<ManageDataProps[]>([]);
    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [deleteId, setDeleteId] = useState<number | null>();
    const [userName, setUserName] = useState('');
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        refresh: true,
        initialCall: true
    });
    const [loading, setLoading] = useState(true);
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<{
        [key: string]: string;
    }>({ company: "" });

    const filters = [
        { name: 'company', fieldType: "singleSelect", options: dropdownData?.companyList, placeholder: "Company", filterType: 'search' },
        { name: 'info', fieldType: "text", placeholder: "Info", filterType: 'search' },
        { name: 'refusedDate', fieldType: "date", placeholder: "Refused date", filterType: 'search' },
        // { name: 'project', fieldType: "singleSelect", options: dropdownData?.companyList, placeholder: "Project", filterType: 'search' },
    ];

    const getSearchData = () => {
        return {
            company: history?.filters?.company ?? "",
            project: history?.filters?.project ?? "",
            refusedDate: history?.filters?.refusedDate ?? "",
            info: history?.filters?.info ?? "",
        }
    };

    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
            search: getSearchData(),
            initialCall: pageData?.initialCall,
            candidateId: candidateId.id
        };
        getRefusedCompaniesData(postData);
    }, [pageData?.refresh, pageData?.initialCall]);


    const validation = (
        name: string,
        value: Option | null | string,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            company: [validateSelectField],
        };
        const validationErrors = validateForm(
            { ...formData, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        if (isSingleFieldValidation) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: validationErrors[name],
            }));
        } else {
            setErrors(validationErrors);
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }
        return true;
    };

    const getRefusedCompaniesData = async (postData: any) => {
        const response = await ApiCall.service(`${FETCH_REFUSED_COMPANIES_DATA}`, "POST", { ...postData, type: 'candidate' }, true, M_IDENTITY_MANAGER);
        if (response?.status === 200) {
            setPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords, initialCall: false }));
            setManageData(response?.data?.manageData);
            setUserName(response?.data?.userName);
            setDropdownData((prevData: any) => (response?.data?.dropdownData));
            setLoading(false);
        }

    }

    const handleSelectChange = (selectedOption: Option, fieldName: string) => {
        setFormData((prevData: any) => ({
            ...prevData,
            [fieldName]: selectedOption
        }));

        if (fieldName === 'company') {
            getCompanyRelatedProjects(selectedOption?.value);
            validation(fieldName, selectedOption, true);
        }

    }

    const getCompanyRelatedProjects = async (companyId: number | null) => {
        const projectOptionsResponse = await ApiCall.getService(`${GET_COMPANY_PROJECT_OPTION}/${companyId}`, 'GET', M_IDENTITY_MANAGER, false);
        if (projectOptionsResponse?.status === 200) {
            setDropdownData((prevData: any) => ({
                ...prevData,
                projectList: projectOptionsResponse?.data?.length > 0 ? projectOptionsResponse?.data : []
            }));
        }
    }

    const handleFieldChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    }

    const handleDateChange = (date: Date | null, fieldName: string) => {
        setFormData((prevData: any) => ({
            ...prevData,
            [fieldName]: date
        }));

    }

    const handleAdd = () => {
        setFormData(initialFormData);
        setShowPopUp(true);
    }

    const handleSubmit = async () => {
        if (validation('company', formData?.company)) {
            const response = await ApiCall.service(REFUSED_COMPANY_CREATION, 'POST', { type: 'candidate', ...formData }, true, M_IDENTITY_MANAGER);
            if (response?.status === 200) {
                setShowPopUp(false);
                handleRefresh();
                CustomNotify({ type: "success", message: response?.message });
            }
            setErrors({});
        }
    }

    const handleClose = () => {
        setShowPopUp(false);
        setShowDeletePopUp(false);
    }

    const handleDelete = (id: number | null) => {
        setShowDeletePopUp(true);
        setDeleteId(id);

    }

    const handleDeleteRefusedCompany = async () => {
        const deleteResponse = await ApiCall.getService(`${REFUSED_COMPANY_DELETE}/${deleteId}`, 'GET', M_IDENTITY_MANAGER, false);
        if (deleteResponse?.status === 200) {
            setShowDeletePopUp(false);
            handleRefresh();
            CustomNotify({ type: "success", message: deleteResponse?.message });
        }
    }

    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "Refused company",
                    read: true,
                },
            ]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <TitleAtom title={t(`${userName} refused  details`)} />
            <ModalPopup
                show={showPopUp}
                onHide={handleClose}
                title={t("Medewerker refused")}
                body={
                    <>
                        <div className="d-grid grid-2">
                            <div>
                                <SelectWithSearch
                                    title={t("Company")}
                                    isMandatory={true}
                                    name="company"
                                    search={true}
                                    options={dropdownData.companyList}
                                    onChange={(e) => handleSelectChange(e, "company")}
                                    isMulti={false}
                                    value={formData.company}
                                    error={errors.company}
                                    placeHolder="Select"
                                />
                            </div>
                            {/* <div className="col-4">
                                <SelectWithSearch
                                    title={t("Project")}
                                    isMandatory={false}
                                    name="project"
                                    search={true}
                                    options={dropdownData.projectList}
                                    onChange={(e) => handleSelectChange(e, "project")}
                                    isMulti={false}
                                    value={formData.project}
                                    // error={errors.project}
                                    placeHolder="Select"
                                />
                            </div> */}
                            <div>
                                <Calender
                                    onChange={(date) => handleDateChange(date, "refusedDate")}
                                    label={t("Refused date")}
                                    isMandatory={false}
                                    name={"refusedDate"}
                                    selectedDate={formData.refusedDate}
                                    maxDate={new Date()}
                                // error={errors.refusedDate}
                                ></Calender>
                            </div>
                        </div>
                        <div className="col">
                            <LabelWithTextAreaField
                                label={t("Info")}
                                name="info"
                                value={formData.info}
                                placeholder={t("info")}
                                isMandatory={false}
                                handleChange={(e) => handleFieldChange(e)}
                            />
                        </div>
                    </>
                }
                onConfirmButtonClick={handleSubmit}
                onCloseButtonClick={handleClose}
                closeTitle={t("Cancel")}
                confirmTitle={t("Save")}
            />
            <div className="position-relative tableMainWrapper">
                <div className="row">
                    <div className="ManageCreateBtn">
                        <span onClick={handleAdd} className="form-button float-end d-flex align-items-center text-decoration-none cursor-pointer">
                            {"+" + " " + t("Create refused medewerker")}
                        </span>
                    </div>
                </div>

                <div className="table-responsive refusedCompanies tableSection">
                    <FilterOrganism
                        dispatch={dispatch}
                        history={history}
                        updatePageFilters={updatePageFilters}
                        filters={filters}
                        handleRefresh={handleRefresh}
                    />
                    <div className="TableHeight">
                        <table className="table table-hover">
                            <thead>
                                <tr className="TableHeader">
                                    {RefusedCompaniesTableHeader?.map((header: any) => (
                                        <th key={header?.alias}>
                                            <>
                                                {t(header.name)}
                                                {header?.issort && (
                                                    <SortAtomForManagePage
                                                        value={{ alias: header?.alias }}
                                                        dispatch={dispatch}
                                                        history={history}
                                                        updatePageFilters={updatePageFilters}
                                                        handleRefresh={handleRefresh}
                                                    />
                                                )}
                                            </>
                                        </th>
                                    ))}
                                    <th>{t("Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {manageData?.length > 0 ? (
                                    manageData.map((data, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">{data.company}</td>
                                            <td className="align-middle">{data.info}</td>
                                            <td className="align-middle">{data?.refusedDate ?? ''}</td>
                                            {/* <td>{data.project}</td> */}
                                            <td onClick={() => handleDelete(data.id)} className=" align-middle">
                                                <span className="table-action-btn cursor-pointer" title={t("Delete")}>
                                                    <DeleteIcon />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5} className="text-center">
                                            <span className="text-danger">{t("No records")}</span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div
                        className="pagination d-block"
                        style={{ paddingTop: "0.25vw" }}
                    >
                        {
                            !loading && <div className="">
                                <PaginationWithPerPage
                                    handleRefresh={handleRefresh}
                                    dispatch={dispatch}
                                    history={history}
                                    pageData={pageData}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ModalPopup
                show={showDeletePopUp}
                onHide={handleClose}
                body={t("Are you sure you want to delete")}
                title={t("Confirmation")}
                onConfirmButtonClick={handleDeleteRefusedCompany}
                confirmTitle={t("Delete")}
                closeTitle={t("Cancel")}
                onCloseButtonClick={handleClose}
            />


        </AccessControl>
    );
}