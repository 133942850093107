import React, { Suspense, useState, useEffect } from "react";
import ManageVacancies from "./ManageVacancies";
import { M_MASTER_DATA } from "constants/Constants";
import { VACANCIES } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import { getPCAndItsParameters } from "./utils/Utils";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { useDispatch } from "react-redux";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import CommonServices from "services/CommonService";


const Vacancies: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectAuth);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const [state, setState] = useState({
    data: [],
    options: {
      BusinessUnit: [],
      VacancyStatus: [
        {value: "open", label: "Open", name: "Open"},
        {value: "closed", label: "Closed", name: "Closed"},
      ],
      Status: [
        {value: "draft", label: "Draft", name: "Draft"},
        {value: "active", label: "Active", name: "Active"},
        {value: "inactive", label: "Inactive", name: "Inactive"},
      ]
    },
    initialCall: true,
    totalRecords: 0,
    totalPages: 0,
    popup: {
      delete: {
        show: false,
        data: {}
      }
    },
    refresh: true
  })

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
      initialCall: state?.initialCall,
      search: {
        businessUnits: history?.filters?.businessUnits ?? [],
        title: history?.filters?.title ?? "",
        company: history?.filters?.company ?? "",
        project: history?.filters?.project ?? "",
        pcFunction: history?.filters?.pcFunction ?? "",
        consultant: history?.filters?.consultant ?? "",
        from: CommonServices?.getTimezoneOffset(history?.filters?.from ?? null),
        to: CommonServices?.getTimezoneOffset(history?.filters?.to ?? null),
        postingMedium: history?.filters?.postingMedium ?? "",
        status: history?.filters?.status ?? "",
        vacancy_status: history?.filters?.vacancy_status ?? "",
        hotlist: history?.filters?.hotlist ?? "",
        orderBy: history?.filters?.orderBy ?? "id",
        sortDirection: history?.filters?.sortDirection ?? "desc",
      },
      optionsPayload: ["BusinessUnit", "PostMediums"],
    };
    getVacanciesData(postData);
  }, [state?.refresh, state?.initialCall]);

  const getVacanciesData = async (postData: any) => {
    const response = await ApiCall.service(VACANCIES, "POST", {...postData, user_id: userData.userId,role:userData.role}, false, M_MASTER_DATA);
    if (response?.status === 200) {      
      let data: any = { ...state, data: response?.data ?? [], initialCall: false, totalPages: response?.totalPages, totalRecords: response?.totalRecords };
      if (response?.options) {
        data.options = {
          ...state.options,
          BusinessUnit: response?.options?.BusinessUnit,
          PostMediums: response?.options?.PostMediums,
        }
      }
      setState(data);
    }
    return response ?? [];
  };

  return (
    <>
      <Suspense fallback={<div className="text-center text-danger"></div>}>
        <ManageVacancies
          state={state}
          dispatch={dispatch}
          history={history}
          setState={setState}
        />
      </Suspense>
    </>
  );
};

export default Vacancies;
