import { TimesheetStatus } from "pages/microservices/timesheets/constants/timesheetConstants";

export const initialFilterProps = {
    optionState: {
        projects: [],
        employees: [],
        performanceTypes: [],
        reimbursementTypes: [],
        regimen: [],
        prjectParameters: [],
        businessUnits: [],
        status: TimesheetStatus,
    },
    selectedState: {
        project_id: null,
        employee_id: null,
        currentDate: new Date(),
        refresh: false,
        initialCall: true,
        tab: "performance",
        showBuFilter: false,
        selectedBu: 'all',
        showStatusFilter: false,
        selectedStatus: 'all',
    },
    filteredOptions: {
        projects: [],
        employees: [],
    },
}
