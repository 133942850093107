import { M_MASTER_DATA } from "constants/Constants";
import { Suspense, useEffect, useState } from "react";
import { t } from "../../translation/Translation";
import AccessControl from "services/AccessControl";
import { Link, useNavigate } from "react-router-dom";
import Title from "components/atoms/Title";
import FilterOrganism from "components/organism/FilterOrganism";
import { useDispatch, useSelector } from "react-redux";
import { addPageToHistory, selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { selectAuth } from "features/auth/AuthSlice";
import { ApiCall } from "services/ApiServices";
import { GET_PAYMENTS } from "routes/ApiEndpoints";
import { PaymentTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { PAGENAMES } from "store/pageHistory/ConstantPageNames";
import CommonServices from "services/CommonService";
import "./css/payments.css";
import NoRecords from "pages/microservices/Company/CompanyCorner/NoRecords";
import { PATH_CREATE_INVOICE } from "constants/Paths";
interface ManageDataProps {
  id: number;
  invoiceNumber: string;
  company: string;
  invoiceDate: string;
  expirationDate: string;
  invoiceAmount: string;
  paymentDate: string;
  payment: string;
}

const ManagePayments: React.FC = () => {
  const userData = useSelector(selectAuth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });
  const [manageData, setManageData] = useState<ManageDataProps[]>([]);

  const getSearchData = () => {
    return {
      company: history?.filters?.company ?? "",
      invoiceNumber: history?.filters?.invoiceNumber ?? "",
      startDate: history?.filters?.startDate ?? "",
      endDate: history?.filters?.endDate ?? "",
      invoiceAmount: history?.filters?.invoiceAmount ?? "",
      startPayment: history?.filters?.startPayment ?? "",
      endPayment: history?.filters?.endPayment ?? "",
      payment: history?.filters?.payment ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    };
  };

  const filters = [
    { name: 'invoiceNumber', fieldType: "text", placeholder: "Invoice Number", filterType: 'search' },
    { name: 'company', fieldType: "text", placeholder: "company", filterType: 'search' },
    {
      filterType: "multiple",
      filters: [
        { name: 'startDate', fieldType: "date", endDate: history?.filters?.endDate, placeholder: "Start", filterType: 'search' },
        { name: 'endDate', fieldType: "date", startDate: history?.filters?.startDate, placeholder: "End", filterType: 'search' },
      ]
    },
    {
      filterType: "multiple",
      filters: [
        { name: 'startExpirationDate', fieldType: "date", endExpirationDate: history?.filters?.endExpirationDate, placeholder: "Start", filterType: 'search' },
        { name: 'endExpirationDate', fieldType: "date", startExpirationDate: history?.filters?.startExpirationDate, placeholder: "End", filterType: 'search' },
      ]
    },
    { name: 'invoiceAmount', fieldType: "text", placeholder: "Invoice Amount", filterType: 'search' },
    {
      filterType: "multiple",
      filters: [
        { name: 'startPayment', fieldType: "date", endPayment: history?.filters?.endPayment, placeholder: "Start", filterType: 'search' },
        { name: 'endPayment', fieldType: "date", startPayment: history?.filters?.startPayment, placeholder: "End", filterType: 'search' },
      ]
    },
    { name: 'payment', fieldType: "text", placeholder: "Payment", filterType: 'search' }
  ];

  useEffect(() => {
    fetchInvoiceDetails();
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchInvoiceDetails = async () => {
    setLoading(true);
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
    };
    const response = await ApiCall.service(GET_PAYMENTS, "POST", postData, false, M_MASTER_DATA);

    setManageData(response?.data?.manageData);

    setPageData((prev: any) => ({
      ...prev,
      totalPages: response?.data?.totalPages,
      totalRecords: response?.data?.totalRecords,
      initialCall: false
    }));
    setLoading(false);
  };

  const handlePayment = (item: ManageDataProps) => {
    // dispatch(addPageToHistory({
    //     pageName: PAGENAMES.INVOICES, route: `/create/invoice/${item.id}`,
    // }));
    // Navigate to the invoices create page with the payment data
    navigate(`${PATH_CREATE_INVOICE}/${item.id}`);
  }

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Payments",
          read: true,
        },
      ]}
      override={userData.isSuperAdmin}
      renderNoAccess={true}
    >
      <div>
        <Suspense
          fallback={
            <div className="text-center text-danger">{t("Loading")}</div>
          }
        >
          <Title title={t("Manage payments")} />
          <div className="position-relative tableMainWrapper managePayments mt-0">
            <div className="table-responsive tableSection">
              <div>
                <FilterOrganism
                  dispatch={dispatch}
                  history={history}
                  updatePageFilters={updatePageFilters}
                  filters={filters}
                  handleRefresh={handleRefresh}
                />
              </div>
              <table className="table table-hover">
                <thead>
                  <tr className='TableHeader'>
                    {PaymentTableHeader?.map((header: any) => (
                      <th key={header?.alias}>
                        <>
                          {t(header.name)}
                          {header?.issort && (
                            <SortAtomForManagePage
                              value={{ alias: header?.alias }}
                              dispatch={dispatch}
                              history={history}
                              updatePageFilters={updatePageFilters}
                              handleRefresh={handleRefresh}
                            />
                          )}
                        </>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {manageData && manageData.length > 0 ? (
                    manageData.map((item: ManageDataProps, index: number) => (
                      <tr
                        key={index}
                        className="border mb-3 box-shadow align-middle"
                        onClick={() => handlePayment(item)}
                      >
                        <td className="text-break" data-label={t("Invoice Number")}>
                          {item?.invoiceNumber}
                        </td>
                        <td className="text-break" data-label={t("company")}>
                          {item?.company}
                        </td>
                        <td className="text-break" data-label={t("Invoice Date")}>
                          {CommonServices?.getFormattedDate(item?.invoiceDate)}
                        </td>
                        <td className="text-break" data-label={t("Expiration Date")}>
                          {CommonServices?.getFormattedDate(item?.expirationDate)}
                        </td>
                        <td className="text-break" data-label={t("Invoice Amount")}>
                          {item?.invoiceAmount}
                        </td>
                        <td className="text-break" data-label={t("Payment Date")}>
                          {CommonServices?.getFormattedDate(item?.paymentDate)}
                        </td>
                        <td className="text-break" data-label={t("Payment Status")}>
                          {item?.payment}
                        </td>
                        <td></td>
                      </tr>
                    ))
                  ) : (
                    <NoRecords headerLength={8} />
                  )}
                </tbody>
              </table>
              {
          !loading && <div className="">
            <PaginationWithPerPage
              handleRefresh={handleRefresh}
              dispatch={dispatch}
              history={history}
              pageData={pageData}
            />
          </div>
        }
            </div>
          </div>
          <div className="row backPadding">
            <div className="col-md-6 align-self-center">
              <Link
                to="/config/settings"
                className="back-btn text-decoration-underline"
              >
                {t("BACK")}
              </Link>
            </div>
          </div>
        </Suspense>
       
      </div>
    </AccessControl>
  );
};

export default ManagePayments;
