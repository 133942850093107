import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import { useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { t } from "pages/microservices/masterData/translation/Translation";

import Button from "components/atoms/Button";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import * as END_POINTS from "routes/ApiEndpoints";
import { Option, ValidationRules } from "utils/TypeAnnotations";

import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import LabelField from "components/atoms/LabelField";
import ModalPopup from "components/atoms/ModalPopup";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { UploadCertificate } from "pages/microservices/CandidateCreation/annotations/CandidateInterface";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import { log } from "console";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

import {
  REMOVE_TEMP_AGENCY_DOCUMENT_FIELD,
  TEMP_AGENCY_ADD_DOCUMENT,
} from "../context/Constants";
import { useFormContext } from "../context/Context";

interface DocumentProps {
  [key: string]: string | Date | number | null | boolean | Option | object[];
  id: number | null;
  documentName: string;
  documentCategory: Option | null;
  documentId: number | null;
  fileName: string;
  filePath: string | null;
  extension: string;
  uploadedBy: string;
  createdAt: string;
  editId: number | null;
}
export interface documents {
  id: number | null;
  editId: number | null;
  fileUpload: UploadCertificate;
  fileName: string;
  fileExtension: string;
  category: Option | null;
  uploadedBy: string | null;
  createdAt: string | null;
}

interface SearchFields {
  documentCategory: { value: number; label: string } | null;
  extension: { value: string; label: string } | null;
  documentName: string;
}
interface ICompanyFormDocumentTabProps {
  type?: string | any;
}
const TempAgencyDocument: React.FC<ICompanyFormDocumentTabProps> = ({
  type,
}) => {
  const { state, dispatch } = useFormContext();

  const [index, setIndex] = useState(0);

  const userAuth = useSelector(selectAuth);
  const documentInitialState = {
    id: null,
    editId: null,
    fileUpload: {
      accessUrl: "", // Initialize with an empty string
      fileName: "", // Initialize with an empty string
    },
    fileName: "",
    fileExtension: "",
    category: null,
    uploadedBy: "",
    createdAt: "",
  };
  const extensionOptions = [
    { value: 1, label: "doc" },
    { value: 2, label: "docx" },
    { value: 3, label: "jpeg" },
    { value: 4, label: "jpg" },
    { value: 5, label: "pdf" },
    { value: 6, label: "png" },
  ];

  const [searchFields, setSearchFields] = useState<SearchFields>({
    documentCategory: null,
    extension: null,
    documentName: "",
  });
  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();
  const [formData, setFormData] = useState<documents>(documentInitialState);

  //   // Check if 'mode=view' is present in the query string
  const isViewMode = false;
  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const { name, value, type, checked } = event.target;
    const { name, value, type, checked } = event.target as HTMLInputElement;
    let updatedValue: string | number | null = value;
    if (type === "checkbox") {
      updatedValue = checked ? 1 : 0;
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    validation(name, updatedValue, true);
  };
  const validation = (
    name: string,
    value: string | number | null | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      fileUpload: [
        (value: { fileName: any }) =>
          value?.fileName ? null : "File is required",
      ],
      fileName: [validateRequired],
      category: [validateSelectField],
    };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: selectedOption,
    }));
    //Filters
    // setSearch((prevData: any) => ({
    //   ...prevData,
    //   [fieldName]: selectedOption,
    // }));
    validation(name, selectedOption, true);
  };
  const editDocumentData = (index: number | null) => {
    if (index !== null) {
      setModal(true);
      const documentToEdit = state.tempAgencyDocument[index];
      // Get the certificate to edit
      if (documentToEdit) {
        setFormData((prev) => ({
          ...prev,
          id: documentToEdit.id || null,
          editId: index,
          fileUpload: {
            ...prev.fileUpload,
            fileName: documentToEdit.fileUpload.fileName || "",
            accessUrl: documentToEdit.fileUpload.accessUrl || "",
          },
          fileName: documentToEdit.fileName || "",
          fileExtension: documentToEdit.fileExtension || "",
          category: (documentToEdit.category as Option) || null,
          uploadedBy: userAuth?.name || "",
          updateAt: documentToEdit.createdAt || "",
        }));
      }
    }
  };
  const removeDocumentFieldSet = (indexToRemove: number | null) => {
    if (indexToRemove !== null) {
      dispatch({ type: REMOVE_TEMP_AGENCY_DOCUMENT_FIELD, index });
    }
  };
  const handleSelectFieldForSearch = (
    selectedOption: any,
    fieldName: string
  ) => {
    setSearchFields((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };
  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);

  const [modal, setModal] = useState(false);
  const closeModal = () => {
    setModal(false);
    resetFields();
    setErrors({});
  };
  const resetFields = () => {
    setFormData({
      id: null,
      editId: null,
      fileUpload: {
        accessUrl: "", // Initialize with an empty string
        fileName: "", // Initialize with an empty string
      },
      fileName: "",
      fileExtension: "",
      category: null,
      uploadedBy: "",
      createdAt: "",
    });
  };
  const handleFileChange = async (event: any, field: any) => {
    let fileData: any = null;

    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "documents");
    }
    const fileName = event?.name;
    const index = fileName?.lastIndexOf(".");
    const name = index !== -1 ? fileName?.slice(0, index) : fileName;
    const extension = index !== -1 ? fileName?.slice(index + 1) : "";
    setFormData((prevData: any) => ({
      ...prevData,
      fileName: name,
      fileExtension: extension,
      uploadedBy: userAuth.name,
      [field]: fileData,
    }));
    validation(field, fileData, true);
    validation("fileName", event?.name, true);
  };

  const addData = (index: number) => {
    setFormData((prevData: any) => ({
      ...prevData,
      editId: index,
    }));
    setIndex(index); // Set the index for dispatch
    setModal(true);
  };
  const handleAddDocument = (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-GB"); // 'en-GB' gives dd-mm-yyyy format
      // Update formData with the current date in updated_at
      const updatedFormData = {
        ...formData,
        createdAt: formattedDate,
      };
      dispatch({
        type: TEMP_AGENCY_ADD_DOCUMENT,
        data: updatedFormData,
        index: index,
      });
      setModal(false); // Close modal
      resetFields();
      setErrors({});
    }
  };
  const handleInputFieldForSearch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setSearchFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <>
      <div className="col-12">
        <div className="form-border" style={{ paddingTop: "2vw" }}>
          <div className="row">
            <div className="col-12">
              <LabelField title={t("Documents")} className="tab-subtitle" />
            </div>
            <div className="col-sm-12 col-md-4">
              <SelectWithSearch
                title={t("Category")}
                name="documentCategory"
                isMandatory={false}
                search={true}
                options={state.options.documentCategoryList}
                placeHolder="Select"
                value={searchFields.documentCategory}
                onChange={(e) =>
                  handleSelectFieldForSearch(e, "documentCategory")
                }
                isMulti={false}
                className="select-field"
              //   error={state.fieldError.locationCountry}
              // isDisabled={isViewMode}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <LabelWithInputField
                isMandatory={false}
                label={t("File name")}
                name={"documentName"}
                handleChange={(event) => handleInputFieldForSearch(event)}
                value={searchFields.documentName ?? ""}
                id={`documentName`}
                placeholder="File name"
              //   error={state.fieldError.locationName}
              //  isDisabled={isViewMode}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <SelectWithSearch
                title={t("File type")}
                name="extension"
                isMandatory={false}
                search={true}
                options={extensionOptions}
                placeHolder="Select"
                value={searchFields.extension}
                onChange={(e) => handleSelectFieldForSearch(e, "extension")}
                isMulti={false}
                className="select-field"
                //   error={state.fieldError.locationCountry}
                isDisabled={isViewMode}
              />
            </div>
          </div>
          <div className="col-12">
            <div
              className="pwa table-responsive overflow-auto scrollBarDesign"
              style={{ maxHeight: "calc(100vh - 30vw)" }}
            >
              <table className="table table-hover">
                <thead className="position-sticky top-0 companyDocumentsThead">
                  <tr className="border-bottom TableHeader bg-white">
                    <th className="border-0 align-middle">{t("File")}</th>
                    <th className="border-0 align-middle">{t("File type")}</th>
                    <th className="border-0 align-middle">{t("Category")}</th>
                    <th className="border-0 align-middle">
                      {t("Uploaded by")}
                    </th>
                    <th className="border-0 align-middle">
                      {t("Uploaded at")}
                    </th>
                    <th className="border-0 align-middle">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {state.tempAgencyDocument
                    .filter((document) => {
                      // If no category is selected, include all documents
                      if (
                        !searchFields.documentCategory ||
                        searchFields.documentCategory.label === "Select"
                      ) {
                        // Check if the document name matches the search criteria
                        const fileNameMatches =
                          searchFields.documentName.trim() === "" ||
                          document.fileName
                            .toLowerCase()
                            .includes(searchFields.documentName.toLowerCase());

                        const extensionMatches =
                          !searchFields.extension ||
                          searchFields.extension?.label === "Select" ||
                          document.fileExtension.toLowerCase() ===
                          searchFields.extension?.label;

                        return fileNameMatches && extensionMatches;
                      }

                      // Check if the document matches the selected category
                      const categoryMatches =
                        document.category?.value ===
                        searchFields.documentCategory?.value;

                      // Check if the document matches the file name
                      const fileNameMatches =
                        searchFields.documentName.trim() === "" ||
                        document.fileName
                          .toLowerCase()
                          .includes(searchFields.documentName.toLowerCase());

                      // Check if the document matches the file extension
                      const extensionMatches =
                        !searchFields.extension ||
                        searchFields.extension?.label === "Select" ||
                        document.fileExtension.toLowerCase() ===
                        searchFields.extension?.label;

                      // Include the document if it matches all filter criteria
                      return (
                        categoryMatches && fileNameMatches && extensionMatches
                      );
                    })
                    .map((document, index) => (
                      <tr key={index}>
                        <td className="align-middle text-break">
                          <a
                            className="file-link"
                            href={
                              document.filePath?.startsWith("https")
                                ? document.fileUpload.accessUrl // If filePath starts with "https", use it directly
                                : `${document.fileUpload.accessUrl}` //  `${FILE_URL}/${document.filePath}`Otherwise, construct the URL using FILE_URL and picFilePath
                            }
                            target="_blank"
                          >
                            {document?.fileName}
                          </a>
                        </td>
                        <td
                          className="align-middle text-break"
                          data-label={t("File type")}
                        >
                          {document.fileExtension}
                        </td>
                        <td
                          className="align-middle text-break"
                          data-label={t("Category")}
                        >
                          {document.category?.label}
                        </td>
                        <td
                          className="align-middle text-break"
                          data-label={t("Uploaded by")}
                        >
                          {document?.uploadedBy}
                        </td>
                        <td
                          className="align-middle text-break"
                          data-label={t("Uploaded at")}
                        >
                          {document?.createdAt}
                        </td>
                        <td
                          className="align-middle text-break table-action-icons"
                          data-label={t("Action")}
                        >
                          {!isViewMode && (
                            <>
                              <span
                                title={t("Edit")}
                                onClick={() =>
                                  editDocumentData(document.editId)
                                }
                                className="table-action-btn cursor-pointer marginRightPoint5 shadow-none"
                              >
                                <EditIcon />
                              </span>
                              <span
                                title={t("Delete")}
                                onClick={() =>
                                  removeDocumentFieldSet(document.editId)
                                }
                                className="table-action-btn cursor-pointer shadow-none"
                              >
                                <DeleteIcon />
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            {!isViewMode && (
              <>
                <Button
                  title={"+ " + t("Add")}
                  handleClick={() => addData(state.tempAgencyDocument.length)}
                  className="form-button"
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <ModalPopup
          show={modal}
          onHide={closeModal}
          title={t("Documents")}
          body={
            <div className="row">
              <div className="col-6 fileUploadFullWidth candidateDoumentsFileUpload">
                <MaxiconFileUpload
                  label={t('Upload file')}
                  name={t("fileUpload")}
                  id={t("fileUpload")}
                  isMandatory={true}
                  edit={false}
                  fileId={null}
                  fileObj={JSON.stringify(formData.fileUpload)}
                  multiple={false}
                  uploadPath="company-documents"
                  formats={"value.formats"}
                  handleChange={(e: any) => handleFileChange(e, "fileUpload")}
                  maxFileSize={10000}
                  error={errors.fileUpload}
                // mode={isView}
                />
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center">
                  <LabelWithInputField
                    isMandatory={true}
                    name="fileName"
                    handleChange={handleFieldChange}
                    id="fileName"
                    label="File name"
                    type="text"
                    value={formData.fileName}
                    error={errors.fileName}
                  />
                  <span style={{ marginLeft: "0.25vw" }}>
                    {formData.fileExtension ? `.${formData.fileExtension}` : ""}
                  </span>
                </div>
              </div>
              <div className="col-6">
                <SelectWithSearch
                  title={t("Category ")}
                  isMandatory={true}
                  search={true}
                  options={state.options.documentCategoryList}
                  onChange={(e) => handleSelectChange(e, "category")}
                  isMulti={false}
                  name="category"
                  value={formData.category}
                  error={errors.category}
                  placeHolder="Select"
                //  isDisabled={isView}
                />
              </div>

              <div className="col-12 d-flex justify-content-end">
                {!isViewMode && (
                  <Button
                    title={t("Add")}
                    handleClick={handleAddDocument}
                    className="form-button shadow-none"
                  />
                )}
              </div>
            </div>
          }
          className="modal-lg contractPerfCodeModal"
        />
      </div>
    </>
  );
};

export default TempAgencyDocument;
