import { t } from "pages/microservices/masterData/translation/Translation";
import React, { useState } from "react";
import { decimalToTime, timeToDecimal } from "../utils/calculateUtils";
import TimeField from "react-simple-timefield";
import InputTextfield from "components/atoms/InputTextField";

const ProjectParameterHeader = ({ projectParameters, initialTimesheetState }) => {
  const [convertState, setConvertState] = useState({
    hours: "00:00",
    decimal: "0"
  });

  const renderExceptions = () => {
    const exceptionDays = [
      "Exception monday",
      "Exception tuesday",
      "Exception wednesday",
      "Exception thursday",
      "Exception friday",
      "Exception saturday",
      "Exception saturday & holidays",
    ];

    return exceptionDays.map((day) => {
      const value = projectParameters?.overtime?.[day]?.value;
      if (value) {
        return (
          <tr key={day}>
            <td className="w-50">{t(day)}</td>
            <td className="w-50">{value}hr/day</td>
          </tr>
        );
      }
      return "";
    });
  };

  const renderSurcharges = () => {
    const surcharges = [
      { key: "regime_time", label: t("Fixed"), parent: true},
      { key: "night", label: t("Night") },
      { key: "weekend_saturdays", label: t("Saturday") },
      { key: "weekend_sundays_and_holidays", label: t("Sun/Holiday") },
      { key: "overtime_percentage", label: t("Overtime"), parent: true},
    ];

    return surcharges.map((surcharge) => {
      const value = !surcharge?.parent ? projectParameters?.overtime?.[surcharge.key]?.value : projectParameters?.[surcharge?.key]?.value;
      if (value) {
        return (
          <tr key={surcharge.key}>
            <td className="w-50">{surcharge.label}</td>
            <td className="w-50">{value} %</td>
          </tr>
        );
      }
      return "";
    });
  };

  const renderProjectParameter = () => {
    const template = projectParameters?.template_name;

    if (template === "Daily rate" || template === "Hourly rate") {
      return (
        <>
          <tr>
            <td className="fw-bold">{t("Rates")}</td>
          </tr>
          <tr>
            <td className="w-50">{t("Project rate")}</td>
            <td className="w-50">
              €{projectParameters?.project_rate?.value}/{template === "Hourly rate" ? "hour" : "day"}
            </td>
          </tr>
          <tr>
            <td className="w-50">{t("KM allowance")}</td>
            <td className="w-50">
              €{projectParameters?.km_to_be_invoiced ? projectParameters?.km_package_fee?.value : "0"}/{projectParameters?.km_package_fee?.parameter ?? "package"}
            </td>
          </tr>
          <tr>
            <td className="fw-bold">{t("Overtime")}</td>
          </tr>
          {(projectParameters?.overtime?.["Weekly limit"]?.value || projectParameters?.overtime?.["Day limit"]?.value) && <tr>
            <td className="w-50">
              {projectParameters?.overtime?.["Weekly limit"]?.value ? t("Weekly limit") : t("Day limit")}
            </td>
            <td className="w-50">
              {projectParameters?.overtime?.["Weekly limit"]?.value ?? projectParameters?.overtime?.["Day limit"]?.value ?? "00:00"} hr
            </td>
          </tr>}
          {renderExceptions()}
          <tr>
            <td className="fw-bold">{t("Surcharges")}</td>
          </tr>
          {renderSurcharges()}
        </>
      );
    } else if (template === "Project price" || template === "Selectie fee") {
      return (
        <>
          <tr>
            <td className="fw-bold">{t("Rates")}</td>
          </tr>
          <tr>
            <td className="w-50">{t("Project amount")}</td>
            <td className="w-50">
              €{projectParameters?.amount} /
              {template === "Selectie fee"
                ? `${projectParameters?.time_period} ${Number(projectParameters?.time_period) === 1 ? t("month") : t("months")}`
                : projectParameters?.time_period}
            </td>
          </tr>
        </>
      );
    } else {
      return <tr>
        <td colSpan={2} className="text-center">
          {t("No template")}
        </td>
      </tr>;
    }
  };

  return (
    <>
      <div className="moreInfoSection">
        <table className="w-100 projectParameterTable">
          {/* <thead>
            <tr>
              <th colSpan="2" className="bg-lightskyblue">
                {t("More info")}
              </th>
            </tr>
          </thead> */}
          <tbody>
            {initialTimesheetState?.more_info && (
              <>
                <tr>
                  <td className="border-bottom">
                    <span className="fw-bold">{t("Company:")} </span>
                    <span className="cursor-pointer nameHoverClass" onClick={() => window.open(`/company/view/${initialTimesheetState?.more_info?.company?.id}`, "_blank")}>
                      <span className="hoverTextName">
                        {initialTimesheetState?.more_info?.company?.name}
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="border-bottom">
                    <span className="fw-bold">{t("Project:")} </span>
                    <span className="cursor-pointer nameHoverClass" onClick={() => window.open(`/project/view/${initialTimesheetState?.more_info?.project?.id}`, "_blank")}>
                      <span className="hoverTextName">
                        {initialTimesheetState?.more_info?.project?.name}
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="border-bottom">
                    <span className="fw-bold">{t("Employee:")} </span>
                    <span className="cursor-pointer nameHoverClass" onClick={() => window.open(`/candidate/view/${initialTimesheetState?.more_info?.user?.id}`, "_blank")}>
                      <span className="hoverTextName">
                        {initialTimesheetState?.more_info?.user?.name}
                      </span>
                    </span>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="projectParametersSection">
        <table className="w-100 projectParameterTableTwo">
          <thead>
            <tr>
              <th colSpan="2" className="bg-lightskyblue projectParameterTableTwoTitle">
                {t("Project parameters")}
              </th>
            </tr>
          </thead>
          <tbody>
            {projectParameters && (
              <>
                <tr className="fw-bold">
                  <td className="w-50">{t("Template name")}</td>
                  <td className="w-50">{projectParameters?.template_name}</td>
                </tr>
                {renderProjectParameter()}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="ConvertToHours">
        <table className="w-100 projectParameterTableTwo">
          <thead>
            <tr>
              <th colSpan="2" className="bg-lightskyblue projectParameterTableTwoTitle">
                {t("Convert to hours")}
              </th>
            </tr>
          </thead>
          <tbody>
            {projectParameters && (
              <>
                <tr className="fw-bold">
                  <td className="w-50 convertToHoursTime">
                    <TimeField
                      // name={name}
                      value={convertState?.hours}
                      onChange={(event, value) => setConvertState((prev) => ({ ...prev, hours: value }))}
                      colon=":"
                      showSeconds={false}
                      style={{
                        width: '100%',
                        height: "100%",
                        // border:"0.052vw solid #fff",
                        // outline:"0",
                        textAlign: "left", paddingLeft: "0.5vw"
                      }}
                    />
                  </td>
                  <td className="w-50">
                    {timeToDecimal?.(convertState?.hours)?.toString()}u
                  </td>
                </tr>
                <tr className="fw-bold">
                  <td className="w-50 convertToHoursTimeFieldTwo">
                    <InputTextfield
                      name="decimal"
                      value={convertState?.decimal}
                      handleChange={(e) => {
                        const value = e.target.value.replace(/[^0-9.,]/g, "");
                        setConvertState((prev) => ({ ...prev, decimal: value }));
                      }}
                    />
                  </td>
                  <td className="w-50">
                    {convertState?.decimal?.trim() !== "" ? decimalToTime?.(convertState?.decimal, ":") : "00:00"}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ProjectParameterHeader;
