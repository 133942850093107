import { M_MASTER_DATA } from "constants/Constants";
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ApiCall } from "services/ApiServices";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import ManageContractTypeOrganism from "./Organisms/ManageContractTypeOrganism";
import { CONTRACT_TYPE_MANAGE } from "routes/ApiEndpoints";
import { selectAuth } from "features/auth/AuthSlice";
import AccessControl from "services/AccessControl";

const ManageContractType: React.FC<any> = () => {
  const dispatch = useDispatch();
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const userData = useSelector(selectAuth);

  const [state, setState] = useState({
    data: [],
    options: {
      status: [
        {value: 1, label: 'Active'},
        {value: 2, label: 'Inactive'},
      ]
    },
    initialCall: true,
    totalRecords: 0,
    totalPages: 0,
    popup: {
      delete: {
        show: false,
        data: {}
      }
    },
    refresh: true
  })

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
      initialCall: state?.initialCall,
      search: {
        name: history?.filters?.name ?? "",
        coefficient: history?.filters?.coefficient ?? "",
        status: history?.filters?.status ?? "",
        orderBy: history?.filters?.orderBy ?? "id",
        sortDirection: history?.filters?.sortDirection ?? "desc",
      },
    };
    fetchData(postData);
  }, [state?.refresh, state?.initialCall]);

  const fetchData = async (postData: any) => {
    const response = await ApiCall.service(CONTRACT_TYPE_MANAGE, "POST", postData, false, M_MASTER_DATA);
    if (response?.status === 200) {
      let data: any = { ...state, data: response?.data ?? [], initialCall: false, totalPages: response?.totalPages, totalRecords: response?.totalRecords };
      setState(data);
    }
    return response ?? [];
  };

  return (
    <AccessControl
      requiredPermissions={[{ permission: "Contract type", read: true }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <Suspense fallback={<div className="text-center text-danger"></div>}>
        <ManageContractTypeOrganism
          state={state}
          dispatch={dispatch}
          history={history}
          setState={setState}
          userData={userData}
        />
      </Suspense>
    </AccessControl>
  );
};

export default ManageContractType;
