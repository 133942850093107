import { M_MASTER_DATA } from "constants/Constants";
import { SAVE_TRANSLATION } from "routes/ApiEndpoints"; 

const getTargetkey = (key: string): string => {
    return key?.trim()?.split(' ')?.join('_')?.toLowerCase();
};

const lookupTranslations = (translations: any, key: string): any => {
    try {
        return translations && key in translations ? translations[key] : false;
    } catch (e) {
        console.error(e); 
    }
    return undefined; 
};

const checkDatabase = async (postdata: any, targetkey: any): Promise<string | undefined> => {
    let response: any = {};
    try {
        const { ApiCall } = await import("services/ApiServices");
        response = await ApiCall.service(SAVE_TRANSLATION, 'POST', postdata, false, M_MASTER_DATA);
    } catch (e) {
        // console.error(e);
    }
    const translationData = response?.data?.[targetkey] ?? postdata?.string;
    // Update localStorage with the new translation
    updateLocalStorageTranslations(targetkey, translationData);
    return translationData;
};

const updateLocalStorageTranslations = (targetkey: string, translation: string): void => {
    try {
        let translations = JSON.parse(localStorage.getItem('translations') || '{}');
        translations[targetkey] = translation;
        localStorage.setItem('translations', JSON.stringify(translations));        
    } catch (e) {
        console.error(e);
    }
};

export const t = (key: string): string => {
    try {
        return key;
        const translations = JSON.parse(localStorage.getItem('translations') || '{}');
        const userLanguage = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}')?.user_language;
        if (!key || key === "") return key;
        let targetkey = getTargetkey(key);
        let translation = lookupTranslations(translations, targetkey);
        if (!translation) {
            let postdata = {
                string: key,
                language_id: userLanguage // Use localStorage for language preference
            };
            checkDatabase(postdata, targetkey).then((translatedValue) => {
                translation = translatedValue || key;
            });
            return key; // Return the key temporarily
        }
        return translation ?? key;
    } catch (error) {
        return key;
    }
};
