import { ITagAtomProps } from "components/atoms/TagAtom";
import {
  ADD_UPDATE_DYNAMIC_COMPANY_CONTACT_PERSON,
  ADD_UPDATE_DYNAMIC_COMPANY_DOCUMENT,
  ADD_UPDATE_DYNAMIC_COMPANY_LOCATION,
  COMPANY_ADD_DOCUMENT,
  DELETE_COMPANY_CONTACT_PERSON_INDEX,
  DELETE_COMPANY_LOCATION_INDEX,
  EDIT_COMPANY_CONTACT_PERSON_FIELD,
  EDIT_COMPANY_LOCATION_FIELD,
  INITIAL_STATE,
  REMOVE_COMPANY_DOCUMENT_FIELD,
  SET_COMPANY_OPTION,
  SET_ERRORS_COMPANY_ADDRESS_TAB,
  SET_ERRORS_COMPANY_BASIC_TAB,
  SET_ERRORS_COMPANY_CONTACT_PERSON_TAB,
  SET_FIELD_ERROR_COMPANY_BASIC_TAB,
  SET_MODE,
  SWITCH_TAB,
  UPDATE_ADDRESS_DEFAULT,
  UPDATE_COMPANY_ADDRESS_ERROR,
  UPDATE_COMPANY_ADDRESS_FIELD,
  UPDATE_COMPANY_BASIC_FIELD,
  UPDATE_COMPANY_CONTACT_PERSON_EDITED_FIELD,
  UPDATE_COMPANY_CONTACT_PERSON_FIELD_ERROR,
  UPDATE_COMPANY_CONTACT_PERSON_TABLE_FIELD,
  UPDATE_COMPANY_CONTACTS_FIELD,
  UPDATE_COMPANY_LOCATION_EDITED_FIELD,
  UPDATE_COMPANY_LOCATION_FIELD,
  UPDATE_COMPANY_LOCATION_TABLE_FIELD,
  UPDATE_CURRENT_TAB,
  UPDATE_TAB_DATA,
  UPDATE_TAB_ERROR,
  UPDATE_VIEW_MODE,
} from "./Constants";

type CompanyReducerProps = {
  state: any;
  action: any;
};

const CompanyReducer = (
  state: CompanyReducerProps["state"],
  action: CompanyReducerProps["action"]
) => {
  switch (action.type) {
    case SET_MODE:
      return {
        ...state,
        [action.mode]: action.payload.value,
      };
    case SWITCH_TAB:
      return {
        ...state,
        [action.field]: action.value,
      };
    case INITIAL_STATE:
      return { ...JSON.parse(JSON.stringify(action.payload)) };

    case UPDATE_TAB_ERROR:
      return {
        ...state,
        tabDetails: state.tabDetails.map((tab: any, index: any) => ({
          ...tab,
          error: index === action.tabIndex ? action.error : tab.error,
          draft: action.draft,
        })),
      };
    case SET_ERRORS_COMPANY_BASIC_TAB:
      return {
        ...state,
        companyBasic: {
          ...state.companyBasic, // Preserve existing data
          errors: action.errors, // Update only the errors
        },
      };

    case SET_ERRORS_COMPANY_ADDRESS_TAB:
      const updatedCompanyAddressTabWithErrors = {
        ...state,
        companyAddress: {
          data: state.companyAddress.data,
          errors: action.errors,
        },
      };
      return updatedCompanyAddressTabWithErrors;
    case SET_ERRORS_COMPANY_CONTACT_PERSON_TAB:
      const updatedCompanyContactPersonTabWithErrors = {
        ...state,
        companyContactPerson: {
          data: state.companyContactPerson.data,
          errors: action.errors,
        },
      };
      return updatedCompanyContactPersonTabWithErrors;

    case SET_COMPANY_OPTION:
      const options = { ...state.options };
      options[action.field] = action.value;
      return { ...state, options };
    case UPDATE_CURRENT_TAB:
      return {
        ...state,
        [action.field]: action.value,
      };
    case UPDATE_COMPANY_BASIC_FIELD:
      return {
        ...state,
        companyBasic: {
          ...state.companyBasic, // Preserve existing properties (e.g., errors)
          data: {
            ...state.companyBasic.data, // Preserve existing fields in data
            [action.field]: action.value, // Update the specific field
          },
        },
      };
    case SET_FIELD_ERROR_COMPANY_BASIC_TAB:
      let updatedbasicTabErrors = state.companyBasic.errors;
      updatedbasicTabErrors[action.fieldName] = action.error;

      const updatedGeneralTabWithFieldError = {
        ...state,
        companyBasic: {
          data: state.companyBasic.data,
          errors: updatedbasicTabErrors,
        },
      };
      return updatedGeneralTabWithFieldError;
    case UPDATE_COMPANY_ADDRESS_FIELD:
      return {
        ...state,
        companyAddress: {
          ...state.companyAddress, // Ensure you spread the existing `address` state
          data: {
            ...state.companyAddress.data, // Only update the `data` part
            [action.field]: action.value,
          },
        },
      };
    case UPDATE_COMPANY_ADDRESS_ERROR:
      return {
        ...state,
        companyAddress: {
          ...state.companyAddress, // Ensure you spread the existing `address` state
          errors: {
            ...state.companyAddress.errors, // Only update the `data` part
            [action.fieldName]: action.error,
          },
        },
      };

    case UPDATE_COMPANY_LOCATION_FIELD: {
      return {
        ...state,
        companyLocation: {
          ...state.companyLocation, // Ensure you spread the existing `address` state
          data: {
            ...state.companyLocation.data, // Only update the `data` part
            [action.field]: action.value,
          },
        },
      };
    }
    case UPDATE_COMPANY_LOCATION_TABLE_FIELD: {
      const newValue = action.value;
      return {
        ...state,
        companyLocationTable: [...state.companyLocationTable, newValue],
      };
    }

    case EDIT_COMPANY_LOCATION_FIELD: {
      const entryIndex = action.index; // Get the index of the entry to edit
      const entryToEdit = state.companyLocationTable[entryIndex];

      // Check if the entry exists to avoid errors
      if (!entryToEdit) {
        return state; // Return the current state if the entry doesn't exist
      }

      return {
        ...state,
        companyLocation: {
          data: {
            ...state.companyLocation.data, // Keep all existing keys
            ...entryToEdit, // Override with entry data
            id: entryIndex, // Set the id field
          },
        },
      };
    }
    case UPDATE_COMPANY_LOCATION_EDITED_FIELD:
      const editedLocation = action.value;
      const editedLocationIndex = editedLocation.id;
      const updatedCompanyLocationTable = [...state.companyLocationTable];
      updatedCompanyLocationTable[editedLocationIndex] = {
        ...updatedCompanyLocationTable[editedLocationIndex],
        ...editedLocation,
      };

      return {
        ...state,
        companyLocationTable: updatedCompanyLocationTable, // Set the updated array back to state
      };
    case DELETE_COMPANY_LOCATION_INDEX:
      const deleteIndex = action.index;

      return {
        ...state,
        companyLocationTable: state.companyLocationTable.filter(
          (_: any, index: any) => index !== deleteIndex
        ),
      };
    case UPDATE_COMPANY_CONTACTS_FIELD:
      return {
        ...state,
        companyContactPerson: {
          ...state.companyContactPerson,
          data: {
            ...state.companyContactPerson.data, // Only update the `data` part
            [action.field]: action.value,
          },
        },
      };
    case UPDATE_COMPANY_CONTACT_PERSON_FIELD_ERROR:
      return {
        ...state,
        companyContactPerson: {
          ...state.companyContactPerson,
          errors: {
            ...state.companyContactPerson.errors, // Keep existing errors
            [action.fieldName]: action.error, // Update the specific error field
          },
        },
      };
    case UPDATE_COMPANY_CONTACT_PERSON_TABLE_FIELD:
      const newContactValue = action.value;
      return {
        ...state,
        companyContactPersonTable: [
          ...state.companyContactPersonTable,
          newContactValue,
        ],
      };
    case EDIT_COMPANY_CONTACT_PERSON_FIELD:
      const contactEntryIndex = action.index; // Get the index of the entry to edit

      // Retrieve the data from companyLocationTable at the specified index
      const contactEntryToEdit =
        state.companyContactPersonTable[contactEntryIndex];

      // Check if the entry exists to avoid errors
      if (!contactEntryToEdit) {
        return state; // Return the current state if the entry doesn't exist
      }
      return {
        ...state,
        companyContactPerson: {
          data: {
            ...state.companyContactPerson.data, // Keep all existing keys
            ...contactEntryToEdit, // Override with entry data
            id: contactEntryIndex, // Set the id field
          },
        },
      };
    case UPDATE_COMPANY_CONTACT_PERSON_EDITED_FIELD:
      const editedContacts = action.value;
      const editedContactsIndex = editedContacts.id;
      const updatedCompanyContactTable = [...state.companyContactPersonTable];
      updatedCompanyContactTable[editedContactsIndex] = {
        ...updatedCompanyContactTable[editedContactsIndex],
        ...editedContacts,
      };
      return {
        ...state,
        companyContactPersonTable: updatedCompanyContactTable,
      };

    case DELETE_COMPANY_CONTACT_PERSON_INDEX:
      const deleteContactIndex = action.index;

      return {
        ...state,
        companyContactPersonTable: state.companyContactPersonTable.filter(
          (_: any, index: any) => index !== deleteContactIndex
        ),
      };
    case COMPANY_ADD_DOCUMENT:
      const { data: documentData, index: documentIndex } = action;
      const newDocuments = [...state.companyDocument];

      // Update the specific coefficient at the given index
      newDocuments[documentIndex] = {
        ...documentData,
      };
      // Return the new state, updating remenurationDetails specifically
      return {
        ...state,
        companyDocument: newDocuments,
      };
    case REMOVE_COMPANY_DOCUMENT_FIELD:
      const updatedDocuments = [...state.companyDocument];
      updatedDocuments.splice(action.index, 1);
      // Update the editId for remaining elements
      const documentsWithUpdatedIds = updatedDocuments.map((doc) => {
        // If editId is a number and greater than the removed index, decrement it
        if (typeof doc.editId === "number" && doc.editId > action.index) {
          return { ...doc, editId: doc.editId - 1 };
        }
        return { ...doc };
      });
      return {
        ...state,
        companyDocument: documentsWithUpdatedIds,
      };
    case UPDATE_TAB_DATA:
      const tabData = state[action.field];
      tabData.data = action.value;
      return { ...state, [action.field]: tabData };
    case ADD_UPDATE_DYNAMIC_COMPANY_LOCATION:
      return {
        ...state,
        companyLocationTable: action.value,
      };
    case ADD_UPDATE_DYNAMIC_COMPANY_CONTACT_PERSON:
      return {
        ...state,
        companyContactPersonTable: action.value,
      };
    case ADD_UPDATE_DYNAMIC_COMPANY_DOCUMENT:
      return {
        ...state,
        companyDocument: action.data,
      };
    case UPDATE_VIEW_MODE:
      return {
        ...state,
        isViewMode: action.value === "View",
      };
    case UPDATE_ADDRESS_DEFAULT:
      const addressData = action?.value ? {
        initialLat: "50.8503",
        initialLn: "4.3517",
        biInitialLat: "50.8503",
        biInitialLn: "4.3517",
      } : {
        initialLat: "",
        initialLn: "",
        biInitialLat: "",
        biInitialLn: ""
      };

      const locationData = action?.value ? {
        initialLat: "50.8503",
        initialLn: "4.3517",
      } : {
        initialLat: "",
        initialLn: "",
      }

      return {
        ...state,
        companyAddress:
        {
          ...state.companyAddress,
          data: { ...state.companyAddress.data, ...addressData }
        },
        companyLocation: {
          ...state.companyLocation,
          data: {
            ...state.companyLocation.data,
            ...locationData
          }
        },
      };

    default:
      return state;
  }
};

export default CompanyReducer;
