import { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import { Accordion } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import * as ENDPOINTS from "routes/ApiEndpoints"
import AccessControl from "services/AccessControl";
import Title from "components/atoms/Title";
import CheckBoxField from "components/atoms/CheckBoxField";
import RadioField from "components/atoms/RadioField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { M_MASTER_DATA } from "constants/Constants";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import BackButton from "components/atoms/BackButton";
import { EDIT_CANDIDATE, PATH_MANAGE_APPLICANTS } from "constants/Paths";
import CompetenceMolecule from "components/molecules/Competence";
import TitleAtom from "components/atoms/Title";

interface ScreeningProps {
  questions: any;
  competence: any;
  competenceOptions: any,
}

const ScreeningAssessment: React.FC = () => {
  const [formData, setFormData] = useState<ScreeningProps>({
    questions: [],
    competence: [],
    competenceOptions: [],
  });
  const { screeningId, candidateId } = useParams<{
    screeningId: string;
    candidateId: string;
  }>();
  const navigate = useNavigate();

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    groupIndex: number,
    questionIndex: number
  ) => { };

  useEffect(() => {
    const fetchScreeningDetails = async () => {
      const response = await ApiCall.getService(
        `${ENDPOINTS.GET_SCREENING_ASSESSMENT}/${screeningId}`,
        "GET",
        M_MASTER_DATA
      );
      if (response?.status == 200) {
        setFormData((prevData) => ({
          ...prevData,
          questions: response?.data?.data,
          competence: response?.data?.competence,
          competenceOptions: response?.data?.competenceOptions
        }));
      }
    };
    fetchScreeningDetails();
  }, []);

  const handleClick = (value: boolean) => {
    let candidateResult = true;
    if (value) {
      candidateResult = true;
      navigate(`${EDIT_CANDIDATE}/${candidateId}`);
    } else {
      candidateResult = false;
      navigate(`${PATH_MANAGE_APPLICANTS}`);
    }
    const postData = {
      id: candidateId,
      candidateResult: candidateResult
    }
    ApiCall.service(`${ENDPOINTS.CANDIDATE_RESULT}`, "POST", postData, true, M_MASTER_DATA).then((response) => {
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
      }
    });
  }

  const noOfEle = formData?.questions?.map((eachItem: any, index: number) => index.toString()) || [];


  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "Screening",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
      <div className="search-bar">
        <Title title={t("View screening")} />
      </div>
      <div className="row">
        {formData?.competence.length > 0 && (
          <div className="col-12 marginBottomPoint5">
            <TitleAtom title={t("Competences")} />
            <CompetenceMolecule
              competenceOptions={formData?.competence}
              data={formData?.competence}
              setFormData={setFormData}
              isViewmode={true}
            />
          </div>
        )}
        <div className="col-12">
          {formData?.questions?.length > 0 && (
            <>
              <TitleAtom title={t("Questions")} className="marginTop1" />
              <div className="screening-heightAssessment marginBotttom1">
                <div className="form-border">
                  {formData.questions.map(
                    (questionGroup: any, groupIndex: number) => (
                      <div key={groupIndex}>
                        <div className="marginBotttom1">
                          <Accordion defaultActiveKey={noOfEle} alwaysOpen>
                            <Accordion.Item eventKey={`${groupIndex}`}>
                              <Accordion.Header className="fw-bold">
                                {questionGroup.function.label}
                              </Accordion.Header>
                              <Accordion.Body className="bg-white">
                                {questionGroup?.questions?.length &&
                                  questionGroup?.questions?.length > 0 ? (
                                  <>
                                    {questionGroup.questions.map(
                                      (question: any, index: number) => (
                                        <div
                                          key={index}
                                          className={` p-3 rounded-3 mb-3 border ${question.disabled
                                            ? " border question-not-applicable-section opacity-50 p-3 rounded-3 mb-3"
                                            : ""
                                            }`}
                                        >
                                          <div className="d-flex justify-content-between align-items-center">
                                            <h5
                                              className="questionName"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  index +
                                                  1 +
                                                  ") " +
                                                  question.question,
                                              }}
                                            />
                                          </div>
                                          {question.questionType === 1 &&
                                            question?.options?.length >
                                            0 && (
                                              <div
                                                className="my-1"
                                                key={index}
                                              >
                                                {question.options.map(
                                                  (
                                                    option: any,
                                                    optionIndex: number
                                                  ) => (
                                                    <div
                                                      key={optionIndex}
                                                      className="screening-checkbox d-flex align-items-center mb-2"
                                                    >
                                                      <CheckBoxField
                                                        name={`answers-${index}-${optionIndex}`}
                                                        id={`answers-${index}-${optionIndex}`}
                                                        onChangeHandler={(
                                                          event
                                                        ) =>
                                                          handleFieldChange(
                                                            event,
                                                            groupIndex,
                                                            index
                                                          )
                                                        }
                                                        value={
                                                          optionIndex
                                                        }
                                                        disable={true}
                                                        isChecked={
                                                          Array.isArray(
                                                            question.answers
                                                          ) &&
                                                          question.answers.includes(
                                                            String(
                                                              optionIndex
                                                            )
                                                          )
                                                        }
                                                      />

                                                      <span
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            option.replace(
                                                              /<a/g,
                                                              '<a target="_blank"'
                                                            ),
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}

                                          {question.questionType === 3 &&
                                            question?.options?.length >
                                            0 && (
                                              <div
                                                className="my-1"
                                                key={index}
                                              >
                                                {question.options.map(
                                                  (
                                                    option: any,
                                                    optionIndex: number
                                                  ) => (
                                                    <div
                                                      key={optionIndex}
                                                      className="d-flex screening-checkbox align-items-center mb-2"
                                                    >
                                                      <RadioField
                                                        className="ps-1"
                                                        id={`answers-${index}-${optionIndex}`}
                                                        value={optionIndex}
                                                        name={`answers-${groupIndex}-${index}`}
                                                        handleChange={(
                                                          event
                                                        ) => {
                                                          handleFieldChange(
                                                            event,
                                                            groupIndex,
                                                            index
                                                          );
                                                        }}
                                                        disable={true}
                                                        ischecked={Array.isArray(
                                                          question.answers
                                                        ) &&
                                                          question.answers.includes(
                                                            String(
                                                              optionIndex
                                                            )
                                                          )}
                                                        label={undefined} />
                                                      <span
                                                        dangerouslySetInnerHTML={{
                                                          __html: option,
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          {question.questionType ===
                                            2 && (
                                              <LabelWithTextAreaField
                                                name="description"
                                                handleChange={(event) =>
                                                  handleFieldChange(
                                                    event,
                                                    groupIndex,
                                                    index
                                                  )
                                                }
                                                label=""
                                                value={
                                                  question?.answers?.length >
                                                    0
                                                    ? question.answers[0]
                                                    : ""
                                                }
                                                isDisabled={true}
                                                rows={2}
                                              />
                                            )}
                                        </div>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <div className="text-danger p-2 text-center">
                                    {t(
                                      "No general and function related questions added"
                                    )}
                                  </div>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>
                    )
                  )}
                  <div className="d-flex align-items-center justify-content-end">
                    <Button
                      title={t("Rejected")}
                      type="submit"
                      handleClick={() => handleClick(false)}
                      className="form-button shadow-none marginRight1"
                    />
                    <Button
                      title={t("Selected")}
                      type="submit"
                      handleClick={() => handleClick(true)}
                      className="form-button shadow-none"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <BackButton />
        </div>
      </div>
      {/* </AccessControl> */}
    </>
  );
};
export default ScreeningAssessment;
