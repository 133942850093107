import React, { useEffect, useState } from "react";
import TimesheetsEmployees from "../molecules/timesheetsEmployees";
import TimesheetsProjects from "../molecules/timesheetsProjects";
import { useTImesheetContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import Button from "components/atoms/Button";
import { ApiCall } from "services/ApiServices";
import { CREATE_TIMESHEET } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { useTimesheetModalContext, useTimesheetModalDispatch } from "../timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import { TIMESHEET_MODAL_ACTIONS } from "../timesheets-redux/actions/TimesheetModalActions";
import LoadingOverlay from "utils/LoadingOverlay";
import { useFilterOptions, useFilterOptionsDispatch } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { TIMESHEET_FILTER_ACTIONS } from "../timesheets-redux/actions/TimesheetFilterActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "pages/microservices/masterData/translation/Translation";
import DatePicker from "react-multi-date-picker";
import WeekCalendar from "../molecules/weekCalendar";
import PlanTimings from "../atoms/planningTimings";
import Work from "../molecules/work";
import Regime from "../atoms/regime";
import CommonCheckBox from "../atoms/commonCheckbox";
import ProjectParameters from "../molecules/projectParameters";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ProjectParameterHeader from "../atoms/projectParameterHeader";
import Popup from "components/molecules/Popup";
import ReimbursementModal from "../molecules/reimbursementModal";
import PerformanceModal from "../molecules/performanceModal";
import { dateFormat, fetchWeeklyTimesheetData, getTimesheetOptions, getWeekDays } from "../utils/utils";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { InputTextFieldWithDebounce } from "pages/microservices/planning/molecules/InputTextFieldWithDebounce";
import LabelBox from "../atoms/LabelBox";
import "../css/timesheet.css";
import { checkForPermission, ROLES } from "../constants/timesheetConstants";
import { useSelector } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { useSearchParams } from "react-router-dom";
import WorkTimings from "../atoms/workTimings";
import SelectableList from "components/atoms/SelectableList";

export default function TimesheetsOverview({ type, user }) {
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const { project_id, employee_id, currentDate } = history?.filters ?? {};
  const dispatch = useDispatch();
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { initialTimesheetState } = useTImesheetContext();
  const { filterOptionsState } = useFilterOptions();
  const { initialTimesheetModalState } = useTimesheetModalContext();
  const { timesheetModalDispatch } = useTimesheetModalDispatch();
  const { dispatchFilterOptions } = useFilterOptionsDispatch();
  const [loading, setLoading] = useState(false);
  const [isFirstColumnOpen, setIsFirstColumnOpen] = useState(checkForPermission(type, [ROLES.MAXICON, ROLES.CONTACT_PERSON, ROLES.TEMP_WORKER]));
  const [isLastColumnOpen, setIsLastColumnOpen] = useState(checkForPermission(type));
  const [searchParams, setSearchParams] = useSearchParams();

  let timesheet_data = initialTimesheetState?.timesheet_data ? initialTimesheetState.timesheet_data : [];

  useEffect(() => {
    getOptions();
  }, [filterOptionsState?.selectedState?.selectedStatus, project_id, filterOptionsState?.selectedState?.project_id, project_id, filterOptionsState?.selectedState?.initialCall])

  useEffect(() => {
    if (searchParams?.get("employee_id")) {
      dispatch(updatePageFilters({
        filters: {
          project_id: searchParams?.get('project_id'),
          employee_id: searchParams?.get('employee_id'),
          currentDate: searchParams?.get('currentDate'),
        }
      }))
      setSearchParams({});
    } else if (filterOptionsState?.selectedState?.employee_id !== null && filterOptionsState?.selectedState?.project_id !== null) {
      fetchTimesheet();
    }
  }, [filterOptionsState?.selectedState?.refresh])

  const getOptions = async () => {
    const date = currentDate ? new Date(currentDate) : filterOptionsState?.selectedState?.currentDate;
    let weekDays = getWeekDays(date);
    const payload = {
      project_id: filterOptionsState?.selectedState?.project_id ?? null,
      options: ['ReimbursementType', 'PerformanceType', 'Regimen', 'BusinessUnit'],
      initialCall: filterOptionsState?.selectedState?.initialCall,
      from_date: dateFormat(weekDays[0]),
      to_date: dateFormat(weekDays[6]),
      user_id: user?.userId, type,
      business_unit_id:  filterOptionsState?.selectedState?.selectedBu ?? 'all',
      status:  filterOptionsState?.selectedState?.selectedStatus ?? 'all',
    }
    try {
      timesheetDispatch({ type: TIMESHEET_ACTIONS.CHANGE_WEEK, payload: { data: [] } });
      const options = await getTimesheetOptions(payload);
      let optionData = {};
      let filteredOptions = {};
      if (filterOptionsState?.selectedState?.initialCall) {
        optionData = {
          projects: options?.Project ?? [],
          employees: options?.Employee ?? [],
          performanceTypes: options?.PerformanceType ?? [],
          reimbursementTypes: options?.ReimbursementType ?? [],
          regimen: options?.Regimen ?? [],
          prjectParameters: options?.Parameters ?? [],
          businessUnits: options?.BusinessUnit ?? [],
        }
        filteredOptions = {
          projects: options?.Project ?? [],
          employees: options?.Employee ?? [],
        }
        dispatch(updatePageFilters({ filters: { project_id: options?.Project?.some((item) => item?.value == project_id) ? project_id : options?.Project?.[0]?.value ?? null } }));
        dispatchFilterOptions({
          type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
          payload: {
            selectAll: true, initialCall: false, project_id: options?.Project?.some((item) => item?.value == project_id) ? project_id : options?.Project?.[0]?.value ?? null
          }
        });
      } else if (filterOptionsState?.selectedState?.project_id) {
        optionData = {
          prjectParameters: options?.Parameters ?? [],
          employees: options?.Employee ?? []
        }
        filteredOptions = {
          employees: options?.Employee ?? [],
        }
        dispatch(updatePageFilters({ filters: { employee_id: options?.Employee?.some((item) => item?.value == employee_id) ? employee_id : options?.Employee?.[0]?.value ?? null } }))
        dispatchFilterOptions({
          type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
          payload: {
            currentDate: currentDate ? new Date(currentDate) : filterOptionsState?.selectedState?.currentDate,
            employee_id: options?.Employee?.some((item) => item?.value == employee_id) ? employee_id : options?.Employee?.[0]?.value ?? null,
            selectAll: true, refresh: !filterOptionsState?.selectedState?.refresh,
          }
        });
      }
      dispatchFilterOptions({ type: TIMESHEET_FILTER_ACTIONS.SET_OPTIONS, payload: optionData });
      dispatchFilterOptions({ type: TIMESHEET_FILTER_ACTIONS.SET_FILTERED_OPTIONS, payload: filteredOptions });
    } catch (error) {
      console.log(error);
    }
  }

  const fetchTimesheet = async () => {
    const date = filterOptionsState?.selectedState?.currentDate ?? new Date();
    let weekDays = getWeekDays(date);
    const data = {
      project_id: filterOptionsState?.selectedState?.project_id,
      employee_id: filterOptionsState?.selectedState?.employee_id,
      from_date: dateFormat(weekDays[0]),
      to_date: dateFormat(weekDays[6]),
      user_id: user?.userId,
      type,
    };
    let response = await fetchWeeklyTimesheetData(data, date, filterOptionsState?.optionState?.prjectParameters);
    timesheetDispatch({ type: TIMESHEET_ACTIONS.CHANGE_WEEK, payload: { data: response || [] } });
    if (Object.values(filterOptionsState?.optionState?.prjectParameters)?.length > 0) {
      let prjectParameters = { ...filterOptionsState?.optionState?.prjectParameters, workingemployeesperday: response?.more_info?.workingemployeesperday }
      dispatchFilterOptions({ type: TIMESHEET_FILTER_ACTIONS.SET_OPTIONS, payload: { prjectParameters } });
    }
  }

  const handleWeekChange = async (value, week = false) => {
    let date = value;
    if (week) {
      date = new Date(filterOptionsState?.selectedState?.currentDate);
      date.setDate(date.getDate() + value);
    }
    dispatch(updatePageFilters({ filters: { currentDate: date?.toString() } }));
    dispatchFilterOptions({
      type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
      payload: {
        initialCall: true, selectAll: true, currentDate: date
      }
    });
  };

  const renderWeekNumber = () => {
    const currentDate = filterOptionsState?.selectedState?.currentDate ?? new Date();
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const daysSinceYearStart = (currentDate - firstDayOfYear + ((firstDayOfYear.getDay() || 7) - 1) * 86400000) / 86400000;
    const weekNumber = Math.ceil(daysSinceYearStart / 7);
    return <div className="text-center">{t("Week") + " " + (weekNumber > 52 ? 1 : weekNumber)}</div>;
  };

  const saveTimesheet = async (invoice = false) => {
    let timesheetData = {
      ...initialTimesheetState,
      timesheet_data: timesheet_data?.filter(
        (plan) => plan?.plan_id
      ),
      isInvoiced: invoice != "yes",
    };
    if (isPlanned) {
      let data = {
        data: {
          ...timesheetData,
          project_id: filterOptionsState?.selectedState?.project_id,
          employee_id: filterOptionsState?.selectedState?.employee_id,
          user_id: user?.userId,
          type,
        },
      };
      setLoading(true);
      await ApiCall.service(CREATE_TIMESHEET, "POST", data, true, M_MASTER_DATA)
        .then(async (response) => {
          if (response.status === 200) {
            CustomNotify({ type: "success", message: response.message });
            invoice && handleModalDispatch("invoicenow");
            dispatchFilterOptions({
              type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
              payload: { name: 'refresh', value: !filterOptionsState?.selectedState?.refresh }
            });
          } else {
            CustomNotify({ type: "error", message: response.message });
          }
        })
        .catch((error) => {
          CustomNotify({ type: "error", message: error });
        });
      setLoading(false);
    }
  };

  const handleModalDispatch = (modalType) => {
    timesheetModalDispatch({
      type: TIMESHEET_MODAL_ACTIONS.OPEN_AND_CLOSE_MODAL,
      payload: {
        status: !initialTimesheetModalState[modalType].isOpen,
        modalType: modalType,
        data: [],
        date: "",
        planId: "",
      },
    });
  };

  const isPlanned = timesheet_data?.some((plan) => plan?.plan_id) && !initialTimesheetState?.isDisabled;

  const handleChange = (value, name) => {
    let payload = {};
    let filteredData = [];
    switch (name) {
      case 'employee':
        filteredData = filterOptionsState?.optionState?.employees?.filter(employee =>
          employee?.label?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase())
        );
        payload = { employees: filteredData ?? [] };
        break;

      case 'project':
        filteredData = filterOptionsState?.optionState?.projects?.filter(project =>
          project?.label?.toLowerCase()?.trim()?.includes(value?.trim()?.toLowerCase())
        );
        payload = { projects: filteredData ?? [] };
        break;

      default:
        break;
    }
    dispatchFilterOptions({ type: TIMESHEET_FILTER_ACTIONS.SET_FILTERED_OPTIONS, payload: payload });
  }

  const onChangeBu = (value) => {    
    // dispatch(updatePageFilters({ filters: { selectedBu: value?.value } }));
    dispatchFilterOptions({
      type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
      payload: {initialCall: true, selectAll: true, selectedBu: value?.value, showBuFilter: false}
    });
  }

  const onChangeStatus = (value) => {    
    // dispatch(updatePageFilters({ filters: { selectedBu: value?.value } }));
    dispatchFilterOptions({
      type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
      payload: {selectAll: true, selectedStatus: value?.value, showStatusFilter: false}
    });
  }

  return (
    <div className="container-fluid ps-0 d-flex flex-column">
      <LoadingOverlay display={loading ? "block" : "none"} />
      <div className="row mx-0 marginBottomPoint5" style={{ marginTop: "0.2vw" }}>
        <div className="col-12 ps-1 pe-0 d-flex align-items-center">
          {isFirstColumnOpen && <div style={{ width: "16%" }} className="hideHeight marginRightPoint5">
            <div className="d-grid" style={{ gridTemplateColumns: "repeat(2, 1fr)", gap: "0 1vw" }}>
              <div>
                <InputTextFieldWithDebounce
                  name="project"
                  handleChange={(e) => handleChange(e, 'project')}
                  // value={filterOptionsState?.selectedState?.project ?? ""}
                  id="project"
                  type={"text"}
                  placeholder={t("Project")}
                />
              </div>
              <div>
                <InputTextFieldWithDebounce
                  name="employee"
                  handleChange={(e) => handleChange(e, 'employee')}
                  // value={filterOptionsState?.selectedState?.employee ?? ""}
                  id="employee"
                  type={"text"}
                  placeholder={t("Employee")}
                />
              </div>
            </div>
          </div>}
          <div style={{ width: checkForPermission(type) ? "40%" : "80%" }} className="me-auto">
            {filterOptionsState?.selectedState?.employee_id ? (
              <>
                {t("Performance of")}{" "}
                <strong>
                  {filterOptionsState?.optionState?.employees?.find(
                    (item) => item?.value == filterOptionsState?.selectedState?.employee_id
                  )?.label ?? ""}
                </strong>
              </>
            ) : (
              ""
            )}

          </div>
          <div style={{ width: "40%" }}>
            <div className="d-flex align-items-center justify-content-end">
              <div className="d-flex justify-content-between align-items-center timesheetWeekNumber marginRightPoint5">
                <div
                  className="planning_cal_btn"
                  onClick={() => handleWeekChange(-7, true)}
                  style={{ cursor: "pointer", marginRight: "0.7vw" }}
                  title={t("Previous")}
                >
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-left" />
                </div>
                <span className="tab-subtitle pb-0">{renderWeekNumber()}</span>
                <div
                  className="planning_cal_btn"
                  onClick={() => handleWeekChange(7, true)}
                  style={{ cursor: "pointer", marginLeft: "0.7vw" }}
                  title={t("Next")}
                >
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </div>
              </div>
              <div className="customDatePicker marginRightPoint5">
                <DatePicker
                  inputClass="form-control field-shadow"
                  name="date"
                  placeholder={"mm-yyyy"}
                  value={filterOptionsState?.selectedState?.currentDate ?? new Date()}
                  onChange={(date) => {
                    const parsedDate = new Date(date);
                    if (!isNaN(parsedDate)) {
                      handleWeekChange(parsedDate);
                    }
                  }}
                  format="MM-YYYY"
                  onlyMonthPicker
                  minDate={new Date(2014, 0, 1)}
                  zIndex={1000}
                />
              </div>
              <div className="d-flex justify-content-end">
                {isPlanned && <Button
                  title={t("Validate")}
                  type="submit"
                  handleClick={() =>
                    initialTimesheetState?.isInvoiced || !checkForPermission(type)
                      ? saveTimesheet()
                      : handleModalDispatch("invoicenow")
                  }
                  className="form-button float-end"
                />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="timesheetContentWrapper">
        <div className="row mx-0 flex-1 overflow-auto">
          {/* First Column with Toggle */}
          {isFirstColumnOpen && (
            <div className="col-2 overflow-auto px-0 timeSheetLeftSection" >
              <div className="d-grid h-100 timeSheetGrid position-relative">
                <div className="text-center p-0 overflow-auto timeSheetProjectAndEmployee" style={{
                  height: checkForPermission(type) ? "calc(100vh - 7vw)" : 'auto',
                }}>
                  <TimesheetsProjects />
                  {filterOptionsState?.selectedState?.showBuFilter && (
                    <div style={{ position: "absolute", zIndex: 1000, backgroundColor: "white", border: "1px solid #ccc", top:"1.8vw", left:"0", maxWidth:"15vw", width:"15vw" }}>
                      <SelectableList
                        options={filterOptionsState?.optionState?.businessUnits}
                        selected={filterOptionsState?.selectedState?.selectedBu}
                        onChange={(value) => onChangeBu(value)}
                      />
                    </div>
                  )}
                </div>
                <div className="text-center p-0 overflow-auto timeSheetProjectAndEmployee" style={{
                  height: checkForPermission(type) ? "calc(100vh - 7vw)" : 'auto',
                }}>
                  <TimesheetsEmployees />
                  {filterOptionsState?.selectedState?.showStatusFilter && (
                    <div style={{ position: "absolute", zIndex: 1000, backgroundColor: "white", border: "1px solid #ccc", top:"1.8vw", left:"0", maxWidth:"15vw", width:"15vw" }}>
                      <SelectableList
                        options={filterOptionsState?.optionState?.status}
                        selected={filterOptionsState?.selectedState?.selectedStatus}
                        onChange={(value) => onChangeStatus(value)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div
            className={`col-${isFirstColumnOpen && isLastColumnOpen
              ? 12 - 4
              : isFirstColumnOpen
                ? 12 - 2
                : isLastColumnOpen
                  ? 12 - 2
                  : 12
              } text-center p-0 overflow-auto`}
          // style={{ height: checkForPermission(type) ? "calc(100vh - 7vw)" : 'auto' }}
          >
            <div style={{ height: checkForPermission(type) ? "94%" : 'auto' }}>
              <table className="w-100 overflow-auto timesheetTable">
                <thead className="position-sticky top-0">
                  <WeekCalendar />
                </thead>
                <tbody>
                  <PlanTimings />
                  <Work />
                  <Regime
                    planTimingsData={timesheet_data}
                    header1="Regime"
                    header2=""
                    name="regime"
                    isDisable={initialTimesheetState?.isDisabled}
                  />
                  {/* <CommonCheckBox
                  planTimingsData={timesheet_data}
                  header1="Holiday"
                  header2=""
                  name={"holiday"}
                  isDisable={initialTimesheetState?.isDisabled}
                /> */}
                  {checkForPermission(type) && <CommonCheckBox
                    planTimingsData={timesheet_data}
                    header1="Invoice milage"
                    header2=""
                    total={""}
                    name={"invoice_milage"}
                    isDisable={initialTimesheetState?.isDisabled}
                  />}
                  {checkForPermission(type) && <LabelBox
                    planTimingsData={timesheet_data}
                    header1="Worksheet no."
                    header2=""
                    isDisable={true}
                    name="week_number"
                  />}
                  {checkForPermission(type) && <LabelBox
                    planTimingsData={timesheet_data}
                    header1="Invoice no."
                    header2=""
                    isDisable={true}
                    linkname={'invoice_id'}
                    name={"invoice_no"}
                  />}
                  {checkForPermission(type) && <CommonCheckBox
                    planTimingsData={timesheet_data}
                    header1="Purchase invoice"
                    header2=""
                    name={"purchase_invoice"}
                    isDisable={initialTimesheetState?.isDisabled}
                  />}
                  <WorkTimings
                    planTimingsData={timesheet_data}
                    header1="Difference"
                    header2=""
                    // total="Weekly total"
                    isDisable={true}
                    name="diff"
                  />
                </tbody>
              </table>
              {checkForPermission(type, [ROLES?.MAXICON, ROLES?.EMPLOYEE]) &&
                <div style={{ height: "calc(100vh - 32vw)", overflow: "auto", marginTop: "1vw" }}>
                  <table className="w-100 timesheetTable timeSheetTableTwo">
                    <tbody>
                      <ProjectParameters type={type} planTimingsData={timesheet_data} />
                    </tbody>
                  </table>
                </div>
              }
            </div>
            {<div className="d-flex justify-content-between position-sticky bottom-0" style={{ padding: "0.25vw" }}>
              {checkForPermission(type, [ROLES.MAXICON, ROLES.CONTACT_PERSON, ROLES.TEMP_WORKER]) && <button
                className="cursor-pointer sidebar-icon d-flex align-items-center justify-content-center"
                onClick={() => setIsFirstColumnOpen(!isFirstColumnOpen)}
              >
                {isFirstColumnOpen ? (
                  <FontAwesomeIcon icon={faAngleLeft} />
                ) : (
                  <FontAwesomeIcon icon={faAngleRight} />
                )}
              </button>}
              {checkForPermission(type) && <button
                className="cursor-pointer sidebar-icon d-flex align-items-center justify-content-center"
                onClick={() => setIsLastColumnOpen(!isLastColumnOpen)}
              >
                {!isLastColumnOpen ? (
                  <FontAwesomeIcon icon={faAngleLeft} />
                ) : (
                  <FontAwesomeIcon icon={faAngleRight} />
                )}
              </button>}
            </div>}
          </div>
          {/* Last Column with Toggle */}
          {isLastColumnOpen && (
            <div className="col-2 overflow-auto p-0 timeSheetRightSection" style={{ height: "calc(100vh - 7vw)" }}>
              <div className="text-center p-0 h-100 overflow-auto">
                <ProjectParameterHeader
                  initialTimesheetState={initialTimesheetState}
                  projectParameters={filterOptionsState?.optionState?.prjectParameters || {}}
                />
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end">
            {/* {initialTimesheetModalState["invoice"].isOpen && (
              <Popup
                title={"Invoice"}
                body={
                  <InvoiceModal
                    onClose={() => {
                      handleModalDispatch("invoice");
                    }}
                  />
                }
                cancel={() => {
                  handleModalDispatch("invoice");
                }}
                modalSize="lg"
                bodyclassName="text-start"
              />
            )} */}
            {initialTimesheetModalState["performance"]?.isOpen && (
              <Popup
                title={t("Perfomance")}
                body={<PerformanceModal />}
                cancel={() => handleModalDispatch("performance")}
                modalSize="lg"
                bodyclassName="text-start"
              />
            )}
            {initialTimesheetModalState["reimbursement"]?.isOpen && (
              <Popup
                title={t("Reimbursements")}
                body={<ReimbursementModal />}
                cancel={() => handleModalDispatch("reimbursement")}
                modalSize="lg"
                bodyclassName="text-start"
              />
            )}
            {initialTimesheetModalState["invoicenow"]?.isOpen && (
              <Popup
                title={t("Confirmation")}
                body={t("Do you want to invoice this now") + "?"}
                close={() => handleModalDispatch("invoicenow")}
                modalSize="lg"
                bodyclassName="text-start"
                yestext={t("Yes")}
                notext={t("No")}
                submit={() => saveTimesheet("yes")}
                cancel={() => saveTimesheet("no")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
