import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { PublicHolidayTableHeader } from "TableHeader";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import ModalPopup from "components/atoms/ModalPopup";
import Pagination from "components/atoms/Pagination";
import Search from "components/atoms/Search";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import TitleFieldMolecule from "components/molecules/TitleField";
import TableActions from "components/organism/Actions/TableAction";
import { M_MASTER_DATA } from "constants/Constants";
import {
  CREATE_PUBLIC_HOLIDAY,
  EDIT_PUBLIC_HOLIDAY,
  HOLIDAY_CONFIGS,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useEffect, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { Link, useNavigate } from "react-router-dom";
import {
  CLONE_PUBLIC_HOLIDAY,
  DELETE_PUBLIC_HOLIDAY,
  GET_PUBLIC_HOLIDAYS,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { IconData } from "services/IconData";
import TitleAtom from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import BackButton from "components/atoms/BackButton";
import { useSelector } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import FilterOrganism from "components/organism/FilterOrganism";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import CommonServices from "services/CommonService";
import TextEllipsis from "components/atoms/TextEllipsis";

interface ManagePublicHolidayData {
  id: any;
  holiday_name: any;
  date: any;
  country: any;
  region: any;
}
interface Option {
  label: string;
  value: string | number;
}

const ManageHolidays = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [holidaysData, setHolidaysData] = useState<ManagePublicHolidayData[]>([]);
  const [fromYear, setFromYear] = useState<number | null>(new Date().getFullYear());
  const [toYear, setToYear] = useState<number | null>(new Date().getFullYear());
  const [fromYearError, setFromYearError] = useState<string>("");
  const [toYearError, setToYearError] = useState<string>("");
  const [option, setOptions] = useState<{ country: Option[] }>({
    country: [],
  });
  const [showModal, setShowModal] = useState(false);

  const [id, setId] = useState<number | undefined>(undefined);
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });
  const getSearchData = () => {
    return {
      year: history?.filters?.year ?? new Date().getFullYear(),
      holiday_name: history?.filters?.holiday_name ?? "",
      country: history?.filters?.country ?? "",
      region: history?.filters?.region ?? "",
      date: CommonServices.getTimezoneOffset(history?.filters?.date ?? ""),
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
  };
  useEffect(() => {
    const data: any = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
      search: getSearchData(),
      initialCall: pageData?.initialCall
    };
    fetchPublicHolidays(data);
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchPublicHolidays = async (data: any) => {
    try {
      const response = await ApiCall.service(GET_PUBLIC_HOLIDAYS, "POST", data, false, M_MASTER_DATA);
      if (response?.status === 200) {
        setHolidaysData(response?.data);
        if (pageData?.initialCall) {
          setOptions({
            country: response?.options?.countryOption || [],
          });
        }
        setPageData((prev: any) => ({ ...prev, totalPages: response.totalPages, totalRecords: response?.totalRecords, initialCall: false }));
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };

  //onClick edit handler
  const handleEdit = (id: any) => {
    navigate(`${EDIT_PUBLIC_HOLIDAY}/${id}`);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleDelete = (id: any) => {
    setId(id);
    setShowModal(true);
  };
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.service(
        DELETE_PUBLIC_HOLIDAY,
        "POST",
        { id: id },
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response?.message });
        setHolidaysData((prevdata) =>
          (prevdata as ManagePublicHolidayData[]).filter(
            (holidayData) => holidayData.id !== id
          )
        );
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
  };
  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const handleFromYearChange = (e: DateObject | null) => {
    if (e !== null) {
      const year = e.year;
      setFromYear(year);
      setToYear(year + 1);
    } else {
      setFromYear(null);
      setToYear(null);
    }
  };

  const handleToYearChange = (e: DateObject | null) => {
    if (e !== null) {
      const year = e.year;
      setToYear(year);
    } else {
      setToYear(null);
    }
  };

  const handleReset = () => {
    setFromYear(new Date().getFullYear());
    setToYear(new Date().getFullYear());
    setFromYearError("");
    setToYearError("");
  };
  const handleClone = async () => {
    let isValid = true;
    if (fromYear === null) {
      setFromYearError("This field is required.");
      isValid = false;
    }
    if (toYear === null) {
      setToYearError("This field is required.");
      isValid = false;
    } else if (fromYear !== null && fromYear === toYear) {
      setToYearError("From and To years cannot be the same.");
      isValid = false;
    }
    if (isValid) {
      setFromYearError("");
      setToYearError("");

      // Create the request data object
      const requestData = {
        from: fromYear,
        to: toYear,
      };
      const response = await ApiCall.service(
        CLONE_PUBLIC_HOLIDAY,
        "POST",
        requestData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response?.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
  };
  const filters = [
    { name: 'holiday_name', fieldType: "text", placeholder: "Name", filterType: 'search' },
    { name: 'date', fieldType: "date", start: new Date(history?.filters?.year ?? new Date().getFullYear(), 0, 1), end: new Date(history?.filters?.year ?? new Date().getFullYear(), 11, 31), placeholder: "Date", filterType: 'search' },
    { name: 'country', fieldType: "singleSelect", options: option?.country, placeholder: "Country", filterType: 'search' },
    { name: 'region', fieldType: "text", placeholder: "Region", filterType: 'search' },
  ];
  const handleYearChange = (e: any) => {
    dispatch(updatePageFilters({ filters: { year: e.year } }));
    handleRefresh();
  }

  return (
    <>
      <div className="search-bar">
        <div className="row">
          <TitleAtom title={t("Public holidays")} />
        </div>
        <div className="row">
          <div className="col-3 datePicker">
            <DatePicker
              inputClass="form-control field-shadow"
              name="date"
              placeholder={fromYear !== null ? fromYear.toString() : ""}
              value={fromYear !== null ? new Date(fromYear, 0, 1) : null}
              onChange={(e: any) => handleFromYearChange(e)}
              format="YYYY"
              onlyYearPicker={true}
              minDate={new Date(2014, 0, 1)}
              zIndex={1000}
            />
            {fromYearError && (
              <div className="text-danger">{fromYearError}</div>
            )}
          </div>
          <div className="col-3 datePicker">
            <DatePicker
              inputClass="form-control field-shadow"
              name="date"
              placeholder={toYear !== null ? toYear.toString() : ""}
              value={toYear !== null ? new Date(toYear, 0, 1) : null}
              onChange={(e: any) => handleToYearChange(e)}
              format="YYYY"
              onlyYearPicker={true}
              minDate={
                fromYear !== null ? new Date(fromYear + 1, 0, 1) : new Date()
              }
              zIndex={1000}
            />
            {toYearError && <div className="text-danger">{toYearError}</div>}
          </div>
          <div className="col-3">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={handleReset} />
              <Button
                title={t("Clone holiday")}
                handleClick={handleClone}
                className={"form-button d-flex align-items-center"}
                type={"button"}
                buttonTitle="me-3"
                icon={faCopy}
              />
            </div>
          </div>
          <div className="col-3 ms-auto text-end publicHolidayYear">
            <DatePicker
              inputClass="form-control field-shadow"
              name="year"
              placeholder={"Year"}
              value={history?.filters?.year ? new Date(history?.filters?.year, 0, 1) : new Date()}
              onChange={(e: any) => handleYearChange(e)}
              format="YYYY"
              onlyYearPicker={true}
              zIndex={1000}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <Link to={CREATE_PUBLIC_HOLIDAY} className="form-button">
                  + {t("Add holiday")}
                </Link>
              </div>
            </div>
            <div className="table-responsive manage-publicholiday-codes tableSection">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
              <div className="managePublicHoliday">
                <table className="table table-hover ">
                  <thead>
                    <tr className="TableHeader">
                      {PublicHolidayTableHeader?.map((value: any) => (
                        <th key={value.name} className="border-bottom-0">
                          {value.name}{" "}
                          {value?.issort && (
                            <SortAtomForManagePage value={value} dispatch={dispatch} history={history} updatePageFilters={updatePageFilters} handleRefresh={handleRefresh} />
                          )}
                        </th>
                      ))}
                      <th>{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(holidaysData && holidaysData.length) > 0 ? (
                      holidaysData.map((eachItem, index) => (
                        <tr key={eachItem.id}>
                          <td className="align-middle">
                            <TextEllipsis title={eachItem?.holiday_name} label={eachItem?.holiday_name} width="17vw" />
                          </td>
                          <td className="align-middle">{eachItem?.date}</td>
                          <td className="align-middle">
                            {eachItem?.country}
                          </td>
                          <td className="align-middle">{eachItem?.region}</td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              handleEdit={handleEdit}
                              handleDelete={handleDelete}
                              value={eachItem.id}
                              permission={"Public holidays"}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          {t("No data found")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className=""
                style={{ paddingTop: "0.25vw" }}
              >
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 align-self-center backPadding">
          <BackButton />
        </div> */}
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Delete confirmation"
        body="Are you sure want to delete?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
    </>
  );
};
export default ManageHolidays;
