import React, { useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
    ADD_NEW_EMPLOYEE_TYPE,
    ADD_NEW_PC_FUNCTION,
    CLONE_FUNCTION_TYPE,
    CoefficientHeaders,
    DELETE_EMPLOYEE_TYPE,
    DELETE_FUNCTION_TYPE,
    optionsData,
    UPDATE_COEFFCIENT_TAB_FIELD,
    UPDATE_COEFFICIENTS,
} from "../context/Constants";
import TableData from "./TableData";
// import "./css/cooperationAgreement.css";
import { useFormContext } from "../context/Context";

// Define the prop types for the component
interface IProjectFormGeneralTabProps {
    // id?: string | number;
}

// Functional component using TypeScript
const Coefficient: React.FC<IProjectFormGeneralTabProps> = () => {
    const { state, dispatch } = useFormContext();

    const addFunctionProfile = (pcIndex: number) => {
        dispatch({
            type: ADD_NEW_PC_FUNCTION,
            mainIndex: pcIndex,
            tab: "coefficient"
        });
    };
    const addEmployeeType = (pcIndex: number, funcIndex: number) => {
        dispatch({
            type: ADD_NEW_EMPLOYEE_TYPE,
            tab: "coefficient",
            mainIndex: pcIndex,
            subIndex: funcIndex
        });
    };

    const deleteEmployeeType = (
        pcIndex: number,
        funcIndex: number,
        empRowIndex: number
    ) => {
        dispatch({ type: DELETE_EMPLOYEE_TYPE, pcIndex, funcIndex, empRowIndex });
    };

    const cloneFunctionType = (pcIndex: number, funcIndex: number) => {
        dispatch({ type: CLONE_FUNCTION_TYPE, pcIndex, funcIndex });
    };

    const deleteFunctionType = (pcIndex: number, funcIndex: number) => {
        dispatch({ type: DELETE_FUNCTION_TYPE, pcIndex, funcIndex });
    };
    const handleFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        pcIndex: number | null,
        funcIndex: number | null,
        empRowIndex: number | null,
        coeffIndex: number | null,
        entityType?: string | null,
    ) => {
        const { name, value } = event.target;
        const regex = /^(\d{1,9})(.\d{0,4})?$/;
        let matches = regex.exec(value);
        if (matches || value === "") {
            dispatch({
                type: UPDATE_COEFFCIENT_TAB_FIELD,
                coeffield: name,
                coeffValue: value,
                pcIndex,
                funcIndex,
                empRowIndex,
                coeffIndex,
                entityType,
            });
        }
    };

    //Get coefficients based on employee type and level of experience
    const getCoefficients = (type: any, level: any, pcIndex: number, funcIndex: number, empRowIndex: number) => {
        const defaultCoeff = state?.options?.defaultCoefficients;
        const currentCompany = state?.general?.data?.company;

        if (defaultCoeff) {
            const requiredCoeff = defaultCoeff[currentCompany?.value]?.[type]?.[level];
            console.log(requiredCoeff);
            dispatch({
                type: UPDATE_COEFFICIENTS,
                pcIndex,
                funcIndex,
                empRowIndex,
                data: [{
                    selection: requiredCoeff['selection_min'],
                    selectionReduced: requiredCoeff['selection_reduced_min'],
                    payroll: requiredCoeff['payroll_min'],
                    selectionCent: requiredCoeff['selection_percent_min'],
                }]
            })
        }
    }


    const handleSelectChange = (
        selectedOption: any,
        title: string,
        pcIndex: number,
        funcIndex: number,
        empRowIndex: number
    ) => {
        dispatch({
            type: UPDATE_COEFFCIENT_TAB_FIELD,
            coeffield: title,
            coeffValue: selectedOption,
            pcIndex,
            funcIndex,
            empRowIndex,
            coeffIndex: null,
        });
        const currentData = state?.coefficient?.[pcIndex]?.funEmpType?.[funcIndex]?.empTypeData?.[empRowIndex];

        const currentEmpType = currentData?.employeeType;
        const currentLevel = currentData?.level;

        if ((title == "employeeType") && (currentLevel !== "")) {
            getCoefficients(selectedOption?.value, currentLevel?.value, pcIndex, funcIndex, empRowIndex);
        }
        else if (title == "level" && (currentEmpType !== "")) {
            getCoefficients(currentEmpType?.value, selectedOption?.value, pcIndex, funcIndex, empRowIndex);
        }

    };

    const handleDateChange = (
        date: Date | null,
        name: string,
        pcIndex: number,
        funcIndex: number,
        empRowIndex: number
    ) => {
        dispatch({
            type: UPDATE_COEFFCIENT_TAB_FIELD,
            coeffield: name,
            coeffValue: date,
            pcIndex,
            funcIndex,
            empRowIndex,
            coeffIndex: 0,
        });
    };

    const generalData: any = state?.general?.data;

    return (
        <div className="row">
            <div className="col-12">
                <div className="form-border" style={{ paddingTop: "3vw" }}>
                    <div>
                        {state.coefficient.length > 0 ? (
                            state.coefficient.map((item: any, pcIndex: number) => (
                                <React.Fragment key={`retention-${pcIndex}`}>
                                    <div key={`pc-${pcIndex}`}>
                                        <div className="pcName color-dark-skyblue fw-bold tab-subtitle pb-1">{item?.pcName ?? ""}</div>
                                        <div className="table-responsive">
                                            <table className="table table-bordered coefficientTableWrapper cooperation-billing-table">
                                                <thead>
                                                    <tr className="TableHeader bg-white">
                                                        <th className="text-center" rowSpan={2}>
                                                            {t("Profile")}
                                                        </th>
                                                        <th className="text-center" rowSpan={2}>
                                                            {t("Employee type")}
                                                        </th>
                                                        <th className="text-center" rowSpan={2}>
                                                            {t("Level")}
                                                        </th>
                                                        <th className="text-center" colSpan={2} rowSpan={2}>
                                                            {t("Duration type")}
                                                        </th>
                                                        {CoefficientHeaders &&
                                                            CoefficientHeaders.map((coeff: any, index: number) => (
                                                                <th
                                                                    key={`coeff-type-${index}`}
                                                                    rowSpan={2}
                                                                    className="text-center"
                                                                >
                                                                    {t(`${coeff?.label}`)}
                                                                </th>
                                                            ))}
                                                        <th>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(item?.funEmpType) &&
                                                        item?.funEmpType.map((func: any, funcIndex: number) => (
                                                            <React.Fragment
                                                                key={`tbody-${pcIndex}-func-${funcIndex}`}
                                                            >
                                                                {Array.isArray(func?.empTypeData) &&
                                                                    func?.empTypeData.map((empRow: any, empRowIndex: number) => (
                                                                        <React.Fragment
                                                                            key={`tbody-${pcIndex}-func-${funcIndex}-emp-${empRowIndex}`}
                                                                        >
                                                                            {Array.isArray(empRow?.coefficient) &&
                                                                                empRow?.coefficient.map(
                                                                                    (coeff: any, coeffIndex: number) => (
                                                                                        <tr
                                                                                            className="align-middle border-bottom"
                                                                                            key={`tbody-${pcIndex}-func-${funcIndex}-emp-${empRowIndex}-coeff-${coeffIndex}`}
                                                                                        >
                                                                                            <TableData
                                                                                                pcIndex={pcIndex}
                                                                                                funcData={func}
                                                                                                funcDropdown={
                                                                                                    item?.functionOptions
                                                                                                }
                                                                                                empTypeDropdown={
                                                                                                    item?.empTypeOptions
                                                                                                }
                                                                                                funcIndex={funcIndex}
                                                                                                empTypeData={empRow}
                                                                                                empRowIndex={empRowIndex}
                                                                                                coeffIndex={coeffIndex}
                                                                                                dropDown={item}
                                                                                                coeffData={coeff}
                                                                                                coeffOption={
                                                                                                    CoefficientHeaders
                                                                                                }
                                                                                                addFunctionProfile={
                                                                                                    addFunctionProfile
                                                                                                }
                                                                                                cloneFunctionType={
                                                                                                    cloneFunctionType
                                                                                                }
                                                                                                deleteFunctionType={
                                                                                                    deleteFunctionType
                                                                                                }
                                                                                                addEmployeeType={addEmployeeType}
                                                                                                deleteEmployeeType={
                                                                                                    deleteEmployeeType
                                                                                                }
                                                                                                handleSelectChange={
                                                                                                    handleSelectChange
                                                                                                }
                                                                                                handleFieldChange={
                                                                                                    handleFieldChange
                                                                                                }
                                                                                                handleDateChange={handleDateChange}
                                                                                                role={""}
                                                                                            />
                                                                                        </tr>
                                                                                    )
                                                                                )}
                                                                        </React.Fragment>
                                                                    ))}
                                                            </React.Fragment>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))
                        ) : (
                            <div>
                                {generalData?.whitecollarPcs?.pcsList.length === 0 ||
                                    generalData?.blueCollarPcs?.pcsList.length === 0 ? (
                                    <div className="text-danger">
                                        {t("Please select at least one Paritair committee and company")}
                                    </div>
                                ) : (
                                    <div>{t("Loading")}</div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Coefficient;
