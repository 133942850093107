import React from 'react'

const CompanyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.25vw" height="1.25vw" viewBox="0 0 65 67" fill="none">
      <path d="M5.30678 66.675H0.800781V20.875H32.5648V25.34H5.30778L5.30678 66.675ZM30.3218 66.675H25.1848V56.999H30.3228L30.3218 66.675ZM21.9728 66.675H16.8358V56.999H21.9728V66.675ZM13.6248 66.675H8.48678V56.999H13.6248V66.675ZM58.1798 66.675H39.3128V8.617H65.0008V66.675L59.7856 66.6937C59.7856 66.6937 58 54.6748 64.0896 56.5812C64.0176 56.6409 63.9689 56.724 63.9518 56.816L63.6258 58.875C63.1545 59.0659 62.7095 59.3162 62.3018 59.62L60.3828 58.807C60.2969 58.7724 60.5 59 60.5 59C60.5482 58.921 60.4121 59.0291 60.5 59L61.4121 61.8996C61.3644 61.979 58.2515 61.8996 58.2515 61.8996C58.2662 61.9911 60.9299 58.9055 61 58.966L60.0008 63.458C59.9615 63.7086 59.9388 63.9614 59.9328 64.215C59.9305 64.4723 59.9445 64.7295 59.9748 64.985V66.675C59.9135 66.7252 58.1798 66.675 58.1798 66.675C58.1567 66.5992 58.1568 66.7508 58.1798 66.675ZM46.3798 64.095H57.2938V55.064H46.3768L46.3798 64.095ZM43.8078 49.903H60.5008V46.032H43.8008L43.8078 49.903ZM43.8078 42.162H60.5008V38.291H43.8008L43.8078 42.162ZM43.8078 34.421H60.5008V30.551H43.8008L43.8078 34.421ZM43.8078 26.68H60.5008V22.809H43.8008L43.8078 26.68ZM43.8078 18.939H60.5008V15.068H43.8008L43.8078 18.939ZM30.3218 53.128H25.1848V47.968H30.3228L30.3218 53.128ZM21.9728 53.128H16.8358V47.968H21.9728V53.128ZM13.6248 53.128H8.48678V47.968H13.6248V53.128ZM30.3248 44.742H25.1848V39.582H30.3228V44.742H30.3248ZM21.9758 44.742H16.8358V39.582H21.9728V44.742H21.9758ZM13.6278 44.742H8.48678V39.582H13.6248V44.742H13.6278ZM30.3278 36.356H25.1848V31.195H30.3228V36.355L30.3278 36.356ZM21.9788 36.356H16.8358V31.195H21.9728V36.355L21.9788 36.356ZM13.6308 36.356H8.48678V31.195H13.6248V36.355L13.6308 36.356ZM18.7188 16.356H14.4008V0.875H48.5088V5.152H18.7088V16.359L18.7188 16.356Z" fill="#00a5ce" />
    </svg>
  )
}

export default CompanyIcon