import React from "react";
import { getWeekDays } from "../utils/utils";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { t } from "pages/microservices/masterData/translation/Translation";
import { calculateTotalForWholeWeek } from "../utils/calculateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";

const WeekCalendar = ({ month = false, title = "", performanceAndInvoiceData = [], isDisabled = false}) => {
  const { filterOptionsState } = useFilterOptions();
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const currentDate = new Date(); // Get current date
  const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const days = getWeekDays(filterOptionsState?.selectedState?.currentDate);

  const isValid = performanceAndInvoiceData && performanceAndInvoiceData?.length > 0;

  let lastMonth = null;

  return (
    <>
      {month && (
        <tr>
          <th key={"heading1"} 
            className={`${(isValid && !isDisabled) ? 'position-relative' : ""}`}
          >
            {(isValid && !isDisabled) && (<span
              onClick={() => calculateTotalForWholeWeek({
                data: performanceAndInvoiceData, parameters: filterOptionsState?.optionState?.prjectParameters,
                options: filterOptionsState?.optionState, dispatch: timesheetDispatch, strict: true
              })}
              className="position-absolute calculatorPosition cursor-pointer"
              title={t("Calculate for whole week")}
            >
              <FontAwesomeIcon icon={faCalculator} />
            </span>)}
          </th>
          <th></th>
          {weekdays.map((_, index) => {
            const date = days[index];
            const monthName = date.toLocaleDateString("en-GB", { month: "short", year: "numeric" });
            const showMonthName = lastMonth !== monthName;
            lastMonth = monthName; // Update lastMonth for next iteration

            return (
              <th
                key={index}
                style={{
                  textAlign: "center",
                  backgroundColor: '#fff',
                  color:"#00a5ce",
                  borderLeft: showMonthName && index !== 0 ? "0.052vw solid #00a5ce " : "none",
                }}
              >
                {showMonthName ? monthName : ""}
              </th>
            );
          })}
          <th></th>
        </tr>
      )}

      <tr>
        <td key={"heading1"} className={`text-start`} colSpan={2}>{t(title)}</td>
        {weekdays?.map((weekday, index) => {
          const date = days[index];
          const isCurrentDay = date.getDate() === currentDate.getDate();          
          return (
            <th
              key={index}
              style={{
                backgroundColor: isCurrentDay ? "#e8f8fb" : "#00a5ce",
                color: isCurrentDay ? "#00a5ce" : "#fff",
                border: "0.052vw solid #fff",
                textAlign: "center",
              }}
            >
              {weekday}
              <span className="ps-1">
                {date.toLocaleDateString("en-GB", { day: "numeric", month: "numeric" })}
              </span>
            </th>
          );
        })}
        <td></td>
      </tr>
    </>
  );
};

export default WeekCalendar;
