import React, { useEffect, useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { ShiftHeader } from "TableHeader";
import { useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ARCHIVE_SHIFT, ARCHIVE_TIMETABLE, GET_SHIFT, GET_TIMETABLES } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import ModalPopup from "components/atoms/ModalPopup";
import { M_MASTER_DATA } from "constants/Constants";
import {
  PATH_CLONE_SHIFT,
  PATH_CREATE_SHIFT,
  PATH_EDIT_SHIFT,
  PATH_TIMETABLE_CREATE,
  PATH_TIMETABLE_EDIT,
} from "constants/Paths";
import { Shift } from "./Manage";
import AccessControl from "services/AccessControl";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TableActions from "components/organism/Actions/TableAction";
import { getStandardStatus, getStandardToolTip } from "services/util/UtilService";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import FilterOrganism from "components/organism/FilterOrganism";

interface ManageShiftsPageProps {
  shiftObject: Shift[];
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  searchData: any;
  isTimeTable?: any;
  currentPage: any;
  setCurrentPage: any;
  totalPages: any;
}

const ManageShiftsPage: React.FC<ManageShiftsPageProps> = ({
  shiftObject,
  searchValue,
  deleteFilter,
  searchData,
  isTimeTable,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {

  const [shift, setShift] = useState<Shift[]>([]);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const getSearchData = () => {
    return {
      shiftName: history?.filters?.shiftName ?? "",
      status: history?.filters?.status ?? "",
    }
  };

  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });

  const fetchShifts = async (data: any) => {
    let endPoints = isTimeTable ? GET_TIMETABLES : GET_SHIFT;

    const response = await ApiCall.service(
      endPoints,
      "POST",
      data,
      true,
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      setShift(response?.data);
      setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords }));
    }
  };

  useEffect(() => {
    const data = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
      search: getSearchData(),
      initialCall: true,
    };
    fetchShifts(data);
  }, [pageData?.refresh, pageData?.initialCall]);


  const [options, setOptions] = useState({
    status: [
      {
        value: '0',
        label: 'Archived'
      },
      {
        value: '1',
        label: 'Active'
      }
    ]
  });

  const dispatch = useDispatch();
  const [deleteID, setDeleteId] = useState<any>({
    id: null,
    type: ''
  });
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);
  // const handleSaveChanges = async () => {
  //   setShowModal(false);
  //   if (deleteID) {
  //     let apiToDelete = isTimeTable ? ARCHIVE_TIMETABLE : ARCHIVE_SHIFT;
  //     const url = `${apiToDelete}/${deleteID}`;
  //     const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);

  //     if (response?.status === 200) {
  //       deleteFilter(deleteID);
  //       CustomNotify({ type: "success", message: response?.message });
  //     }
  //   }
  // };

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const response = await archiveOrUnarchiveFunction(deleteID);

      if (response?.status == 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const archiveOrUnarchiveFunction = async (postData: any) => {
    try {
      const response = await ApiCall.service(
        isTimeTable ? ARCHIVE_TIMETABLE : ARCHIVE_SHIFT,
        "POST",
        postData,
        true,
        M_MASTER_DATA
      );
      return response;
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleDelete = (id: any) => {
    setShowModal(true);
    setDeleteId({
      id: id?.id,
      type: 'archive'
    });
  };

  const handleEdit = (id: any) => {
    if (id) {
      let navigatePath = isTimeTable ? PATH_TIMETABLE_EDIT : PATH_EDIT_SHIFT;
      navigate(`${navigatePath + "/" + id?.id}`);
    }
  };
  const handleClone = (Shift: Shift | any) => {
    if (Shift.id) {
      let navigatePath = isTimeTable ? PATH_TIMETABLE_EDIT : PATH_CLONE_SHIFT;
      navigate(`${navigatePath + "/" + Shift.id}`);
    }
  }

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const permissionType = isTimeTable ? "Timetable" : "Shifts";
  const userData = useSelector(selectAuth);

  const handleUnArchive = (id: any) => {
    setDeleteId({
      id: id?.id,
      type: 'unarchive'
    });
    setShowModal(true);
  }

  const filterFields = [
    { name: 'shiftName', fieldType: "text", placeholder: "Shift name", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: options?.status, placeholder: "Status", filterType: 'search' },
  ]

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: permissionType,
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="position-relative tableMainWrapper">
        <div className="row">
          <div className="ManageCreateBtn">
            <AccessControl
              requiredPermissions={[
                {
                  permission: permissionType,
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <LinkTo
                pagelink={
                  isTimeTable ? PATH_TIMETABLE_CREATE : PATH_CREATE_SHIFT
                }
                title={isTimeTable ? t("Create timetable") : t("Create shift")}
                icon={faPlus}
              />
            </AccessControl>
          </div>
        </div>
        <div className="table-responsive manage-competences tableSection">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={filterFields}
            handleRefresh={handleRefresh}
          />
          <div className="manageCompetencesHeight">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  {ShiftHeader.map((header) => (
                    <th key={header.alias}>
                      <span className="d-flex align-items-center">
                        {t(header.name)}
                        {header.issort && (
                          <SortAtomForManagePage
                            value={{ alias: header.alias }}
                            dispatch={dispatch}
                            history={history}
                            updatePageFilters={updatePageFilters}
                            handleRefresh={handleRefresh}
                          />
                        )}
                      </span>
                    </th>
                  ))}
                  <th style={{ width: "10%" }}>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: permissionType,
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                      override={userData.isSuperAdmin}
                    >
                      {t("Actions")}
                    </AccessControl>
                  </th>
                </tr>
              </thead>
              <tbody>
                {shift && shift.length > 0 ? (
                  shift.map((record: any, key: any) => {
                    return (
                      <tr key={key}>
                        <td className="align-middle text-break">
                          {record?.shift_name}
                        </td>
                        <td className="align-middle text-break">
                          <div
                            className={`${getStandardStatus(record.status)} d-inline-block rounded-circle status-icon`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={getStandardToolTip(record.status)}
                          ></div>
                        </td>
                        <td className="align-middle">
                          <TableActions
                            isAction={true}
                            handleEdit={handleEdit}
                            handleClone={handleClone}
                            {...(record?.status) ? { handleArchive: handleDelete } : { handleUnArchive: handleUnArchive }}
                            permission={permissionType}
                            value={record}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={16} className="text-center">
                      {t("No records found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            className="pagination d-block"
            style={{ paddingTop: "0.25vw" }}
          >
            <PaginationWithPerPage
              handleRefresh={handleRefresh}
              dispatch={dispatch}
              history={history}
              pageData={pageData}
            />
          </div>
        </div>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t(deleteID.type == 'archive' ? "Archive confirmation" : "Unarchive confirmation")}
        body={t(deleteID.type == 'archive' ? "Are you sure want to archive?" : "Are you sure want to unarchive?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};
export default ManageShiftsPage;
