import { Option } from "utils/TypeAnnotations";
import {
  EUROPEAN_CITIZEN,
  GENERAL,
  UPDATE_EXPERIENCE_FIELD,
  UPDATE_FIELD,
} from "../annotations/CandidateConstants";
import { studies, workExperience } from "../annotations/CandidateInterface";
import axios from "axios";

export interface Suggestion {
  label: string;
  value: string;
}

export function formatDate(date: Date | null): string {
  // Ensure date is a valid Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return "";
  }
  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = String(date.getFullYear()); // Full 4-digit year

  // Format as dd/mm/yyyy
  return `${day}/${month}/${year}`;
}

export const emergencyContactInitialData = {
  id: null,
  emergencyContactName: "",
  emergencyContactEmail: "",
  emergencyContactPhoneNumber: "",
};

export const studiesInitialState: studies = {
  id: null,
  educationLevel: null,
  diploma: null,
  fieldOfStudy: "",
  school: "",
  from: null,
  to: null,
  completed: 0,
  uploadCertificate: {
    id: null,
    accessUrl: "", // Initialize with an empty string
    fileName: "", // Initialize with an empty string
  },
  info: "",
};

export const workExperienceInitialState: workExperience = {
  id: null,
  experience: 0,
  // experienceInYears: null,
  designation: "",
  jobCategory: "",
  function: "",
  employer: "",
  jobDescription: "",
  reasonForDeparture: "",
  from: null,
  to: null,
  reasonForLeaving: "",
  reference: 0,
  companyId: "",
  companyName: "",
  name: "",
  email: "",
  phoneNumber: "",
  referenceInfo: "",
};

export const documentInitialState = {
  id: null,
  editId: null,
  fileUpload: {
    id: null,
    accessUrl: "", // Initialize with an empty string
    fileName: "", // Initialize with an empty string
  },
  fileName: "",
  fileExtension: "",
  category: null,
  validFrom: null,
  validUntil: null,
};

export const resetSSNRelatedFields = (dispatch: any) => {
  const fieldsToUpdate = [
    { field: "dob", value: null },
    { field: "age", value: "" },
    { field: "gender", value: null },
  ];

  fieldsToUpdate.forEach(({ field, value }) => {
    dispatch({
      type: UPDATE_FIELD,
      tab: GENERAL,
      field: field,
      value: value,
    });
  });
};

export const YesOrNoOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

export const genderOptions: Option[] = [
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
];

export const workExperienceRelatedFields = (dispatch: any, index: number) => {
  const fieldsToUpdate = [
    { field: "experience", value: 0 },
    // { field: "experienceInYears", value: null },
    { field: "designation", value: "" },
    { field: "jobCategory", value: "" },
    { field: "function", value: "" },
    { field: "employer", value: "" },
    { field: "jobDescription", value: "" },
    { field: "from", value: null },
    { field: "to", value: null },
    { field: "reasonForLeaving", value: "" },
    { field: "reference", value: 0 },
    { field: "companyId", value: "" },
    { field: "companyName", value: "" },
    // { field: "externalCompany", value: "" },
    { field: "firstName", value: "" },
    { field: "lastName", value: "" },
    { field: "email", value: "" },
    { field: "phoneNumber", value: "" },
    { field: "referenceInfo", value: "" },
  ];

  fieldsToUpdate.forEach(({ field, value }) => {
    dispatch({
      type: UPDATE_EXPERIENCE_FIELD,
      field: field,
      value: value,
      index: index,
    });
  });
};

export const refernceRelatedFields = (dispatch: any, index: number) => {
  const fieldsToUpdate = [
    { field: "reference", value: 0 },
    // { field: "company", value: null },
    // { field: "externalCompany", value: "" },
    { field: "firstName", value: "" },
    { field: "lastName", value: "" },
    { field: "email", value: "" },
    { field: "phoneNumber", value: "" },
    { field: "referenceInfo", value: "" },
  ];

  fieldsToUpdate.forEach(({ field, value }) => {
    dispatch({
      type: UPDATE_EXPERIENCE_FIELD,
      field: field,
      value: value,
      index: index,
    });
  });
};

export const resetEuropeanCitizenRelatedFields = (dispatch: any) => {
  const fieldsToUpdate = [
    { field: "isEuropeanCitizen", value: 0 },
    { field: "workPermit", value: "" },
    { field: "from", value: null },
    { field: "to", value: null },
  ];

  fieldsToUpdate.forEach(({ field, value }) => {
    dispatch({
      type: UPDATE_FIELD,
      tab: EUROPEAN_CITIZEN,
      field: field,
      value: value,
    });
  });
};

// Utility function to extract values from an array of options
export const extractIds = (array: any[]): any[] =>
  Array.isArray(array) ? array.map((option: any) => option?.value) : [];

// common functions
// const dispatchAndNotifySingleField = (
//   fieldName: string,
//   errorMessage: string
// ) => {
//   dispatch({
//     type: "UPDATE_FIELD_ERROR",
//     fieldName: fieldName,
//     error: t(errorMessage),
//   });
//   CustomNotify({ type: "error", message: t(errorMessage) });
// };

export const CandidateFormStatus = {
  0: "Drafted",
  1: "Submitted",
};

export const CandidateStatus = {
  0: "Not screened",
  1: "Selected",
  2: "Wage signed",
};

export const recruitmentStatus = [
  {
    value: 1,
    label: "Never contact again",
  },
  {
    value: 2,
    label: "Not to be held again",
  },
  {
    value: 3,
    label: "Positive",
  },
  {
    value: 4,
    label: "Reserve",
  },
  {
    value: 5,
    label: "Wanted",
  },
];

export const ApplicantRecruitmentStatus = [
  {
    value: 1,
    label: "New",
  },
  {
    value: 2,
    label: "In treatment",
  },
  {
    value: 3,
    label: "Completed",
  },
  {
    value: 4,
    label: "Everything",
  },
];

export const getCityAndCountry = async (zipcode: string, tab: string = "") => {
  try {
    if (zipcode.length === 4) {
      const response = await axios.get(
        `https://api.zippopotam.us/be/${zipcode}`
      );
      // Check if the response contains places data and is in the expected format
      if (
        response.data &&
        response.data.places &&
        response.data.places.length > 0
      ) {
        return {
          city: response.data.places?.[0]?.["place name"] || "Not found",
          country: response.data?.country || "Belgium",
        };
      } else {
        return { city: "Not found", country: "Belgium" };
      }
    } else {
      return { city: "", country: "" };
    }
  } catch (error) {
    return { city: "Error", country: "Error" };
  }
};
