import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";

import { M_MASTER_DATA } from "constants/Constants";
import { GET_TEMP_AGENCY } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import ManageTempAgencyPage from "./ManageTempAgencyPage";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

// Define the prop types for the component

// Functional component using TypeScript
const ManageTempAgency = () => {
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
    const dispatch = useDispatch();
    const userData = useSelector(selectAuth);
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        initialCall: true,
        refresh: true,
    });

    const getSearchData = () => {
        return {
            company: history?.filters?.company ?? "",
            businessUnit: history?.filters?.businessUnit ?? "",
            tempAgency: history?.filters?.tempAgency ?? "",
            status: history?.filters?.status ?? "",
            orderBy: history?.filters?.orderBy ?? "id",
            sortDirection: history?.filters?.sortDirection ?? "desc",
            loginUserId:userData?.userId,
            loginUserRole:userData?.role,
            loginUserBu:userData.businessUnit
        }
    };

    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
            search: getSearchData(),
        };
        getTempAgencyData(postData);
    }, [pageData?.initialCall, pageData?.refresh]);

    const [tempData, setTempData] = useState([]);


    const getTempAgencyData = async (postData: any) => {
        const response = await ApiCall.service(
            GET_TEMP_AGENCY,
            "POST",
            postData,
            false,
            M_MASTER_DATA
        );
        if (response?.status === 200) {
            setTempData(response?.data?.data);
            setPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords }));
        } else {
            console.log("error");
        }
    };

    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    return (
        <>
            <Suspense
                fallback={
                    <div className="text-center text-danger">{t("Loading")}...</div>
                }
            >
                <ManageTempAgencyPage
                    data={tempData}
                    setTempData={setTempData}
                    handleGetVacany={getTempAgencyData}
                    dispatch={dispatch}
                    history={history}
                    handleRefresh={handleRefresh}
                    pageData={pageData}
                />
            </Suspense>
        </>
    );
};

export default ManageTempAgency;