import { Option } from "utils/TypeAnnotations";
import { E164Number } from "libphonenumber-js";

export interface jobApplicationProps {
    userId: number | null;
    status: Option | null;
    recruitmentStatus: Option | null;
    dateOfApplication: string;
    channel: string;
    candidate: string;
    firstName: string;
    lastName: string;
    businessUnit: string;
    jobOffer: string;
    viewApplicationStatus: string;
    spontaneous: number;
    sponsered: number;
    telephone: E164Number | string | undefined;
    mobNumber: E164Number | string | undefined;
    email: string;
    street: string;
    number: string;
    zipCode: string;
    roles?: any;
    box: string;
    city: string;
    country: Option | null;
    motivation: string;
    externalRef: string;
    externalInfo: string;
    cvId: number | null;
    cvFileName: string,
    cvFilePath: string,
    vacancyName: string;
    jobDescription: string;
    offer: string;
    profile: string;
    functionId: number | null;
    domainId: number | null;
    candidateId: number | null;
    companyName: string;
    vacancyId: number | null;
}

export const applicationOptions: Option[] = [
    { value: 1, label: "New" },
    { value: 2, label: "In treatment" },
    { value: 3, label: "Completed" },
];

// Define the initial formData state
export const initialFormData: jobApplicationProps = {
    userId: null,
    status: null,
    recruitmentStatus: null,
    dateOfApplication: "",
    channel: "",
    candidate: "",
    firstName: "",
    lastName: "",
    businessUnit: "",
    jobOffer: "",
    viewApplicationStatus: "",
    spontaneous: 0,
    sponsered: 0,
    telephone: "" as E164Number,
    mobNumber: "" as E164Number,
    email: "",
    roles: null,
    street: "",
    number: "",
    city: "",
    country: null,
    zipCode: "",
    box: "",
    motivation: "",
    externalRef: "",
    externalInfo: "",
    vacancyName: "",
    jobDescription: " ",
    cvId: null,
    cvFileName: "",
    cvFilePath: "",
    offer: "",
    profile: "",
    functionId: null,
    domainId: null,
    candidateId: null,
    companyName: "",
    vacancyId: null
}

const channels = [
    { id: 1, name: 'AI Matchbox' },
    { id: 2, name: 'facebook-ads' },
    { id: 3, name: 'Flyer' },
    { id: 4, name: 'Google' },
    { id: 5, name: 'Indeed apply' },
    { id: 6, name: 'LinkedIn' },
    { id: 7, name: 'Mail' },
    { id: 8, name: 'Office' },
    { id: 9, name: 'Advertisement' },
    { id: 10, name: 'Robe' },
    { id: 11, name: 'SMS' },
    { id: 12, name: 'Vdab' },
    { id: 13, name: 'Website' },
    { id: 14, name: 'Whatsapp' }
];

export function findInflowChannel(channelName: string | null | undefined) {
    if (!channelName) {
        return null;
    }

    const lowerCaseChannelName = channelName.toLowerCase();

    const channel = channels.find(c => c.name.toLowerCase().startsWith(lowerCaseChannelName));

    if (!channel) {
        return null;
    }

    return channel.id;
}





