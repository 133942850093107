import { HOLIDAY_CONFIGS, MANAGE_EMPLOYEE_LEAVES, MANAGE_EMPLOYER_LEAVES, PATH_MANAGE_APPLICANTS, PATH_HR_PLANNING, PATH_SALES_PLANNING, PATH_MANAGE_TRANSLATIONS, MANAGE_CANDIDATES, PATH_HOTLIST, PATH_MANAGE_TIMEHSHEETS, PATH_WORK_PAY_BILL_ROLL_MANAGE, PATH_MANAGE_TEMP_AGENCY, PATH_DOCUMENT, PATH_CREATE_GENERAL_TEMP_AGENCY, PATH_TODO_MANAGE, PATH_MANAGE_INVOICE, MANAGE_CONTRACT_PROPOSAL, PATH_MANAGE_PAYMENTS, PATH_MANAGE_PARTENA, MANAGE_WAGE_PROPOSAL } from "constants/Paths";
import { IconData } from "services/IconData";

interface Tab {
  requiredPermissions: any;
  path: string;
  name: string;
  icon: any;
  circleColor: string;
}

export const getTiles = (userData: any): Tab[] => [
  { name: "Users", circleColor: "", icon: IconData.userIconNew, path: "/manage-users", requiredPermissions: [{ permission: "Users", read: true }] },
  { name: "Roles", circleColor: "", icon: IconData.RolesIcon, path: "/manage-roles", requiredPermissions: [{ permission: "Role", read: true }] },
  // { name: "Permissions", circleColor: "", icon: IconData.PermissionsRoundIcon, path: "/manage-permissions", requiredPermissions: [{ permission: "Permission", read: true }]},
  ...((userData.role?.includes("CANDIDATE") || userData.role?.includes("EMPLOYEE")) ? [] : [
    { name: "Medewerkers", circleColor: "", icon: IconData.CandidateIconNew, path: MANAGE_CANDIDATES, requiredPermissions: [{ permission: "Candidates", read: true }] },
  ]),
  { name: "Todo followup", circleColor: "", icon: IconData.TodoFollowupIcon, path: PATH_TODO_MANAGE, requiredPermissions: [{ permission: "Todo", read: true }] },
  { name: "Companies", circleColor: "", icon: IconData.CompaniesRoundIcon, path: "/manage-companies", requiredPermissions: [{ permission: "Company", read: true }] },
  { name: "Projects", circleColor: "", icon: IconData.ProjectsRoundIcon, path: "/projects", requiredPermissions: [{ permission: "Project", read: true }] },
  ...(userData.role?.includes("EMPLOYER") ? [] : [
    { name: "Vacancies", circleColor: "", icon: IconData.VacancyIcon, path: "/vacancies", requiredPermissions: [{ permission: "Vacancy", read: true }] },
  ]),
  { name: "Planning", circleColor: "", icon: (userData.role?.includes("HR")) ? IconData.HRPlanningRoundIcon : IconData.SalesPlanningRoundIcon, path: (userData.role?.includes("HR")) ? PATH_HR_PLANNING : PATH_SALES_PLANNING, requiredPermissions: [{ permission: (userData.role?.includes("HR")) ? "Planning" : "Sales planning", read: true }] },
  { name: "Timesheets", circleColor: "", icon: IconData.TimesheetRoundIcon, path: PATH_MANAGE_TIMEHSHEETS, requiredPermissions: [{ permission: "Timesheets", read: true }] },
  { name: "Payroll", circleColor: "", icon: IconData.PayrollIcon, path: PATH_WORK_PAY_BILL_ROLL_MANAGE, requiredPermissions: [{ permission: "PayRoll", read: true }] },
  // { name: "Partena", circleColor: "", icon: IconData.PartenaIcon, path: '/partena', requiredPermissions: [{ permission: "", read: true }] },
  { name: "Margin calculator", circleColor: "", icon: IconData.MarginCalculatorIcon, path: '/margin', requiredPermissions: [{ permission: "MarginCalculation", read: true }] },
  { name: "Fleet and material", circleColor: "", icon: IconData.FleetAndMaterial, path: "/fleetOverview/car", requiredPermissions: [{ permission: "FleetMaterials", read: true }] },
  {
    name: "Leaves",
    circleColor: "",
    icon: IconData.LeaveIconNew,
    path: (userData.role?.includes("HR") || userData.role?.includes("ADMIN") || userData.role?.includes("SALES"))
      ? MANAGE_EMPLOYER_LEAVES
      : MANAGE_EMPLOYEE_LEAVES,
    requiredPermissions: [{ permission: "Leaves", read: true }],
  },
  { name: "Application", circleColor: "", icon: IconData.JobApplicantsIcon, path: PATH_MANAGE_APPLICANTS, requiredPermissions: [{ permission: "Applicants", read: true }] },
  { name: "Temp agency", circleColor: "", icon: IconData.TempAgencyICon, path: `${PATH_MANAGE_TEMP_AGENCY}`, requiredPermissions: [{ permission: "Temp agency", read: true }] },
  { name: "Configuration and settings", circleColor: "", icon: IconData.ConfigAndSettings, path: "/config/settings", requiredPermissions: [{ permission: "Masterdata", read: true }] },
  // { name: "Hotlist", circleColor: "", icon: IconData.HotlistIconNew, path: PATH_HOTLIST, requiredPermissions: [{ permission: "Hotlist", read: true }]},
  // {name: "Screening", circleColor: "", icon: IconData.CompaniesRoundIcon, path: PATH_CANDIDATE_SCREENING, requiredPermissions: [{permission: "Screening", read: true}]},
  { name: "Documents", circleColor: "", icon: IconData.DocumentsIcon, path: `${PATH_DOCUMENT}`, requiredPermissions: [{ permission: "Document", read: true }] },
  //{ name: "Temp agency v1", circleColor: "", icon: IconData.TempAgencyICon, path: `${PATH_CREATE_GENERAL_TEMP_AGENCY}`, requiredPermissions: [{ permission: "Temp agency", read: true }] }
  { name: "Payments", circleColor: "", icon: IconData.InvoiceIcon, path: `${PATH_MANAGE_PAYMENTS}`, requiredPermissions: [{ permission: "Payments", read: true }] },
  { name: "Invoicing", circleColor: "", icon: IconData.InvoiceIcon, path: `${PATH_MANAGE_INVOICE}`, requiredPermissions: [{ permission: "Invoices", read: true }] },
  { name: "Partena", circleColor: "", icon: IconData.PartenaIcon, path: `${PATH_MANAGE_PARTENA}`, requiredPermissions: [{ permission: "Partena", read: true }] },];

export const sideBarItems = (userData: any): any => [
  { name: "Dashboard", path: "/dashboard", icon: IconData.DashBoardIcon, id: "dashboard" },
  // { name: "Candidates", path: "/manage-candidates", icon: IconData.EmployeeIcons, id: "employees", requiredPermissions: [{ permission: "Candidates", read: true }]},
  { name: "Users", path: "/manage-users", icon: IconData.UserWithShieldIcon, id: "users", requiredPermissions: [{ permission: "Users", read: true }] },
  { name: "Roles", icon: IconData.RolesIcon, path: "/manage-roles", requiredPermissions: [{ permission: "Role", read: true }] },
  ...((userData.role?.includes("CANDIDATE") || userData.role?.includes("EMPLOYEE")) ? [] : [
    { name: "Medewerkers", path: MANAGE_CANDIDATES, icon: IconData.CandidateIconNew, id: "candidates", requiredPermissions: [{ permission: "Candidates", read: true }] }
  ]),
  { name: "Companies", path: "/manage-companies", icon: IconData.CompaniesRoundIcon, id: "companies", requiredPermissions: [{ permission: "Company", read: true }] },
  {
    name: "Application", icon: (
      userData?.applicantsCount > 0 ? <>
        <span style={{
          position: 'relative',
          display: 'inline-block',
        }}>
          {IconData?.JobApplicantsIcon}
          <span style={{
            position: 'absolute',
            top: '-0.15vw',
            left: '1vw',
            width: '0.5vw',
            height: '0.5vw',
            borderRadius: '50%',
            backgroundColor: 'green',
          }}></span>
        </span></> : IconData.JobApplicantsIcon
    ), path: PATH_MANAGE_APPLICANTS, requiredPermissions: [{ permission: "Applicants", read: true }]
  },
  { name: "Vacancies", path: "/vacancies", icon: IconData.VacancyIcon, id: "vacancies", requiredPermissions: [{ permission: "Vacancy", read: true }] },
  { name: "Company contacts", path: "/manage-contact/person", icon: IconData.CompanyContactsIcon, id: "company-contacts", requiredPermissions: [{ permission: "Contact person", read: true }] },
  { name: "Projects", icon: IconData.ProjectsRoundIcon, path: "/projects", id: "projects", requiredPermissions: [{ permission: "Project", read: true }] },
  { name: "Timesheets", icon: IconData.TimesheetRoundIcon, path: PATH_MANAGE_TIMEHSHEETS, id: "timesheets", requiredPermissions: [{ permission: "Timesheets", read: true }] },
  { name: "Payroll", icon: IconData.PayrollIcon, path: PATH_WORK_PAY_BILL_ROLL_MANAGE, id: "payroll", requiredPermissions: [{ permission: "PayRoll", read: true }] },
  { name: "Margin calculator", icon: IconData.MarginCalculatorIcon, path: '/margin', id: "margin-calculator", requiredPermissions: [{ permission: "MarginCalculation", read: true }] },
  { name: "Temp agency", icon: IconData.TempAgencyICon, path: `${PATH_MANAGE_TEMP_AGENCY}`, id: "temp-agency", requiredPermissions: [{ permission: "Temp agency", read: true }] },
  { name: "Configuration and settings", icon: IconData.ConfigAndSettings, id: "configuration-settings", path: "/config/settings", requiredPermissions: [{ permission: "Masterdata", read: true }] },
  { name: "Documents", icon: IconData.DocumentsIcon, path: `${PATH_DOCUMENT}`, id: "documents", requiredPermissions: [{ permission: "Document", read: true }] },
  { name: "Todo followups", path: `${PATH_TODO_MANAGE}`, icon: IconData.TodoFollowupIcon, id: "todo-followups", requiredPermissions: [{ permission: "", read: true }] },
  { name: "Translations", path: PATH_MANAGE_TRANSLATIONS, icon: IconData.TranslationsIcon, id: "translation", requiredPermissions: [{ permission: "Translations", read: true }] },
  { name: "Invoicing", id: "invoicing", icon: IconData.InvoiceIcon, path: `${PATH_MANAGE_INVOICE}`, requiredPermissions: [{ permission: "Invoices", read: true }] },
  { name: "Budget", path: "/dashboard", icon: IconData.BudgetIcon, id: "budget", requiredPermissions: [{ permission: "Budget", read: true }] },
  { name: "Planning", path: (userData.role?.includes("HR")) ? PATH_HR_PLANNING : PATH_SALES_PLANNING, icon: (userData.role?.includes("HR")) ? IconData.HRPlanningRoundIcon : IconData.HRPlanningRoundIcon, id: "planning", requiredPermissions: [{ permission: (userData.role?.includes("HR")) ? "Planning" : "Sales planning", read: true }] },
  { name: "Leaves", path: "/manage/employer-leaves", icon: IconData.LeaveIconNew, id: "leaves", requiredPermissions: [{ permission: "Leaves", read: true }] },
  { name: "Matching", path: (userData.role?.includes("HR")) ? PATH_HR_PLANNING : PATH_SALES_PLANNING, icon: IconData.MatchingIcon, id: "matching", requiredPermissions: [{ permission: "Matching", read: true }] },
  { name: "Cars", path: "/fleetOverview/car", icon: IconData.CarsIcons, id: "cars", requiredPermissions: [{ permission: "Car", read: true }] },
  { name: "Material", path: "/fleetOverview/material", icon: IconData.MaterialIcon, id: "material", requiredPermissions: [{ permission: "Material", read: true }] },
  { name: "Clothes", path: "/fleetOverview/clothes", icon: IconData.ClothsIcon, id: "clothes", requiredPermissions: [{ permission: "Clothes", read: true }] },
  { name: "Supplier", path: "/fleetOverview/supplier", icon: IconData.SupplierIcon, id: "supplier", requiredPermissions: [{ permission: "Supplier", read: true }] },
  { name: "Purchase order", path: "/fleetOverview/purchaseOrder", icon: IconData.PurchaseOrderIcon, id: "purchase-order", requiredPermissions: [{ permission: "PurchaseOrder", read: true }] },
  { name: "Client", path: "/fleetOverview/client", icon: IconData.ClientsIcon, id: "client", requiredPermissions: [{ permission: "Client", read: true }] },
  { name: "Backoffice", path: "/dashboard", icon: IconData.BackOfficeIcon, id: "backoffice", requiredPermissions: [{ permission: "Backoffice", read: true }] },
  { name: "Accidents", path: "/dashboard", icon: IconData.AccidentsIcon, id: "accidents", requiredPermissions: [{ permission: "Accidents", read: true }] },
  { name: "Medical examination", path: "/dashboard", icon: IconData.MedicalEmaminationIcon, id: "medical-examination", requiredPermissions: [{ permission: "Medical examination", read: true }] },
  { name: "Work post visits", path: "/dashboard", icon: IconData.WorkPostVisitsIcon, id: "work-post-visits", requiredPermissions: [{ permission: "Work post visits", read: true }] },
];

export const rolesTilesData = (userData: any): any => [
  { id: 1, title: "Number of customer visits", path: "/manage-companies", component: IconData.CustomerVisitIcon, requiredPermissions: [{ permission: "Company", read: true }] },
  { id: 2, title: "New vacancies", path: "/vacancies", component: IconData.ProspectVisitIcon, requiredPermissions: [{ permission: "Vacancy", read: true }] },
  { id: 3, title: "Number of projects", path: "/projects", component: IconData.NumberOfProjectsIcon, requiredPermissions: [{ permission: "Project", read: true }] },
  { id: 6, title: "Medewerkers", path: MANAGE_CANDIDATES, menu: "dashboard", component: IconData.CandidateIconNew, requiredPermissions: [{ permission: "Candidates", read: true }] },
  ...((userData.role?.includes("SALES") || userData.role?.includes("HR")) ?
    [
      { id: 5, title: "Current projects", ath: "/dashboard", component: IconData.CurrentProjectsIcon, requiredPermissions: [{ permission: "Current projects", read: true }] },
      { id: 7, title: "Starters", path: "/dashboard", component: IconData.StartersIcon, requiredPermissions: [{ permission: "Starters", read: true }] },
      { id: 8, title: "Stoppers", path: "/dashboard", menu: "users", component: IconData.StoppersIcon, requiredPermissions: [{ permission: "Stoppers", read: true }] },
      { id: 9, title: "Total", path: "/dashboard", component: IconData.TotalIcon, requiredPermissions: [{ permission: "total", read: true }] },
      { id: 11, title: "ECW", path: "/dashboard", component: IconData.ECWIcon, requiredPermissions: [{ permission: "ECW", read: true }] },
      { id: 12, title: "Illness", path: "/dashboard", component: IconData.IllnessIcon, requiredPermissions: [{ permission: "Illness", read: true }] },
      { id: 13, title: "Other", path: "/dashboard", component: IconData.OthersIcon, requiredPermissions: [{ permission: "Other", read: true }] },
    ]
    : []),
  {
    id: 10,
    title: "Leaves", component: IconData.LeaveIconNew,
    path: (userData.role?.includes("HR") || userData.role?.includes("ADMIN") || userData.role?.includes("SALES"))
      ? MANAGE_EMPLOYER_LEAVES
      : MANAGE_EMPLOYEE_LEAVES,
    requiredPermissions: [{ permission: "Leaves", read: true }],
  },
  ...(
    userData.role?.includes("EMPLOYEE") ?
      [{ id: 16, title: "Contract proposal", component: IconData.ContractProposalIcon,   path: `${MANAGE_CONTRACT_PROPOSAL}/${userData.userId}`, requiredPermissions: [{ permission: "Contract proposal", read: true }] },
      { id: 17, title: "Wage proposal", component: IconData.ContractProposalIcon,  path: `${MANAGE_WAGE_PROPOSAL}/${userData.userId}`, requiredPermissions: [{ permission: "Wage proposal", read: true }] }
      ] : []
      
  ),
  { id: 14, title: "Planning", circleColor: "", component: (userData.role?.includes("HR")) ? IconData.HRPlanningRoundIcon : IconData.SalesPlanningRoundIcon, path: (userData.role?.includes("HR")) ? PATH_HR_PLANNING : PATH_SALES_PLANNING, requiredPermissions: [{ permission: (userData.role?.includes("HR")) ? "Planning" : "Sales planning", read: true }] },
  { id: 15, title: "Timesheets", circleColor: "", component: IconData.TimesheetRoundIcon, path: PATH_MANAGE_TIMEHSHEETS, requiredPermissions: [{ permission: "Timesheets", read: true }] },
  { id: 16, title: "Material", circleColor: "", component: IconData.MaterialIcon, path: "/fleetOverview/material", requiredPermissions: [{ permission: "Material", read: true }] },
];
