import { float } from "aws-sdk/clients/cloudfront";
import { ChangeEvent, Dispatch } from "react";
import { OptionProps } from "react-select";
import { AnyAction } from "redux";
import { Option } from "utils/TypeAnnotations";

export interface PartenaData {
  id: number;
  month: number;
  businessUnit: number;
  noOfEmployees: number;
  numberSent: number;
  quantityNotSent: number;
  createdBy: string;
  creationTime: string;
  status: string;
}

export const initialExportSearch = {
  month: "",
  businessUnit: "",
  numberOfEmployees: "",
  numberSent: "",
  quantityNotSent: "",
  createdBy: "",
};

// export interface BusinessUnitProps {
//   value: number;
//   label: string;
// }

export interface FormDataProps {
  month: string;
  year: number;
  businessUnits: OptionProps[];
  createdBy: number | null;
  name: string;
}

export const initialAddPartenaData = {
  year: new Date().getFullYear(),
  businessUnits: [],
  month: "",
  createdBy: null,
  name: "",
};

export interface CalculationSearchProps {
  month: string;
  businessUnit: string;
  createdBy: string;
  creationTime: string;
}

export interface EmployeeDataProps {
  userId: number | null;
  partenaId: number | null;
  name: string;
  isExported: boolean | null;
  exportedBy: string;
  exportedTime: string;
  exportedFile: string;
}

export interface AccomplishmentDataProps {
  performanceData: AccomplishData[];
  handleChange: (event: ChangeEvent<HTMLInputElement>, field?: string) => void;
  filtersData: {};
  dropdownData?: Option[];
  type: string;
  handleRefresh?: () => void;
  dispatch?: Dispatch<AnyAction>;
}
export interface AccomplishData {
  id: number | null;
  employeeName: string;
  date: string;
  department: number | null;
  type: string;
  wageCode: number | null;
  shiftCode: number | null;
  hours: float | null;
}

export const initialCalculationSearch = {
  month: "",
  businessUnit: "",
  createdBy: "",
  creationTime: "",
};

export const initialEmployeeData: EmployeeDataProps = {
  userId: null,
  partenaId: null,
  name: "",
  isExported: null,
  exportedBy: "",
  exportedTime: "",
  exportedFile: "",
};

export const initialAccomplishmentData = {
  id: null,
  employeeName: "",
  date: "",
  department: null,
  type: "",
  wageCode: null,
  shiftCode: null,
  hours: null,
};

export const initialAccomplishSearchData = {
  employeeName: "",
  date: "",
  department: "",
  all: undefined,
  wageCode: "",
};
