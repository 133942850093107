import SortAtomForManagePage from 'components/atoms/SortAtomForManagePage';
import PaginationWithPerPage from 'components/molecules/PaginationWithPerPage';
import FilterOrganism from 'components/organism/FilterOrganism';
import { M_MASTER_DATA } from 'constants/Constants';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_FOLLOWUP, MANAGE_TODO_DATA } from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';
import { selectPageHistory, updatePageFilters } from 'store/pageHistory/pageHistorySlice';
import { TodoHeader } from 'TableHeader';
import { t } from '../translation/Translation';
import EditIcon from 'static/images/EditIcon';
import { Link, useNavigate } from 'react-router-dom';
import { PATH_TODO_CREATE, PATH_TODO_VIEW } from 'constants/Paths';
import ViewIcon from 'static/images/ViewIcon';
import AccessControl from 'services/AccessControl';
import { selectAuth } from 'features/auth/AuthSlice';
import LinkTo from 'components/atoms/LinkTo';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Title from 'components/atoms/Title';
import CloseFile from 'static/images/CloseFile';
import ModalPopup from 'components/atoms/ModalPopup';
import CustomNotify from 'components/atoms/CustomNotify';
import CommonServices from 'services/CommonService';
import './css/follow-up-todo.css';
import InfoIcon from 'static/images/InfoIcon';
import { Spinner } from 'react-bootstrap';
import TextEllipsis from 'components/atoms/TextEllipsis';
export interface Todo {
  id?: number;
  todo_type: string;
  // assigned_by?: number;
  todo_status?: any;
  info?: string;
  business_unit?: any;
  todo_date?: string;
  candidate?: string;
  company?: string;
  project?: string;
  vacancy?: string;
  nextFollowupDate?: string;
  toBeFollowedBy?: string;
  followupStatus?: number;
}

const ManageTodos = () => {
  const userData = useSelector(selectAuth);
  const navigate = useNavigate();
  const [todo, setTodo] = useState<Todo[]>();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });
  const [dropdownData, setDropdownData] = useState({
    businessUnits: [],
  });
  const [loading, setLoading] = useState(false);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [followUpCloseId, setFollowUpCloseId] = useState<number | null>()
  const [showModal, setShowModal] = useState<boolean>(false);


  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE ?? 20),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
    };
    fetchTodos(postData);
  }, [pageData?.refresh, pageData?.initialCall]);

  // Function to get search data
  const getSearchData = () => {
    return {
      businessUnits: history?.filters?.businessUnits ?? [],
      startDate: CommonServices?.getTimezoneOffset(history?.filters?.startDate ?? null),
      endDate: CommonServices?.getTimezoneOffset(history?.filters?.endDate ?? null),
      // createdBy: history?.filters?.createdBy ?? "",
      todoType: history?.filters?.todoType ?? "",
      info: history?.filters?.info ?? "",
      todoStatus: history?.filters?.todoStatus ?? "",
      candidate: history?.filters?.candidate ?? "",
      company: history?.filters?.company ?? "",
      project: history?.filters?.project ?? "",
      vacancy: history?.filters?.vacancy ?? "",
      nextFollowupDate: CommonServices?.getTimezoneOffset(history?.filters?.nextFollowupDate ?? null),
      toBeFollowedBy: history?.filters?.toBeFollowedBy ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc"
    };
  };

  const fetchTodos = async (postData: any) => {
    setLoading(true);
    const response = await ApiCall.service(MANAGE_TODO_DATA, "POST", postData, false, M_MASTER_DATA);
    if (response?.status === 200) {
      if (pageData?.initialCall) {
        setDropdownData(response?.dropdownData);
      }
      setTodo(response?.data);
      setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords, initialCall: false }));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  // Filters
  const filters = [
    { name: 'businessUnits', fieldType: "multiSelect", options: dropdownData?.businessUnits, placeholder: "Business unit", filterType: 'search' },
    {
      filterType: "multiple",
      filters: [
        { name: 'startDate', fieldType: "date", endDate: history?.filters?.endDate, placeholder: "Start", filterType: 'search' },
        { name: 'endDate', fieldType: "date", startDate: history?.filters?.startDate, placeholder: "End", filterType: 'search' },
      ]
    },
    // { name: 'createdBy', fieldType: "text", placeholder: "Created by", filterType: 'search' },
    { name: 'todoType', fieldType: "text", placeholder: "Todo Type", filterType: 'search' },
    // { name: 'info', fieldType: "text", placeholder: "Info", filterType: 'search' },
    {
      filterType: "multiple",
      filters: [
        { name: 'candidate', fieldType: "text", placeholder: "User", filterType: 'search' },
        { name: 'company', fieldType: "text", placeholder: "Company", filterType: 'search' },
        { name: 'project', fieldType: "text", placeholder: "Project", filterType: 'search' },
      ]
    },
    { name: 'vacancy', fieldType: "text", placeholder: "Vacancy", filterType: 'search' },
    { name: 'nextFollowupDate', fieldType: "date", placeholder: "Next", filterType: 'search' },
    { name: 'toBeFollowedBy', fieldType: "text", placeholder: "To be followed by", filterType: 'search' },
    { name: 'todoStatus', fieldType: "text", placeholder: "Status", filterType: 'search' },
  ];

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const handleFollowUpClose = () => {
    if (followUpCloseId) {
      ApiCall.getService(
        `${CLOSE_FOLLOWUP}/${followUpCloseId}`,
        'GET',
        M_MASTER_DATA,
        true
      ).then((response) => {
        if (response.status === 200) {
          CustomNotify({ type: "success", message: response.message });
          handleRefresh();
        }
      }).catch((error) => {
        console.log(error);
      })
      setShowModal(false);
    }
  }

  const handleFollowUpCloseId = (id: number | undefined) => {
    setFollowUpCloseId(id);
    setShowModal(true);
  }

  const handleModalClose = () => {
    setShowModal(!showModal);
  }

  const handleAddFeedback = () => {
    navigate(`/todo/create/${followUpCloseId}?destination=/todo`);
  }

  return (
    <div className="manage-todos">
      <Title title={t("Todo followups")} />

      <div className="position-relative tableMainWrapper">
        <AccessControl
          requiredPermissions={[
            {
              permission: "Todo",
              create: true,
            },
          ]}
          override={userData.isSuperAdmin}
        >
          <div className="row">
            <div className="ManageCreateBtn">
              <LinkTo
                pagelink={PATH_TODO_CREATE}
                title={t("Create follow up todo")}
                icon={faPlus}
              />
            </div>
          </div>
        </AccessControl>
        <div className="table-responsive manageTodosFollowUps tableSection">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={filters}
            handleRefresh={handleRefresh}
          />
          <div className="TableHeight">
            <table className="table table-hover">
              {loading ? (
                <td
                  className="text-center text-info border-0 spinner-wrapper "
                  colSpan={10} rowSpan={4}
                >
                  <Spinner size="sm" className="me-2 spinnerStyle" />
                  {t("Loading")}
                </td>
              ) : (
                <>
                  <thead>
                    <tr className='TableHeader'>
                      {TodoHeader?.map((header: any) => (
                        <th key={header?.alias}>
                          <>
                            {t(header.name)}
                            {header?.issort && (
                              <SortAtomForManagePage
                                value={{ alias: header?.alias }}
                                dispatch={dispatch}
                                history={history}
                                updatePageFilters={updatePageFilters}
                                handleRefresh={handleRefresh}
                              />
                            )}
                          </>
                        </th>
                      ))}
                      <th>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {todo && todo?.length > 0 ? (
                      todo?.map((data: Todo) => (
                        <tr key={data.id} className="data-item">
                          <td>
                            <TextEllipsis title={Array.isArray(data?.business_unit) && data?.business_unit?.join(', ')}
                              label={Array.isArray(data?.business_unit) && data?.business_unit?.join(', ')} width="6vw" />
                          </td>
                          <td>{data?.todo_date}</td>
                          {/* <td>{data.assigned_by}</td> */}
                          <td>{data.todo_type}</td>
                          {/* <td>{data?.candidate}</td> */}
                          <td>
                            <TextEllipsis title={data?.candidate || data?.company || data?.project} label={data?.candidate || data?.company || data?.project}
                              width="12.5vw" />
                          </td>
                          <td>{Array.isArray(data?.vacancy) && data?.vacancy?.join(', ')}</td>
                          <td>{data?.nextFollowupDate}</td>
                          <td>
                            <TextEllipsis title={data?.toBeFollowedBy} label={data?.toBeFollowedBy}
                              width="12.5vw" />
                          </td>
                          <td>{data?.todo_status}</td>
                          <td>
                            <span className="table-action-btn cursor-pointer marginRightPoint2" onClick={() => navigate(`${PATH_TODO_CREATE}/${data.id}`)} title={t("Edit")}>
                              <EditIcon />
                            </span>
                            <span className="table-action-btn cursor-pointer marginRightPoint2" onClick={() => navigate(`${PATH_TODO_VIEW}/${data.id}`)} title={t("Edit")}>
                              <ViewIcon />
                            </span>
                            {data?.followupStatus !== 1 &&
                              <span
                                className="p-0 cursor-pointer table-action-btn marginRightPoint2"
                                title={t("Close")}
                                onClick={() => handleFollowUpCloseId(data?.id)}
                              >
                                <CloseFile />
                              </span>
                            }
                            <span className='table-action-btn'>
                              <div className="position-relative manageInfoIcon table-action-btn cursor-pointer">
                                <InfoIcon />
                                <span className="infoHelpText text-center">
                                  {t(`${data.info}`)}
                                </span>
                              </div>
                            </span>
                          </td>
                        </tr>
                      ))
                    ) :
                      (
                        <tr className="border">
                          <td
                            colSpan={10}
                            className="border-0 text-center py-3"
                          >
                            <span className="text-danger fw-bold">
                              {t("No records")}
                            </span>
                          </td>
                        </tr>
                      )

                    }
                  </tbody>


                </>

              )}
            </table>
          </div >
          <ModalPopup
            show={showModal}
            onHide={handleModalClose}
            title={t("Feedback")}
            body={t("Do you want to add feedback") + "?"}
            onCloseButtonClick={handleFollowUpClose}
            onConfirmButtonClick={handleAddFeedback}
            className="modal-lg"
            confirmTitle={t("Yes")}
            closeTitle={t("No")}
          />
          {/* Pagination */}
          <PaginationWithPerPage
            handleRefresh={handleRefresh}
            dispatch={dispatch}
            history={history}
            pageData={pageData}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageTodos;
