import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_CLOTHES, PATH_EDIT_CLOTHES } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  CLOTHES_DELETE,
  FLEET_EMP_DELETE,
  FLEET_EMP_CREATE,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { fetchOptions } from "../utils/FetchOptions";
import { getClothesToolStatus, getClothesToolTip, getStatusColor, getTooltipMessage } from "services/util/UtilService";
import FleetEmployeeModal from "../FleetEmployeeModal";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import FilterOrganism from "components/organism/FilterOrganism";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { ClothsTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { formatDate } from "../utils/Utils";

interface ManageClothesProps {
  data?: any;
  fleetProps?: any;
  setMaterialData: any;
  handleGetVacany: any;
  dispatch: any;
  history: any;
  pageData: any;
  handleRefresh: () => void;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManageClothes: React.FC<ManageClothesProps> = ({
  data,
  fleetProps,
  setMaterialData,
  dispatch,
  history,
  pageData,
  handleRefresh,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const { id: routeId, userType: userType } = useParams();
  const [showFleetEmployeeModal, setShowFleetEmployeeModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    businessUnit: [],
    status: [],
    companies: [],
  });

  const [fleetData, setFleetData] = useState({
    fromDate: "",
    toDate: "",
    fleetStatus: '',
    type: "clothes",
    userId: routeId,
    userType,
    fleetId: "",
    status: false,
    count: 1,
    remainingUnits: "",
    purchaseDate: "",
  });

  const navigate = useNavigate();
  /** Adding fleet material to employee */
  const handleAddFleetEmp = async () => {
    if (validationFunction()) {
      try {
        const response = await ApiCall.service(
          FLEET_EMP_CREATE,
          "POST",
          fleetData,
          true,
          M_MASTER_DATA
        );
        if (response.status == 200) {
          CustomNotify({ type: "success", message: response.message });
          setShowFleetEmployeeModal(false);
          handleRefresh();
        } else {
          CustomNotify({ type: "warning", message: response.message });
        }
      } catch (error) {
        console.log("Error");
      }
    }
  };

  const [error, setErrors] = useState({
    fromDate: "",
    toDate: "",
    count: "",
    fleetStatus: "",
  });

  const validationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationRules: any = {
      fromDate: [validateRequired],
      toDate: [validateRequired],
      count: [validateRequired],
      fleetStatus: [validateSelectField],
    };
    const validationErrors: any = validateForm(
      { ...fleetData, [name]: value },
      isSingleFieldValidation ? validationRules[name] : validationRules
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: ty, ...remains } = prevErrors;
        return { ...remains };
      });
    } else {
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return false;
      } else {
        const fromDate = new Date(fleetData.fromDate);
        const lastDate = new Date(fleetData.toDate);
        const purchaseDate = new Date(fleetData.purchaseDate);
        if (fromDate > lastDate) {
          setErrors((prevState: any) => ({
            ...prevState,
            fromDate: "From date should be on or before to date",
          }));
          return false;
        }
        if (fromDate < purchaseDate) {
          setErrors((prevState: any) => ({
            ...prevState,
            fromDate: "From date should be on or after purchase date",
          }));
          return false;
        }
        return true;
      }
    }
  };

  const handleFleetEmpModalClose = () => {
    setFleetData({
      fromDate: "",
      toDate: "",
      fleetStatus: '',
      type: "clothes",
      userId: routeId,
      fleetId: "",
      status: false,
      count: 0,
      remainingUnits: "",
      purchaseDate: "",
      userType,
    });
    setShowFleetEmployeeModal(false);
  };

  const handleRemoveData = async () => {
    try {
      const response = await ApiCall.service(
        FLEET_EMP_DELETE,
        "POST",
        { fleetId: fleetData.fleetId, userId: routeId, type: "clothes" },
        true,
        M_MASTER_DATA
      );
      if (response.status == 200) {
        CustomNotify({ type: "success", message: response?.message });
        handleRefresh();
        setShowFleetEmployeeModal(false);
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
    } catch (error) {
      console.log("Error");
    }
  };

  const handleLink = (id: any) => {
    const filteredData = data.filter((eachItem: any) => eachItem.id == id);
    if (filteredData.length > 0) {
      const { fromDate, toDate, count, remainingUnits, purchaseDate, fleetStatus } =
        filteredData[0];
      setFleetData({
        fromDate: fromDate !== null ? formatDate(fromDate) : '',
        toDate: toDate !== null ? formatDate(toDate) : "",
        fleetStatus: fleetStatus,
        type: "clothes",
        userId: routeId,
        fleetId: id,
        status: true,
        count: count,
        remainingUnits,
        purchaseDate,
        userType,
      });
    }
    setShowFleetEmployeeModal(true);
  };

  const handleRemove = (id: any) => {
    const filteredData = data.filter((eachItem: any) => eachItem.id == id);
    if (filteredData.length > 0) {
      const { fromDate, toDate, count, remainingUnits, purchaseDate, fleetStatus } =
        filteredData[0];
      setFleetData({
        fromDate: fromDate,
        toDate: toDate,
        fleetStatus: fleetStatus,
        type: "clothes",
        userId: routeId,
        fleetId: id,
        status: true,
        count: count,
        remainingUnits,
        purchaseDate,
        userType,
      });
    } else {
      setFleetData((prevState: any) => ({ ...prevState, fleetId: id }));
    }
    setShowFleetEmployeeModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);

    if (id) {
      let postData = {
        id: id,
        type: 'archive',
      };
      const response = await unArchiveOrArchiveFunction(postData);

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState,
          businessUnit: filterData.data.businessUnit,
          status: [
            { value: 1, label: 'Archieved' },
            { value: 2, label: 'Active' }
          ],
          companies: filterData?.data?.clothesCompany,
        }));
      } else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_CLOTHES}/${id}`);
  };

  const handleUnarchive = async (id: number) => {
    if (id) {
      let postData = {
        id: id,
        type: 'unarchive'
      }
      const response = await unArchiveOrArchiveFunction(postData);
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  }

  const unArchiveOrArchiveFunction = async (postData: any) => {
    try {
      const response = await ApiCall.service(
        CLOTHES_DELETE,
        "POST",
        postData,
        true,
        M_MASTER_DATA
      );
      return response;
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleDelete = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);
  const filters = [
    { name: 'businessUnits', fieldType: "multiSelect", options: filterOptions?.businessUnit, placeholder: "Business unit", filterType: 'search' },
    { name: 'ownerCompany', fieldType: "text", placeholder: "Company", filterType: 'search' },
    { name: 'brand', fieldType: "text", placeholder: "Brand", filterType: 'search' },
    { name: 'model', fieldType: "text", placeholder: "Model", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: filterOptions?.status, placeholder: "Status", filterType: 'search' },
  ];
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Clothes",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        {fleetProps !== "fleet" && (
          <div className="search-bar">
            {/* <TitleAtom title={t("Clothes")} /> */}
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filters}
              class={{
                rowClass: 'd-grid manageCarSearchCol manageClothesSearchCol',
                searchBtnClass: 'col-2 ms-auto'
              }}
              handleRefresh={handleRefresh}
            />
          </div>
        )}

        <div className="position-relative tableMainWrapper">
          {fleetProps !== "fleet" && (
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Clothes",
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <Link to={PATH_CREATE_CLOTHES} className="form-button">
                    + {"Create clothes"}
                  </Link>
                </div>
              </div>
            </AccessControl>
          )}
          <div className="tableSection table-responsive">
            <div className={`${fleetProps !== "fleet" ? "manageClothesHeight" : "manageCarHeightWIthoutSearch"}`}>
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    {ClothsTableHeader?.map((header) => (
                      <th key={header.alias}>
                        <>
                          {t(header.name)}
                          {header.issort && (
                            <SortAtomForManagePage
                              value={{ alias: header.alias }}
                              dispatch={dispatch}
                              history={history}
                              updatePageFilters={updatePageFilters}
                              handleRefresh={handleRefresh}
                            />
                          )}
                        </>
                      </th>
                    ))}
                    <th style={{ width: "15%" }}>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Clothes",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Actions")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record: any, key: any) => {
                      return (
                        <tr key={key}>
                          <td className="align-middle">
                            {record.businessUnits}
                          </td>
                          <td className="align-middle">
                            {record.ownerCompany}
                          </td>
                          <td className="align-middle">{record?.brand}</td>
                          <td className="align-middle">{record?.model}</td>
                          <td className="align-middle">
                            <div
                              className={getClothesToolStatus(record?.status)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getClothesToolTip(record?.status)}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              {...(fleetProps !== "fleet"
                                ? {
                                  handleEdit: handleEdit,
                                  ...(record.status == '1' ? { handleUnArchive: handleUnarchive } : { handleArchive: handleDelete, })
                                } //handleSendMaterialMail: () => { }
                                : record.isLinked
                                  ? { handleRemove: handleRemove }
                                  : { handleAddFleet: handleLink })}
                              value={record.id}
                              permission={"Clothes"}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              style={{ paddingTop: "0.25vw" }}
            >
              <PaginationWithPerPage
                handleRefresh={handleRefresh}
                dispatch={dispatch}
                history={history}
                pageData={pageData}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />

        {showFleetEmployeeModal && (
          <FleetEmployeeModal
            handleClose={handleFleetEmpModalClose}
            data={fleetData}
            errors={error}
            setFleetData={setFleetData}
            handleSave={handleAddFleetEmp}
            handleRemove={handleRemoveData}
            validationFn={validationFunction}
          />
        )}
      </>
    </AccessControl>
  );
};
export default ManageClothes;
