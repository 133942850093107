const CommonServices = {
    capitalizeLabel: function (str: string): string {
        let convertedstr = '';
        convertedstr = str.replace(/\d+$/, '');
        convertedstr = convertedstr.charAt(0).toUpperCase() + convertedstr.slice(1);
        convertedstr = convertedstr.replace(/_/g, " ");
        return convertedstr;
    },
    convertIntoModelName: function (str: string): string {
        const words = str.split('_');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join('');
    },
    stringFormat: function (str: string): string {
        let convertedstr = str.replaceAll("_", " ");
        convertedstr = convertedstr.charAt(0).toUpperCase() + convertedstr.slice(1);
        return convertedstr;
    },
    convertToISODate: (date: string | Date | null): string | null => {
        if (!date) return null;
        let parsedDate;
        if (typeof date === 'string') {
            // Try to parse the date string
            parsedDate = new Date(date);
            if (isNaN(parsedDate?.getTime())) {
                return null;
            }
        } else if (date instanceof Date) {
            parsedDate = date;
        } else {
            return null;
        }
        // Adjust for timezone offset
        const timezoneOffset = parsedDate?.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(parsedDate?.getTime() - timezoneOffset);
        return adjustedDate?.toISOString();
    },
    checkPermissions: async (
        userPermissions: any,
        requiredPermissions: any,
        strict: any,
        actions: any,
        override?: any
    ) => {
        if (override === true) {
            return true;
        }
        if (requiredPermissions.length === 0) {
            return true; // No allowed permissions specified, grant access
        }
        if (strict) {
            // Strictly check all allowed permissions.
            for (const allowedPermission of requiredPermissions) {
            try {              
                const matchedPermissions = userPermissions?.filter(
                    (perm: any) =>
                        perm?.permission?.toLowerCase() ===
                        allowedPermission?.permission?.toLowerCase()
                );
                if (!matchedPermissions) {
                    // If the permission is not found in matchedPermissions, it's not satisfied.
                    return false;
                }
                const matchedTruePermissions = matchedPermissions.find((item: any) =>
                    Object.values(item).some((val) => val === true)
                );
                if (!matchedTruePermissions) {
                    // If the permission is not found in matchedPermissions, it's not satisfied.
                    return false;
                }
                // Check each specific permission (create, read, update, delete).
                if (allowedPermission.read && !matchedTruePermissions.read) {
                    return false;
                }
                if (allowedPermission.create && !matchedTruePermissions.create) {
                    return false;
                }
                if (allowedPermission.update && !matchedTruePermissions.update) {
                    return false;
                }
                if (allowedPermission.delete && !matchedTruePermissions.delete) {
                    return false;
                }
            } catch (error) {
                console.log(error);
            }  
            }
            return true;
        } else if (actions) {
            for (const allowedPermission of requiredPermissions) {
                try {
                const matchedPermissions = userPermissions?.filter(
                    (perm: any) =>
                        perm?.permission?.toLowerCase() ===
                        allowedPermission?.permission?.toLowerCase()
                );
                const isAnyPermissionTrue = Object.keys(allowedPermission).some((key: any) => {
                    return matchedPermissions.some((matched: any) =>
                        key != "permission" && matched.permission.toLowerCase() === allowedPermission.permission.toLowerCase() &&
                        allowedPermission[key] ==
                        matched[key]
                    );
                });
                return isAnyPermissionTrue
                }  catch (error) {
                    console.log(error);
                }
            }
        } else {
            let status;
            for (const allowedPermission of requiredPermissions) {
                const matchedPermissions = userPermissions?.filter(
                    (perm: any) =>
                        perm?.permission?.toLowerCase() ===
                        allowedPermission?.permission?.toLowerCase()
                );
                const matchedTruePermissions = matchedPermissions?.find((item: any) =>
                    Object.values(item).some((val) => val === true)
                );
                if (!matchedTruePermissions) {
                    // If the permission is not found in matchedPermission, it's not satisfied.
                    status = false;
                } else {
                    for (const key in matchedTruePermissions) {


                        if (matchedTruePermissions[key] === true) {
                            status = true;
                            return status
                        }
                    }
                }
            }
            return status
        }
    },
    getTimezoneOffset: (dateString: any) => { // To convert time according to zone
        if (dateString) {
            const date = new Date(dateString);
            const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
            const formattedDate = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, 10);
            return formattedDate;
        }
        return null;
    },
    getFormattedDate: (date: any) => {
        if (!date) return "";
        const parsedDate = new Date(date);
        return isNaN(parsedDate.getTime()) ? "" : parsedDate.toLocaleDateString("en-GB").split("/").join("-");
    },    
};
export default CommonServices;

