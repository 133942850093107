import axios from "axios";
import { NamedRouteObject, routes } from "routes/Routes";
import { PAGENAMES } from "store/pageHistory/ConstantPageNames";
import { AddressDetails } from "utils/AddAddressLoactionViaMap";
import { Option } from "utils/TypeAnnotations";

export const getObjectEntryByKey = (object: any, targetKey: any): any => {
  return Object.keys(object).filter((key) => key === targetKey);
};

/**
 * @param object
 *
 * @param ignoreKeys
 * The keys, that are to be ignored in format {myKey: "", myKey2: ""}
 *
 * @returns true
 * If object is empty ({})
 *
 * @retutns false
 * Otherwise
 */
export const isObjectEmpty = (
  object: Object,
  ignoreKeys: Object = {}
): boolean => {
  if (Object.keys(ignoreKeys).length > 0) {
    // let objectCopy = structuredClone(object);
    let objectKeys = Object.keys(object).filter((objKey) => {
      return !ignoreKeys.hasOwnProperty(objKey as string);
    });
    return objectKeys.length === 0;
  }
  return Object.keys(object).length === 0;
};

// export const getKeyByValue = (object: any, value: any): any => {
//     return Object.keys(object).find(key => object[key] === value);
// }

export const getStatusColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
    case "false":
    case false:
      return "danger";
    case "1":
    case 1:
    case "true":
    case true:
      return "success";
    case 2:
    case "2":
      return "orange";
    default:
      return "success";
  }
};

export const getStatusColorForClients = (data: any) => {
  switch (data) {
    case "0":
    case 0:
    case "false":
    case false:
      return "orange";
    case "1":
    case 1:
    case "true":
    case true:
      return "bg-secondary";
    case 2:
    case "2":
      return "success";
    case 3:
    case "3":
      return "danger";
    default:
      return "";
  }
};

export const getTempAgencyStatusColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
    case "false":
    case false:
      return "orange";
    case "1":
    case 1:
      return "success";
    case 2:
    case "2":
      return "danger";

    default:
      return "success";
  }
};

export const getTempAgencyToolTip = (data: any) => {
  switch (data) {
    case "0":
    case 0:
    case "false":
    case false:
      return "Draft";
    case "1":
    case 1:
      return "Active";
    case 2:
    case "2":
      return "In active";
    default:
      return "";
  }
};

export const getTempAgencyColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
    case "false":
    case false:
      return "danger";
    case "1":
    case 1:
      return "danger";
    case 2:
    case "2":
      return "orange";

    case 3:
    case "3":
      return "success";

    default:
      return "success";
  }
};
export const getAgreementStatusColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
    case "false":
    case false:
      return "danger";
    case "1":
    case 1:
    case "true":
    case true:
      return "success";
    default:
      return "success";
  }
};

export const getFleetStatus = (data: any) => {
  switch (data) {
    case "0":
    case 0:
      return "orange";
    case "1":
    case 1:
      return "bg-secondary";
    case "2":
    case 2:
      return "success";
    case "3":
    case 3:
      return "brown";
    case "4":
    case 4:
      return "bg-info";
    case "5":
    case 5:
      return "secondary";
    case 6:
    case "6":
      return "warning";
    case 7:
    case "7":
      return "danger";
    default:
      return "success";
  }
};

export const getFleetToolTip = (data: any) => {
  switch (data) {
    case "0":
    case 0:
      return "Draft";
    case "1":
    case 1:
      return "Archived";
    case "2":
    case 2:
      return "Open";
    case "3":
    case 3:
      return "Maintainance";
    case "4":
    case 4:
      return "Assigned";
    case "5":
    case 5:
      return "Reserved";
    case 6:
    case "6":
      return "Parking";
    case 7:
    case "7":
      return "Inactive";
    default:
      return "";
  }
};

export const getFleetOrderStatus = (data: any) => {
  switch (data) {
    case "1":
    case 1:
      return "orange";
    case 2:
    case "2":
      return "success";
    default:
      return "success";
  }
};

export const getFleetOrderToolTip = (data: any) => {
  switch (data) {
    case "1":
    case 1:
      return "Order placed";
    case "2":
    case 2:
      return "Received";
    default:
      return "";
  }
};

export const getTooltipMessage = (isActive: any) => {
  switch (isActive) {
    case "0":
      return "Drafted project";
    case "1":
    case "false":
    case false:
      return "Inactive project";
    case "2":
      return "Contract not signed";
    case "3":
    case "true":
    case true:
      return "Active project";
    default:
      return "Unknown status";
  }
};
export const getTempAgencyTooltipMessage = (isActive: any) => {
  switch (isActive) {
    case "0":
      return "Drafted temp agency";
    case "1":
    case 1:
    case "false":
    case false:
      return "Inactive temp agency";
    case "2":
    case 2:
      return "Registerd temp agency";
    case "3":
    case 3:
    case "true":
    case true:
      return "Active Temp agency";
    default:
      return "Unknown status";
  }
};
export const getAgreementTooltipMessage = (
  proposal_status: number,
  mail_send_client: number,
  mail_send_manager: number
) => {
  if (
    proposal_status === 0 &&
    mail_send_client === 0 &&
    mail_send_manager === 0
  ) {
    return "Draft proposal";
  }

  if (
    proposal_status === 1 &&
    mail_send_client === 1 &&
    mail_send_manager === 1
  ) {
    return "Proposal signed";
  }

  if (
    proposal_status === 1 &&
    mail_send_client === 0 &&
    mail_send_manager === 0
  ) {
    return "Proposal signed by client";
  }

  if (
    proposal_status === 0 &&
    mail_send_client === 1 &&
    mail_send_manager === 1
  ) {
    return "Proposal sent to client and manager, not signed";
  }

  if (
    proposal_status === 1 &&
    mail_send_client === 1 &&
    mail_send_manager === 0
  ) {
    return " Proposal sent to client, signed by client";
  }

  if (
    proposal_status === 1 &&
    mail_send_client === 0 &&
    mail_send_manager === 1
  ) {
    return "Proposal sent to manager, signed by manager";
  }

  if (
    proposal_status === 0 &&
    mail_send_client === 1 &&
    mail_send_manager === 0
  ) {
    return "Proposal sent to client, not signed";
  }

  if (
    proposal_status === 0 &&
    mail_send_client === 0 &&
    mail_send_manager === 1
  ) {
    return "Proposal sent to manager not signed";
  }

  return "Status unknown";
};

export const getProjectTooltipMessage = (isSign: any) => {
  switch (isSign) {
    case "0":
    case 0:
      return "Drafted project";
    case "1":
    case 1:
      return "Inactive project";
    case 2:
    case "2":
    case true:
    case "true":
      return "Active project";
    case 3:
    case "3":
    case false:
    case "false":
      return "Archived project";
    default:
      return "Unknown status";
  }
};

/**
 * user status
 *
 * 0 => pending
 * 1 => active
 * 2 => Inactive
 * 3 =>
 *
 */
export const getProjectStatusColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
      return "orange";
    case "1":
    case 1:
    case "false":
      return "danger";
    case 2:
    case "2":
    case true:
    case "true":
      return "success";
    case 3:
    case "3":
    case false:
    case "false":
      return "danger";
    default:
      return "success";
  }
};
export const getuserStatusColor = (data: any) => {
  switch (data) {
    case "0":
    case 0:
      return "orange";
    case "1":
    case 1:
    case "true":
      return "success";
    case 2:
    case "2":
    case false:
      return "danger";
    default:
      return "success";
  }
};

export const getEmployeeStatusColor = (data: any, employeeStatus: boolean) => {
  if (employeeStatus) {
    switch (data) {
      case 0:
        return "secondary";
      case 1:
      case "true":
        return "orange";
      case 2:
        return "primary";
      case 3:
        return "cyan";
      case 4:
        return "success";
      default:
        return "success";
    }
  } else {
    return "danger";
  }
};

export const getEmployeeTooltipMessageUser = (
  isActive: any,
  contractProposalFlow: boolean,
  employeeStatus: boolean
) => {
  if (employeeStatus) {
    switch (isActive) {
      case "0":
        return "Draft employee";
      case 1:
        return "Wage proposal not approved";
      case 2:
        if (contractProposalFlow) {
          return "Contract proposal approved";
        } else {
          return "Wage proposal approved";
        }
      case 3:
        return "Contract generated";
      case 4:
        return "Contract proposal flow done";
      default:
        return "Unknown status";
    }
  } else {
    return "Inactive employee";
  }
};

export const getTooltipMessageUser = (isActive: any) => {
  switch (isActive) {
    case "0":
      return "Employee not activated";
    case "1":
    case 1:
    case "true":
    case true:
      return "Active employee";
    case "2":
    case 2:
    case "false":
    case false:
      return "Inactive employee";

    default:
      return "Unknown status";
  }
};

export const getTooltipForClientAndSupplier = (isActive: any) => {
  switch (isActive) {
    case "0":
    case 0:
      return "Drafted";
    case "1":
    case 1:
    case "true":
    case true:
      return "Archive";
    case "2":
    case 2:
    case "false":
    case false:
      return "Active";
    case "3":
    case 3:
      return "In active";
    default:
      return "Unknown status";
  }
};

export const getClothesToolStatus = (status: any) => {
  switch (status) {
    case "1":
    case 1:
      return "bg-secondary";
    case "2":
    case 2:
      return "success";
    default:
      return;
  }
};

export const getClothesToolTip = (status: any) => {
  switch (status) {
    case "1":
    case 1:
      return "Archieved";
    case "2":
    case 2:
      return "Active";
    default:
      return;
  }
};

export const getMaterialToolTip = (status: any) => {
  switch (status) {
    case "0":
    case 0:
      return "Drafted";
    case "1":
    case 1:
      return "Archive";
    case "2":
    case 2:
      return "Open";
    case "3":
    case 3:
      return "Maintenance";
    case "4":
    case 4:
      return "Assigned";
    case "5":
    case 5:
      return "Reserved";
    default:
      return "Unknown status";
  }
};

export const getStandardStatus = (status: any) => {
  switch (status) {
    case "0":
    case 0:
    case false:
      return "danger";
    case "1":
    case 1:
    case true:
      return "success";
    default:
      return;
  }
}

export const getStandardToolTip = (status: any) => {
  switch (status) {
    case "0":
    case 0:
    case false:
      return "Archived";
    case "1":
    case 1:
    case true:
      return "Active";
    default:
      return;
  }
}

export const getFleetMaterialStatus = (status: any) => {
  switch (status) {
    case "0":
    case 0:
      return "orange";
    case "1":
    case 1:
      return "bg-secondary";
    case "2":
    case 2:
      return "success";
    case "3":
    case 3:
      return "brown";
    case "4":
    case 4:
      return "bg-info";
    case "5":
    case 5:
      return "secondary";
    case 6:
    case "6":
      return "warning";
    case 7:
    case "7":
      return "danger";
    default:
      return "success";
  }
};

// Function to map routes to their names
export const getPageName = (path: string): string => {
  const basePath = path.split("?")[0]; // Strip query parameters
  const rootRoute = routes.find((route) => route.name === "main");
  const findRouteInChildren = (
    children: NamedRouteObject[] = []
  ): NamedRouteObject | undefined =>
    children.find((route) => {
      const cleanPath = route.path?.replace(/\/:[^\/]+/g, ""); // Remove all dynamic params
      return cleanPath === basePath || basePath.startsWith(cleanPath + "/");
    });
  const matchingRoute = rootRoute?.children
    ? findRouteInChildren(rootRoute.children)
    : undefined;
  if (matchingRoute?.name === PAGENAMES?.FLEET_OVERVIEW) {
    return getDynamicValue(path);
  }
  return matchingRoute?.name || "";
};

const getDynamicValue = (path: string) => {
  let string = PAGENAMES?.MANAGE_CAR;
  if (path?.includes("material")) {
    string = PAGENAMES.MANAGE_MATERIAL;
  } else if (path?.includes("clothes")) {
    string = PAGENAMES.MANAGE_CLOTHS;
  } else if (path?.includes("supplier")) {
    string = PAGENAMES.MANAGE_SUPPLIER;
  } else if (path?.includes("client")) {
    string = PAGENAMES.MANAGE_CLIENT;
  } else if (path?.includes("purchaseOrder")) {
    string = PAGENAMES.MANAGE_PURCHASE_ORDER;
  } else if (path?.includes("locations")) {
    string = PAGENAMES.MANAGE_LOCATION;
  }
  return string;
};

export const handleBackClick = ({
  pageHistory,
  dispatch,
  navigate,
  addPageToHistory,
}: any) => {
  const history = pageHistory?.history?.filter(
    (item: any) => item?.route && item?.route !== ""
  );
  if (history?.length > 1) {
    const previousPage = history[history?.length - 2];
    dispatch(addPageToHistory({ isBackAction: true }));
    navigate(previousPage?.route);
  } else {
    navigate("/");
  }
};

export const convertArrayToOptions = (array: any[]) => {
  return array?.map((item: any, index: number) => {
    return { label: item, value: index.toString(), name: item };
  });
};

export const convertObjectToOptions = (candidateStatus: any) => {
  return Object.entries(candidateStatus).map(([key, value]) => ({
    label: value,
    value: key,
  }));
};

export const handleSort = async (props: any) => {
  props?.dispatch(
    props?.updatePageFilters({
      filters: {
        currentPage: "1",
        orderBy: props?.value?.alias ?? "id",
        sortDirection:
          props?.history?.filters?.orderBy !== props?.value?.alias ||
            props?.history?.filters?.sortDirection === "desc"
            ? "asc"
            : "desc",
      },
    })
  );
  props?.handleRefresh && (await props?.handleRefresh());
};

export const handleClear = async (props: any) => {
  props?.dispatch(props?.updatePageFilters({ reset: true }));
  props?.handleRefresh && props?.handleRefresh();
};

export const getCityAndCountry = async (zipcode: string) => {
  if (zipcode.length === 4) {
    try {
      const response = await axios.get(
        `https://api.zippopotam.us/be/${zipcode}`
      );

      // Check if the response contains places data and is in the expected format
      if (
        response.data &&
        response.data.places &&
        response.data.places.length > 0
      ) {
        return {
          city: response.data.places?.[0]?.["place name"] || "Not found",
          country: response.data?.country || "Belgium",
        };
      } else {
        return { city: "", country: "Belgium" };
      }
    } catch (error) {
      return { city: "", country: "" };
    }
  } else {
    return { city: "", country: "" };
  }
};

export const getCountryFormat = (data: any, name: string) => {
  const country = data
    ?.filter(
      (eachCountry: any) =>
        eachCountry.label.toLowerCase() == name.toLowerCase()
    )
    ?.map((item: any) => {
      return { value: item?.value, label: item?.name };
    });

  return country[0];
};

export const formatDateAndTime = (dateString: Date | null) => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month and pad with zero
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0"); // Get hours and pad with zero
  const minutes = String(date.getMinutes()).padStart(2, "0"); // Get minutes and pad with zero

  return `${day}/${month}/${year}, ${hours}:${minutes}`; // Return formatted date string
};

export const downloadFile = (fileName: string, accessUrl: string) => {
  fetch(accessUrl)
    ?.then((response) => response?.blob())
    ?.then((blob) => {
      const blobURL = URL?.createObjectURL(blob);
      const link = document?.createElement("a");
      link.href = blobURL;
      link.download = fileName;
      document?.body?.appendChild(link);
      link?.click();
      document?.body?.removeChild(link);
    })
    ?.catch((error) => console.error("Error downloading file:", error));
};
