import { GenerateRefreshToken } from "./RefreshTokenService";
import EncryptDecryptService from "./EncryptDecryptService";
import { M_IDENTITY_MANAGER } from "../constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
/*
 *Call to the API
 *@param urlendpoint=urlendpoint of the API
 *@param httpmethod=METHOD
 *@param data=data to the API
 *@returns response from the API
 */


async function getService(endpoint: string = "", method: string = "GET", microService = M_IDENTITY_MANAGER, loadingParam: boolean = false) {
  const externalElement = document.getElementById("overlay") as HTMLDivElement | null;
  if (externalElement && loadingParam) {
    externalElement.style.display = "flex";
  }
  let decrypteddata = [];
  let token = await GenerateRefreshToken();
  const authtoken = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}')?.token;
  let headers: any = {
    "Accept": "application/json",
    "X-API-KEY": token,
    "Content-Type": "application/json"
  };
  if (authtoken) {
    headers = {...headers, "Authorization": `Bearer ${authtoken}`}
  }
  try {
    const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
    const response: any = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        method: "GET",
        data: {},
      }),
    });
    //Handle 401 Unauthorized - Logout user
    if (response?.status === 401 && response?.statusCode === 'unauthenticated') {
      handleUnauthorized();
      return;
    }  
    const data = await response.json();
    decrypteddata = await JSON.parse(EncryptDecryptService.decryptData(data));
  } catch (error) {
    //
  }
  if (externalElement && loadingParam) {
    externalElement.style.display = "none";
  }
  return decrypteddata;
}

async function service(
  endpoint: string = "",
  method: string = "POST",
  dataObj: any = "",
  loadingParam: boolean = false,
  microService: string = M_IDENTITY_MANAGER, 
  entryptDecrypt: boolean = true
) {
  let decrypteddata = [];
  const externalElement = document.getElementById("overlay") as HTMLDivElement | null;
  if (externalElement && loadingParam) {
    externalElement.style.display = "flex";
  }
  try {
    let token = await GenerateRefreshToken();
    const authtoken = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}')?.token;
    let headers: any = {
      "Accept": "application/json",
      "X-API-KEY": token,
      "Content-Type": "application/json"
    };
    if (authtoken) {
      headers = {...headers, "Authorization": `Bearer ${authtoken}`}
    }
    const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;    
    const response: any = await fetch(url, {
      method: method,
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      referrer: "no-referrer",
      redirect: "follow",
      body: JSON.stringify({
        method: "POST",
        data: entryptDecrypt ? EncryptDecryptService.encryptData(JSON.stringify(dataObj)) : dataObj,
      }),
      headers: headers,
    });    
    const data = await response.json();
    decrypteddata = (response?.status != 401 && entryptDecrypt) ? await JSON?.parse(EncryptDecryptService?.decryptData(data)) : data;
    //Handle 401 Unauthorized - Logout user
    if (decrypteddata?.status === 401 && decrypteddata?.statusCode === 'unauthenticated') {
      handleUnauthorized();
      return;
    }
  } catch (error) {
    //
  }
  if (externalElement && loadingParam) {
    externalElement.style.display = "none";
  }
  return decrypteddata;
}

//Function to Handle Logout on 401 Unauthorized
function handleUnauthorized() {
  CustomNotify({ type: "error", message: "Not authenticated" });
  localStorage.clear(); // Clear Local Storage
  // store.dispatch(logoutUser()); // Dispatch Redux action to clear state
  window.location.href = "/login"; // Redirect to login page
}

export const ApiCall = {
  service,
  getService,
};
