import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { t } from "pages/microservices/masterData/translation/Translation";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { DOWNLOAD_VACANCY, VACANCY_OVERVIEW } from "routes/ApiEndpoints";
import BackButton from "components/atoms/BackButton";
import Button from "components/atoms/Button";
import VacancyDownload from "static/images/VacancyDownload";
interface ContractProposalData {
  iframeUrl: any;
  vancacyId: any;
  languageId: any;
}
const VacancyOverView: React.FC = () => {
  const { vacancyId } = useParams<{ vacancyId: string }>();
  const handleDownload = async (vacancyId: string | number) => {
    const response = await ApiCall.service(
      DOWNLOAD_VACANCY,
      "POST",
      { vacancyId },
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const path = response?.path;
      window.open(path, "_blank");
    }
  };
  return (
    <>
      <>
        <div className="d-flex overflow-auto flex-column">
          <div>
            {vacancyId && (
              <span
                onClick={() => handleDownload(vacancyId)}
                className="DownloadIconColor float-end border-0 cursor-pointer"
                title={t("Download")}
              >
                <VacancyDownload />
              </span>
            )}
          </div>
          <div
            className="flex-1 overflow-auto"
            style={{ height: "calc(100vh - 10vw)" }}
          >
            {vacancyId ? (
              <iframe
                src={`${process.env.REACT_APP_MASTER_DATA_URL}/vacancy/overview/${vacancyId}`}
                height="99%"
                width="100%"
              />
            ) : (
              <div>Loading Overview...</div>
            )}
          </div>
        </div>
      </>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginTop: "0.8vw" }}
      >
        <div className="w-25">
          <BackButton />
        </div>
      </div>
    </>
  );
};

export default VacancyOverView;
